export default {
  namespaced: true,
  state: {
    copiedRangeTimeoutId: null,
    confetti: false,
    fullPageLoading: false,
    pageLoading: true,
    btnLoading: false,
    forceEmailConfirmation: true,
    showBrickForm: false,
    prxPrint: '',
    showNps: false,
    npsScoreActivationsFrequency: null,
    npsScoreDaysFrequency: null,
    anyOtherWarning: 0,
    servicesIdsWithCountryCode: [],
    highDemandMinPrice: 0
  },
  mutations: {
    setCopiedRangeTimeoutId (state, id = null) {
      clearTimeout(state.copiedRangeTimeoutId)
      state.copiedRangeTimeoutId = id
    },
    setConfetti (state, val) {
      state.confetti = val
    },
    setFullPageLoading (state, val) {
      state.fullPageLoading = val
    },
    setPageLoading (state, val) {
      state.pageLoading = val
    },
    setBtnLoading (state, val) {
      state.btnLoading = val
    },
    setForceEmailConfirmation (state, val) {
      state.forceEmailConfirmation = val
    },
    setShowBrickForm (state, val) {
      state.showBrickForm = val
    },
    setPrxPrint: (state, val) => {
      state.prxPrint = val
    },
    setShowNps: (state, val) => {
      state.showNps = val
    },
    setNpsScoreActivationsFrequency: (state, val) => {
      state.npsScoreActivationsFrequency = val
    },
    setNpsScoreDaysFrequency: (state, val) => {
      state.npsScoreDaysFrequency = val
    },
    setAnyOtherWarning: (state, val) => {
      state.anyOtherWarning = val
    },
    setServicesIdsWithCountryCode: (state, val) => {
      if (val) {
        state.servicesIdsWithCountryCode = val.split(',')
      }
    },
    setHighDemandMinPrice: (state, val) => {
      state.highDemandMinPrice = val
    }
  }
}
