<template>
  <span v-html="message" v-linkified />
</template>

<script>
import linkify from 'vue-linkify'

export default {
  props: {
    message: {
      type: String,
      default: () => ''
    }
  },
  directives: {
    linkified: linkify
  }
}
</script>

<style lang="scss">
  a.linkified{
    color: #FA8231;
  }
</style>
