export default {
  namespaced: true,
  state: {
    paymentOnReview: false
  },
  mutations: {
    setPaymentOnReview (state, val) {
      state.paymentOnReview = val
    }
  }
}
