<template>
  <div class="nps-score">
    <div class="nps-score__items">
      <div
        v-for="item in 11"
        :key="`item${item}`"
        class="npsBtn"
      >
        <input :value="item - 1" :id="`item${item}`" type="radio" name="npsScore" aria-label="NPS button">
        <label :for="`item${item}`" @click="$emit('saveScore', item - 1)">
          <span class="npsBtn-text">
            {{ item - 1 }}
          </span>
        </label>
      </div>
    </div>
    <div class="nps-score__footer">
      <div>
        <img class="fingerDown" src="@/assets/icons/nps-finger-down.svg" alt="finger-down">
        {{ $t('text_640ebc83d294120056cf31f6') }}
      </div>
      <div>
        {{ $t('text_640ebc83d294120056cf31f7') }}
        <img class="fingerUp" src="@/assets/icons/nps-finger-up.svg" alt="finger-up">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['saveScore']
}
</script>

<style lang="scss" scoped>
  .nps-score {
    &__items {
      display: flex;
      margin: 24px 0;

      .npsBtn {
        margin-right: 15px;
        label {
          position: relative;
          border-radius: 50%;
          white-space: nowrap;
          box-sizing: border-box;
          text-align: center;
          width: 56px;
          height: 56px;
          border: 1px solid #14142B;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover{
            border: 1px solid #FA8231;
            background: #FA8231;
            span.npsBtn-text{
              color: #fff;
            }
          }

          span{
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #14142B;
          }
        }
        &:last-child {
          margin-right: 0;
        }

        input[type="radio"] {
          position: absolute;
          width: 1px;
          height: 1px;
          overflow: hidden;
          clip: rect(0 0 0 0);

          &:checked + label{
            border: 1px solid #FA8231;
            background: #FA8231;
            span{
              color: #fff;
            }
          }
        }
      }
    }

    &__footer{
      display: flex;
      justify-content: space-between;

      div{
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        display: flex;
        color: rgba(20, 20, 43, 0.6);

        .fingerDown{
          margin-right: 6px;
        }
        .fingerUp{
          margin-left: 6px;
        }
      }
    }
  }

  .rtl {
    .nps-score {
      &__items {
        .npsBtn {
          margin-left: 15px;
          margin-right: 0;

          &:last-child {
            margin-left: 0;
          }
        }
      }

      &__footer {
        display: flex;
        justify-content: space-between;

        div {
          .fingerDown {
            margin-left: 6px;
            margin-right: 0;
          }

          .fingerUp {
            margin-right: 6px;
            margin-left: 0;
          }
        }
      }
    }
  }
</style>
