<template>
  <component :is="layout" />
  <Alert />
  <AccountHoldModal />
  <PaymentReviewModal />
  <HelpModal />
  <FullPageLoader />
  <Nps />
</template>

<script>
import { useCrispChat } from '@/use/crispChat'
import { useTapfiliate } from '@/use/tapfiliate'
import { useLocale } from '@/use/locale'
import Alert from '@/components/Alert.vue'
import AccountHoldModal from '@/components/Modals/AccountHoldModal.vue'
import PaymentReviewModal from '@/components/Modals/PaymentReviewModal.vue'
import HelpModal from '@/components/Modals/HelpModal.vue'
import FullPageLoader from '@/components/Loaders/FullPageLoader.vue'
import Nps from '@/components/Nps/Index.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'

export default {
  components: {
    Alert,
    AccountHoldModal,
    PaymentReviewModal,
    HelpModal,
    FullPageLoader,
    Nps,
    MainLayout,
    AuthLayout,
    EmptyLayout
  },
  data: () => ({
    layout: 'MainLayout'
  }),
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.meta.layout) {
          this.layout = route.meta.layout
        }
      }
    }
  },
  setup () {
    const { initCrispChat } = useCrispChat()
    const { initTapfiliate } = useTapfiliate()
    const { setAdditionalLocaleParams } = useLocale()
    return { initCrispChat, initTapfiliate, setAdditionalLocaleParams }
  },
  mounted () {
    // Fix Safari iOS Menu Bar Conflicts with bottom aligned Buttons
    const userAgent = window.navigator.userAgent
    const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i)
    const webkit = !!userAgent.match(/WebKit/i)
    const isMobileSafari = iOS && webkit && !userAgent.match(/CriOS/i)
    if (isMobileSafari) {
      document.querySelector('html').classList.add('mobileSafariOverflowScrolling')
      document.querySelector('body').classList.add('mobileSafariOverflowScrolling')
    }

    this.setAdditionalLocaleParams()
    this.initCrispChat()
    this.initTapfiliate()
  }
}
</script>

<style lang="scss">
  @import "./assets/fonts/stylesheet.css";

   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Euclid Circular A';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  .container {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1296px;
  }

  .mobileSafariOverflowScrolling {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch !important;
  }

  .control {
    position: relative;

    &__error {
      padding-top: 16px;
      padding-left: 12px;
      color: #FA3131;
      font-size: 12px;
    }

    input {
      width: 100%;
      border: 1px solid #EFEFEF;
      border-radius: 15px;
      padding: 20px 20px 20px 40px;
      font-size: 14px;
      color: #848194;
      background: #FFF;

      &:focus {
        outline: none;
      }
    }
  }

  .rtl{
    .control {
      &__error {
        padding-right: 12px;
        padding-left: 0;
      }

      input {
        padding: 20px 40px 20px 20px;
      }
    }
  }

  /****** Order stepper ******/
  .stepper {
    &>.container {
      padding-top: 60px;
      padding-bottom: 140px;

      .title__main {
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: -0.2px;
        color: #14142B;
        margin: 16px 0;
        padding-bottom: 0;
      }
      .title__subtitle{
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: rgba(20, 20, 43, 0.6);
      }
    }

    &__buttons {
      position: relative;
    }

    &__search {
      position: relative;
    }
  }

  @media (max-width: 590px) {
    .stepper {
      &>.container {
        padding-top: 36px;
        padding-bottom: 120px;

        .title__main {
          font-size: 24px;
          line-height: 30px;
          margin: 4px 0 8px;
        }
        .title__subtitle{
          font-size: 14px;
          line-height: 24px;
          color: rgba(20, 20, 43, 0.6);
        }
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .stepper {
      & > .container {
        padding-top: 10px;
        padding-bottom: 140px;
      }

      &-control {
        padding: 15px 0 !important;

        &__buttons .btn {
          height: 50px !important;
        }
      }
    }
  }
  /****** END Order stepper ******/

  .form {
    margin-top: 40px;

    &__control {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__send {
      margin-top: 8px;
    }

    &__help-link {
      text-align: center;
      margin-top: 30px;
      font-size: 14px;
      line-height: 18px;
      color: #848194;
      opacity: 0.8;
      letter-spacing: -0.2px;

      a{
        color: #FA8231;
      }

      span {
        cursor: pointer;
        color: #FA8231;
        font-size: 16px;
      }
    }
    &__help-link-bold {
      a{
        font-weight: 500;
      }
    }
  }

  .services-button {
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 14px;

    img {
      margin-right: 4px;
    }

    span {
      position: relative;
      top: -4px;
    }
  }

  .rtl{
    .services-button {
      img {
        margin-left: 4px;
        margin-right: 0;
      }
    }
  }

  .help-link {
    margin-top: 60px;
    margin-bottom: 80px;
    text-align: center;

    a {
      position: relative;
      color: #fa8231;
      font-size: 14px;
      @media (max-width: 590px) {
        display: block;
      }

      &:before {
        content: url('./assets/icons/help-icon.svg');
        position: absolute;
        left: -25px;
      }
    }
  }

  .rtl{
    .help-link {
      a {
        &:before {
          right: -25px;
          left: auto;
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;
    &__container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 310px;
      padding: 48px 24px 24px;
      background: #FFFFFF;
      border-radius: 16px;
      text-align: center;

      .title{
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.2px;
        color: #14142B;
        margin: 8px 0;
      }
      .body{
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
        text-align: center;
        color: #727280;
        margin: 8px 0 0;
      }
      a, a:visited, a:active, a:hover, a:focus {
        color: #FA8231;
      }
    }
    &__button {
      margin-top: 32px;
      width: 100%;
    }

    &__buttons {
      display: flex;

      .modal__button {
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  .rtl{
    .modal {
      &__container {
        right: 50%;
        left: auto;
        transform: translate(50%, -50%);
      }
    }
  }

  @media (max-width: 590px) {
    .help-link {
      position: relative;
      margin-top: 18px;
      padding: 18px;
      border: 1px solid #FA8231;
      border-radius: 12px;
      font-size: 16px;
      text-align: left;
      padding-left: 44px;

      &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 14px;
        top: 21px;
        right: 21px;
        background: url('./assets/icons/help-arrow.svg');
      }
    }

    .rtl{
      .help-link {
        padding-right: 44px;
        text-align: right;

        &:after {
          left: 21px;
          right: auto;
          transform: rotate(180deg);
        }
      }
    }

    .services-button {
      &--hide-word {
        span {
          display: none;
        }
      }
    }

    .modal {
      &__container {
        min-width: 280px !important;
        padding: 24px !important;
      }

      &__buttons {
        .modal__button {
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }

  /* ServicesCountries radio buttons */
  .radio-buttons {
    margin: 0 auto;
    max-width: 536px;

    &__column {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      li {
        margin: 0 auto 16px;
        width: 448px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 590px) {
    .radio-buttons{
      max-width: 448px;

      &__column {
        margin-top: 25px;

        li {
          width: 100%;
        }
      }
    }
  }
  .radio-button {
    cursor: pointer;
    height: 72px;
    width: 100%;
    display: flex;
    padding: 20px 18px;
    border-radius: 12px;
    border: 1px solid rgba(25, 22, 50, 0.12);

    &__images {
      position: relative;
      margin-right: 8px;
    }

    &__images--pair{
      margin-left: 5px;
    }

    &__main-image {
      position: relative;
      width:32px;
      height: 32px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__pre-image {
      position: absolute;
      top: 0;
      left: -6px;
      z-index: 2;
      width: 15px;
      border: 1px solid #FFFFFF;
      border-radius: 2px;
    }

    &__text {
      color: #191632;
      display: flex;
      align-items: center;
      height: 64px;
      margin-top: -18px;
      width: 325px;
      overflow: hidden;
    }

    &__edit {
      width: 100%;
      text-align: right;
      margin-top: 7px;
      color: #FA8231;
      font-size: 14px;
    }

    &__toggle {
      margin-right: 0;
      margin-left: auto;
      margin-top: 7px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid #FA8231;

      &--active {
        width: 19px;
        height: 19px;
        border: none;
        background-image: url('./assets/icons/active.svg');
        background-size: cover;
      }
    }
  }

  @media (max-width: 590px) {
    .radio-button {
      height: 60px;
      padding: 18px;

      &__text {
        font-size: 14px;
        height: 56px;
        margin-top: -17px;
        width: 185px;
      }

      &__toggle {
        margin-top: 2px;
      }

      &__edit {
        margin-top: 4px;
      }

      &__images--pair{
        margin-left: 0;
      }

      &__images {
        width: 24px;
        height: 24px;
        margin-left: 7px;
        &-country{
          width: 28px;
          margin-left: 0;
          height: 22px;
        }

        .radio-button__main-image {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .radio-button {
      height: 60px;
      padding: 14px 18px;
    }
  }

  .noNumbers{
    .radio-button__images,
    .radio-button__text{
      opacity: 0.3;
    }
  }
  .orderCountryFlag{
    height: 24px !important;
    border-radius: 2px;
    @media (max-width: 590px) {
      width: 28px !important;
      height: 21px !important;
    }
  }
  /* END ServicesCountries radio buttons */

  /* Search */
  .search {
    position: relative;
    width: 536px;
    height: 64px;
    margin: 60px auto 32px;

    &__input {
      position: relative;

      &:before {
        position: absolute;
        z-index: 2;
        content: '';
        top: 20px;
        left: 20px;
        background-image: url('./assets/icons/search.svg');
        height: 24px;
        width: 24px;
      }
    }

    &__clear {
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        background: url('./assets/icons/search-close.svg');
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 2;
        top: 18px;
        right: 18px;
        height: 12px;
        width: 12px;
        padding: 8px;
      }
    }

    &__close {
      display: none;
    }

    input {
      width: 100%;
      padding: 22px 52px;
      background: #F8F8F8;
      border-radius: 15px;
      color: rgba(39, 46, 53, 0.7);
      border: none;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;

      &:focus {
        outline: transparent;
      }
    }
  }

  .rtl{
    .search{
      &__input {
        &:before {
          right: 20px;
          left: auto;
        }
      }
      &__clear {
        cursor: pointer;
        &:after {
          left: 18px;
          right: auto;
        }
      }
    }
  }

  @media (max-width: 590px) {
    .search {
      width: 100%;
      height: 58px;
      margin: 30px auto;

      &--open {
        height: 100vh;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 24px;
        background: #fff;
        z-index: 5;
      }

      &__input {
        &:before {
          top: 17px;
          left: 18px;
        }
      }

      &__clear {
        &:after {
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
          top: 14px;
          right: 10px;
          padding: 10px;
        }
      }

      &__close {
        margin-top: 40px;
        margin-bottom: 24px;
        display: block;
        cursor: pointer;
        padding-top: 12px;
        width: 48px;
        height: 48px;
        border: 1px solid #EAEAEA;
        text-align: center;
        border-radius: 50%;
      }

      input {
        padding: 20px 48px;
        font-size: 14px;
        line-height: 18px;
        color: rgba(39, 46, 53, 0.7);
      }
    }

    .rtl{
      .search{
        &__input {
          &:before {
            right: 18px;
            left: auto;
          }
        }

        &__clear {
          &:after {
            left: 10px;
            right: auto;
          }
        }
      }
    }
  }

  .search-items {
    position: absolute;
    top: 100%;
    max-height: 359px;
    overflow-y: auto;
    width: inherit;
    z-index: 3;
    background: #FFF;

    &__empty {
      padding-top: 5px;
      text-align: center;
    }
  }
  .search-placeholder{
    min-height: 300px;
  }

  @media (max-width: 590px) {
    .search-items {
      position: static;
      max-height: 430px;
    }
  }

  .search-item {
    cursor: pointer;
    display: flex;
    padding: 20px 0 19px;
    border-bottom: 1px solid rgba(25, 22, 50, 0.12);

    &:last-child {
      border-bottom: none;
    }

    &.noNumbers{
      .search-item__images,
      .search-item__text {
        opacity: 0.3;
      }
    }

    &__images {
      margin-right: 12px;
      position: relative;
      margin-left: 7px;
      height: 32px;
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
      }
    }

    &__text {
      padding-top: 5px;
    }
  }

  .rtl {
    .search-item {
      &__images {
        margin-left: 12px;
        margin-right: 7px;
      }
    }
  }

  @media (max-width: 590px) {
    .search-item {
      padding: 24px 0;

      &__images {
        margin-right: 8px;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
        }
      }

      &__text {
        padding-top: 3px;
        font-size: 14px;
      }
    }
  }

  .search-items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.08);
    border-radius: 6px;
    background-color: #F5F5F5;
  }

  .search-items::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  .search-items::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(103, 103, 103, 0.1);
    background-color: #d1d1d1;
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .search {
      margin: 30px auto 32px;
      &-item {
        padding: 13px 0 12px;
      }
      &-items {
        max-height: 280px;
      }
    }
  }
  /* END Search */

  .grecaptcha-badge {
    visibility: hidden;
  }

  .content-page {
    padding-top: 75px;
    max-width: 650px;
    padding-bottom: 20px;

    h1 {
      letter-spacing: -0.2px;
      color: #14142B;
      font-size: 36px;
      font-weight: 600;
      padding-bottom: 30px;
    }

    h2, h3 {
      font-size: 18px;
      color: rgba(20, 20, 43, 0.6);
      font-weight: bold;
      padding: 15px 0 30px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
      padding-bottom: 20px;
    }

    ul,
    ol{
      margin-left: 20px;
      li {
        font-size: 18px;
        line-height: 28px;
        color: rgba(20, 20, 43, 0.6);
        padding-bottom: 20px;
        list-style: initial;
        padding-left: 8px;
      }
    }
    ol{
      li{
        list-style: decimal;
      }
    }
  }

  @media (max-width: 590px) {
    .content-page {
      padding-top: 15px;
      padding-bottom: 20px;

      h1 {
        font-size: 28px;
        padding-bottom: 20px;
      }

      h2, h3 {
        font-size: 18px;
        padding-bottom: 20px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 15px;
      }

      ul,
      ol{
        li {
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 15px;
          padding-left: 5px;
        }
      }
    }
  }

  .input__clear {
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      background: #fff url('./assets/icons/search-close.svg');
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 2;
      top: 16px;
      right: 10px;
      height: 12px;
      width: 12px;
      padding: 8px;
      border-radius: 50%;
    }
  }

  .rtl{
    .input__clear {
      &:after {
        left: 10px;
        right: auto;
      }
    }
  }

  /* Side menu */
  .side-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 23;

    &__side {
      overflow-y:scroll;
      overflow-x:hidden;
      position: fixed;
      padding: 40px 30px 65px 80px;
      right: 0;
      top: 0;
      bottom: 0;
      width: 400px;
      background: #FFF;
      display: flex;
      flex-direction: column;
    }

    &__close {
      top: 40px;
      left: 40px;
      position: absolute;
      cursor: pointer;
      padding-top: 12px;
      width: 48px;
      height: 48px;
      border: 1px solid #EAEAEA;
      text-align: center;
      border-radius: 50%;
    }

    &__bottom {
      width: 100%;
      position: absolute;
      bottom: 65px;
      left: 80px;
    }

    &__change-ep {
      li {
        padding-bottom: 14px;
      }
      a {
        font-size: 15px;
        line-height: 19px;
        color: #14142B;
        display: flex;
        align-items: center;

        img{
          margin-left: -3px;
          margin-right: 10px;
        }
      }
    }

    &__info-links {
      padding-top: 32px;
      padding-bottom: 32px;
      flex: 1;

      li {
        padding-bottom: 8px;
        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        display: block;
        padding: 5px 0;
        font-size: 15px;
        line-height: 19px;
        color: #14142B;
      }
    }

    &__in-out {
      padding-top: 16px;
      border-top: 1px solid rgba(25, 22, 50, 0.12);
      a {
        display: flex;
        align-items: center;
        img {
          margin-left: -3px;
          margin-right: 10px;
        }

        span {
          position: relative;
          color: #14142B;
          font-size: 15px;
          line-height: 19px;
        }
      }
    }
  }

  .rtl {
    .side-nav {
      &__side {
        left: 0;
        right: auto;
        padding: 40px 80px 65px 30px;
      }
      &__close {
        left: auto;
        right: 40px;
        img{
          transform: rotate(180deg);
        }
      }
      &__in-out {
        a {
          img {
            margin-right: -3px;
            margin-left: 10px;

            &.loginBtn, &.logoutBtn{
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .menu {
    padding-top: 90px;

    li {
      &:last-child {
        .menu__link {
          margin-bottom: 0;
        }
      }
    }

    &__header {
      font-weight: 600;
      font-size: 26px;
      line-height: 33px;
      letter-spacing: -0.2px;
      color: #14142B;
      margin-top: 10px;
      margin-bottom: 32px;
    }

    &__link {
      cursor: pointer;
      position: relative;
      padding: 7px 0;
      margin-bottom: 16px;
      display: flex;

      p {
        padding-left: 18px;
        font-size: 20px;
        letter-spacing: -0.2px;
        color: #14142B;
      }
    }

    &__notifications-link {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        .notifications-switcher {
          margin-top: 1px;
          width: 42px;
          height: 24px;
          background: gray;
          border-radius: 18px;
          display: flex;
          align-items: center;
          padding: 0 3px;
          cursor: pointer;
          &--active {
            justify-content: flex-end;
            background: #26d583;
          }
        }
        .notifications-switcher-inner {
          width: 18px;
          height: 18px;
          background-color: #fff;
          border-radius: 50%;
        }
      }
    }

    &__link-notification {
      margin-left: 16px;
      margin-top: 3px;
      padding-top: 2px;
      width: 20px;
      height: 20px;
      background: #FA8231;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
      color: #FFF;
    }
  }

  .rtl {
    .menu {
      &__link {
        p {
          padding-right: 18px;
          padding-left: 0;
        }
      }
      &__link-notification {
        margin-left: 0;
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 590px) {
    .side-nav {
      &__side {
        padding: 40px 50px 42px 32px;
        width: 325px;
      }

      &__close {
        top: 40px;
        right: auto;
        left: 32px;
      }

      .menu{
        padding-top: 80px;
      }

      &__bottom {
        bottom: 42px;
        left: 32px;
      }
    }

    .rtl{
      .side-nav {
        &__side {
          padding: 40px 32px 42px 50px;
        }
      }
    }

    .menu {
      &__header {
        font-size: 24px;
        line-height: 30px;
      }

      &__link {
        p {
          font-size: 16px;
        }
      }

      &__link-notification {
        margin-top: 2px;
        padding-top: 1px;
        width: 18px;
        height: 18px;
      }
    }
  }
  /* END Side menu */

  @media (max-width: 320px) {
    .modal {
      &__container {
        min-width: 270px !important;
      }
    }
    .side-nav {
      &__side {
        width: 100%;
      }
    }
    .radio-button {
      height: 60px;
      padding: 18px 10px;
      &__text {
        max-width: 150px;
      }
    }
    .content-page {
      word-wrap: break-word;
    }
  }

  .rtl{
    .radio-button {
      &__pre-image{
        left: auto;
        right: -6px;
      }

      &__images{
        margin-left: 8px;
        margin-right: 0;
      }

      &__images--pair {
        margin-right: 5px !important;
      }

      &__toggle {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }
</style>
