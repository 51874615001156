import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export function usePaymentwall () {
  const store = useStore()
  const router = useRouter()

  const getPaymentwallWidgetUrl = async (amount, uid, email) => {
    try {
      const products = JSON.parse(process.env.VUE_APP_PAYMENTWALL_PACKAGES)
      const productId = products[`${amount}_dollars_purchase`] ? `${amount}_dollars_purchase` : 'custom'
      const params = {
        product_id: productId,
        fb_uid: uid,
        amount,
        email
      }

      const formDataResp = await axios.get(process.env.VUE_APP_PAYMENTWALL_DATA_URL, { params })
      if (formDataResp.data) {
        return formDataResp.data
      }
    } catch (e) {
      await router.push({ name: 'Funds', query: { status: 'error' } })
    } finally {
      store.commit('funds/setAddFundsLoading', false)
    }
  }

  return { getPaymentwallWidgetUrl }
}
