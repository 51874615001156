export default {
  namespaced: true,
  state: {
    alerts: []
  },
  mutations: {
    add (state, alert) {
      if (alert.name) {
        this.commit('alert/removeByName', alert.name)
      }
      if (!alert.zIndex) {
        // Default z-index for alert
        alert.zIndex = 22
      }
      const _this = this
      const timeout = alert.timeout ? alert.timeout : 0
      state.alerts = [...state.alerts, alert]

      if (timeout !== 0) {
        setTimeout(() => {
          _this.commit('alert/remove', alert.id)
        }, timeout)
      }
    },
    remove (state, id) {
      state.alerts = state.alerts.filter(a => a.id !== id)
    },
    removeByName (state, name) {
      state.alerts = state.alerts.filter(a => a.name !== name)
    }
  }
}
