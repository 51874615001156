<template>
  <div v-if="$store.state.notificationModal.show" @click.stop="outsideClickHandler" class="modal">
    <div @click.stop="" class="modal__container notification-modal__container">
      <div class="title">{{ title }}</div>
      <div class="body">
        <component :is="{ template: text }"></component>
      </div>
      <ButtonElement @click="action(buttonAction, buttonRouteName)" class="modal__button" fill height="58px">{{ buttonText }}</ButtonElement>
      <ButtonElement v-if="button2Text" @click="action(button2Action, button2RouteName)" class="btn modal__button modal__button-2" fill height="58px">{{ button2Text }}</ButtonElement>
    </div>
  </div>
</template>

<script>
import { useCloudMessaging } from '@/use/cloudMessaging'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  components: {
    ButtonElement
  },
  computed: {
    outsideClickHide () {
      return this.$store.state.notificationModal.outsideClickHide
    },
    title () {
      return this.$store.state.notificationModal.title
    },
    text () {
      return this.$store.state.notificationModal.text
    },
    buttonText () {
      return this.$store.state.notificationModal.buttonText
    },
    buttonRouteName () {
      return this.$store.state.notificationModal.buttonRouteName
    },
    buttonAction () {
      return this.$store.state.notificationModal.buttonAction
    },
    button2Text () {
      return this.$store.state.notificationModal.button2Text
    },
    button2RouteName () {
      return this.$store.state.notificationModal.button2RouteName
    },
    button2Action () {
      return this.$store.state.notificationModal.button2Action
    }
  },
  watch: {
    '$route' (to) {
      if (to.path === '/contact-us') {
        this.$store.commit('notificationModal/hide')
      }
    }
  },
  setup () {
    const { enableNotifications } = useCloudMessaging()
    return { enableNotifications }
  },
  methods: {
    async action (buttonAction, buttonRouteName) {
      if (buttonAction) {
        await this.handleButtonAction(buttonAction)
      } else {
        if (buttonRouteName) {
          await this.$router.push({ name: buttonRouteName })
        }
      }
      this.$store.commit('notificationModal/hide')
    },
    outsideClickHandler () {
      if (this.outsideClickHide) {
        this.hide()
      }
    },
    hide () {
      this.$store.commit('notificationModal/hide')
    },
    async handleButtonAction (buttonAction) {
      switch (buttonAction) {
        case 'activating404':
        case 'activating429':
          this.$store.commit('order/clearOrder')
          await this.$router.push({ name: 'Home' })
          break
        case 'activating406':
          this.$store.commit('order/clearOrder')
          await this.$router.push({ name: 'History' })
          break
        case 'activating410TryAgain':
          this.$store.commit('order/setActivateAgain', true)
          break
        case 'enableNotifications':
          this.enableNotifications()
          break
        case 'reloadPage':
          this.$router.go(0)
          break
        case 'activating410ChangeCountry':
          this.$store.commit('order/clearCountry')
          await this.$router.push({ name: 'OrderStep2' })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .notification-modal__container{
    max-width: 310px;
    padding: 40px 24px 24px;

    .body {
      margin: 8px 3px 0;
    }

    .modal__button-2{
      margin-top: 16px;
      border: 1px solid #FA8231;
      background: #FFF;
      color: #FA8231;
      &:hover {
        box-shadow: none;
        border: 2px solid #FA8231;
      }
      &:active {
        opacity: 0.8;
        background: #FA8231;
        color: #FFF;
      }
    }
  }
</style>
