<template>
  <div
    v-if="!isSearchOpen && currentStep > 1"
    class="mobile-prev-button"
    @click="prevHandle"
  >
    <img src="@/assets/icons/prev-mob-button.svg" alt="Previous">
  </div>
  <footer v-if="!isSearchOpen" class="stepper-control">
    <div class="container">
      <div class="stepper-control__buttons">
        <ButtonElement
          v-if="activeService && currentStep === 1"
          class="stepper-control__prev-button"
          @click="clearActiveService"
        >
          {{ $t('text_6167c4a5321c670152f0e1cb') }}
        </ButtonElement>
        <ButtonElement
          v-else-if="currentStep > 1"
          class="stepper-control__prev-button"
          @click="prevHandle"
        >
          {{ $t('text_6167c4a5321c670152f0e1df') }}
        </ButtonElement>
        <ButtonElement
          @click="nextHandle"
          :loading="loading"
          class="stepper-control__next-button"
          fill
        >
          {{ currentStep === 3 ? $t('text_6167c4a5321c670152f0e1f1') : $t('text_6167c4a5321c670152f0e1cc') }} <img src="@/assets/icons/Icon-color.svg" alt="continue">
        </ButtonElement>
      </div>
    </div>
  </footer>
</template>

<script>
import { useUser } from '@/use/user'
import { useOrder } from '@/use/order'
import { useErrorHandlers } from '@/use/errorHandlers'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  components: {
    ButtonElement
  },
  props: {
    currentStep: Number
  },
  data () {
    return {
      loading: false
    }
  },
  emits: ['prev', 'next', 'activate'],
  computed: {
    activeService () {
      return this.$store.state.order.service
    },
    activeCountry () {
      return this.$store.state.order.country
    },
    oneClickSelect () {
      return this.$store.state.order.oneClickSelect
    },
    isAuthenticated () {
      return this.$store.state.auth.isAuthenticated
    },
    isSearchOpen () {
      return this.$store.state.order.isSearchOpen
    },
    price () {
      return this.$store.state.order.price
    },
    forceEmailConfirmation () {
      return this.$store.state.common.forceEmailConfirmation
    }
  },
  setup () {
    const { isUserOnHold, isUserEmailVerified } = useUser()
    const { serviceCountryAvailable, forceActivationRedirect } = useOrder()
    const { showNoNumbersError } = useErrorHandlers()
    return { isUserOnHold, isUserEmailVerified, serviceCountryAvailable, forceActivationRedirect, showNoNumbersError }
  },
  mounted () {
    if (this.$store.state.funds.promoCode) {
      this.$router.push({ name: 'Funds' })
    }
  },
  methods: {
    prevHandle () {
      if (this.currentStep === 2) {
        this.$store.commit('order/setCountry', null)
        this.$router.push({ name: 'Home' })
      } else if (this.currentStep === 3) {
        if (this.oneClickSelect) {
          this.$router.push({ name: 'Home' })
        } else {
          this.$router.push({ name: 'OrderStep2' })
        }
      }
    },
    nextHandle () {
      if (this.currentStep === 3) {
        this.activateOrder()
      } else if (this.currentStep === 1) {
        this.handleNext2Step()
      } else if (this.currentStep === 2) {
        this.handleNext3Step()
      }
    },
    async handleNext2Step () {
      if (this.activeService && !this.activeService.hasNumbers) {
        this.showNoNumbersError()
        return false
      }

      if (this.activeService && this.activeCountry) {
        this.$amplitude.logEvent('order.service_selected', {
          selection_source: 'popular',
          service: this.activeService.serviceId
        })
        this.$amplitude.logEvent('order.country_selected', {
          selection_source: 'popular',
          country: this.activeCountry.countryId
        })
        if (this.oneClickSelect) {
          await this.$router.push({ name: 'OrderStep3' })
        } else {
          const isServiceCountryAvailable = await this.serviceCountryAvailable(this.activeService.serviceId, this.activeCountry)
          if (isServiceCountryAvailable) {
            await this.$router.push({ name: 'OrderStep3' })
          } else {
            this.$store.commit('order/setCountry', null)
            await this.$router.push({ name: 'OrderStep2' })
          }
        }
      } else if (this.activeService && !this.activeCountry) {
        this.$amplitude.logEvent('order.service_selected', {
          selection_source: 'popular',
          service: this.activeService.serviceId
        })
        await this.$router.push({ name: 'OrderStep2' })
      } else {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: this.$t('select_service_validation'),
          timeout: 2000,
          name: 'select_service_validation'
        })
      }
    },
    handleNext3Step () {
      if (this.activeCountry && !this.activeCountry.hasNumbers) {
        this.showNoNumbersError()
        return false
      }

      if (this.activeService && this.activeCountry) {
        this.$amplitude.logEvent('order.country_selected', {
          selection_source: 'popular',
          country: this.activeCountry.countryId
        })
        this.$router.push({ name: 'OrderStep3' })
      } else {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: this.$t('select_country_validation'),
          timeout: 2000,
          name: 'select_country_validation'
        })
      }
    },
    async activateOrder () {
      this.loading = true
      if (!this.isAuthenticated) {
        this.$store.commit('order/setForceActivation', true)
        const orderData = Object.assign(this.$store.state.order, { timestamp: Date.now() })
        localStorage.setItem('order', JSON.stringify(orderData))
        await this.$router.push({ name: 'CreateAccount' })
      } else {
        if (this.forceEmailConfirmation && !this.isUserEmailVerified()) {
          await this.$store.commit('modal/toggleForceEmailConfirmationModal', false)
          await this.$store.commit('modal/toggleForceEmailConfirmationModal', true)
          this.loading = false
          return
        }
        const isUserOnHold = await this.isUserOnHold()
        if (isUserOnHold) {
          this.loading = false
          return
        }
        this.forceActivationRedirect(true)
      }
    },
    clearActiveService () {
      this.$store.commit('order/clearOrder')
    }
  }
}
</script>

<style scoped lang="scss">
  .stepper-control {
    position: fixed;
    bottom: 0;
    margin-top: 95px;
    width: 100%;
    padding: 28px 0;
    background: #FFFFFF;
    box-shadow: 0px -16px 32px rgba(0, 0, 0, 0.03);
    z-index: 3;

    &__buttons {
      display: flex;
      justify-content: flex-end;
      @media (min-width: 591px) and (max-width: 1430px) {
        margin: 0 60px;
      }

      .btn {
        margin-right: 16px;
        height: 64px;
        width: 151px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__next-button {
      margin-right: 0;
      width: 248px !important;

      @media (max-width: 590px) {
        width: 100% !important;
        max-width: 448px;
      }

      img {
        position: relative;
        left: 8px;
        top: 0;
      }
    }
  }

  .rtl {
    .stepper-control{
      &__buttons{
        .btn{
          margin-right: 0;
          margin-left: 16px;

          &:last-child {
            margin-left: 0;
          }
        }
      }

      &__next-button {
        img {
          right: 8px;
          left: auto;
          transform: rotate(180deg)
        }
      }
    }
  }

  @media (max-width: 590px) {
    .stepper-control {
      margin-top: 0;
      box-shadow: none;

      &__buttons {
        display: flex;
        justify-content: center;
        .btn {
          height: 58px;
          margin-right: 0;
          &:first-child {
            display: none;
          }
        }
        .btn.stepper-control__next-button{
          display: flex;
        }
      }
    }
  }

  .mobile-prev-button{
    display: none;
    @media (max-width: 590px) {
      cursor: pointer;
      width: 48px;
      height: 48px;
      display: block;
      position: absolute;
      top: 75px;
      left: 24px;
    }
  }

  .rtl{
    .mobile-prev-button{
      @media (max-width: 590px) {
        right: 24px;
        left: auto;
        img{
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
