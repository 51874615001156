<template>
  <div class="currencySelect">
    <Select2
      v-model="cryptoCurrency"
      :options="currencies"
      :settings="{
        placeholder: $t('text_62aae8f9b01ff38a84096689'),
        minimumResultsForSearch: -1,
        templateResult: formatSelectOption,
        templateSelection: formatSelectResult,
        tags: true
      }"
    />
  </div>
</template>

<script>
import $ from 'jquery'
import Select2 from 'vue3-select2-component'

export default {
  emits: ['selectCryptoCurrency'],
  components: { Select2 },
  data () {
    return {
      cryptoCurrency: null,
      currencies: [
        'BTC',
        'DAI',
        'AXS',
        'DASH',
        'DOGE',
        'ETH',
        'HUSD',
        'LTC',
        'TUSD',
        'USDC',
        'USDT',
        'USDT (OMNI)',
        'XRP',
        'TRX',
        'USDT (TRC20)',
        'AXS (TRC20)',
        'HT (TRC20)',
        'HUSD (TRC20)',
        'ETH (TRC20)',
        'BNB',
        'ETH (BEP20)',
        'DAI (BEP20)',
        'USDT (BEP20)',
        'DESU (BEP20)',
        'ADA (BEP20)',
        'USDC (BEP20)',
        'BTCB (BEP20)',
        'CSC'
      ]
    }
  },
  watch: {
    cryptoCurrency (val) {
      this.$emit('selectCryptoCurrency', val)
    }
  },
  methods: {
    formatSelectOption (state) {
      if (!state.id) return state.text
      const imgPath = this.getImgPath(state.text)
      return $(`
        <div style="display:flex;align-items:center;">
          <img src="${imgPath}" class="img-flag" width=30px" height="30px" />
          <span style="margin-left:12px;">${state.text}</span>
        </div>
      `)
    },
    formatSelectResult (state) {
      if (!state.id) return state.text
      const imgPath = this.getImgPath(state.text)
      return $(`
        <div style="display:flex;align-items:center;justify-content:center">
          <img src="${imgPath}" class="img-flag" width=30px" height="30px" />
          <span style="margin-left:12px;">${state.text}</span>
        </div>
      `)
    },
    getImgPath (text) {
      const name = (text.split(' ')[0]).toLowerCase()
      return require('@/assets/crypto-currencies/' + name + '.svg')
    }
  }
}
</script>

<style lang="scss">
  .currencySelect{
    margin-top: 20px;
    .select2-container{
      width: 100% !important;
      .select2-selection{
        &--single{
          height: 52px;
          border: 1px solid #EFEFEF;
        }
        &__rendered {
          line-height: 50px;
        }
        &__arrow{
          height: 50px;
        }
      }
    }
  }
</style>
