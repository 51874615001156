<template>
  <div class="loader">
    <img src="@/assets/loader.gif" alt="loader">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .loader {
    margin: 0 auto;
    width: 240px;

    img {
      width: 100%;
    }
  }
</style>
