import i18n from '@/utils/i18n'
import store from '@/store'
import { useTranslate } from '@/use/translate'
const { addBreakLinesToText, addLinksToText, addCrispChatLink } = useTranslate()

export default {
  namespaced: true,
  state: {
    show: false,
    outsideClickHide: true,
    title: '',
    text: '',
    buttonText: '',
    buttonRouteName: '',
    buttonAction: '',
    button2Text: '',
    button2RouteName: '',
    button2Action: ''
  },
  mutations: {
    show (state, payload) {
      state.show = true
      state.outsideClickHide = typeof payload.outsideClickHide !== 'undefined' ? payload.outsideClickHide : true
      state.title = payload.title
      state.text = payload.text
      state.buttonText = payload.buttonText
      state.buttonRouteName = payload.buttonRouteName ? payload.buttonRouteName : ''
      state.buttonAction = payload.buttonAction ? payload.buttonAction : ''
      state.button2Text = payload.button2Text
      state.button2RouteName = payload.button2RouteName ? payload.button2RouteName : ''
      state.button2Action = payload.button2Action ? payload.button2Action : ''
    },
    showSomethingWrongModal (state, status) {
      let text = addBreakLinesToText(i18n.global.t('general_error_subtitle'))
      text = store.state.auth.isAuthenticated ? addCrispChatLink(text) : addLinksToText(text, ['ContactUs'])
      state.show = true
      state.title = i18n.global.t('general_error_title')
      state.text = status ? text.replace('__0__', status) : text.replace(' (__0__)', '')
      state.buttonText = i18n.global.t('ok_button')
    },
    hide (state) {
      state.show = false
      state.title = state.text = state.buttonText = state.buttonRouteName = state.buttonAction = ''
      state.button2Text = state.button2RouteName = state.button2Action = ''
    }
  }
}
