import { createStore } from 'vuex'
import alert from '@/store/modules/alert'
import modal from '@/store/modules/modal'
import notificationModal from '@/store/modules/notificationModal'
import auth from '@/store/modules/auth'
import order from '@/store/modules/order'
import user from '@/store/modules/user'
import payment from '@/store/modules/payment'
import activation from '@/store/modules/activation'
import funds from '@/store/modules/funds'
import common from '@/store/modules/common'

export default createStore({
  modules: {
    alert,
    modal,
    notificationModal,
    auth,
    order,
    user,
    payment,
    activation,
    funds,
    common
  }
})
