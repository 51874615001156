import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config'
import store from '../store'

export default async function (fbApp) {
  const remoteConfig = getRemoteConfig(fbApp)
  // remoteConfig.settings.minimumFetchIntervalMillis = 20000
  remoteConfig.defaultConfig = {
    force_email_confirmation_web: 1,
    nps_score_activations_frequency_web: 10,
    nps_score_days_frequency_web: 7,
    any_other_warning_web: 0,
    show_country_code_for_services_web: '',
    show_high_demand_min_web: 0
  }

  try {
    await fetchAndActivate(remoteConfig)
    const forceEmailConfirmation = await getValue(remoteConfig, 'force_email_confirmation_web').asBoolean()
    const npsScoreActivationsFrequency = await getValue(remoteConfig, 'nps_score_activations_frequency_web').asNumber()
    const npsScoreDaysFrequency = await getValue(remoteConfig, 'nps_score_days_frequency_web').asNumber()
    const anyOtherWarning = await getValue(remoteConfig, 'any_other_warning_web').asNumber()
    const servicesIdsWithCountryCode = await getValue(remoteConfig, 'show_country_code_for_services_web').asString()
    const highDemandMinPrice = await getValue(remoteConfig, 'show_high_demand_min_web').asNumber()

    store.commit('common/setForceEmailConfirmation', forceEmailConfirmation)
    store.commit('common/setNpsScoreActivationsFrequency', npsScoreActivationsFrequency)
    store.commit('common/setNpsScoreDaysFrequency', npsScoreDaysFrequency)
    store.commit('common/setAnyOtherWarning', anyOtherWarning)
    store.commit('common/setServicesIdsWithCountryCode', servicesIdsWithCountryCode)
    store.commit('common/setHighDemandMinPrice', highDemandMinPrice)
  } catch (e) {}
}
