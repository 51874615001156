<template>
  <div>
    <ul class="slider">
      <transition v-for="slide in slides" :key="slide.id" name="fade">
        <li class="slider__slide" v-if="slide.id === currentSlide">
          {{slide.text}}
        </li>
      </transition>
    </ul>

    <SliderControls
      :currentSlide="currentSlide"
      :length="slides.length"
      @change="handleChangeClick"
    />
  </div>
</template>

<script>
import SliderControls from '@/components/Slider/SliderControls.vue'

export default {
  components: {
    SliderControls
  },
  data () {
    return {
      slideChangeDelay: parseInt(process.env.VUE_APP_ACTIVATING_SLIDE_DELAY, 10) || 4000,
      slideIntervalId: null,
      currentSlide: 0,
      slides: [
        {
          id: 0,
          text: this.$t('text_6167c4a5321c670152f0e207')
        },
        {
          id: 1,
          text: this.$t('text_61681bb2c9489b01455e959e')
        },
        {
          id: 2,
          text: this.$t('text_61681c0b0493d4011bdf5677')
        }
      ]
    }
  },
  mounted () {
    this.startSlider()
  },
  beforeUnmount () {
    clearInterval(this.slideIntervalId)
    this.slideIntervalId = null
  },
  methods: {
    handleChangeClick (id) {
      clearInterval(this.slideIntervalId)
      this.currentSlide = id
      this.startSlider()
    },
    changeSlide (id) {
      this.currentSlide = id
    },
    startSlider () {
      this.slideIntervalId = setInterval(() => {
        let id = this.currentSlide

        if (this.currentSlide === this.slides.length - 1) {
          id = 0
        } else {
          id++
        }

        this.changeSlide(id)
      }, this.slideChangeDelay)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/animation/fade-pair.scss';

  .slider {
    position: relative;

    &__slide {
      border: 1px solid rgba(25, 22, 50, 0.12);
      border-radius: 15px;
      padding: 34px 38px;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #191632;
    }
  }

  @media (max-width: 590px) {
    .slider {
      &__slide {
        padding: 24px 20px;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
</style>
