export default {
  namespaced: true,
  state: {
    showConfirmPasswordModal: false,
    retryConfirmationModal: false,
    enableNotificationsModal: false,
    showHelpModal: false,
    whatsWrongModal: false,
    alreadyHaveAccountModal: false,
    forceEmailConfirmationModal: false
  },
  mutations: {
    toggleConfirmPasswordModal (state, value) {
      state.showConfirmPasswordModal = value
    },
    toggleRetryConfirmationModal (state) {
      state.retryConfirmationModal = !state.retryConfirmationModal
    },
    toggleEnableNotificationsModal (state) {
      state.enableNotificationsModal = !state.enableNotificationsModal
    },
    toggleHelpModal (state) {
      state.showHelpModal = !state.showHelpModal
    },
    toggleWhatsWrongModal (state) {
      state.whatsWrongModal = !state.whatsWrongModal
    },
    toggleAlreadyHaveAccountModal (state) {
      state.alreadyHaveAccountModal = !state.alreadyHaveAccountModal
    },
    toggleForceEmailConfirmationModal (state, val) {
      state.forceEmailConfirmationModal = val
    }
  }
}
