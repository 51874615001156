<template>
  <div v-if="accountOnHold" class="modal">
    <div class="modal__container">
      <div class="title">{{ $t('text_LDKVvnKALyomGEFWAK8') }}</div>
      <div class="body">
        <component :is="{ template: modalText }" />
      </div>
      <div>
        <ButtonElement @click="openChat" class="modal__button" fill height="58px">
          <img src="@/assets/icons/headset_white.svg" alt="Open chat">
          {{ $t('text_iypeq6r74mvc4wlkax7') }}
        </ButtonElement>
        <ButtonElement @click="closeTab" class="modal__button mt-16" height="58px">
          {{ $t('text_7mSO0m9EOmmO3SUFfax') }}
        </ButtonElement>
      </div>
    </div>
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import { useCrispChat } from '@/use/crispChat'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  components: {
    ButtonElement
  },
  computed: {
    accountOnHold () {
      return this.$store.state.user.accountOnHold
    },
    modalText () {
      return this.addBreakLinesToText(this.$t('text_w9qYvN12sxTOD8X13u9'))
    }
  },
  watch: {
    '$route' (to) {
      if (to.path === '/contact-us') {
        this.$store.commit('user/setAccountOnHold', false)
      }
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    const { openChatWindow } = useCrispChat()
    return { addBreakLinesToText, openChatWindow }
  },
  methods: {
    openChat () {
      this.$store.commit('user/setAccountOnHold', false)
      this.openChatWindow()
    },
    closeTab () {
      this.$store.commit('user/setAccountOnHold', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal{
    &__container{
      width: 311px;

      .mt-16{
        margin-top: 16px !important;
      }
    }
    &__button{
      img {
        margin-right: 15px;
      }
    }
    @media (max-width: 320px) {
      &__container {
        width: 270px;
      }
    }
  }
</style>
