import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useStore } from 'vuex'

export function useBalance () {
  const { initApiInst, smsUserApi } = useSmsApi()
  const { handleNotAuthorizedError, handleNetworkError } = useErrorHandlers()
  const store = useStore()
  const timeoutExpStartValue = 0.6
  const timeoutStep = 0.2
  let timeoutExpValue

  const getBalance = async () => {
    try {
      await initApiInst()
      const resp = await smsUserApi.value.getUserBalance({ prxPrint: store.state.common.prxPrint })
      store.commit('alert/removeByName', 'network_error')
      return resp && resp.balance ? resp.balance : 0
    } catch (e) {
      if (e.status === 401) {
        handleNotAuthorizedError()
      } else if (e.status === 502) {
        handleNetworkError(true)
      } else {
        if (e.error && e.error.message && e.error.message.includes('Request has been terminated')) {
          handleNetworkError(true)
        }
      }
    }
  }

  const checkBalance = async () => {
    if (!store.state.auth.isAuthenticated) return
    const storeBalance = store.state.user.balance
    const balance = await getBalance()

    if (storeBalance === null) {
      store.commit('user/setBalance', balance)
    } else if (balance !== undefined && balance !== storeBalance) {
      store.commit('user/setBalance', balance)
    }

    const timeout = Math.round(Math.exp(timeoutExpValue))
    const timeoutId = setTimeout(() => {
      checkBalance()
    }, timeout * 1000)
    store.commit('user/setBalanceTimeoutId', timeoutId)
    timeoutExpValue += timeoutStep
  }

  const restartCheckBalance = async () => {
    stopCheckBalance()
    timeoutExpValue = timeoutExpStartValue

    await checkBalance()
  }

  const stopCheckBalance = () => {
    const timeoutId = store.state.user.balanceTimeoutId
    if (timeoutId) {
      clearTimeout(timeoutId)
      store.commit('user/setBalanceTimeoutId')
    }
  }

  return { getBalance, restartCheckBalance, stopCheckBalance }
}
