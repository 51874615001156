export function useTranslate () {
  const addBreakLinesToText = (text) => {
    return text.split('\r\n').join('<br />').split('\n').join('<br />')
  }

  const addLinksToText = (text, links) => {
    const textParts = getTextPartsForLinks(text)
    textParts.map((part, index) => {
      if (links.length > index) {
        const linkText = part[0].split('**').join('')
        const routerLink = `<router-link :to="{ name: '${links[index]}' }">${linkText}</router-link>`
        text = text.split(part[0]).join(routerLink)
      }
    })

    return text
  }

  const addExternalLinksToText = (text, links) => {
    const textParts = getTextPartsForLinks(text)
    textParts.map((part, index) => {
      if (links.length > index) {
        const linkText = part[0].split('**').join('')
        const externalLink = `<a href="${links[index]}">${linkText}</a>`
        text = text.split(part[0]).join(externalLink)
      }
    })

    return text
  }

  const addCrispChatLink = (text, routeName = null) => {
    const routeToData = routeName
      ? `name: '${routeName}'`
      : `path: '${location.pathname.replace('/app', '')}'`
    const textParts = getTextPartsForLinks(text)
    const part = textParts[0]
    const linkText = part[0].split('**').join('')
    const routerLink = `<router-link :to="{ ${routeToData}, query: { crispChat: 'true' } }">${linkText}</router-link>`
    text = text.split(part[0]).join(routerLink)

    return text
  }

  const getTextPartsForLinks = (text) => {
    const regexp = new RegExp('\\*\\*[^-\\s].+\\*\\*', 'g')
    return [...text.matchAll(regexp)]
  }

  return { addBreakLinesToText, addLinksToText, addExternalLinksToText, addCrispChatLink }
}
