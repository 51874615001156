<template>
  <div v-if="item.details.type === 'in'">
    <template v-if="item.details.status === 'review'">
      {{ $t('text_61af3e84d1c8ab011531e1ef') }}
    </template>
    <template v-else-if="item.details.gateway === 'reward'">
      {{ $t('text_61af3e84d1c8ab011531e1f1') }}
    </template>
    <template v-else>
      {{ $t('text_61af3e84d1c8ab011531e1e9') }}
    </template>
  </div>
  <div v-else-if="item.details.type === 'refund'">
    <template v-if="item.details.gateway === 'support'">
      {{ $t('text_61af3e84d1c8ab011531f1ed') }}
    </template>
    <template v-else>
      {{ $t('text_61af3e84d1c8ab011531e1eb') }}
    </template>
  </div>
  <div v-else>{{ `${item.details.serviceName} (${item.details.countryId.toUpperCase()})` }}</div>
</template>

<script>
export default {
  props: {
    item: {}
  }
}
</script>

<style lang="scss" scoped>
  div {
    padding: 0 10px;
  }

  @media (max-width: 590px) {
    div {
      width: 100%;
      height: 48px;
      padding-left: 5px;
      overflow: hidden;
    }

    .rtl{
      div {
        padding-right: 5px;
        padding-left: 0;
      }
    }
  }
</style>
