<template>
  <div class="stepper">
    <div class="container">
      <transition name="fade" appear>
        <TitleElement :tace="true">
          <h1 class="title__main">{{ $t('text_6167c4a5321c670152f0e1e6') }}</h1>
          <h2 v-html="activateH2" class="title__subtitle" />
        </TitleElement>
      </transition>

      <Steps :currentStep="3" />

      <div class="stepper__buttons">
        <transition name="fade">
          <div>
            <SelectedButtons />
            <ActivationPrice />
            <OrderWarning />
            <ProVersionLink />
          </div>
        </transition>
      </div>
    </div>

    <StepperControl :currentStep="3" />
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import Steps from '@/components/Stepper/Steps.vue'
import StepperControl from '@/components/Stepper/StepperControl.vue'
import TitleElement from '@/components/TitleElement.vue'
import SelectedButtons from '@/components/Order/SelectedButtons.vue'
import ActivationPrice from '@/components/Order/ActivationPrice.vue'
import OrderWarning from '@/components/Order/OrderWarning.vue'
import ProVersionLink from '@/components/Order/ProVersionLink.vue'

export default {
  components: {
    Steps,
    SelectedButtons,
    ActivationPrice,
    OrderWarning,
    ProVersionLink,
    StepperControl,
    TitleElement
  },
  computed: {
    activateH2 () {
      return this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e1e7'))
    },
    activeService () {
      return this.$store.state.order.service
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { addBreakLinesToText }
  },
  mounted () {
    if (!this.activeService) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/animation/fade-solo.scss';
</style>
