<template>
  <div>
    <SuccessIcon />
    <TitleElement :text="$t('text_6419d46f85fa97007e8c04c1')" />
    <TextElement :text="$t('text_6419d46f85fa97007e8c04c2').replace('__0__', price)" />
    <OpenLiveChatBtn @closeModal="$emit('closeModal')" />
    <OkBtn @closeModal="$emit('closeModal')" />
  </div>
</template>

<script>
import SuccessIcon from '@/components/Modals/Support/Elements/SuccessIcon'
import TitleElement from '@/components/Modals/Support/Elements/TitleElement'
import TextElement from '@/components/Modals/Support/Elements/TextElement'
import OpenLiveChatBtn from '@/components/Modals/Support/Elements/OpenLiveChatBtn'
import OkBtn from '@/components/Modals/Support/Elements/OkBtn'

export default {
  emits: ['closeModal'],
  components: {
    SuccessIcon,
    TitleElement,
    TextElement,
    OpenLiveChatBtn,
    OkBtn
  },
  props: {
    price: Number
  }
}
</script>
