<template>
  <div :style="`margin-top: ${marginTop}px`" class="loading-wrapper">
    <v-lottie-player
      :animationData="require('@/assets/loaders/iphone-orange-spinner.json')"
      loop
      width="60px"
      height="60px"
    />
  </div>
</template>

<script>
export default {
  props: {
    marginTop: {
      type: Number,
      default: () => 100
    }
  }
}
</script>

<style lang="scss" scoped>
  .loading-wrapper {
    margin: 100px 0;
    display: flex;
    justify-content: center;
  }
</style>
