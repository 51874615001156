<template>
  <div class="new-password">
    <TitleElement tace>
      <h1 class="auth-title__main title__main--pb0">{{ $t('text_61725a931769100136b9ca0d') }}</h1>
      <h2 class="auth-title__subtitle">{{ $t('text_61725a931769100136b9ca09') }}</h2>
    </TitleElement>
    <NewPasswordForm />
  </div>
</template>

<script>
import TitleElement from '@/components/TitleElement.vue'
import NewPasswordForm from '@/components/Forms/NewPasswordForm/NewPasswordForm.vue'

export default {
  components: {
    TitleElement,
    NewPasswordForm
  }
}
</script>

<style lang="scss" scoped>
  .new-password {
    width: 400px;
  }
</style>
