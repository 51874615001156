<template>
  <div>
    <TitleElement :text="$t('text_64119de89cf25d009d31c9dd')" />
    <TextElement :text="$t('text_64119de89cf25d009d31c9e9')" />
    <OpenLiveChatBtn @closeModal="$emit('closeModal')" />
    <OkBtn @closeModal="$emit('closeModal')" />
  </div>
</template>

<script>
import TitleElement from '@/components/Modals/Support/Elements/TitleElement'
import TextElement from '@/components/Modals/Support/Elements/TextElement'
import OpenLiveChatBtn from '@/components/Modals/Support/Elements/OpenLiveChatBtn'
import OkBtn from '@/components/Modals/Support/Elements/OkBtn'

export default {
  emits: ['closeModal'],
  components: {
    TitleElement,
    TextElement,
    OpenLiveChatBtn,
    OkBtn
  }
}
</script>
