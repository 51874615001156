<template>
  <div class="amount-control amount-control--active">
    <input
      :value="modelValue"
      :placeholder="$t('text_62459c30f6f1df00cfbcbe23')"
      @input="$emit('update:modelValue', $event.target.value)"
      type="text"
    >
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: String || null
  }
}
</script>

<style lang="scss" scoped>
  .amount-control {
    border: 1px solid rgba(25, 22, 50, 0.12);
    border-radius: 15px;

    &--active {
      border: 1px solid #FA8231;
    }

    input {
      width: 100%;
      font-size: 18px;
      border: none;
      padding: 24px 20px;
      color: rgba(20, 20, 43, 0.6);
      border-radius: 15px;

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 590px) {
    .amount-control {
      width: 100%;

      input {
        font-size: 14px;
        padding: 24px 18px;
      }
    }
  }
</style>
