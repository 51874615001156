<template>
  <div v-if="showWarning" class="any-other-warning">
    <div @click="hideWarning" class="any-other-warning__close-btn">
      <img src="@/assets/icons/any_other_warn_close.svg" alt="close">
    </div>
    <div class="any-other-warning__header">
      <img src="@/assets/icons/any_other_warn_attention.svg" alt="attention img">
      {{ $t('text_641024c6ac45b50048533620') }}
    </div>
    <div class="any-other-warning__text">
      {{ $t('text_641024c6ac45b50048533621') }}
    </div>
    <img src="@/assets/icons/any_other_warn_tail.svg" class="any-other-warning__tail" alt="tail">
  </div>
</template>

<script>
export default {
  data () {
    return {
      showWarning: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.hideWarning()
    }, 12000)
  },
  methods: {
    hideWarning () {
      this.showWarning = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .any-other-warning{
    position: absolute;
    bottom: 83px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 330px;
    background: #FA8231;
    border-radius: 8px;
    padding: 16px 16px 10px;
    box-shadow: 0 20px 25px -5px rgba(51, 65, 85, 0.1), 0px 10px 10px -5px rgba(51, 65, 85, 0.04);
    @media (min-width: 590px) and (min-height: 768px) {
      bottom: 95px;
    }
    &__header{
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: #F5F8FB;
      margin-bottom: 12px;
      img{
        margin-right: 10px;
      }
      @media (max-width: 590px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    &__text{
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #E2E8F0;
      @media (max-width: 590px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__close-btn{
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    &__tail{
      position: absolute;
      bottom: -16px;
      left: 0;
      right: 0;
      margin: 0 auto;
      @media (max-width: 900px) {
        bottom: -15px;
      }
    }
  }

  .rtl {
    .any-other-warning{
      &__close-btn {
        left: 16px;
        right: auto;
      }
      &__header{
        img{
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }
</style>
