<template>
  <div :class="{'search--open': isSearchOpen}" v-click-outside="onClickOutside" class="search">
    <div v-if="isSearchOpen" @click="hideList" class="search__close">
      <img src="@/assets/icons/close.svg" alt="close">
    </div>

    <div v-show="!orderLoading" class="search__input">
      <input
        :value="searchString"
        @input="e => searchString = e.target.value"
        @focus="focusSearchInput"
        :placeholder="searchData.length === 0 && isSearchOpen ? $t('search_service_edt_hint') : $t('text_6167c4a5321c670152f0e1c4')"
        ref="serviceSearchInput"
        type="text"
      />
      <div v-if="searchString" @click="clearSearch" class="search__clear" />
    </div>

    <div v-if="isSearchOpen" class="search-items">
      <ul v-if="searchString.length <= 1 && searchData.length === 0">
        <li class="search-item-startTypingHint">
          <p class="search-item__text">{{ $t('search_service_start_typing') }}</p>
        </li>
        <li
          v-for="(serviceName, key) in searchHistory"
          :key="serviceName"
          @click.stop="searchString = serviceName"
          class="history-search-item"
        >
          <div class="history-search-item__image">
            <img src="@/assets/icons/searchHistoryImg.svg" alt="search history image">
          </div>
          <p class="history-search-item__text">{{ serviceName }} <span v-if="key === 0" class="historyHint"> - {{ $t('text_627be1630155d000e27cb4ce') }}</span></p>
          <div @click.stop="deleteSearchHistoryService(serviceName)" class="history-search-item__delete">
            <img src="@/assets/icons/searchHistoryDelete.svg" alt="search history delete">
          </div>
        </li>
      </ul>
      <ul v-if="searchData.length">
        <li v-if="showAnyOtherHint" class="search-item search-item-otherHint">
          <p class="search-item__text">{{ $t('search_service_not_found_subtitle') }}</p>
        </li>
        <li
          v-for="service in searchData"
          :key="service.serviceId"
          @click="selectService(service)"
          :class="{ 'noNumbers': !service.hasNumbers }"
          class="search-item"
        >
          <div class="search-item__images">
            <img :src="`${iconBaseUrl + service.serviceIconPath}`" class="search-item__main-img">
          </div>
          <p class="search-item__text">{{ service.serviceName }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useOrder } from '@/use/order'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      searchData: [],
      searchString: '',
      searchHistory: [],
      iconBaseUrl: null,
      showSearchPlaceholder: false,
      showAnyOtherHint: false,
      searchHistoryCount: 5,
      searchHistoryLSKeyName: 'serviceSearchHistory'
    }
  },
  computed: {
    isSearchOpen () {
      return this.$store.state.order.isSearchOpen
    },
    country () {
      return this.$store.state.order.country
    },
    orderLoading () {
      return this.$store.state.order.orderLoading
    }
  },
  watch: {
    searchString (str) {
      if (str) {
        this.searchServices(str)
      } else {
        this.searchData = []
      }
    },
    isSearchOpen (val) {
      if (val) {
        this.getSearchHistory()
      }
    }
  },
  setup () {
    const { initApiInst, smsServicesApi } = useSmsApi()
    const { handle503Error, handleNetworkError, showNoNumbersError } = useErrorHandlers()
    const { serviceCountryAvailable } = useOrder()
    return { initApiInst, smsServicesApi, handle503Error, handleNetworkError, showNoNumbersError, serviceCountryAvailable }
  },
  mounted () {
    if (this.isSearchOpen) {
      this.$refs.serviceSearchInput.focus()
    }
  },
  unmounted () {
    this.$store.commit('order/setIsSearchOpen', false)
  },
  methods: {
    async searchServices (str) {
      if (str.length < 2) return false
      try {
        await this.initApiInst()
        const servicesResp = await this.smsServicesApi.getService(str, {
          prxLang: this.$i18n.locale,
          prxPrint: this.$store.state.common.prxPrint
        })
        if (servicesResp) {
          this.iconBaseUrl = servicesResp.iconBaseUrl
          this.searchData = servicesResp.services
          this.showAnyOtherHint = this.searchData.length === 1 && this.searchData[0].serviceId === 'other'
        }
      } catch (e) {
        if (e.status === 503) {
          this.handle503Error()
        } else {
          this.handleNetworkError()
        }
        this.searchData = []
      }
    },
    focusSearchInput () {
      this.$store.commit('order/setIsSearchOpen', true)
    },
    onClickOutside () {
      if (!this.isMobile()) {
        this.$store.commit('order/setIsSearchOpen', false)
        this.searchString = ''
        this.searchData = []
      }
    },
    async selectService (service) {
      if (service.hasNumbers) {
        let isServiceCountryAvailable = false
        if (this.country) {
          isServiceCountryAvailable = await this.serviceCountryAvailable(service.serviceId, this.country)
        }
        this.setServiceState(service)
        this.setServiceToHistory(service)
        if (isServiceCountryAvailable) {
          await this.$router.push({ name: 'OrderStep3' })
        } else {
          this.$store.commit('order/setCountry', null)
          await this.$router.push({ name: 'OrderStep2' })
        }
      } else {
        this.showNoNumbersError()
      }
    },
    setServiceState (service) {
      this.$store.commit('order/setService', service)
      this.$store.commit('order/setIsSearchOpen', false)
      this.$store.commit('order/setIconBaseUrl', this.iconBaseUrl)
      this.$store.commit('order/setOneClickSelect', false)
      this.$amplitude.logEvent('order.service_selected', {
        selection_source: 'search',
        service: service.serviceId
      })
    },
    hideList () {
      this.$store.commit('order/setIsSearchOpen', false)
      this.searchString = ''
      this.searchData = []
    },
    clearSearch () {
      this.searchString = ''
      this.searchData = []
      this.$refs.serviceSearchInput.focus()
    },
    getSearchHistory: function () {
      const searchHistory = JSON.parse(localStorage.getItem(this.searchHistoryLSKeyName))
      this.searchHistory = searchHistory ?? []
    },
    setServiceToHistory (service) {
      if (service && service.serviceName) {
        const serviceHistoryIndex = this.searchHistory.findIndex(item => item === service.serviceName)
        if (serviceHistoryIndex !== -1) {
          this.searchHistory.splice(serviceHistoryIndex, 1)
        }
        this.searchHistory.unshift(service.serviceName)
        if (this.searchHistory.length > this.searchHistoryCount) {
          this.searchHistory = this.searchHistory.slice(0, this.searchHistoryCount)
        }
        localStorage.setItem(this.searchHistoryLSKeyName, JSON.stringify(this.searchHistory))
      }
    },
    deleteSearchHistoryService (serviceName) {
      const serviceIndex = this.searchHistory.findIndex(item => item === serviceName)
      if (serviceIndex !== -1) {
        this.searchHistory.splice(serviceIndex, 1)
        localStorage.setItem(this.searchHistoryLSKeyName, JSON.stringify(this.searchHistory))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-item-otherHint,
  .search-item-startTypingHint{
    padding: 14px 0 18px;
    cursor: default;
    @media (max-width: 590px) {
      padding: 14px 0 14px;
    }
    p{
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: #605D76;
      width: 100%;
      text-align: center;
      @media (max-width: 590px) {
        font-size: 10px;
      }
    }
  }
  .search-item-startTypingHint{
    margin: 5px 0;
  }
  .history-search-item{
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #14142B;
    padding: 17px 46px 17px 20px;
    border-top: 1px solid rgba(25, 22, 50, 0.12);
    cursor: pointer;
    position: relative;
    &:hover{
      background: #f3f3f3;
    }

    .historyHint {
      color: rgba(20, 20, 43, 0.6);
    }

    &__image,
    &__delete{
      height: 24px;
    }
    &__image{
      margin-right: 8px;
    }
    &__delete{
      position: absolute;
      right: 20px;
    }
  }

  .rtl{
    .history-search-item{
      padding: 17px 20px 17px 46px;

      &__image{
        margin-left: 8px;
        margin-right: 0;
      }
      &__delete{
        left: 20px;
        right: auto;
      }
    }
  }

  @media (max-width: 590px) {
    .history-search-item{
      padding: 14px 36px 14px 7px;
      border-top: none;
      &:hover{
        background: transparent;
      }
      .historyHint {
        display: none;
      }
      &__delete{
        right: 8px;
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .history-search-item{
      padding: 11px 46px 11px 20px;
    }
    .search-item-startTypingHint{
      margin: 0;
    }
  }
</style>
