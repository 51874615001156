<template>
  <div class="contact-page container">
    <h1>{{ $t('text_6261357f6d8ce401573ad3e8') }}</h1>
    <h2>{{ $t('text_6261357f6d8ce401573ad3ea') }}</h2>

    <form @submit.prevent="contactUs" class="form">
      <div class="control input-control reason-control">
        <Select2
          v-model="reason"
          :options="Object.values(reasons)"
          :settings="{
            placeholder: $t('text_6261357f6d8ce401573ad3ec'),
            minimumResultsForSearch: -1
          }"
        />
        <div v-if="this.v$.reason.$errors?.[0]?.$validator" class="control__error">
          {{ $t('text_62616011fd1202016490264a') }}
        </div>
      </div>

      <div class="control input-control email-control">
        <input
          v-model="email"
          :placeholder="$t('text_6261357f6d8ce401573ad3f0')"
          type="email"
          ref="emailInput"
        >
        <div v-if="email" @click="clearEmail" class="input__clear" />
        <div v-if="this.v$.email.$errors?.[0]?.$validator" class="control__error">
          <p v-for="error of this.v$.email.$errors" :key="error.$uid">
            {{ error.$validator === 'required' ? $t('enter_email_validation') : $t('email_not_correct_validation') }}
          </p>
        </div>
      </div>

      <div class="control input-control message-control">
        <textarea v-model="message" :placeholder="$t('text_6261357f6d8ce401573ad3f2')" rows="4" />
        <div v-if="this.v$.message.$errors?.[0]?.$validator" class="control__error">
          <p v-for="error of this.v$.message.$errors" :key="error.$uid">
            <span v-if="error.$validator === 'required'">{{ $t('text_625f09ede4603200c6ef1407') }}</span>
            <span v-if="error.$validator === 'minLength'">{{ $t('text_62616011fd12020164902646').replace('__0__', messageMinLength) }}</span>
          </p>
        </div>
      </div>

      <ButtonElement :loading="loading" class="form__send" width="100%" height="58px" fill>
        {{ $t('text_6261357f6d8ce401573ad3f4') }}
      </ButtonElement>
    </form>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { useSmsApi } from '@/use/smsApi'
import { useUser } from '@/use/user'
import Select2 from 'vue3-select2-component'
import ButtonElement from '@/components/ButtonElement'

export default {
  components: { Select2, ButtonElement },
  data () {
    return {
      loading: false,
      userId: '',
      email: '',
      reason: null,
      message: '',
      messageMinLength: 2
    }
  },
  computed: {
    reasons () {
      return {
        'Number problem': this.$t('text_6261357f6d8ce401573ad3f8'),
        'Finance / Billing': this.$t('text_6261357f6d8ce401573ad3fa'),
        'Account hold': this.$t('text_6261357f6d8ce401573ad3fc'),
        'Account delete': this.$t('text_6261357f6d8ce401573ad3fe'),
        'Feedback / Questions': this.$t('text_6261357f6d8ce401573ad400'),
        Partnership: this.$t('text_6261357f6d8ce401573ad402'),
        API: this.$t('text_6261357f6d8ce401573ad404')
      }
    }
  },
  setup () {
    const { initApiInst, smsUtilsApi } = useSmsApi()
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const { getOS, getBrowserData } = useUser()
    return { v$: useVuelidate(), executeRecaptcha, recaptchaLoaded, initApiInst, smsUtilsApi, getOS, getBrowserData }
  },
  mounted () {
    this.$store.commit('notificationModal/hide')
    const auth = getAuth()
    this.userId = auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : ''
    this.email = auth.currentUser && auth.currentUser.email ? auth.currentUser.email : ''
  },
  validations () {
    return {
      reason: {
        required
      },
      email: {
        required,
        email
      },
      message: {
        required,
        minLength: minLength(this.messageMinLength)
      }
    }
  },
  methods: {
    async contactUs () {
      const isValid = await this.v$.$validate()

      if (isValid) {
        await this.recaptchaLoaded()
        const recaptchaToken = await this.executeRecaptcha('contact')
        this.loading = true
        try {
          const subject = this.createSubject()
          this.message = this.message.replace(/\r\n|\r|\n/g, '<br />')
          const message = this.reportProblemMessage()
          const formData = {
            contact: {
              subject,
              email: this.email,
              message,
              recaptchaToken
            }
          }

          await this.initApiInst()
          const resp = await this.smsUtilsApi.contacts(formData)
          if (resp && resp.result === 'success') {
            this.$store.commit('notificationModal/show', {
              title: this.$t('text_625f09ede4603200c6ef1405'),
              text: this.$t('text_625f09ede4603200c6ef1409'),
              buttonText: this.$t('text_625f09ede4603200c6ef140c')
            })
            this.clearFormData()
            this.v$.$reset()
          }
        } catch (e) {
          this.$store.commit('notificationModal/showSomethingWrongModal', e.status ? e.status : '')
        } finally {
          this.loading = false
        }
      }
    },
    createSubject () {
      let subjectPrefix = ''
      const reason = Object.keys(this.reasons).find(reasonKey => this.reasons[reasonKey] === this.reason)
      const reasonsKeys = Object.keys(this.reasons)
      switch (reason) {
        case reasonsKeys[0]:
          subjectPrefix = '[Activation]'
          break
        case reasonsKeys[1]:
          subjectPrefix = '[Finance]'
          break
        case reasonsKeys[2]:
          subjectPrefix = '[Account hold]'
          break
        case reasonsKeys[3]:
          subjectPrefix = '[Delete account]'
          break
        case reasonsKeys[4]:
          subjectPrefix = '[Questions]'
          break
        case reasonsKeys[5]:
          subjectPrefix = '[Partnership]'
          break
        case reasonsKeys[6]:
          subjectPrefix = '[API]'
          break
      }

      return `${subjectPrefix} Temp Number`
    },
    reportProblemMessage () {
      const os = this.getOS()
      const browser = this.getBrowserData()
      return `${this.$t('logs_reason').replace('__0__', this.reason)}<br /><br />` +
        `${this.$t('logs_message').replace('__0__', this.message)}<br /><br />` +
        `------------------------------<br/>${this.$t('logs_details')}<br />------------------------------<br/>` +
        `Platform/OS: ${os}<br />` +
        `Browser: ${browser}<br />` +
        `Language: ${navigator.language}<br />` +
        `User id: ${this.userId ? this.userId : ''}<br />` +
        `User email: ${this.email ? this.email : ''}`
    },
    clearFormData () {
      this.reason = this.message = ''
    },
    clearEmail () {
      this.email = ''
      this.$refs.emailInput.focus()
    }
  }
}
</script>

<style lang="scss">
  .contact-page {
    padding-top: 30px;
    max-width: 448px;
    padding-bottom: 20px;
    margin-bottom: 70px;

    h1 {
      margin: 30px 0 8px;
      font-weight: 600;
      font-size: 34px;
      line-height: 43px;
      letter-spacing: -1px;
      color: #191632;
      text-align: center;
    }
    h2 {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #848194;
      text-align: center;
    }

    form {
      margin-top: 40px;
    }

    .input-control {
      margin-bottom: 16px;

      .select2-container {
        width: 100% !important;

        .select2-selection {
          border: 1px solid #EFEFEF;
          border-radius: 15px;
          padding: 20px 20px 20px 40px;
          font-size: 14px;
          color: #848194;
          background: #FFF;
          outline: none;
          height: auto;
          &__rendered {
            padding-left: 0;
            line-height: 18px;
            color: #848194;
          }
          &__placeholder{
            color: #848194;
          }
          &__arrow{
            top: 16px;
            right: 10px;
          }
        }
      }

      textarea {
        width: 100% !important;
        border: 1px solid #EFEFEF;
        border-radius: 15px;
        padding: 20px 20px 20px 40px;
        font-size: 14px;
        color: #848194;
        background: #FFF;
        outline: none;
        resize: none;
      }

      &:before {
        position: absolute;
        content: '';
        left: 18px;
        top: 24px;
        width: 13px;
        height: 11px;
        z-index: 2;
      }
    }

    .number-control {
      &:before {
        left: 15px;
        top: 21px;
        width: 18px;
        height: 18px;
        background-size: cover !important;
        background: url('../assets/form/identifier.svg');
      }
    }
    .email-control {
      &:before {
        background-size: cover !important;
        background: url('../assets/form/email.svg');
      }
    }
    .reason-control {
      &:before {
        left: 15px;
        top: 23px;
        width: 16px;
        height: 16px;
        background-size: cover !important;
        background: url('../assets/form/message-question-outline.svg');
      }
    }
    .message-control {
      &:before {
        left: 15px;
        top: 23px;
        width: 16px;
        height: 16px;
        background-size: cover !important;
        background: url('../assets/form/message-outline.svg');
      }
    }

    .warning-alert {
      margin: 10px 0 30px;
      border: 1px solid #fb8c00 !important;
      color: #fb8c00 !important;
      caret-color: #fb8c00 !important;
      border-radius: 4px;
      padding: 16px 20px;
      position: relative;
      text-align: center;
      font-size: 15px;
      line-height: 22px;
      a {
        color: #fb8c00;
        font-weight: 600;
      }
    }

    @media (max-width: 590px) {
      .contact-page {
        padding-top: 15px;
        padding-bottom: 20px;
      }

      h1 {
        font-size: 28px;
        padding-bottom: 10px;
      }
    }
  }

  .rtl{
    .contact-page {
      .input-control {
        .select2-container {
          .select2-selection {
            padding: 20px 40px 20px 20px;
            &__rendered {
              padding-right: 0;
            }
            &__arrow{
              left: 10px;
              right: auto;
            }
          }
        }

        textarea {
          padding: 20px 40px 20px 20px;
        }

        &:before {
          right: 18px;
          left: auto;
        }
      }

      .number-control {
        &:before {
          right: 15px;
          left: auto;
        }
      }
      .reason-control {
        &:before {
          right: 15px;
          left: auto;
        }
      }
      .message-control {
        &:before {
          right: 15px;
          left: auto;
        }
      }
    }
  }

  .select2-dropdown {
    border: 1px solid #EFEFEF;
    border-radius: 15px;
    &--above {
      .select2-results__option:first-child {
        border-radius: 15px 15px 0 0;
      }
    }
    &--below {
      .select2-results__option:last-child {
        border-radius: 0 0 15px 15px;
      }
    }
  }
  .select2-results__option {
    padding: 12px 20px;
    font-size: 14px;
    color: #848194;
  }
  .select2-results__option--highlighted {
    background-color: #fbe2ba !important;
    color: #848194 !important;
  }
  .select2-container--default .select2-results>.select2-results__options {
    max-height: 300px;
    overflow-y: auto;
  }
</style>
