<template>
  <div
    class="amount-control"
    :class="{'amount-control--active': active}"
  >
    <input
      :value="modelValue"
      :placeholder="$t('text_61a8d7461cd34e01670c871b')"
      @keypress="isNumber($event)"
      @input="$emit('update:modelValue', $event.target.value)"
      type="text"
    >
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    active: Boolean,
    modelValue: String || null
  },
  methods: {
    isNumber: function (evt) {
      if (/\d/.test(evt.key)) {
        return true
      } else {
        evt.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .amount-control {
    border: 1px solid rgba(25, 22, 50, 0.12);
    border-radius: 15px;

    &--active {
      border: 1px solid #FA8231;
    }

    input {
      width: 100%;
      font-size: 18px;
      border: none;
      padding: 24px 20px;
      color: rgba(20, 20, 43, 0.6);
      border-radius: 15px;

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 590px) {
    .amount-control {
      width: 100%;

      input {
        font-size: 14px;
        padding: 24px 18px;
      }
    }
  }
</style>
