<template>
  <div class="authWrapper">
    <TitleElement tace>
      <h1 class="auth-title__main title__main--pb0 mb-2">{{ $t('text_6167c4a5321c670152f0e2a3') }}</h1>
      <h2 class="auth-title__subtitle">{{ $t('text_6167c4a5321c670152f0e20b') }}</h2>
    </TitleElement>
    <Login />
  </div>
</template>

<script>
import TitleElement from '@/components/TitleElement.vue'
import Login from '@/components/SignIn/Login.vue'

export default {
  components: {
    TitleElement,
    Login
  }
}
</script>
