import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useStore } from 'vuex'

export function useStripe () {
  const { initApiInst, smsPaymentsApi } = useSmsApi()
  const { handleNotAuthorizedError, showCommonError } = useErrorHandlers()
  const store = useStore()

  const getPaymentIntent = async (amount, os = '', prxAfId = '', recaptchaToken = '') => {
    try {
      await initApiInst()
      const userAgent = window.navigator.userAgent
      const stripeData = {
        amount,
        userAgent
      }
      if (os) stripeData.os = os
      return await smsPaymentsApi.value.newPaymentIntentWithAppCheck({
        stripe: stripeData,
        prxAfId,
        prxCaptchaToken: recaptchaToken
      })
    } catch (e) {
      store.commit('funds/setAddFundsLoading', false)
      if (e.status === 401) {
        handleNotAuthorizedError()
      } else {
        showCommonError(e)
      }
    }
  }

  return { getPaymentIntent }
}
