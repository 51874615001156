<template>
  <div>
    <TitleElement :text="$t('text_64119de89cf25d009d31c9c8')" />
    <TextElement :text="$t('text_64119de89cf25d009d31c9ca')" />

    <div class="reasons">
      <ButtonElement
        v-for="reason in reasons"
        :key="reason.id"
        @click="$emit('handleReason', reason.id)" class="reason" fill
      >
        {{ reason.text }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import TitleElement from '@/components/Modals/Support/Elements/TitleElement'
import TextElement from '@/components/Modals/Support/Elements/TextElement'
import ButtonElement from '@/components/ButtonElement'

export default {
  emits: ['handleReason'],
  components: {
    TitleElement,
    TextElement,
    ButtonElement
  },
  computed: {
    reasons () {
      return [
        {
          name: 'SMS is not coming',
          text: this.$t('text_64119de89cf25d009d31c9cc'),
          id: 1
        },
        {
          name: 'Wrong SMS received',
          text: this.$t('text_64119de89cf25d009d31c9ce'),
          id: 2
        },
        {
          name: 'Number not accepted',
          text: this.$t('text_64119de89cf25d009d31c9d0'),
          id: 3
        },
        {
          name: 'Number has been used before',
          text: this.$t('text_64119de89cf25d009d31c9d1'),
          id: 4
        },
        {
          name: 'Blocked after activation',
          text: this.$t('text_64119de89cf25d009d31c9d2'),
          id: 6
        },
        {
          name: 'Other reason',
          text: this.$t('text_64119de89cf25d009d31c9d3'),
          id: 5
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .reason{
    width: 100%;
    height: 58px;
    background: #F8F8F8;
    border: 1px solid #F8F8F8;
    border-radius: 12px;
    color: #14142B;
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 16px;
    &:active{
      background: #F8F8F8;
      border: 1px solid #F8F8F8;
      color: #14142B;
    }
    &:hover{
      border: 1px solid #F8F8F8;
    }
    @media screen and (max-height: 680px) {
      margin-bottom: 10px;
      height: 48px;
      font-size: 15px;
    }
  }
</style>
