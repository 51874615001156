<template>
  <svg
    :viewBox="viewBox"
    @mousemove="mouseMoving"
    @click="selected"
    @touchstart="touchStart"
    @touchend="touchEnd"
    height="20"
    width="22"
    class="vue-star-rating-star"
  >
    <path :opacity="fill ? 1 : 0.25" fill="#FA8231" d="M10.5313 1.53937e-05C10.3837 -0.000905827 10.2389 0.0395371 10.1131 0.116757C9.98742 0.193977 9.88584 0.304885 9.81993 0.436901L7.10816 5.86045L0.668984 6.85141C0.524852 6.87368 0.389705 6.93543 0.27853 7.02983C0.167356 7.12422 0.0844979 7.24756 0.0391457 7.38618C-0.00620649 7.52479 -0.0122807 7.67325 0.0215965 7.81511C0.0554737 7.95696 0.127979 8.08666 0.231071 8.18982L4.68525 12.644L3.69326 19.0904C3.67107 19.2346 3.68928 19.3821 3.74587 19.5166C3.80246 19.6511 3.89521 19.7673 4.01384 19.8523C4.13246 19.9373 4.27232 19.9877 4.41788 19.998C4.56343 20.0083 4.709 19.9781 4.83841 19.9107L10.5261 16.9429L16.2139 19.9107C16.3433 19.9781 16.4889 20.0083 16.6344 19.998C16.78 19.9877 16.9198 19.9373 17.0385 19.8523C17.1571 19.7673 17.2498 19.6511 17.3064 19.5166C17.363 19.3821 17.3812 19.2346 17.359 19.0904L16.367 12.644L20.8212 8.18982C20.9243 8.08666 20.9968 7.95696 21.0307 7.81511C21.0646 7.67325 21.0585 7.52479 21.0131 7.38618C20.9678 7.24756 20.8849 7.12422 20.7738 7.02983C20.6626 6.93543 20.5274 6.87368 20.3833 6.85141L13.9441 5.86045L11.2324 0.436901C11.1672 0.306447 11.0673 0.196574 10.9435 0.11946C10.8198 0.0423461 10.6771 0.00100373 10.5313 1.53937e-05Z" />
  </svg>
</template>

<script type="text/javascript">
export default {
  name: 'Star',
  props: {
    fill: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 22
    },
    starId: {
      type: Number,
      required: true
    },
    rtl: {
      type: Boolean,
      default: false
    }
  },
  emits: ['star-mouse-move', 'star-selected'],
  data () {
    return {
      isStarActive: true
    }
  },
  computed: {
    viewBox () {
      return '0 0 ' + this.size + ' ' + this.size
    }
  },
  methods: {
    mouseMoving ($event) {
      if ($event.touchAction !== 'undefined') {
        this.$emit('star-mouse-move', {
          event: $event,
          position: this.getPosition($event),
          id: this.starId
        })
      }
    },
    touchStart () {
      this.$nextTick(() => {
        this.isStarActive = true
      })
    },
    touchEnd () {
      this.$nextTick(() => {
        this.isStarActive = false
      })
    },
    getPosition ($event) {
      // calculate position in percentage.
      const starWidth = (92 / 100) * this.size
      const offset = (this.rtl) ? Math.min($event.offsetX, 45) : Math.max($event.offsetX, 1)
      const position = Math.round((100 / starWidth) * offset)

      return Math.min(position, 100)
    },
    selected ($event) {
      this.$emit('star-selected', {
        id: this.starId,
        position: this.getPosition($event)
      })
    }
  }
}
</script>

<style scoped>
  .vue-star-rating-star {
    overflow: visible !important;
  }
</style>
