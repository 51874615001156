<template>
  <div :class="{
  'amount--plus': amountPlus && !paymentInReview,
  'payment--review': paymentInReview,
  'amount--minus': !amountPlus,
  }">
    {{ amountPlus ? '+$' : '-$' }}{{ item.amount }}
  </div>
</template>

<script>
export default {
  props: {
    item: {}
  },
  computed: {
    amountPlus () {
      const isSupportRefund = this.item.details.type === 'refund' && this.item.details.gateway === 'support'
      return this.item.details.type === 'in' || isSupportRefund
    },
    paymentInReview () {
      return this.item.details.type === 'in' && this.item.details.status === 'review'
    }
  }
}
</script>

<style lang="scss" scoped>
  div {
    padding-left: 10px;
    padding-right: 32px;
    justify-content: flex-end;
    &.amount--minus {
      color: #FA8231;
    }
    &.amount--plus {
      color: #47B01A;
    }
    &.payment--review {
      color: rgba(20, 20, 43, 0.6);
    }
  }

  .rtl{
    div {
      padding-left: 32px;
      padding-right: 10px;
    }
  }

  @media (max-width: 590px) {
    div {
      padding-right: 13px !important;
      border-radius: 0 12px 12px 0;
      border-right: 1px solid rgba(25, 22, 50, 0.12);
    }

    .rtl{
      div {
        padding-right: 0 !important;
        padding-left: 13px !important;
        border-radius: 12px 0 0 12px;
        border-right: none;
        border-left: 1px solid rgba(25, 22, 50, 0.12);
      }
    }
  }
</style>
