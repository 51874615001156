<template>
  <form ref="oxprocessingForm" action="https://app.0xprocessing.com/Payment" method="post" class="d-none">
    <input name="amountUsd" type="hidden" :value="amount" />
    <input name="currency" type="hidden" :value="currency" />
    <input name="email" type="hidden" :value="email" />
    <input name="shopId" type="hidden" :value="merchantId" />
    <input name="clientId" type="hidden" :value="uid" />
    <input name="successUrl" type="hidden" :value="`${baseUrl}?status=success&type=crypto`" />
    <input name="cancelUrl" type="hidden" :value="`${baseUrl}?status=error`" />
    <input v-if="testMode" name="test" type="hidden" :value="true" />
    <input name="description" type="hidden" :value="`Temp-Number purchase ${amount}$`" />
    <input name="billingId" type="hidden" :value="billingId" />
    <input name="emailRequest" type="hidden" :value="!Boolean(email)" />
  </form>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'

export default {
  props: {
    oxprocessingPay: Boolean,
    currency: String,
    amount: Number,
    uid: String,
    email: String,
    os: String,
    afId: String
  },
  data () {
    return {
      baseUrl: `${process.env.VUE_APP_URL}funds`,
      merchantId: process.env.VUE_APP_0XPROCESSING_MERCHANT_ID,
      testMode: Boolean(Number(process.env.VUE_APP_0XPROCESSING_TEST_MODE)),
      billingId: ''
    }
  },
  watch: {
    oxprocessingPay (val) {
      if (val) {
        this.add0xprocessingFunds()
      }
    }
  },
  setup () {
    const { initApiInst, smsPaymentsApi } = useSmsApi()
    const { handleNotAuthorizedError, showCommonError } = useErrorHandlers()
    return { initApiInst, smsPaymentsApi, handleNotAuthorizedError, showCommonError }
  },
  methods: {
    async add0xprocessingFunds () {
      await this.getBillingId()
      if (!this.billingId) return
      this.$nextTick(() => {
        this.$refs.oxprocessingForm.submit()
      })
    },
    async getBillingId () {
      this.$store.commit('funds/setAddFundsLoading', true)
      try {
        await this.initApiInst()
        const oxprocessingData = {
          amount: this.amount,
          user_id: this.uid
        }
        if (this.os) oxprocessingData.os = this.os
        const resp = await this.smsPaymentsApi.newOxProcessingPayment({
          oxProcessing: oxprocessingData,
          prxAfId: this.afId
        })
        this.billingId = resp?.bill_id || ''
      } catch (e) {
        if (e.status === 401) {
          this.handleNotAuthorizedError()
        } else {
          this.showCommonError(e)
        }
      } finally {
        this.$store.commit('funds/setAddFundsLoading', false)
      }
    }
  }
}
</script>
