<template>
  <div class="change-email">
    <TitleElement tace>
      <h1 class="auth-title__main title__main--pb0">{{ $t('text_6167c4a5321c670152f0e2ad') }}</h1>
      <h2 class="auth-title__subtitle">{{ $t('text_6167c4a5321c670152f0e22c') }}</h2>
    </TitleElement>
    <ChangeEmailForm />
  </div>
</template>

<script>
import TitleElement from '@/components/TitleElement.vue'
import ChangeEmailForm from '@/components/Forms/ChangeEmailForm/ChangeEmailForm.vue'

export default {
  components: {
    TitleElement,
    ChangeEmailForm
  },
  mounted () {
    if (!this.$store.state.auth.authPasswordProvider) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .change-email {
    width: 400px;
  }
</style>
