<template>
  <div class="modal">
    <div class="modal__container">
      <div class="title">
        <slot name="title" />
      </div>
      <div class="body">
        <slot name="text" />
      </div>
      <div class="modal__buttons-question">
        <slot name="acceptBtn" />
        <ButtonElement @click="$emit('decline')" class="modal__button modal__button-decline" height="58px">
          <slot name="declineBtn" />
        </ButtonElement>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  emits: ['decline'],
  components: {
    ButtonElement
  }
}
</script>

<style lang="scss" scoped>
  .modal{
    &__container{
      width: 311px;
    }
    &__button-decline{
      margin-top: 16px;
    }
  }
</style>
