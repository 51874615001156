import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useLoadScript } from '@/use/loadScript'
import { getAuth } from 'firebase/auth'
import { useI18n } from 'vue-i18n'

export function useCrispChat () {
  const { loadScript } = useLoadScript()
  const router = useRouter()
  const store = useStore()
  const { locale } = useI18n()
  const crispChatEnabled = parseInt(process.env.VUE_APP_CRISP_ENABLED, 10)

  const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
  watch(isAuthenticated, async (val) => {
    await toggleCrispChat(val)
  })

  const initCrispChat = async () => {
    if (isAuthenticated.value && crispChatEnabled) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID
      window.CRISP_RUNTIME_CONFIG = { locale: locale.value }
      await loadScript('https://client.crisp.chat/l.js')
      window.$crisp.push(['safe', true])
      setCrispChatUserData()
    }
  }

  const toggleCrispChat = async (isAuthenticated) => {
    if (crispChatEnabled) {
      if (isAuthenticated) {
        if (window.$crisp) {
          window.$crisp.push(['config', 'hide:vacation', [false]])
          setCrispChatUserData()
        } else {
          await initCrispChat()
        }
      } else {
        window.$crisp.push(['do', 'session:reset'])
        window.$crisp.push(['do', 'chat:close'])
        window.$crisp.push(['config', 'hide:vacation', [true]])
      }
    }
  }

  const setCrispChatUserData = () => {
    const { email, userId, balance } = getUserData()

    window.CRISP_TOKEN_ID = userId
    window.$crisp.push(['do', 'session:reset'])
    window.$crisp.push(['set', 'user:email', email])
    window.$crisp.push(['set', 'session:data', [[['balance', `$${balance}`], ['userId', userId]]]])
  }

  const sendCrispReport = (message) => {
    setCrispChatUserData()
    window.$crisp.push(['do', 'message:send', ['text', message]])
  }

  const getUserData = () => {
    const auth = getAuth()
    const email = auth.currentUser && auth.currentUser.email ? auth.currentUser.email : ''
    const userId = auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : ''
    const balance = store.state.user.balance ? store.state.user.balance : 0

    return { email, userId, balance }
  }

  const openChatWindow = () => {
    window.$crisp.push(['do', 'chat:open'])
  }

  const openCrispChatFromSupportLink = async (toRoute) => {
    if (toRoute.query.crispChat && toRoute.query.crispChat === 'true') {
      if (store.state.auth.isAuthenticated && window.$crisp) {
        openChatWindow()
        await router.push({ name: toRoute.name })
      } else {
        await router.push({ name: 'ContactUs' })
      }
      store.commit('notificationModal/hide')
    }
  }

  return { initCrispChat, sendCrispReport, openChatWindow, openCrispChatFromSupportLink }
}
