import { getAuth } from 'firebase/auth'
import amplitude from 'amplitude-js'
import i18n from '@/utils/i18n'
import Fingerprint from '@/utils/fingerprint'
import { useTranslate } from '@/use/translate'

const { addBreakLinesToText } = useTranslate()

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    authPasswordProvider: false,
    alreadyHaveAccountText: '',
    authCredentials: null
  },
  mutations: {
    setIsAuthenticated: (state, val) => {
      state.isAuthenticated = val
    },
    setAuthPasswordProvider: (state, val) => {
      state.authPasswordProvider = val
    },
    setAlreadyHaveAccountText: (state, type) => {
      let provider = (type.charAt(0).toUpperCase() + type.slice(1)).replace('.com', '')
      provider = provider === 'Password' ? 'Email' : provider
      provider = '<strong>' + provider + '</strong>'
      state.alreadyHaveAccountText = addBreakLinesToText(i18n.global.t('accounts_merge_popup_subtitle').replace('__0__', provider))
    },
    clearAlreadyHaveAccountText: (state) => {
      state.alreadyHaveAccountText = ''
    },
    setAuthCredentials (state, val) {
      state.authCredentials = val
    }
  },
  actions: {
    setUserData ({ commit, rootState }) {
      const auth = getAuth()
      const user = auth.currentUser
      const fingerprint = getFingerprint()
      commit('common/setPrxPrint', fingerprint, { root: true })
      commit('setIsAuthenticated', !!user)

      if (user) {
        amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_API_KEY, user.uid)

        if (user.providerData && user.providerData.length > 0) {
          const passwordProvider = user.providerData.find(provider => provider.providerId === 'password')
          commit('setAuthPasswordProvider', !!passwordProvider)

          const forceEmailConfirmation = rootState.common.forceEmailConfirmation
          if (passwordProvider && !user.emailVerified) {
            if (forceEmailConfirmation) {
              commit('modal/toggleForceEmailConfirmationModal', true, { root: true })
            } else {
              const alertExists = rootState.alert.alerts.find(alert => alert.name === 'confirm_email')
              if (!alertExists) {
                setTimeout(() => {
                  commit('alert/add', {
                    id: Date.now(),
                    name: 'confirm_email',
                    text: i18n.global.t('confirm_email_notification')
                  }, { root: true })
                }, 100)
              }
            }
          }
        }
      }
    }
  }
}

function getFingerprint () {
  let fingerprint = localStorage.getItem('prxPrint')
  if (!fingerprint) {
    const fp = new Fingerprint({ canvas: true, ie_activex: true, screen_resolution: true })
    fingerprint = fp.get()
    localStorage.setItem('prxPrint', fingerprint)
  }
  return fingerprint
}
