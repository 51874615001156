<template>
  <div class="tooltip">
    <slot></slot>
    <p class="tooltip__text">{{text}}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
  .tooltip {
    cursor: pointer;
    position: relative;

    &:hover {
      .tooltip__text {
        display: block;
      }
    }

    &__text {
      bottom: calc(100% + 12px);
      left: calc(50% - 75px);
      position: absolute;
      display: none;
      width: 150px;
      padding: 12px;
      background: #FA8231;
      border-radius: 8px;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.2px;
      color: #FFFFFF;

      &:after {
        content: '';
        position: absolute;
        bottom: -24px;
        left: calc(50% - 10px);
        border: 10px solid transparent; border-top: 15px solid #FA8231;
      }
    }
  }
</style>
