<template>
  <div v-if="!isMobile() && showNps" class="nps">
    <div class="nps__block">
      <div v-if="step === 1" class="nps__block-step1">
        <div class="nps__header">
          {{ $t('text_640ebc83d294120056cf31e7') }}
        </div>
        <NpsScore @saveScore="saveScore" />
      </div>
      <div v-show="step === 2" class="nps__block-step2">
        <div class="nps__header">
          {{ $t('text_640ebc83d294120056cf31e4') }}
        </div>
        <div class="nps__subheader">
          {{ $t('text_640ebc83d294120056cf31e5') }}
        </div>
        <NpsComment @saveComment="saveComment" />
      </div>
      <div v-if="step === 3" class="nps__block-step3">
        <div class="nps__header">
          {{ $t('text_640ebc83d294120056cf31e9') }}
        </div>
        <div class="nps__subheader">
          {{ $t('text_640ebc83d294120056cf31eb') }}
        </div>
      </div>
    </div>

    <div @click="hideNps(true)" class="closeBtn">
      <img src="@/assets/icons/nps-close-btn.svg" alt="close">
    </div>
  </div>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import NpsScore from '@/components/Nps/Score'
import NpsComment from '@/components/Nps/Comment'

export default {
  components: { NpsScore, NpsComment },
  data () {
    return {
      step: 1,
      npsId: null
    }
  },
  computed: {
    showNps () {
      return this.$store.state.common.showNps
    },
    npsScoreDaysFrequency () {
      return this.$store.state.common.npsScoreDaysFrequency
    }
  },
  setup () {
    const { initApiInst, smsUserApi } = useSmsApi()
    return {
      initApiInst,
      smsUserApi
    }
  },
  methods: {
    async saveScore (score) {
      try {
        this.setNpsTimestamp(this.npsScoreDaysFrequency)
        localStorage.removeItem('npsActivationsCount')
        const _this = this
        await this.initApiInst()
        this.smsUserApi.nps({
          NPS: {
            platform: 'web',
            score: parseInt(score, 10)
          }
        }).then((res) => {
          _this.npsId = res.id
        })
      } catch (e) {
      } finally {
        this.step = 2
      }
    },
    async saveComment (comment) {
      try {
        if (comment && comment.length > 0 && this.npsId) {
          await this.initApiInst()
          this.smsUserApi.updateNps({
            nPS2: {
              id: this.npsId,
              comment: comment
            }
          })
        }
      } catch (e) {
      } finally {
        this.step = 3
        setTimeout(() => {
          this.hideNps()
        }, 5000)
      }
    },
    hideNps (force = false) {
      // Force close NPS window on step 1 (show net time after 24 hours)
      if (force && this.step === 1) {
        this.setNpsTimestamp(1)
      }
      this.$store.commit('common/setShowNps', false)
      this.step = 1
    },
    setNpsTimestamp (days) {
      const startTimestamp = Date.now()
      const npsTimestamp = startTimestamp + days * 24 * 60 * 60 * 1000
      localStorage.setItem('npsTimestamp', npsTimestamp.toString())
    }
  }
}
</script>

<style lang="scss" scoped>
  .nps{
    min-height: 200px;
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 5;
    box-shadow: 0px -16px 32px rgba(0, 0, 0, 0.03);

    &__block{
      width: 766px;
      margin: 0 auto;

      &-step1{
        margin: 52px 0 50px;
      }
      &-step2{
        margin: 38px 0 36px;
      }
      &-step3{
        margin: 100px 0;
      }
    }

    &__header {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.2px;
      color: #14142B;
    }

    &__subheader {
      text-align: center;
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #14142B;
      opacity: 0.6;
    }

    .closeBtn{
      position: absolute;
      top: 28px;
      right: 32px;
      cursor: pointer;
      width: 48px;
      height: 48px;
    }

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .rtl{
    .nps{
      .closeBtn{
        left: 32px;
        right: auto;
      }
    }
  }
</style>
