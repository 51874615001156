<template>
  <div :style="{height: height}" class="title" :class="{'title--center': tace}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    height: String,
    tace: Boolean
  }
}
</script>

<style lang="scss">
  .title {
    &--center {
      text-align: center;
    }

    &__main {
      padding-bottom: 16px;
      font-size: 36px;
      line-height: 46px;
      font-weight: 600;
      color: #14142B;
      letter-spacing: -0.2px;

      &--pb0 {
        padding-bottom: 0;
      }

      &--sm {
        font-size: 24px;
      }

      &--mobile {
        display: none;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 24px;
      color: rgba(20, 20, 43, 0.6);
      font-weight: 400;

      &--mobile {
        display: none;
      }

      &--sm {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 680px) {
    .title {
      &--mobile {
        .title__main {
          display: none;
        }

        .title__subtitle {
          display: none;
        }
      }

      &__main {
        &--mobile {
          font-size: 24px;
          padding-bottom: 8px;
          display: block !important;
        }
      }

      &__subtitle {
        &--mobile {
          font-size: 24px;
          padding-bottom: 8px;
          display: block !important;
        }
      }
    }
  }

  @media (max-width: 590px) {
    .title {
      &__main {
        font-size: 24px;
        line-height: 30px;
      }

      &__subtitle {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
</style>
