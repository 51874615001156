<template>
  <div @click="closeModal" class="modal">
    <div @click.stop="" class="modal__container">
      <div class="title">{{ $t('text_61bae35883730600eabf9b5c') }}</div>
      <div class="body">{{ $t('text_61bae35883730600eabf9b62') }}</div>

      <ButtonElement @click="enableNotificationsHandler" class="modal__button" fill height="58px">{{ $t('text_61bae35883730600eabf9b60') }}</ButtonElement>
    </div>
  </div>
</template>

<script>
import { useCloudMessaging } from '@/use/cloudMessaging'
import ButtonElement from '@/components/ButtonElement'

export default {
  components: { ButtonElement },
  setup () {
    const { enableNotifications } = useCloudMessaging()
    return { enableNotifications }
  },
  methods: {
    async enableNotificationsHandler () {
      this.$store.commit('modal/toggleEnableNotificationsModal')
      await this.enableNotifications()
    },
    closeModal () {
      localStorage.setItem('fcmEnabled', 'false')
      this.$store.commit('user/setFcmEnabled', false)
      this.$store.commit('modal/toggleEnableNotificationsModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal{
    &__container {
      max-width: 311px;

      .modal__button-close{
        margin-top: 16px;
      }
    }
  }
</style>
