<template>
  <PageLoader v-if="orderLoading" :marginTop="20" />
  <ul v-else-if="!isSearchOpen && countries" class="radio-buttons radio-buttons__column">
    <li v-for="(country, index) in countries" :key="index">
      <div
        @click="selectCountry(country)"
        :class="{ 'noNumbers': !country.hasNumbers }"
        class="radio-button"
      >
        <div class="radio-button__images radio-button__images-country">
          <img :src="`${iconBaseUrl}${country.countryIconPath}`" class="radio-button__main-image orderCountryFlag" alt="img">
        </div>
        <div class="radio-button__text">{{ country.countryName }}</div>
        <div
          :class="{ 'radio-button__toggle--active': activeCountry && activeCountry.countryId === country.countryId }"
          class="radio-button__toggle"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import PageLoader from '@/components/Loaders/PageLoader.vue'

export default {
  components: { PageLoader },
  data () {
    return {
      iconBaseUrl: null,
      countries: null,
      cacheMaxItems: 20,
      cacheTime: 300 // in seconds
    }
  },
  computed: {
    activeService () {
      return this.$store.state.order.service
    },
    activeCountry () {
      return this.$store.state.order.country
    },
    isSearchOpen () {
      return this.$store.state.order.isSearchOpen
    },
    currentTimestamp () {
      return Math.round(Date.now() / 1000)
    },
    popularCountriesCache () {
      return JSON.parse(localStorage.getItem('popularCountries')) || {}
    },
    orderLoading () {
      return this.$store.state.order.orderLoading
    }
  },
  setup () {
    const { initApiInst, smsServicesApi } = useSmsApi()
    const { handle503Error } = useErrorHandlers()
    return { initApiInst, smsServicesApi, handle503Error }
  },
  mounted () {
    this.$store.commit('order/setOrderLoading', true)
    this.loadPopularCountries()
  },
  methods: {
    async loadPopularCountries () {
      if (this.activeService && this.activeService.serviceId) {
        try {
          let needSaveToCache = false
          let popularCountriesResp = this.getPopularCountriesFromCache()
          if (!popularCountriesResp) {
            await this.initApiInst()
            popularCountriesResp = await this.smsServicesApi.getPopularCountriesByService(this.activeService.serviceId, {
              prxLang: this.$i18n.locale,
              prxPrint: this.$store.state.common.prxPrint
            })
            needSaveToCache = true
          }
          if (popularCountriesResp) {
            if (needSaveToCache) this.savePopularCountriesCache(popularCountriesResp)
            this.iconBaseUrl = popularCountriesResp.iconBaseUrl
            this.countries = popularCountriesResp.countries
            if (this.activeCountry) {
              const existsCountryIndex = this.countries.findIndex(country => country.countryId === this.activeCountry.countryId)
              if (existsCountryIndex !== -1) {
                this.countries.splice(existsCountryIndex, 1)
              }
              this.countries.unshift(this.activeCountry)
              this.countries = this.countries.slice(0, 5)
            }
          }
        } catch (e) {
          if (e.status === 503) {
            this.handle503Error()
          }
        } finally {
          this.$store.commit('order/setOrderLoading', false)
        }
      }
    },
    getPopularCountriesFromCache () {
      if (this.popularCountriesCache.items) {
        const popularCountriesByService = this.popularCountriesCache.items.find(item => item.service === this.activeService.serviceId)
        if (popularCountriesByService) {
          if (popularCountriesByService.time > this.currentTimestamp) {
            return {
              iconBaseUrl: this.popularCountriesCache.iconBaseUrl,
              countries: popularCountriesByService.countries
            }
          } else {
            const index = this.popularCountriesCache.items.findIndex(item => item.service === this.activeService.serviceId)
            if (index !== -1) {
              this.popularCountriesCache.items.splice(index, 1)
              localStorage.setItem('popularCountries', JSON.stringify(this.popularCountriesCache))
            }
          }
        }
      }
      return null
    },
    savePopularCountriesCache (data) {
      if (this.popularCountriesCache.items && this.popularCountriesCache.items.length >= this.cacheMaxItems) {
        this.popularCountriesCache.items.pop()
      }
      const newItem = {
        time: this.currentTimestamp + this.cacheTime,
        service: this.activeService.serviceId,
        countries: data.countries
      }
      this.popularCountriesCache.iconBaseUrl = data.iconBaseUrl
      if (!this.popularCountriesCache.items) {
        this.popularCountriesCache.items = []
      }
      this.popularCountriesCache.items.unshift(newItem)

      localStorage.setItem('popularCountries', JSON.stringify(this.popularCountriesCache))
    },
    selectCountry (country) {
      if (this.isMobile() && this.$store.state.order.country && this.$store.state.order.country.countryId === country.countryId) {
        // Clear selected country on it click (only on Mobile)
        this.$store.commit('order/setCountry', null)
        this.$store.commit('order/setPrice', null)
        return
      }
      this.$store.commit('order/setIconBaseUrl', this.iconBaseUrl)
      this.$store.commit('order/setCountry', country)
      this.$store.commit('order/setPrice', country.price)
    }
  }
}
</script>

<style lang="scss" scoped></style>
