<template>
  <div>
    <TitleElement :text="$t('text_64119de89cf25d009d31c9d6')" />
    <TextElement :text="$t('text_64119de89cf25d009d31c9dc')" />
    <OpenLiveChatBtn @closeModal="$emit('closeModal')" />
    <ButtonElement
      @click="readMore" class="read-more" fill height="58px" width="100%"
    >
      {{ $t('text_64119de89cf25d009d31c9ef') }}
    </ButtonElement>
  </div>
</template>

<script>
import TitleElement from '@/components/Modals/Support/Elements/TitleElement'
import TextElement from '@/components/Modals/Support/Elements/TextElement'
import OpenLiveChatBtn from '@/components/Modals/Support/Elements/OpenLiveChatBtn'
import ButtonElement from '@/components/ButtonElement'

export default {
  emits: ['closeModal'],
  components: {
    TitleElement,
    TextElement,
    OpenLiveChatBtn,
    ButtonElement
  },
  methods: {
    readMore () {
      this.$emit('closeModal')
      window.open('https://help.temp-number.org/en/article/i-have-not-received-the-code-what-to-do-1vs762k/', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .read-more{
    margin-bottom: 10px;
  }
</style>
