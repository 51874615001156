<template>
  <div class="stepper">
    <div class="container">
      <transition name="fade" appear>
        <TitleElement :tace="true">
          <h1 class="title__main">{{ $t('text_6167c4a5321c670152f0e1c1') }}</h1>
          <h2 v-html="chooseServiceH2" class="title__subtitle" />
        </TitleElement>
      </transition>

      <Steps :currentStep="1" />

      <ServicesSearch />

      <div class="stepper__buttons">
        <transition name="fade">
          <ServicesButtons />
        </transition>
      </div>
    </div>

    <StepperControl :currentStep="1" />
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import Steps from './Steps.vue'
import ServicesSearch from '@/components/Search/ServicesSearch.vue'
import ServicesButtons from '@/components/Order/ServicesButtons.vue'
import StepperControl from '@/components/Stepper/StepperControl.vue'
import TitleElement from '@/components/TitleElement.vue'

export default {
  components: {
    Steps,
    ServicesSearch,
    ServicesButtons,
    StepperControl,
    TitleElement
  },
  data () {
    return {
      isModal: false,
      activeServiceId: null,
      activeCountryId: null,
      countries: [],
      searchItems: [],
      searchItems2: []
    }
  },
  computed: {
    chooseServiceH2 () {
      return this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e1c2'))
    },
    activateH2 () {
      return this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e1e7'))
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { addBreakLinesToText }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/animation/fade-solo.scss';
</style>
