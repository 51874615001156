<template>
  <div class="steps">
    <h3 class="steps__title">
      <span v-if="currentStep === 1">{{ $t('text_6167c4a5321c670152f0e1c3') }}</span>
      <span v-if="currentStep === 2">{{ $t('text_6167c4a5321c670152f0e1d5') }}</span>
      <span v-if="currentStep === 3">{{ $t('text_6167c4a5321c670152f0e1e8') }}</span>
    </h3>
    <div class="steps__bar">
      <div :style="{width: currentStep * 33.33 + '%'}" class="steps__bar-fill"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: Number
  }
}
</script>

<style scoped lang="scss">
  .steps {
    padding-top: 30px;
    max-width: 448px;
    margin: 0 auto;

    &__title {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FA8231;
      margin: 8px 0;
    }

    &__bar {
      height: 12px;
      background: #F8F9FA;
      border-radius: 6px;

      &-fill {
        transition: 0.3s;
        height: 100%;
        background: #FA8231;
        border-radius: 6px;
      }
    }
  }

  @media (max-width: 590px) {
    .steps {
      padding-top: 40px;

      &__title {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 320px) {
    .steps {
      padding-top: 20px;
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .steps {
      padding-top: 20px;
    }
  }
</style>
