<template>
  <div v-if="isAuthenticated && balance !== null">
    <router-link :to="{ name: 'Funds' }" class="balance">
      <img class="balance__bag" src="@/assets/icons/money-bag.svg" alt="money-bag">
      <div
        class="balance__info"
        :class="{'balance__info--not-zero': balance !== 0}"
      >
        <p>{{ balance === 0 ? $t('text_61aa1cc4acec8d00be35c816') : $t('text_6167c4a5321c670152f0e29d') }}</p>
        <span>{{ balance === 0 ? '$0.00' : `$${balance}` }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import { useBalance } from '@/use/balance'

export default {
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.isAuthenticated
    },
    balance () {
      return this.$store.state.user.balance
    }
  },
  setup () {
    const { restartCheckBalance, stopCheckBalance } = useBalance()
    return { restartCheckBalance, stopCheckBalance }
  },
  mounted () {
    // Check if not Funds page to avoid double balance check
    if (window.location.pathname && !window.location.pathname.includes('app/funds')) {
      if (this.isAuthenticated) {
        this.restartCheckBalance()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .balance{
    display: flex;
    padding: 8px 12px;
    background: #F8F8F8;
    border-radius: 8px;

    &__bag{
      margin-right: 16px;
    }

    &__info {
      p {
        color: #1C1D36;
        font-size: 12px;
        line-height: 15px;
      }

      span {
        color: #14142B;
        letter-spacing: -0.2px;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  .rtl {
    .balance {
      &__bag {
        margin-left: 16px;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 590px) {
    .balance {
      padding: 12px;
      min-width: 72px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__bag {
        display: none;
      }

      &__info {
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          padding: 0 4px;
        }
        span {
          display: none;
        }

        &--not-zero {
          p {
            display: none;
          }

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.2px;
            display: block;
          }
        }
      }
    }
  }
</style>
