import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useLoadScript } from '@/use/loadScript'

export function useBrick () {
  const store = useStore()
  const router = useRouter()
  const { t } = useI18n()
  const { loadScript } = useLoadScript()
  let brick

  const initBrick = async () => {
    if (!brick) {
      await loadScript(process.env.VUE_APP_BRICK_CDN_URL)
      // await loadScript(`${process.env.VUE_APP_URL}/js/brick-default.1.5.0.min.js`)
    }
  }

  const addBrickFunds = async (amount, uid, email) => {
    const products = JSON.parse(process.env.VUE_APP_PAYMENTWALL_PACKAGES)
    const productId = products[amount] ? products[amount] : 'custom'

    brick = new window.Brick({
      public_key: process.env.VUE_APP_BRICK_PUBLIC_KEY,
      amount: amount,
      currency: 'USD',
      container: 'brickForm',
      action: `${process.env.VUE_APP_BRICK_CHARGE_ACTION}/${uid}/${productId}/${amount}`,
      form: {
        merchant: 'Temp Number',
        product: `${amount}$ payment`,
        pay_button: 'Pay',
        show_zip: false,
        show_email: !email,
        show_cardholder: false,
        _values: {
          email
        }
      }
    })
    store.commit('common/setShowBrickForm', true)

    brick.showPaymentForm(function (data) {
      handleBrickCallback(data)
    }, function (data) {
      handleBrickCallback(data)
    })
  }

  const handleBrickCallback = async (data) => {
    hideBrickForm()
    if (!data || data.object === 'Error') {
      store.commit('funds/setAddFundsLoading', false)
      await router.push({ name: 'Funds', query: { status: 'error' } })
    } else if (data.object === 'charge') {
      if (data.risk === 'pending') {
        store.commit('notificationModal/show', {
          title: t('text_6200f409bd91d200c5076c07'),
          text: t('text_6200f409bd91d200c5076c13'),
          buttonText: t('text_6200f409bd91d200c5076c0f')
        })
      } else if (data.risk === 'approved' && data.captured) {
        store.commit('funds/setAddFundsLoading', false)
        await router.push({ name: 'Funds', query: { status: 'success' } })
      }
      store.commit('funds/setAddFundsLoading', false)
    }
  }

  const hideBrickForm = () => {
    store.commit('common/setShowBrickForm', false)
    store.commit('funds/setAddFundsLoading', false)
  }

  return { initBrick, addBrickFunds, hideBrickForm }
}
