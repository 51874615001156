<template>
  <div :class="['vue-star-rating', {'vue-star-rating-rtl':rtl}, {'vue-star-rating-inline': inline}]">
    <div class="sr-only">
      <slot
        name="screen-reader"
        :rating="selectedRating"
        :stars="maxRating"
      >
        <span>Rated {{ selectedRating }} stars out of {{ maxRating }}</span>
      </slot>
    </div>

    <div
      class="vue-star-rating"
      @mouseleave="resetRating"
    >
      <span
        v-for="n in maxRating"
        :key="n"
        :class="[{'vue-star-rating-pointer': !readOnly }, 'vue-star-rating-star']"
        :style="{'margin-right': margin + 'px'}"
      >
        <star
          :fill="fillLevel[n-1]"
          :star-id="n"
          :step="step"
          :rtl="rtl"
          @star-selected="setRating($event, true)"
          @star-mouse-move="setRating"
        />
      </span>
      <span
        v-if="showRating"
        :class="['vue-star-rating-rating-text', textClass]"
      > {{ formattedRating }}</span>
    </div>
  </div>
</template>

<script>
import Star from '@/components/StarRating/Star'

export default {
  name: 'VueStarRating',
  components: { Star },
  props: {
    increment: {
      type: Number,
      default: 1
    },
    rating: {
      type: Number,
      default: 0
    },
    roundStartRating: {
      type: Boolean,
      default: true
    },
    maxRating: {
      type: Number,
      default: 5
    },
    showRating: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textClass: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Number,
      default: 0
    },
    rtl: {
      type: Boolean,
      default: false
    },
    fixedPoints: {
      type: Number,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    activeOnClick: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:rating', 'hover:rating'],
  data () {
    return {
      step: 0,
      fillLevel: [],
      currentRating: 0,
      selectedRating: 0,
      ratingSelected: false
    }
  },
  computed: {
    formattedRating () {
      return (this.fixedPoints === null) ? this.currentRating : this.currentRating.toFixed(this.fixedPoints)
    },
    shouldRound () {
      return this.ratingSelected || this.roundStartRating
    },
    margin () {
      return this.padding
    },
    roundedRating () {
      const inv = 1.0 / this.increment
      return Math.min(this.maxRating, Math.ceil(this.currentRating * inv) / inv)
    }
  },
  watch: {
    rating (val) {
      this.currentRating = val
      this.selectedRating = val
      this.createStars(this.shouldRound)
    }
  },
  created () {
    this.step = this.increment * 100
    this.currentRating = this.rating
    this.selectedRating = this.currentRating
    this.createStars(this.roundStartRating)
  },
  methods: {
    setRating ($event, persist) {
      if (!this.readOnly) {
        const position = (this.rtl) ? (100 - $event.position) / 100 : $event.position / 100
        this.currentRating = (($event.id + position) - 1).toFixed(2)
        this.currentRating = (this.currentRating > this.maxRating) ? this.maxRating : this.currentRating
        if (persist) {
          this.createStars(true, true)
          this.selectedRating = (this.clearable && this.currentRating === this.selectedRating) ? 0 : this.currentRating
          this.$emit('update:rating', this.selectedRating)
          this.ratingSelected = true
        } else {
          this.createStars(true, !this.activeOnClick)
          this.$emit('hover:rating', this.currentRating)
        }
      }
    },
    resetRating () {
      if (!this.readOnly) {
        this.currentRating = this.selectedRating
        this.createStars(this.shouldRound)
      }
    },
    createStars (round = true, applyFill = true) {
      this.currentRating = (round) ? this.roundedRating : this.currentRating
      for (let i = 0; i < this.maxRating; i++) {
        let level = 0
        if (i < this.currentRating) {
          level = (this.currentRating - i > 1) ? 100 : (this.currentRating - i) * 100
        }
        if (applyFill) {
          this.fillLevel[i] = Math.round(level)
        }
      }
    }
  }
}
</script>

<style scoped>
  .vue-star-rating-star {
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
    height: 20px;
  }

  .vue-star-rating-pointer {
    cursor: pointer;
  }

  .vue-star-rating {
    display: flex;
    align-items: center;
  }

  .vue-star-rating-inline {
    display: inline-flex;
  }

  .vue-star-rating-rating-text {
    margin-left: 7px;
  }

  .vue-star-rating-rtl {
    direction: rtl;
  }

  .vue-star-rating-rtl .vue-star-rating-rating-text {
    margin-right: 10px;
    direction: rtl;
  }

  .sr-only {
    display: none;
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
</style>
