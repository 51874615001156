<template>
  <div class="help-link-fixed">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM18 10H15C15 8.2 14 6.6 12.5 5.7L14 3.1C16.5 4.5 18 7.1 18 10ZM10 7C8.3 7 7 8.3 7 10C7 11.7 8.3 13 10 13C11.7 13 13 11.7 13 10C13 8.3 11.7 7 10 7ZM6 3.1L7.5 5.7C6 6.6 5 8.2 5 10H2C2 7.1 3.5 4.5 6 3.1ZM7.5 14.3L6 16.9C8.5 18.4 11.5 18.4 14 16.9L12.5 14.3C11 15.2 9 15.2 7.5 14.3Z" fill="#FA8231"/>
    </svg>

    <span>Need Help</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .help-link-fixed {
    display: none;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #FA8231;
    border-radius: 40px;
    padding: 18px 26px;
    color: #fff;

    &:hover {
      box-shadow: 0px 4px 4px 0px rgba(34, 60, 80, 0.2);
    }

    svg {
      position: relative;
      top: 2px;
      margin-right: 6px;

      path {
        fill: #fff;
      }
    }

    span {
      top: -2px;
      position: relative;
    }
  }

  @media (max-width: 590px) {
    .help-link-fixed {
      display: none;
    }
  }
</style>
