import { useStore } from 'vuex'
import { useOrder } from '@/use/order'

export function useLogo () {
  const store = useStore()
  const { handleNewOrderClick } = useOrder()

  const handleLogoClick = async () => {
    store.commit('order/setForceActivation', false)
    handleNewOrderClick()
  }

  return { handleLogoClick }
}
