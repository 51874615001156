<template>
  <HeaderElement />
  <div class="container clear-layout">
    <div class="clear-layout__logo">
      <img
        v-if="$route.name === 'CreateAccount' && isMobile()"
        @click="handleLogoClick"
        src="@/assets/logo/logo.png"
        alt="temp number"
      >
      <img
        v-else
        @click="handleLogoClick"
        src="@/assets/logo/logo2.svg"
        alt="temp number"
      >
    </div>

    <div class="clear-layout__main">
      <router-view></router-view>
    </div>

    <NotificationModal />
  </div>
</template>

<script>
import { useLogo } from '@/use/logo'
import { useAuth } from '@/use/auth'
import { useCrispChat } from '@/use/crispChat'
import HeaderElement from '@/components/Header/HeaderElement.vue'
import NotificationModal from '@/components/Modals/NotificationModal'

export default {
  components: { HeaderElement, NotificationModal },
  computed: {
    alreadyHaveAccountText () {
      return this.$store.state.auth.alreadyHaveAccountText
    },
    alreadyHaveAccountModal () {
      return this.$store.state.modal.alreadyHaveAccountModal
    },
    authCredentials () {
      return this.$store.state.auth.authCredentials
    }
  },
  watch: {
    $route (to) {
      this.openCrispChatFromSupportLink(to)
    }
  },
  setup () {
    const { handleLogoClick } = useLogo()
    const { getProviderByName } = useAuth()
    const { openCrispChatFromSupportLink } = useCrispChat()
    return { handleLogoClick, getProviderByName, openCrispChatFromSupportLink }
  }
}
</script>

<style lang="scss" scoped>
  .clear-layout {
    margin-top: 65px;
    margin-bottom: 40px;
    display: flex;
    align-content: center;
    flex-direction: column;

    &__logo {
      text-align: center;
      margin-bottom: 32px;
      img {
        cursor: pointer;
      }
    }

    &__main {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 590px) {
    .clear-layout {
      margin-top: 30px;
      &__logo {
        margin-left: auto;
        margin-right: auto;
        width: 86px;
        height: 84px;
        img {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .clear-layout {
      margin-top: 15px;
      margin-bottom: 10px;

      &__logo {
        margin-bottom: 15px;
      }
    }
  }
</style>
