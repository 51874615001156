<template>
  <div class="container history">
    <div>
      <div class="history__header">
        <TitleElement class="history__title">
          <h1 class="title__main title__main--pb0">{{ $t('text_61939fd80716840139f4769f') }}</h1>
          <h2 class="title__subtitle title__subtitle-history">
            <template v-if="itemsCount !== null">
              <component v-if="itemsCount === 0" :is="{ template: addBreakLinesToText($t('text_61b1ec64983da401387f2a37')) }" />
              <component v-if="itemsCount > 0" :is="{ template: addBreakLinesToText($t('text_61939fd80716840139f476b4')) }" />
            </template>
          </h2>
        </TitleElement>

        <TitleElement class="history__title--mobile" tace>
          <h1 class="title__main title__main--pb0">{{ $t('text_61939fd80716840139f4769f') }}</h1>
          <h2 v-if="itemsCount === 0">
            <component :is="{ template: addBreakLinesToText($t('text_61b1ec64983da401387f2a37')) }" />
          </h2>
        </TitleElement>

        <ButtonElement @click="handleNewOrderClick" class="history__order-new" height="64px"  width="210px" fill>
          <img src="@/assets/icons/plus.svg" alt="plus">
          <span>{{ $t('text_61939fd80716840139f476b3') }}</span>
        </ButtonElement>
      </div>

      <transition name="fade" mode="out-in">
        <div v-if="loading">
          <PageLoader />
        </div>
        <div v-else>
          <div v-if="itemsCount > 0" class="activation-history">
            <div v-if="activeActivations.length > 0">
              <div class="activation-history__block-title">{{ $t('text_61939fd80716840139f476b6') }}</div>
              <ul class="activation-history__list">
                <li
                  v-for="activation in activeActivations"
                  :key="activation.id"
                >
                  <router-link :to="{ name: 'Activation', params: { id: activation.id } }" class="activation-history__item">
                    <div class="activation-history__images">
                      <img :src="activation.iconBaseUrl + activation.serviceIconPath" :alt="activation.serviceName" class="activation-history__social-icon">
                      <img :src="activation.iconBaseUrl + activation.countryIconPath" :alt="activation.countryName" class="activation-history__flag-icon">
                    </div>
                    <p class="activation-history__num">{{ activation.formatNumber }}</p>
                    <Tooltip @click.prevent :text="$t('timer_active_message')">
                      <div class="activation-history__time-bar">
                        <vue-countdown
                          :transform="addLeadingZeroToCountdown"
                          @end="finishCountdownHandler()"
                          :time="(activation.numberExpireAt * 1000 + (Date.now() - activation.utc * 1000)) - Date.now()"
                          v-slot="{ minutes, seconds }"
                          class="countdown"
                        >
                          {{ minutes }}:{{ seconds }}
                        </vue-countdown>
                      </div>
                    </Tooltip>
                  </router-link>
                </li>
              </ul>
            </div>
            <div v-if="expiredActivations.length > 0">
              <div class="activation-history__block-title">{{ $t('text_61939fd80716840139f476b8') }}</div>
              <ul class="activation-history__list">
                <li
                  v-for="activation in expiredActivations"
                  :key="activation.id"
                >
                  <router-link :to="{ name: 'Activation', params: { id: activation.id } }" class="activation-history__item">
                    <div class="activation-history__images">
                      <img :src="activation.iconBaseUrl + activation.serviceIconPath" :alt="activation.serviceName" class="activation-history__social-icon">
                      <img :src="activation.iconBaseUrl + activation.countryIconPath" :alt="activation.countryName" class="activation-history__flag-icon">
                    </div>
                    <p class="activation-history__num">{{ activation.formatNumber }}</p>
                    <div v-if="!activation.code && !activation.message" @click.prevent.stop="showNotReceivedModal" class="activation-history__attention">
                      <img src="@/assets/icons/attention.svg" alt="Attention">
                    </div>
                    <div v-if="activation.status === 'supRefunded'" class="activation-history__sup-refunded">
                      {{ $t('text_64147bff2e99e4009ed03526') }}
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div v-else>
            <EmptyListState>
              <component :is="{ template: addBreakLinesToText($t('text_61b1ec64983da401387f2a39')) }" />
            </EmptyListState>
          </div>

          <Paginator
            @next="showNextPage"
            @prev="showPrevPage"
            @selectPage="selectPage"
            :pagesCount="pagesCount"
            :currentPage="currentPage"
            :limit="limit"
            :itemsCount="itemsCount"
            class="pagination"
          />
        </div>
      </transition>

      <MobileBottomMenu />
    </div>
  </div>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useCountdown } from '@/use/countdown'
import { useTranslate } from '@/use/translate'
import { useActiveActivationsCount } from '@/use/activeActivationsCount'
import { useOrder } from '@/use/order'
import PageLoader from '@/components/Loaders/PageLoader.vue'
import TitleElement from '@/components/TitleElement.vue'
import ButtonElement from '@/components/ButtonElement.vue'
import Paginator from '@/components/Paginator.vue'
import MobileBottomMenu from '@/components/MobileBottomMenu/MobileBottomMenu.vue'
import EmptyListState from '@/components/EmptyListState.vue'
import Tooltip from '@/components/Tooltip'

export default {
  components: {
    PageLoader,
    TitleElement,
    ButtonElement,
    Paginator,
    MobileBottomMenu,
    EmptyListState,
    Tooltip
  },
  data () {
    return {
      activations: [],
      activeActivations: [],
      expiredActivations: [],
      currentPage: 1,
      limit: parseInt(process.env.VUE_APP_HISTORY_PAGINATION_LIMIT, 10) || 10,
      pagesCount: 1,
      itemsCount: null,
      loading: true,
      activeStatuses: ['smsRequested', 'retryRequested', 'numberReceived', 'smsReceived', 'retryReceived']
    }
  },
  watch: {
    $route (to) {
      if (to.name === 'History') {
        this.currentPage = to.query.page && parseInt(to.query.page, 10) > 1 ? parseInt(to.query.page, 10) : 1
        this.getHistory()
      }
    }
  },
  setup () {
    const { initApiInst, smsActivationApi } = useSmsApi()
    const { handleNotAuthorizedError, handleNetworkError } = useErrorHandlers()
    const { addLeadingZeroToCountdown } = useCountdown()
    const { getActiveActivationsCount } = useActiveActivationsCount()
    const { handleNewOrderClick } = useOrder()
    const { addBreakLinesToText } = useTranslate()
    return {
      initApiInst,
      smsActivationApi,
      addLeadingZeroToCountdown,
      handleNotAuthorizedError,
      handleNetworkError,
      getActiveActivationsCount,
      handleNewOrderClick,
      addBreakLinesToText
    }
  },
  mounted () {
    this.currentPage = this.$route.query.page && parseInt(this.$route.query.page, 10) > 1
      ? parseInt(this.$route.query.page, 10) : 1
    this.getHistory()
    this.getActiveActivationsCount()
  },
  methods: {
    async getHistory () {
      this.loading = true
      try {
        await this.initApiInst()
        const requestData = {
          page: this.currentPage,
          limit: this.limit,
          prxPrint: this.$store.state.common.prxPrint
        }
        const resp = await this.smsActivationApi.getActivationsHistory(requestData)
        if (resp) {
          this.pagesCount = resp.pages
          this.itemsCount = resp.total
          this.activations = resp.activations
          this.formActivationsArrays()
        }
      } catch (e) {
        this.itemsCount = 0
        if (e.status === 401) {
          await this.handleNotAuthorizedError()
        } else if (e.status === 502) {
          await this.handleNetworkError()
        } else {
          if (e.error && e.error.message && e.error.message.includes('Request has been terminated')) {
            this.handleNetworkError()
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal', e.status ? e.status : '')
          }
        }
      } finally {
        this.loading = false
      }
    },
    formActivationsArrays () {
      this.activeActivations = []
      this.expiredActivations = []
      this.activations.map(activation => {
        const timeSkew = Date.now() - activation.utc * 1000
        const isActive = (activation.numberExpireAt * 1000 + timeSkew) > Date.now()
        isActive && this.activeStatuses.includes(activation.status)
          ? this.activeActivations.push(activation) : this.expiredActivations.push(activation)
      })
    },
    finishCountdownHandler () {
      this.formActivationsArrays()
      this.getActiveActivationsCount()
    },
    showNextPage () {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage () {
      this.openPage(this.currentPage - 1)
    },
    selectPage (id) {
      this.openPage(id)
    },
    async openPage (page) {
      if (page > 1) {
        await this.$router.push({ name: 'History', query: { page } })
      } else {
        await this.$router.push({ name: 'History' })
      }
    },
    showNotReceivedModal () {
      this.$store.commit('notificationModal/show', {
        title: this.$t('text_6193db2a6253c201008eb3d6'),
        text: this.$t('text_6193db2a6253c201008eb3d4'),
        buttonText: this.$t('text_6193db2a6253c201008eb3d8')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/animation/fade-history.scss';

  .history {
    padding-top: 60px;
    max-width: 718px;
    margin: 0 auto 60px auto;

    .title__subtitle-history {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
      margin: 2px 0;
    }

    &__title--mobile {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__order-new {
      margin-top: 5px;
      img {
        margin-right: 10px;
      }

      span {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: justify;
        top: -1px;
      }
    }
  }

  .rtl {
    .history {
      &__order-new {
        img {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }

  .activation-history {
    margin-top: 55px;
    color: #14142b;

    &__block-title {
      height: 42px;
      background: #f8f8f8;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0 32px;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 32px;
    }

    &__list {
      padding-bottom: 56px;
      li{
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      max-width: 650px;
      margin: 0 auto;
      height: 60px;
      border: 1px solid rgba(25, 22, 50, 0.12);
      border-radius: 15px;
      padding: 0 25px;
      cursor: pointer;
      position: relative;

      &:after {
        content: url('../assets/icons/history-arrow.svg');
        position: absolute;
        right: 23px;
      }

      .countdown{
        color: #14142b;
      }
    }

    &__images {
      position: relative;
      height: 24px;
    }

    &__social-icon {
      width: 24px;
      height: 24px;
    }

    &__flag-icon {
      position: absolute;
      top: -1px;
      left: -8px;
      width: 16px;
      height: 12px;
      border: 1px solid #FFFFFF;
      border-radius: 2px;
    }

    &__num {
      min-width: 134px;
      font-size: 14px;
      line-height: 18px;
      margin: 0 10px 0 20px;
      padding: 0;
      color: #191632;
    }

    &__attention {
      height: 18px;
    }

    &__time-bar {
      width: 46px;
      height: 26px;
      background: #ffc100;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }

    &__sup-refunded{
      background: #ffc100;
      font-weight: 300;
      font-size: 10px;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      padding: 6.5px 8px;
      border-radius: 4px;
    }
  }

  .rtl {
    .activation-history {
      &__item {
        &:after {
          left: 23px;
          right: auto;
          transform: rotate(180deg);
        }
      }

      &__flag-icon {
        right: -8px;
        left: auto;
      }

      &__num {
        margin: 0 20px 0 10px;
        direction: ltr;
      }
    }
  }

  .pagination {
    margin-top: 34px;
  }

  @media (max-width: 590px) {
    .activation-history {
      margin-top: 28px;

      &__block-title {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        padding: 12px 14px;
        height: 40px;
        margin-bottom: 16px;
      }

      &__item {
        margin: 0 auto 16px auto;
        padding: 17px 18px 17px 22px;

        &:after {
          right: 18px;
        }
      }

      &__num {
        margin: 0 4px 0 12px;
      }

      &__list {
        padding-bottom: 32px;
      }

      &__time-bar {
        left: 190px;
      }
    }

    .rtl{
      .activation-history {
        &__item {
          padding: 17px 22px 17px 18px;

          &:after {
            left: 18px;
            right: auto;
          }
        }

        &__num {
          margin: 0 12px 0 4px;
        }

        &__time-bar {
          right: 190px;
          left: auto;
        }
      }
    }

    .history {
      padding-top: 0;

      &__title {
        display: none;

        &--mobile {
          display: block;
          margin: 0 auto;
          h2{
            margin-top: 8px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            font-weight: normal;
            color: #14142B;
            opacity: 0.6;
          }
        }
      }

      &__order-new {
        display: none;
      }
    }

    .pagination {
      margin-top: 0;
    }
  }

  @media (max-width: 380px) {
    .activation-history {
      &__time-bar {
        left: 170px;
      }
    }

    .rtl{
      .activation-history {
        &__time-bar {
          right: 170px;
          left: auto;
        }
      }
    }
  }

  @media (max-width: 340px) {
    .activation-history__item:after {
      right: 5px;
    }
    .rtl{
      .activation-history__item:after {
        left: 5px;
        right: auto;
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .history {
      padding-top: 20px;
      &__header {
        margin-top: 10px;
      }
      .pagination {
        margin-top: 14px;
      }
    }
  }

  @media (max-width: 320px) {
    .activation-history{
      &__item {
        padding: 17px 12px 17px 12px;
        &:after{
          display: none;
        }
      }
      &__num {
        margin: 0 4px 0 8px;
        min-width: 125px;
        font-size: 13px;
      }
    }

    .rtl{
      .activation-history{
        &__num {
          margin: 0 8px 0 4px;
        }
      }
    }
  }
</style>
