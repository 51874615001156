<template>
  <div class="create-account-by-socials">
    <CreateAccountList @socialLogin="socialLogin" :items="links" class="create-account-by-socials__links" />

    <div class="create-account-by-socials__line">
      <svg width="348" height="1" viewBox="0 0 348 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="0.5" x2="347.5" y2="0.5" stroke="#14142B" stroke-opacity="0.2" stroke-dasharray="5 5"/>
      </svg>
      <span>{{ $t('text_6167c4a5321c670152f0e1f9') }}</span>
    </div>

    <ButtonElement @click="redirectToSignUp" class="create-account-by-socials__btn" height="58px" fill>{{ $t('text_6167c4a5321c670152f0e1fa') }}</ButtonElement>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithPopup
} from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useAuth } from '@/use/auth'
import { useUser } from '@/use/user'
import { useTapfiliate } from '@/use/tapfiliate'
import CreateAccountList from '@/components/CreateAccount/CreateAccountBySocials/CreateAccountList.vue'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  components: {
    CreateAccountList,
    ButtonElement
  },
  emits: ['changeToEmailForm'],
  data () {
    return {
      auth: null
    }
  },
  computed: {
    links () {
      return [
        {
          id: 0,
          text: this.$t('text_6167c4a5321c670152f0e1f6'),
          imgName: 'apple.svg',
          provider: 'apple.com'
        },
        {
          id: 1,
          text: this.$t('text_6167c4a5321c670152f0e1f7'),
          imgName: 'Facebook.svg',
          provider: 'facebook.com'
        },
        {
          id: 2,
          text: this.$t('text_6167c4a5321c670152f0e1f8'),
          imgName: 'Google.svg',
          provider: 'google.com'
        }
      ]
    }
  },
  setup () {
    const { getProviderByName } = useAuth()
    const { updateTapfiliateTrackId } = useTapfiliate()
    const { getUserProject } = useUser()
    return { getProviderByName, updateTapfiliateTrackId, getUserProject }
  },
  methods: {
    redirectToSignUp () {
      this.$router.push({ name: 'SignUp' })
    },
    async socialLogin (providerName) {
      const auth = getAuth()
      const provider = this.getProviderByName(providerName)
      if (!provider) return

      try {
        const result = await signInWithPopup(auth, provider)
        if (result && result.user) {
          const analytics = getAnalytics()
          logEvent(analytics, 'login', {
            uid: auth.currentUser?.uid ?? ''
          })
          this.$amplitude.logEvent('signup.completed_social')
          await this.updateTapfiliateTrackId()
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('text_6231a5d0ff911700d6c42070'),
            type: 'success',
            timeout: 2000,
            name: 'success_login'
          })
          await this.getUserProject(result.user.uid)
          await this.$router.push({ name: 'Home' })
        }
      } catch (e) {
        if (e.code === 'auth/popup-closed-by-user') return
        if (e.code === 'auth/popup-blocked') {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('blocked_popups_error_web'),
            timeout: 3000,
            name: 'blocked_popups_error_web'
          })
        } else if ([
          'auth/email-already-in-use',
          'auth/credential-already-in-use',
          'auth/account-exists-with-different-credential'
        ].includes(e.code)) {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('email_already_registered_validation'),
            timeout: 3000,
            name: 'email_already_registered_validation'
          })
        } else {
          this.$store.commit('notificationModal/showSomethingWrongModal')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .create-account-by-socials {
    &__links {
      margin-top: 32px;
      margin-bottom: 27px;
    }

    &__line {
      position: relative;
      max-width: 347px;
      margin: 0 auto;
      height: 28px;

      svg {
        width: 100%;
        position: absolute;
        top: 0;
      }

      span {
        top: -8px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 500;
        font-size: 12px;
        color: rgba(20, 20, 43, 0.6);
        padding: 0 7px;
        background: #FFF;
      }
    }

    &__btn {
      width: 400px;
    }
  }

  @media (max-width: 590px) {
    .create-account-by-socials {
      &__btn {
        width: 100%;
      }
    }
  }

</style>
