import Confetti from './confetti'

export { Confetti }

export default {
  install (app, options) {
    if (this.installed) {
      return
    }
    this.installed = true
    app.config.globalProperties.$confetti = new Confetti(options)
  }
}
