<template>
  <router-view/>
</template>

<script>
import { useCrispChat } from '@/use/crispChat'

export default {
  watch: {
    $route (to) {
      this.openCrispChatFromSupportLink(to)
    }
  },
  setup () {
    const { openCrispChatFromSupportLink } = useCrispChat()
    return { openCrispChatFromSupportLink }
  }
}
</script>
