<template>
  <div v-if="showHelpModal" @click="closeModal" class="modal">
    <div @click.stop="" class="modal__container">
      <div @click="closeModal" class="closeBtn">
        <img src="@/assets/icons/closeModalBtn.svg" alt="close modal">
      </div>
      <div class="title">
        <img src="@/assets/icons/needHelpModalImg.svg" alt="need help modal">
        <span>{{ $t('text_6261357f6d8ce401573ad3d5') }}</span>
      </div>
      <div class="body">
        <div class="subtitle">
          {{ $t('text_6261357f6d8ce401573ad3da') }}
        </div>

        <div @click="closeModal" class="buttons">
          <a href="https://help.temp-number.org/" target="_blank" class="btn">
            {{ $t('text_6261357f6d8ce401573ad3df') }}
          </a>
          <router-link :to="{ name: 'ContactUs' }" class="btn">
            {{ $t('text_6261357f6d8ce401573ad3e3') }}
          </router-link>
          <a href="https://help.temp-number.org/en/category/faq-8igld0/" target="_blank" class="btn">
            {{ $t('text_6261357f6d8ce401573ad3e7') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    showHelpModal () {
      return this.$store.state.modal.showHelpModal
    }
  },
  methods: {
    closeModal () {
      this.$store.commit('modal/toggleHelpModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal{
    &__container{
      width: 90%;
      max-width: 375px;
      position: relative;

      .closeBtn {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }

      .title {
        margin-top: 46px;
        span {
          display: block;
          margin: 16px 0 8px;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          letter-spacing: -0.2px;
          color: #14142B;
        }
      }

      .body {
        .subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #14142B;
          opacity: 0.6;
        }
        .buttons {
          margin: 32px 0 0;
          padding-bottom: 6px;

          .btn {
            height: 50px;
            margin-bottom: 16px;
            background: #F8F8F8;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #14142B;
            transition: background-color 150ms linear;

            &:hover {
              background: #f0f0f0;
            }
          }
        }
      }
    }
  }

  .rtl{
    .modal {
      &__container {
        .closeBtn {
          left: 24px;
          right: auto;
        }
      }
    }
  }
</style>
