<template>
  <div class="wrap">
    <div class="alertBl">
      <img src="@/assets/error503.gif" width="100" alt="Image">
      <div class="text">
        <p>We apologise for the inconvenience. We are working hard to make the service available.</p>
        <p>Please check back shortly.</p>
        <router-link :to="{ name: 'Home' }" class="link">Home page</router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .wrap{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alertBl{
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text{
    margin: 30px 0;
    text-align: center;
    p {
      text-align: center;
      margin: 0 0 10px;
      font-size: 16px;
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
    }
    .link{
      display: inline-block;
      margin-top: 10px;
      color: #fa8231;
    }
  }
  @media (max-width: 590px) {
    p{
      text-align: center;
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      color: rgba(20, 20, 43, 0.6);
    }
  }
</style>
