<template>
  <div class="mb-2">
    <strong>{{ $t('notificationnotification_new_sms_title') }}</strong>
  </div>
  <div class="mb-3">
    {{ $t('notificationnotification_new_sms_message').replace('__0__', '') }} <span ref="code">{{ code }}</span>
  </div>
  <div class="actionsBtns">
    <button @click="viewActivation" class="mr-3">{{ $t('notification_action_open_message') }}</button>
    <button @click="copyCode">{{ $t('notificationnotification_action_copy_code') }}</button>
  </div>
</template>

<script>
import selectText from '@/helpers/select-text.js'

export default {
  emits: ['closeAlert'],
  props: {
    payload: Object
  },
  computed: {
    alertData () {
      return this.payload.data.data
    },
    code () {
      return this.alertData.code ? this.alertData.code : this.alertData.fullMessage
    }
  },
  methods: {
    viewActivation () {
      if (this.alertData.activationId) {
        this.$emit('closeAlert', this.payload.id)
        this.$router.push({ name: 'Activation', params: { id: this.alertData.activationId } })
      }
    },
    copyCode () {
      const _this = this
      this.$copyText(this.code, undefined, (error) => {
        if (!error) {
          selectText(this.$refs.code)
          _this.$store.commit('alert/add', {
            id: Date.now(),
            text: _this.$t('text_618d5a996329050141a2473d'),
            type: 'success',
            timeout: 1500,
            name: 'copy'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .actionsBtns{
    button{
      cursor: pointer;
      border: none;
      background: transparent;
      font-weight: 500;
      color: #fff;
      text-shadow: 0 0 1px #212121;
      font-size: 16px;
    }
  }
</style>
