<template>
  <div>{{ format(new Date(parseInt(item.date, 10) * 1000), 'dd.MM.yy') }}</div>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    item: {}
  },
  data () {
    return {
      format
    }
  }
}
</script>

<style lang="scss" scoped>
  div {
    padding: 0 10px;
    text-align: center;
  }

  @media (max-width: 590px) {
    div {
      padding-left: 7px !important;
      text-align: center;
    }

    .rtl{
      div {
        padding-right: 7px !important;
        padding-left: 0 !important;
      }
    }
  }
</style>
