<template>
  <div class="activating-wrap container">
    <Activating />
  </div>
</template>

<script>
import Activating from '@/components/Activating/Activating.vue'

export default {
  components: {
    Activating
  }
}
</script>

<style lang="scss" scoped>
  .activating-wrap {
    height: 100%;
    position: relative;
  }
  @media (max-width: 590px) {
    .activating-wrap {
      height: 100vh;
      display: flex;
      align-items: center;
    }
  }
</style>
