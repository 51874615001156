<template>
  <div class="modal">
    <div @click.stop="" class="modal__container">
      <div class="title">{{ $t('text_6193db2a6253c201008eb3cb') }}</div>
      <div class="body">{{ $t('text_6193db2a6253c201008eb3d2') }}</div>

      <ButtonElement
        @click="confirmRetry"
        :loading="loading"
        class="modal__button"
        fill
        height="58px"
      >
        {{ $t('text_6193db2a6253c201008eb3d7') }}
      </ButtonElement>
      <ButtonElement
        @click="closeModal"
        :disabled="loading"
        class="modal__button modal__button-close"
        height="58px"
      >
        {{ $t('text_6193db2a6253c201008eb3d1') }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/ButtonElement'

export default {
  emits: ['retry'],
  components: { ButtonElement },
  props: {
    message: String
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    confirmRetry () {
      this.loading = true
      this.$emit('retry')
    },
    closeModal () {
      this.$store.commit('modal/toggleRetryConfirmationModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal{
    &__container {
      max-width: 311px;

      .modal__button-close{
        margin-top: 16px;
      }
    }
  }
</style>
