<template>
  <ButtonElement
    @click="$emit('closeModal')" class="ok-btn" fill height="58px"
  >
    {{ $t('text_6419d46f85fa97007e8c04c4') }}
  </ButtonElement>
</template>

<script>
import ButtonElement from '@/components/ButtonElement'

export default {
  components: { ButtonElement },
  emits: ['closeModal']
}
</script>

<style lang="scss" scoped>
  .ok-btn{
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
</style>
