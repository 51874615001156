<template>
  <div class="empty-list-state">
    <div class="empty-list-state__info">
      <img src="@/assets/icons/empty-box.svg" alt="empty state">
      <div class="text">
        <slot />
      </div>
    </div>
    <div v-for="line in 6" :key="line" class="empty-list-state__bg-lines" />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .empty-list-state{
    height: 530px;
    position: relative;

    &__info{
      position: absolute;
      top: 160px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 273px;
      height: 175px;
      padding: 20px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & .text{
        font-size: 10px;
        line-height: 13px;
        text-align: center;
        letter-spacing: -0.2px;
        color: rgba(0, 0, 0, 0.25);
        opacity: 0.6;
        margin-top: 13px;
      }
    }
    &__bg-lines{
      width: 100%;
      height: 42px;
      background: #F8F8F8;
      border-radius: 6px;
      margin-top: 56px;
    }
  }

  @media (max-width: 590px) {
    .empty-list-state{
      height: auto;
      min-height: 300px;
      position: relative;

      &__info{
        top: calc((100vh / 2) - 242px);
        border: none;

        & .text{
          font-size: 10px;
          line-height: 13px;
          text-align: center;
          letter-spacing: -0.2px;
          color: rgba(0, 0, 0, 0.25);
          opacity: 0.6;
          margin-top: 13px;
        }
      }
      &__bg-lines{
        display: none;
      }
    }
  }

  @media (max-width: 320px) {
    .empty-list-state {
      &__info {
        width: 220px;
      }
    }
  }
</style>
