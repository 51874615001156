<template>
  <div class="activating-step">
    <TitleElement tace>
      <h1 class="title__main title__main--pb0">{{ $t('text_6167c4a5321c670152f0e204') }}</h1>
      <h2 class="title__subtitle">{{ $t('text_6167c4a5321c670152f0e205') }}</h2>
    </TitleElement>

    <div class="activating-step__loader">
      <Loader />
    </div>

    <h3 class="activating-step__title">{{ $t('text_6167c4a5321c670152f0e206') }}</h3>

    <Slider />
  </div>

  <QuestionModal v-if="showQuestionModal" @decline="$router.push({ name: 'OrderStep3' })">
    <template v-slot:title>{{ $t('text_62697843fd35bc0145532d24') }}</template>
    <template v-slot:text>
      <component :is="{ template: error409Text }"></component>
    </template>
    <template v-slot:acceptBtn>
      <ButtonElement @click="reactivateWithNewPrice" class="modal__button modal__button-accept" fill height="58px">
        {{ $t('text_62697843fd35bc0145532d28') }}
      </ButtonElement>
    </template>
    <template v-slot:declineBtn>{{ $t('text_62697843fd35bc0145532d2a') }}</template>
  </QuestionModal>
</template>

<script>
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useSmsApi } from '@/use/smsApi'
import { useBalance } from '@/use/balance'
import { useActiveActivationsCount } from '@/use/activeActivationsCount'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useTranslate } from '@/use/translate'
import { useNps } from '@/use/nps'
import TitleElement from '@/components/TitleElement'
import Loader from '@/components/Loader'
import ButtonElement from '@/components/ButtonElement'
import Slider from '@/components/Slider/Slider'
import QuestionModal from '@/components/Modals/QuestionModal'

export default {
  components: {
    TitleElement,
    Loader,
    ButtonElement,
    Slider,
    QuestionModal
  },
  data () {
    return {
      activatingDelay: parseInt(process.env.VUE_APP_ACTIVATING_PAGE_DELAY, 10) || 12000,
      timeoutId: null,
      showQuestionModal: false
    }
  },
  computed: {
    activeService () {
      return this.$store.state.order.service
    },
    activeCountry () {
      return this.$store.state.order.country
    },
    activationPrice () {
      return this.$store.state.order.price
    },
    activateAgain () {
      return this.$store.state.order.activateAgain
    },
    error404Text () {
      const textWithBrs = this.addBreakLinesToText(this.$t('no_numbers_error_subtitle'))
      return this.addCrispChatLink(textWithBrs, 'Home')
    },
    error406Text () {
      return this.addBreakLinesToText(this.$t('text_618d4cee12b95801234d2cc0'))
    },
    error429Text () {
      return this.addBreakLinesToText(this.$t('text_618d4cee12b95801234d2cc1'))
    },
    error409Text () {
      return this.addBreakLinesToText(this.$t('text_62697843fd35bc0145532d26').replace('__0__', this.activationPrice))
    }
  },
  watch: {
    activateAgain (val) {
      if (val && this.activeService && this.activeCountry) {
        this.activate()
        this.$store.commit('order/setActivateAgain', false)
      }
    }
  },
  setup () {
    const { initApiInst, smsActivationApi } = useSmsApi()
    const { getBalance } = useBalance()
    const { getActiveActivationsCount } = useActiveActivationsCount()
    const { handleNotAuthorizedError, handleNetworkError, handle503Error, showCommonError } = useErrorHandlers()
    const { addBreakLinesToText, addCrispChatLink } = useTranslate()
    const { handleNps } = useNps()
    return {
      initApiInst,
      smsActivationApi,
      getBalance,
      getActiveActivationsCount,
      handleNotAuthorizedError,
      handleNetworkError,
      handle503Error,
      showCommonError,
      addBreakLinesToText,
      addCrispChatLink,
      handleNps
    }
  },
  mounted () {
    if (this.activeService && this.activeCountry) {
      this.$amplitude.getInstance().logEvent('activation.number_requested', {
        service: this.activeService.serviceId,
        country: this.activeCountry.countryId
      })
      this.activate()
    } else {
      this.$router.push({ name: 'Home' })
    }
  },
  beforeUnmount () {
    clearTimeout(this.timeoutId)
    this.timeoutId = null
  },
  methods: {
    async activate () {
      const currentBalance = await this.getBalance()
      if (currentBalance < this.activationPrice) {
        this.$store.commit('order/setForceActivation', true)
        localStorage.setItem('order', JSON.stringify(this.$store.state.order))
        await this.$router.push({ name: 'Funds' })
        return
      }
      const _this = this
      const startTime = Date.now()
      try {
        const activationReqData = {
          newActivation: {
            countryId: this.activeCountry.countryId,
            serviceId: this.activeService.serviceId,
            expectedPrice: this.activationPrice
          },
          prxPrint: this.$store.state.common.prxPrint
        }
        await this.initApiInst()
        const activationResp = await this.smsActivationApi.newActivation(activationReqData)
        if (activationResp && activationResp.id) {
          const analytics = getAnalytics()
          logEvent(analytics, 'activation', {
            countryId: this.activeCountry.countryId,
            serviceId: this.activeService.serviceId,
            price: this.activationPrice
          })
          const successTimeout = this.getTimeout(startTime)
          this.getActiveActivationsCount()
          this.$amplitude.getInstance().logEvent('activation.number_received', {
            service: this.activeService.serviceId,
            country: this.activeCountry.countryId
          })
          this.$store.commit('order/clearOrder')
          localStorage.removeItem('order')
          this.timeoutId = setTimeout(async () => {
            await _this.$router.push({ name: 'Activation', params: { id: activationResp.id } })
          }, successTimeout)
          this.handleNps()
        }
      } catch (e) {
        if (e.status === 409) {
          if (e.body.newPrice) this.$store.commit('order/setPrice', e.body.newPrice)
          this.showQuestionModal = true
        } else {
          const errorTimeout = this.getTimeout(startTime)
          this.timeoutId = setTimeout(() => {
            this.handleActivationError(e)
          }, errorTimeout)
        }
      }
    },
    async handleActivationError (e) {
      if (e.status) {
        switch (e.status) {
          case 401:
            await this.handleNotAuthorizedError()
            break
          case 402:
            this.$store.commit('order/setForceActivation', true)
            await this.$router.push({ name: 'Funds' })
            break
          case 404:
            this.$store.commit('order/clearOrder')
            this.$store.commit('notificationModal/show', {
              outsideClickHide: false,
              title: this.$t('no_numbers_error_title'),
              text: this.error404Text,
              buttonText: this.$t('text_616e4d4b66577e00e3d6ba23'),
              buttonAction: 'activating404'
            })
            break
          case 406:
            this.$store.commit('notificationModal/show', {
              outsideClickHide: false,
              title: this.$t('text_618d4cee12b95801234d2cba'),
              text: this.error406Text,
              buttonText: this.$t('text_618d4cee12b95801234d2cc5'),
              buttonAction: 'activating406'
            })
            break
          case 410:
            this.$store.commit('notificationModal/show', {
              outsideClickHide: false,
              title: this.$t('text_618d4cee12b95801234d2cb9'),
              text: this.addBreakLinesToText(this.$t('text_618d4cee12b95801234d2cbf')
                .replace('__0__', this.activeService.serviceName)
                .replace('__1__', this.activeCountry.countryName)
              ),
              buttonText: this.$t('text_618d4cee12b95801234d2cc3'),
              buttonAction: 'activating410TryAgain',
              button2Text: this.$t('text_618d4cee12b95801234d2cbd'),
              button2Action: 'activating410ChangeCountry'
            })
            break
          case 429:
            this.$store.commit('notificationModal/show', {
              outsideClickHide: false,
              title: this.$t('text_618d4cee12b95801234d2cbc'),
              text: this.error429Text,
              buttonText: this.$t('text_618d4cee12b95801234d2cc4'),
              buttonAction: 'activating429'
            })
            break
          case 502:
            this.handleNetworkError()
            break
          case 503:
            await this.handle503Error()
            break
          default:
            this.$store.commit('order/setForceActivation', false)
            this.showCommonError(e)
        }
      } else {
        if (e.error && e.error.message && e.error.message.includes('Request has been terminated')) {
          this.handleNetworkError()
        } else {
          this.$store.commit('order/setForceActivation', false)
          this.showCommonError(e)
        }
      }
    },
    getTimeout (startTime) {
      const requestTime = Date.now() - startTime
      const timeout = this.activatingDelay - requestTime
      return timeout > 0 ? timeout : 0
    },
    reactivateWithNewPrice () {
      this.showQuestionModal = false
      this.activate()
    }
  }
}
</script>

<style lang="scss" scoped>
  .activating-step {
    padding-top: 58px;
    max-width: 434px;
    margin: auto;

    &__loader {
      margin-top: 24px;
      margin-bottom: 75px;
    }

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #14142B;
      margin-bottom: 40px;
    }

    .title__main {
      font-weight: 600;
      font-size: 36px;
      line-height: 46px;
      text-align: center;
      letter-spacing: -0.2px;
      color: #14142B;
      margin: 0 0 16px;
    }

    .title__subtitle {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: rgba(20, 20, 43, 0.6);
      margin: 16px 0;
    }
  }

  @media (max-width: 590px) {
    .activating-step {
      .title__main {
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 8px;
      }

      .title__subtitle {
        font-size: 14px;
        line-height: 24px;
        margin: 8px 0;
      }
      &__title {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .activating-step{
      &__loader {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
</style>
