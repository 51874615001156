<template>
  <ul class="slider-controls">
    <li
      v-for="(item, idx) in length"
      :key="idx"
      class="slider-controls__item"
      :class="{'slider-controls__item--active': idx === currentSlide}"
      @click="handleClick(idx)"
    >

    </li>
  </ul>
</template>

<script>
export default {
  props: {
    length: Number,
    currentSlide: Number
  },

  methods: {
    handleClick (id) {
      this.$emit('change', id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .slider-controls {
    margin-bottom: 140px;
    margin-top: 24px;
    display: flex;
    justify-content: center;

    &__item {
      cursor: pointer;
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 12px;
      background: url('../../assets/icons/slider/control.svg') no-repeat center center;

      &--active {
        background: url('../../assets/icons/slider/control-active.svg') no-repeat center center;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .rtl{
    .slider-controls {
      &__item {
        margin-left: 12px;
        margin-right: 0;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 590px) {
    .slider-controls {
      margin-bottom: 58px;
    }
  }
</style>
