<template>
  <div class="control email-control">
    <input
      :placeholder="placeholder"
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      ref="emailInput"
    >
    <div v-if="modelValue" @click="clearInput" class="input__clear" />

    <div v-if="errorMessage" class="control__error">
      {{ correctError(errorMessage) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    errorMessage: String,
    placeholder: String
  },

  emits: ['update:modelValue'],

  methods: {
    correctError (errorMessage) {
      switch (errorMessage) {
        case 'required':
          return this.$t('enter_email_validation')
        case 'email':
          return this.$t('email_not_correct_validation')
        case 'sameAsEmail':
          return this.$t('text_61725ebf970e2700fb98d3fc')
        default:
          break
      }
    },
    clearInput () {
      this.$emit('update:modelValue', '')
      this.$refs.emailInput.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  .email-control {
    &:before {
      position: absolute;
      content: '';
      left: 18px;
      top: 24px;
      width: 13px;
      height: 11px;
      background: url('../../assets/form/email.svg');
      background-size: cover;
      z-index: 2;
    }
  }

  .rtl{
    .email-control {
      &:before {
        right: 18px;
        left: auto;
      }
    }
  }
</style>
