<template>
  <div v-if="$store.state.order.service && $store.state.order.service.warning" class="order-warning">
    <p class="order-warning__text">{{ $store.state.order.service.warning }}</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
  .order-warning {
    width: 275px;
    padding-left: 19px;
    margin: 24px auto 32px;
    position: relative;
    background: url('../../assets/icons/asterisk.svg') no-repeat top left;

    &__text {
      font-weight: normal;
      font-size: 10px;
      line-height: 13px;
      text-align: justify;
      letter-spacing: -0.2px;
      color: #848194;
      margin: 0 3px;
    }
  }

  .rtl {
    .order-warning {
      padding-right: 19px;
      padding-left: 0;
      background: url('../../assets/icons/asterisk.svg') no-repeat top right;
    }
  }

  @media (max-width: 590px) {
    .order-warning {
      margin: 24px auto;
    }
  }
</style>
