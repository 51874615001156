<template>
  <div class="locale-switcher-wrap">
    <div class="locale-select" tabindex="0" @blur="open = false">
      <div @click="handleLocaleSwitcherClick" :class="{ open: open }" class="selected">
        {{ localesNames[selected] }}
      </div>
      <div class="items" :class="{ selectHide: !open }">
        <div
          v-for="(localeName, locale) of localesNames"
          :key="locale"
          @click="changeLocale(locale)"
        >
          {{ localeName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLocale } from '@/use/locale'

export default {
  emits: ['hideMenu'],
  data () {
    return {
      localesNames: {
        en: 'English',
        ar: 'Arabic',
        zh: 'Chinese',
        pt: 'Portuguese',
        es: 'Spanish',
        ru: 'Russian',
        tr: 'Turkish'
      },
      selected: this.$root.$i18n.locale,
      open: false
    }
  },
  setup () {
    const { setAdditionalLocaleParams } = useLocale()

    return { setAdditionalLocaleParams }
  },
  methods: {
    handleLocaleSwitcherClick () {
      if (!this.open) {
        this.$amplitude.getInstance().logEvent('ui.clicked_on_change_language')
      }
      this.open = !this.open
    },
    changeLocale (locale) {
      this.open = false
      this.selected = locale
      this.$root.$i18n.locale = locale
      localStorage.setItem('locale', locale)
      this.setAdditionalLocaleParams(locale)
      this.$emit('hideMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
  .locale-switcher-wrap{
    position: absolute;
    top: 13px;
    right: 40px;
    width: 150px;
  }
  .rtl {
    .locale-switcher-wrap {
      left: 40px;
      right: auto;
      .locale-select {
        text-align: right;
        .selected {
          padding-right: 1em;
          padding-left: 0;
          &:after {
            left: 1em;
            right: auto;
          }
        }
        .items {
          div {
            padding-right: 1em;
            padding-left: 0;
          }
        }
      }
    }
  }
  .locale-select {
    position: relative;
    width: 100%;
    max-width: 180px;
    text-align: left;
    outline: none;
    height: 40px;
    line-height: 40px;
    margin: 30px 0;

    .selected {
      background-color: #fff;
      border-radius: 6px;
      border: 1px solid rgba(25, 22, 50, 0.12);
      color: #14142B;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
    }

    .selected.open {
      border-radius: 6px 6px 0 0;
    }

    .selected:after {
      position: absolute;
      content: "";
      top: 19px;
      right: 1em;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-color: rgba(25, 22, 50, 0.32) transparent transparent transparent;
    }

    .items {
      color: #14142B;
      border-radius: 0 0 6px 6px;
      overflow: hidden;
      border-right: 1px solid rgba(25, 22, 50, 0.12);
      border-left: 1px solid rgba(25, 22, 50, 0.12);
      border-bottom: 1px solid rgba(25, 22, 50, 0.12);
      position: absolute;
      background-color: #fff;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .items div {
      color: #14142B;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
    }

    .items div:hover {
      color: #FA8231;
    }

    .selectHide {
      display: none;
    }
  }
</style>
