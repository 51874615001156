import { getAuth, sendEmailVerification, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import amplitude from 'amplitude-js'
import { useBalance } from '@/use/balance'
import { useCloudMessaging } from '@/use/cloudMessaging'
import { useErrorHandlers } from '@/use/errorHandlers'

export function useAuth () {
  const router = useRouter()
  const store = useStore()
  const { t } = useI18n()
  const { stopCheckBalance } = useBalance()
  const { updateFbNotificationToken } = useCloudMessaging()
  const { showCommonError } = useErrorHandlers()

  const logout = async (redirectRouteName = 'CreateAccount', showLogoutAlert = true) => {
    try {
      const auth = getAuth()
      await auth.signOut()
      stopCheckBalance()
      updateFbNotificationToken('', false)
      localStorage.removeItem('order')
      store.commit('user/setBalance', null)
      store.commit('auth/setAuthPasswordProvider', false)
      store.commit('alert/removeByName', 'confirm_email')
      store.commit('order/clearOrder', null)
      store.commit('activation/setActiveActivationsCount', 0)
      store.commit('activation/setActiveActivationsTimeoutId')
      store.commit('user/setApiKey', null)
      store.commit('modal/toggleForceEmailConfirmationModal', false)
      if (showLogoutAlert) {
        store.commit('alert/add', {
          id: Date.now(),
          text: t('text_6231a5d0ff911700d6c42074'),
          type: 'success',
          timeout: 2000,
          name: 'logout'
        })
      }
      localStorage.removeItem('lastUsedNumber')
      localStorage.removeItem('lastVisitedPath')
      amplitude.getInstance().setUserId(null)
      await router.push({ name: redirectRouteName })
    } catch (e) {
      store.commit('notificationModal/showSomethingWrongModal', e.status ? e.status : '')
    }
  }

  const getProviderByName = (name) => {
    let provider = null
    switch (name) {
      case 'facebook.com':
        provider = new FacebookAuthProvider()
        break
      case 'google.com':
        provider = new GoogleAuthProvider()
        break
      case 'apple.com':
        provider = new OAuthProvider('apple.com')
        break
    }
    return provider
  }

  const resendEmailConfirmation = async () => {
    store.commit('common/setBtnLoading', true)
    const auth = getAuth()
    const user = auth.currentUser
    try {
      await sendEmailVerification(user)
      store.commit('alert/add', {
        id: Date.now(),
        text: t('check_link_psw_reset_notification'),
        type: 'success',
        timeout: 3000
      })
    } catch (e) {
      if (e.code && e.code === 'auth/too-many-requests') {
        store.commit('alert/add', {
          id: Date.now(),
          text: t('text_623347bf2c69070128ddc5c0'),
          timeout: 3000
        })
      } else {
        showCommonError(e)
      }
    } finally {
      store.commit('common/setBtnLoading', false)
    }
  }

  return { logout, getProviderByName, resendEmailConfirmation }
}
