<template>
  <div class="change-password">
    <TitleElement tace>
      <h1 class="auth-title__main title__main--pb0">{{$t('text_6167c4a5321c670152f0e2a7') }}</h1>
      <h2 class="auth-title__subtitle">{{ $t('text_6167c4a5321c670152f0e224') }}</h2>
    </TitleElement>
    <ChangePasswordForm />
  </div>
</template>

<script>
import TitleElement from '@/components/TitleElement.vue'
import ChangePasswordForm from '@/components/Forms/ChangePasswordForm/ChangePasswordForm.vue'

export default {
  components: {
    TitleElement,
    ChangePasswordForm
  },
  mounted () {
    if (!this.$store.state.auth.authPasswordProvider) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .change-password {
    width: 400px;
  }
</style>
