<template>
  <div class="content-page container">
    <h1 style="text-align: center;">Terms and Conditions</h1>
    <p style="text-align: center;">( a.k.a. &ldquo;Terms of Service&rdquo; )</p>
    <p>&nbsp;</p>
    <p style="text-align: right;"><strong>Last update: January 1, 2022</strong></p>
    <p>This Terms and conditions (hereinafter referred to as the &ldquo;Contract&rdquo;) is an official offer (public offer) of Temp-Number (hereinafter referred to as the &ldquo;Provider&rdquo;) to any individual or any legal entity (hereinafter referred to as the &ldquo;Client&rdquo;) who accepts this offer on the terms and conditions below.</p>
    <p>The Service is provided by Privatix LTD, a company registered in the State of Israel and having its registered address at 150 Menachem Begin Road, WE Tower - Level 9, Tel Aviv 6492105.&nbsp;</p>
    <h2>Terms and Definitions&nbsp;</h2>
    <ol>
      <li aria-level="2">In this Contract, the following terms and definitions are used in the following meaning:</li>
      <li aria-level="2">&ldquo;Acceptance&rdquo; - a full and unconditional acceptance by the Client of the terms of this Contract, made by registering up on Website or Mobile Applications or by prepaying the services provided.&nbsp;</li>
      <li aria-level="2">&ldquo;Site&rdquo;, &ldquo;Service&rdquo; &ndash; an Internet site under the Temp-Number.org domain name or Mobile Applications which allows the Client the technical ability to use the Services under this Contract.</li>
      <li aria-level="2">&ldquo;Mobile Application&rdquo; - an mobile application &ldquo;Temp Number&rdquo;</li>
      <li aria-level="2">&ldquo;Tariff&rdquo; - a set of price terms according to which the Provider offers to use one or more services available on the Site.</li>
      <li aria-level="2">&ldquo;API service&rdquo; is a set of ready-made&nbsp;technical solutions by the Provider to be used in the Client's external software products.&nbsp;</li>
      <li aria-level="2">&ldquo;Mobile phone operator&rdquo; - a company that provides mobile communications services for mobile phones of its subscribers.</li>
      <li aria-level="2">&ldquo;Contact e-mail address&rdquo; - a set of characters that identifies the Client and the Provider and is used to receive and send e-mail.&nbsp;</li>
      <li aria-level="2">&ldquo;Credits Balance (USD)&rdquo; - an account on the Client's personal page which allows tracking money transferred to the Provider's account for services under the Contract.&nbsp;</li>
      <li aria-level="2">&ldquo;Message, SMS&rdquo; - a text message to the Active Number the reception of which is ensured by the Provider.</li>
      <li aria-level="2">&ldquo;Personal page&rdquo; - a user interface that allows receiving and managing the Services under this Contract.</li>
      <li aria-level="2">&ldquo;User Identification&rdquo; - a procedure of voluntary identification of the user's identity carried out via the Service.&nbsp;</li>
      <li aria-level="2">&ldquo;Subscriber&rdquo; - an individual or a legal entity who uses the services of mobile communication of one of the Mobile operators.</li>
      <li aria-level="2">&ldquo;Active Number&rdquo; - a subscriber's number owned by the Service and used to provide the Services under this Contract.&nbsp;</li>
      <li aria-level="2">&ldquo;Sip-number&rdquo; - a number used to provide call forwarding services via Internet telephony and owned by the Service.</li>
      <li aria-level="2">&ldquo;SMS Reception&rdquo; - a service under this Contract that allows the Client a technical opportunity to read the texts of Messages received on the Active Number.</li>
      <li aria-level="2">&ldquo;Subscriber's account&rdquo; - an account on the client&rsquo;s personal page that allows tracking the amount of money transferred from the Credits Balance (USD) to the balance of the Active Number.</li>
      <li aria-level="2">&ldquo;Refund operation&rdquo; - the Client&rsquo;s actions to refund the payment or a part of the payment to the Client in connection with the refund (cancellation, refusal) by the Client of/from services as well as because of the failure by the Provider to provide services to the Client that have been previously paid by the Client.</li>
    </ol>
    <h2>Subject Agreement</h2>
    <ol>
      <li aria-level="2">The subject of this Contract is the provision to the client of intermediary services allowing a technical opportunity to read SMS received on the Active Number (hereinafter - the Services). The Services are provided to legal entities, sole traders, individuals who have accepted the terms and conditions of this contract. The Client undertakes to pay the Services in the amount and on the conditions provided for by this Contract.</li>
      <li aria-level="2">The Client understands that under this Contract the Provider is an intermediary between the Client and the mobile operator.</li>
      <li aria-level="2">The information on the cost and duration of the Services as well as the registration form are posted on the Site.</li>
      <li aria-level="2">The moment of full and unconditional acceptance by the Client of the Provider's proposal to enter into the offer contract (acceptance of the offer) is the fact of registration on the Temp-Number.org website or Mobile Application prepayment by the Client of the Provider's services.&nbsp;</li>
      <li aria-level="2">The client has the right to use the services under this Country in any country, if this does not contradict the legislation of the country. If the client plans to use this Service in a territory where the use of the Services is not provided for by law or is prohibited, the Client does not have the right to enter into this Contract and to use the Service.</li>
      <li aria-level="2">By accepting the terms and conditions of this Contract, the Client confirms that the use of the Services on the territory where the Client resides is permitted by law.&nbsp;</li>
    </ol>
    <h2>General Terms and Conditions for Providing the Services</h2>
    <ol>
      <li aria-level="2">Services are provided in accordance with the terms and conditions set out in this Contract and annexes thereto, as well as in accordance with the terms and conditions posted on the temp-number.org website.</li>
      <li aria-level="2">If the Terms and conditions for providing the services posted on the Site contradict the terms and conditions for providing the services set out in this Contract or annexes thereto, the terms and conditions posted on the Site shall prevail.&nbsp;</li>
      <li aria-level="2">When performing obligations under this contract, it is allowed to provide services using the API service located at temp-number.org website API category</li>
      <li aria-level="2">The Provider provides the Services to the Client only subject to the following conditions:</li>
      <ol>
        <li aria-level="3">The Client has registered on the Site in the proper manner;</li>
        <li aria-level="3">The Client acknowledges that all the numbers are disposable (temporary) and should not be used in the way that imply additional usage or/and the usage beyond 20 minutes.&nbsp;</li>
        <li aria-level="3">The Client acknowledges that the Tariff is per one sms message only and pegged to the specific website or/and service.&nbsp;</li>
        <li aria-level="3">The Client acknowledges that Temp-Numbers will not be responsible for any damage or loss caused by improper usage of the temporary numbers.&nbsp;</li>
        <li aria-level="3">The Provider has confirmed the possibility of providing the service by sending a message to the e-mail indicated at registration.&nbsp;</li>
      </ol>
      <li aria-level="2">The Provider has the right to refuse to register the Client on the site without explanation.</li>
      <li aria-level="2">The Services are provided to the Client in the scope corresponding to the Tariff and the type of service selected by the Client.</li>
      <li aria-level="2">In order to prevent fraudulent activities within the SMS Reception services, User identification may be required.</li>
      <ol>
        <li aria-level="3">User identification can be done by submitting and verifying ID data when paying for services via some payment services.</li>
      </ol>
    </ol>
    <h2>Payment Procedure and Сost of Services</h2>
    <ol>
      <li aria-level="2">The Client pays the Provider&rsquo;s services 100% in advance by making a transfer to the Provider's bank account or by electronic payments to the Credits Balance (USD).&nbsp;</li>
      <li aria-level="2">Transfers are displayed in the Client's Credits Balance (USD).</li>
      <li aria-level="2">The Credits are in U.S. Dollars and exchange rate is $1 Credit = 1 U.S. Dollar</li>
      <li aria-level="2">The cost of services under the Contract is determined in accordance with this Contract, annexes thereto, Tariffs and information posted on the site. The cost can be changed and depends on:</li>
      <ol>
        <li aria-level="3">Scope of services ordered;</li>
        <li aria-level="3">The Client's individual discounts;</li>
        <li aria-level="3">Tariff;</li>
        <li aria-level="3">Other terms and conditions, if this is expressly indicated on the Site or in the text of this Contract and annexes thereto.</li>
        <li aria-level="3">The Credits Balance (USD) is debited for the SMS Reception service immediately after the provision of the Service.</li>
        <li aria-level="3">Our order process on the Site is conducted by our online reseller Paddle.com. Paddle.com is the Merchant of Record for all our orders. Paddle provides all customer service inquiries and handles returns. Paddle.com acts according to their Terms of Use (https://paddle.com/legal/) and Privacy Policy (https://paddle.com/privacy/ ) .&nbsp;</li>
        <li aria-level="3">The crypto payments on the Site process is conducted by our online reseller Payeer.com and according to their Terms of Use (https://payeer.com/en/agreements/), AML/KYC (https://payeer.com/en/aml/) and Privacy Policy (https://payeer.com/en/policy/)&nbsp;</li>
        <li aria-level="3">The crypto payments on the Site process is conducted by our online reseller Coinpayments.com and according to their User Agreement (https://www.coinpayments.net/help-terms), Restricted Jurisdictions Policy (https://www.coinpayments.net/help-restricted) and Privacy Policy (https://www.coinpayments.net/help-privacy)&nbsp;</li>
        <li aria-level="3">Payments on the Mobile Applications will be charged from native mobile store payment systems.&nbsp;</li>
      </ol>
    </ol>
    <h2>Rights and Obligations of The Parties</h2>
    <p><strong>The Client shall:</strong></p>
    <ol>
      <ol>
        <ol>
          <li aria-level="3">Not use the Services provided by the Provider for unlawful purposes, and neither shall perform actions harmful to the Provider and (or) third parties.</li>
          <li aria-level="3">Duly pay for the Provider's services in accordance with the terms and conditions of this Contract.</li>
          <li aria-level="3">Reimburse the Provider for losses caused by payments recovered from the Provider by third parties, including administrative fines from control and supervisory authorities incurred by the Provider because of the Client's violations of the provisions of this Contract provided that the Client is presented with documents confirming the Provider's damages.</li>
        </ol>
      </ol>
    </ol>
    <p><strong>The Client is entitled to:&nbsp;</strong></p>
    <ol>
      <ol>
        <ol>
          <li aria-level="3">Request from the Provider information on the range of Services, Tariffs, crediting of payments, the status of their Credits Balance (USD) and other data as part of the Services under this Contract.</li>
        </ol>
      </ol>
    </ol>
    <p><strong>The Provider shall:</strong> &nbsp;</p>
    <ol>
      <ol>
        <ol>
          <li aria-level="3">Ensure the smooth running of the Service during the term of this Contract.</li>
          <li aria-level="3">Provide the Services to the Client in accordance with the Contract.</li>
          <li aria-level="3">Provide the Client with information on the range of Services, Tariffs, crediting of payments, the status of their Credits Balance (USD).&nbsp;</li>
        </ol>
      </ol>
    </ol>
    <p><strong>The Provider is entitled to:</strong></p>
    <ol>
      <ol>
        <li aria-level="3">Unilaterally amend this Contract and annexes thereto with prior notification of the Client 5 (five) calendar days prior to the date when such amendments are supposed to come into force by posting information about the amendments on the Providers's site.&nbsp;</li>
        <li aria-level="3">Unilaterally amend the Tariffs without notifying the Client, by posting information about the changes on the Provider's website.&nbsp;</li>
        <li aria-level="3">If the Client continues to use the Services, this is recognized as their consent with the terms of the amended Contract and Tariffs. If the Client does not agree with the terms and conditions of the amended Contract and the new Tariffs, they stop using the Services and notify the Provider via the contact e-mail address provided for in clause 9.1.3. of the Contract within 5 (five) business days from the receipt of a notice from the Provider. In this case, the Provider blocks the User&rsquo;s Personal page.</li>
        <li aria-level="3">Block the Client&rsquo;s Personal page if they violate any of the clauses of this Contract.</li>
        <li aria-level="3">Unilaterally set arbitrary storage terms for any statistical data of the Client, except when such data relate to personal data.&nbsp;</li>
        <li aria-level="3">The Provider has the right to engage third parties (specialists, consultants) to provide the Services under this contract without notifying the Client.&nbsp;</li>
        <li aria-level="3">The Provider has the right to add the Client&rsquo;s e-mail address submitted at registration to their mailing list. The Client has the right to refuse from receiving messages sent by the Provider by following a special link available in every e-mail.</li>
        <li aria-level="3">Unless otherwise specified in this Contract, the Provider shall not be liable to the Client and other persons for: indirect losses, loss of profit, regardless of how they were caused.</li>
        <li aria-level="3">The Provider has the right to place the trademark logo on the Provider&rsquo;s website with the written consent of the Client.</li>
      </ol>
    </ol>
    <h2>Duration of the Contract, Entering into Agreement and Termination Procedure</h2>
    <ol>
      <li aria-level="2">This Contract shall enter into force from the moment the Client accepts the offer and is valid until the Parties completely fulfill their obligations.</li>
      <li aria-level="2">In the event that one party fails to fulfill its obligations under the contract as a result of force majeure circumstances (force majeure), which is confirmed by necessary documents indicating the period of the force majeure, the term of the parties' obligations under the contract is extended according to the duration of the force majeure period. The parties are exempt from liability for losses incurred by the other party as a result of force majeure. The party which has become unable to fulfil its obligations under this contract shall inform the other party in writing about the fact of force majeure circumstances, their estimated duration, as well as the end date of their action. A sufficient confirmation of the presence of the above circumstances will be certificates issued by the competent authorities. If it is impossible to fulfil the obligations of one of the parties partly or completely due to force majeure circumstances before the start of an event, any party has the right to terminate this contract.</li>
    </ol>
    <h2>Confidentiality and Personal Data</h2>
    <ol>
      <li aria-level="2">Additional information regarding the privacy policy may be observed on temp-number.org website.</li>
      <li aria-level="2">Any information obtained as a result of the execution of this contract (including, but not limited to information about the commercial activities of any of the parties, technologies, decisions, etc.) is confidential and not subject to disclosure to third parties without a written consent of the other party to this contract.</li>
      <li aria-level="2">The parties shall keep the information received in strict confidentiality and take all possible actions to protect it, which will be no less than the actions that each of the Parties takes to protect its own information.&nbsp;</li>
      <li aria-level="2">The Provider does not disclose information about the technical aspects of the provision of the services.</li>
      <li aria-level="2">The Provider has the right to process and store the personal data of the Client or their authorized representative, to ensure their confidentiality and not to provide access to this information to third parties. By accepting this offer, the Client confirms their consent and allows the Provider to process their personal data, including: last name, first name, father's name, registration / post address, email address, phone number, date and (or) place of birth, photo, passport details, ip address. The processing of personal data in this offer means: collection of the above data, its systematization, accumulation, storage, specification (updating, changing), use, blocking, destruction. Access to the processing of personal data is restricted to persons designated as responsible for processing of personal data.</li>
      <li aria-level="2">The Client expresses consent and permits the Provider to process the Client&rsquo;s personal data using automated database management systems, as well as other software and hardware.</li>
      <li aria-level="2">Such systems are used according to the algorithm described in clause 8.4 above (collection, systematization, accumulation, storage, clarification, use, blocking, destruction).</li>
      <li aria-level="2">The Provider has the right to independently determine the methods used for processing the Client's personal data.&nbsp;</li>
      <li aria-level="2">The operator ensures the confidentiality of the personal data provided by the Client, their protection from copying, distribution. At any time, the Client has the right to request a list of their personal data and / or to demand to change, destroy their personal data.&nbsp;</li>
    </ol>
    <h2>Dispute Resolution Procedure</h2>
    <ol>
      <li aria-level="2">Should any dispute or disagreement arise during the discharge of the Contract, they are to be settled through negotiations by the Parties. The pre-court claim procedure for resolving a dispute before going to court is mandatory.</li>
      <li aria-level="2">The parties send claims to the contact e-mail address or official address.</li>
      <li aria-level="2">The provider's contact e-mail address is support@temp-number.org</li>
      <li aria-level="2">The e-mail address specified by the Client at registration is considered to be their contact address.&nbsp;</li>
      <li aria-level="2">The claim response period is thirty calendar days.</li>
    </ol>
    <h2>Account Deletion</h2>
    <ol>
      <li aria-level="2">The Client may permanently delete his account and all data associated with it at any time.</li>
      <li aria-level="2">The Client should send the request from the registered email address to support@temp-number.org or fill the form on the https://temp-number.org website, and his request will proceed manually by Client within seven days.</li>
    </ol>
    <h2>Miscellaneous</h2>
    <ol>
      <li aria-level="2">All the numbers are temporary and proposed for short-term use up to 20 minutes.&nbsp;</li>
      <li aria-level="2">The functionality of retrying extra messages on same number is not guaranteed and provided as a bonus.&nbsp;</li>
      <li aria-level="2">The Service may apply limits to the number of requested numbers per day.&nbsp;</li>
      <li aria-level="2">The Service is not responsible for second-factor limitations or passwords on third-party accounts established to that number before us</li>
      <li aria-level="2">Any notification requested or provided by one of the Parties to the other Party in accordance with this Contract shall be sent to the e-mail address provided for in clauses 9.1.3. and 8.1.4. of this Contract. If it is not possible to send a claim to the indicated e-mail addresses, the claim is sent in writing to the official address.</li>
      <li aria-level="2">If any provision of this Contract is declared invalid by a competent authority in whole or in part, this should not affect the validity of other provisions of this Contract.</li>
      <li aria-level="2">Payment under this Contract means acceptance of all the terms and conditions (clauses) listed above.</li>
      <li aria-level="2">The party that violates the obligations provided for in clauses 8.1.-8.3. pays the other party a fine of 1000 (one thousand) dollars for each violation.</li>
      <li aria-level="2">Annex 1 and Annex 2 are an integral part of the Contract.</li>
    </ol>
    <h1 style="text-align: center;">&nbsp;</h1>
    <h1 style="text-align: center;">Refund policy</h1>
    <p style="text-align: right;"><strong>Annex No. 1</strong> to the Terms and conditions for the provision of intermediary and technical services by Temp-Number</p>
    <p style="text-align: right;"><strong>Last update: January 1, 2022</strong></p>
    <p>&nbsp;</p>
    <p>This annex is an integral part of the Terms and conditions for the provision of intermediary and technical services by Temp-Number (hereinafter - the Contract). <br /><br /></p>
    <p>In this Annex, the Terms provided for in section "1" of the Contract are used.<br /><br /></p>
    <h2>Refund Operation Procedure</h2>
    <p>The Temp-Number keeps the right not to refund balances from the Client&rsquo;s Credits Balance. Despite this, any request for a refund are done in the following way :&nbsp;&nbsp;</p>
    <ol>
      <li aria-level="1">The Client Balance should be more than $50 Balance Credits.&nbsp;</li>
      <li aria-level="1">The Client files with the Temp-Number service a request for a Refund Operation using the support email: support@temp-number.org;</li>
      <li aria-level="1">The Temp-Number service reviews the request within 7 business days;</li>
      <li aria-level="1">The Temp-Number service offers options for amending the Service if the reason for the Refund operation is a failure to provide the Service or its disability;</li>
      <li aria-level="1">The Temp-Number service determines the amount of a refund based on the balance of the Client&rsquo;s Credits Balance on the Personal page;&nbsp;</li>
      <li aria-level="1">The service does the Refund operation based on the method of refund specified in the Client&rsquo;s request, and if it is not possible to refund the Client&rsquo;s money according to the selected refund method, the Temp-Number service offers the Client other ways to do the Refund operation.</li>
      <li aria-level="1">All refunds will be issued primarily to the original method of payment within 7 business days.</li>
      <li aria-level="1">All applicable fees are payable by the Client and deducted from the refunded amount.</li>
      <li aria-level="1">All mentioned below refers to Credit Balance only. In any case, no refunds will be made for succesfull activations ( sms message arrived ).&nbsp; &nbsp;</li>
    </ol>
    <p>&nbsp;</p>
    <h1 style="text-align: center;">Restricted services</h1>
    <p>&nbsp;</p>
    <p style="text-align: right;"><strong>Annex No. 2</strong> to the Terms and conditions for the provision of intermediary and technical services by Temp-Number</p>
    <p style="text-align: right;"><strong>Last update: January 1, 2022</strong><strong><br /></strong><strong><br /><br /></strong></p>
    <ol>
      <li aria-level="1">This annex is an integral part of the Terms and conditions for the provision of intermediary and technical services by Temp-Number (hereinafter - the Contract).</li>
      <li aria-level="1">In this Annex, the Terms provided for in section "1" of the Contract are used.</li>
      <li aria-level="1">The list provided for in clause 4 of this Annex may be amended and supplemented by the Provider in accordance with the conditions of clause 5.3.1. of the Contract.</li>
      <li aria-level="1"><strong>The list of names of senders for whom the SMS Reception service is not provided: </strong>DDOnline, MKKDDOnline, PMSM, Tinkoff, PayQR, Denga, Wooppay.com, MOBI.Dengi, Modulbank, Ezaem, denvzaimy, 111, QIWIWallet, YandexMoney, Wallet One, PrivatBank, WebMoney, qppi.ru, PlatiPotom, WM Mini, MoneXy, Eurozaem, MEGAFON, PLATIZA.RU, ekapusta, MoneyClick, Alfa-Bank, INTERKASSA, Beeline, WM Check, MegaFonPRO, Zaymer.ru, QIWI Wallet, QIWI_WALLET, AdminVPS, WalletOne, qppiru, WMMini, PLATIZARU, AlfaBank, MobiDengi, WMCheck, Zaymerru, uBank, QIWI, MegaFon, GreenMoney, LIME-ZAIM, MegaFon_web, MegaFonTV, MigCredit, Moneyman.ru, PLATIZA, QIWI_Wallet, QPPI.RU,SMSfinance, unicom24ru, Vivus.ru, 0500, ActiveBC, LOVIZAIM, MangoMoney, moneyman, POCHTABANK, SPASIBO, MTC or <strong>any other financial institution or government services.</strong>&nbsp;</li>
    </ol>
  </div>
</template>
