<template>
  <transition name="fade" appear>
    <div v-if="fullPageLoading" class="loading-wrapper">
      <v-lottie-player
        :animationData="require('@/assets/loaders/iphone-orange-spinner.json')"
        loop
        width="60px"
        height="60px"
      />
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    fullPageLoading () {
      return this.$store.state.common.fullPageLoading
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/animation/fade-solo.scss';

  .loading-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.55);
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
