import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useBalance } from '@/use/balance'
import { computed } from 'vue'

export function useOrder () {
  const router = useRouter()
  const store = useStore()
  const { initApiInst, smsServicesApi } = useSmsApi()
  const { handleNotAuthorizedError, handle503Error } = useErrorHandlers()
  const { getBalance } = useBalance()
  const { locale } = useI18n()

  const toOrderWithPreset = async (activation) => {
    try {
      await initApiInst()
      const resp = await smsServicesApi.value.getSingleService(activation.serviceId, activation.countryId, {
        prxLang: locale.value,
        prxPrint: store.state.common.prxPrint
      })
      if (resp && resp.services && resp.services.length > 0 && resp.services[0].hasNumbers) {
        const service = resp.services[0]
        store.commit('order/setIconBaseUrl', resp.iconBaseUrl)
        const { serviceId, serviceName, serviceIconPath, price, snippet, warning, hasNumbers } = service
        store.commit('order/setService', { serviceId, serviceName, serviceIconPath, snippet, warning, hasNumbers })
        const { countryId, countryName, countryIconPath, isVirtual } = service
        const country = { countryId, countryName, countryIconPath, hasNumbers, price, isVirtual }

        store.commit('order/setPrice', price)
        store.commit('order/setCountry', country)
        store.commit('order/setOneClickSelect', false)
        await router.push({ name: 'OrderStep3' })
      } else {
        store.commit('order/clearOrder')
        await router.push({ name: 'Home' })
      }
    } catch (e) {
      if (e.status === 401) {
        await handleNotAuthorizedError()
      } else if (e.status === 503) {
        await handle503Error()
      } else {
        await router.push({ name: 'Home' })
      }
    }
  }

  const serviceCountryAvailable = async (serviceId, country) => {
    if (!serviceId || !country) return false
    store.commit('common/setFullPageLoading', true)
    try {
      await initApiInst()
      const resp = await smsServicesApi.value.getSingleService(serviceId, country.countryId, {
        prxLang: locale.value,
        prxPrint: store.state.common.prxPrint
      })
      if (resp && resp.services && resp.services.length > 0 && resp.services[0].hasNumbers) {
        country.price = resp.services[0].price
        store.commit('order/setCountry', country)
        store.commit('order/setPrice', resp.services[0].price)
        return true
      }
      return false
    } catch (e) {
      return false
    } finally {
      store.commit('common/setFullPageLoading', false)
    }
  }

  const handleNewOrderClick = async () => {
    store.commit('order/setForceActivation', false)
    const activeService = computed(() => store.state.order.service)
    const activeCountry = computed(() => store.state.order.country)

    if (activeService.value && activeCountry.value) {
      await router.push({ name: 'OrderStep3' })
    } else {
      await router.push({ name: 'Home' })
    }
  }

  const forceActivationRedirect = async (saveOrderData = false) => {
    const currentBalance = await getBalance()
    if (currentBalance < store.state.order.price) {
      if (saveOrderData) {
        store.commit('order/setForceActivation', true)
        const orderData = Object.assign(store.state.order, { timestamp: Date.now() })
        localStorage.setItem('order', JSON.stringify(orderData))
      }
      await router.push({ name: 'Funds' })
    } else {
      await router.push({ name: 'Activating' })
    }
  }

  return { toOrderWithPreset, serviceCountryAvailable, handleNewOrderClick, forceActivationRedirect }
}
