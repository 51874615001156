import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import store from '@/store'
import i18n from '@/utils/i18n'
import Home from '@/views/Home.vue'
import OrderStep2 from '@/views/Order/Step2.vue'
import OrderStep3 from '@/views/Order/Step3.vue'
import Transactions from '@/views/Transactions.vue'
import Funds from '@/views/Funds.vue'
import History from '@/views/History.vue'
import CreateAccount from '@/views/CreateAccount.vue'
import SignUp from '@/views/SignUp.vue'
import SignIn from '@/views/SignIn.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import NewPassword from '@/views/NewPassword.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import ChangeEmail from '@/views/ChangeEmail.vue'
import Activating from '@/views/Activating.vue'
import Activation from '@/views/Activation.vue'
import Privacy from '@/views/Privacy.vue'
import Terms from '@/views/Terms.vue'
import ContactUs from '@/views/ContactUs.vue'
import Api from '@/views/Api.vue'
import DeepLink from '@/views/DeepLink.vue'
import Error503 from '@/views/Error503.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'MainLayout'
    },
    component: Home
  },
  {
    path: '/order/country',
    name: 'OrderStep2',
    meta: {
      layout: 'MainLayout'
    },
    component: OrderStep2
  },
  {
    path: '/order/activation',
    name: 'OrderStep3',
    meta: {
      layout: 'MainLayout'
    },
    component: OrderStep3
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: {
      layout: 'MainLayout',
      requiresAuth: true
    },
    component: Transactions
  },
  {
    path: '/funds',
    name: 'Funds',
    meta: {
      layout: 'MainLayout'
    },
    component: Funds
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    meta: {
      layout: 'AuthLayout',
      noAuthRequired: true
    },
    component: CreateAccount
  },
  {
    path: '/signup',
    name: 'SignUp',
    meta: {
      layout: 'AuthLayout',
      noAuthRequired: true
    },
    component: SignUp
  },
  {
    path: '/signin',
    name: 'SignIn',
    meta: {
      layout: 'AuthLayout',
      noAuthRequired: true
    },
    component: SignIn
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      layout: 'AuthLayout',
      noAuthRequired: true
    },
    component: ResetPassword
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    meta: {
      layout: 'AuthLayout'
    },
    component: NewPassword
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: {
      layout: 'AuthLayout',
      requiresAuth: true
    },
    component: ChangePassword
  },
  {
    path: '/change-email',
    name: 'ChangeEmail',
    meta: {
      layout: 'AuthLayout',
      requiresAuth: true
    },
    component: ChangeEmail
  },
  {
    path: '/activating',
    name: 'Activating',
    meta: {
      layout: 'MainLayout',
      requiresAuth: true
    },
    component: Activating
  },
  {
    path: '/activation/:id',
    name: 'Activation',
    meta: {
      layout: 'MainLayout',
      requiresAuth: true
    },
    component: Activation
  },
  {
    path: '/history',
    name: 'History',
    meta: {
      layout: 'MainLayout',
      requiresAuth: true
    },
    component: History
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    meta: {
      layout: 'MainLayout'
    },
    component: ContactUs
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    meta: {
      layout: 'MainLayout'
    },
    component: Privacy
  },
  {
    path: '/terms-of-service',
    name: 'Terms',
    meta: {
      layout: 'MainLayout'
    },
    component: Terms
  },
  {
    path: '/api',
    name: 'Api',
    meta: {
      layout: 'MainLayout',
      requiresAuth: true
    },
    component: Api
  },
  {
    path: '/deeplink',
    name: 'DeepLink',
    meta: {
      layout: 'EmptyLayout'
    },
    component: DeepLink
  },
  {
    path: '/error503',
    name: 'Error503',
    meta: {
      layout: 'EmptyLayout'
    },
    component: Error503
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const noAuthRequired = to.matched.some(x => x.meta.noAuthRequired)
  const auth = getAuth()
  const currentUser = auth.currentUser

  if (requiresAuth && !currentUser) {
    store.commit('alert/add', {
      id: Date.now(),
      text: i18n.global.t('auth_needed_error_login_signup'),
      timeout: 3000,
      name: 'auth_needed_error_login_signup'
    })
    next({ name: 'CreateAccount' })
  } else if (noAuthRequired && currentUser) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export default router
