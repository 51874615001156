<template>
  <div v-if="showProVersion" class="pro-version-text form__help-link form__help-link-bold">
    <component :is="{ template: proVersionText }" />
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'

export default {
  data () {
    return {
      showProVersion: process.env.VUE_APP_SHOW_PRO_VERSION ? Number(process.env.VUE_APP_SHOW_PRO_VERSION) : 0,
      proVersionUrl: process.env.VUE_APP_PRO_VERSION_URL + '?project=act'
    }
  },
  setup () {
    const { addBreakLinesToText, addExternalLinksToText } = useTranslate()
    return { addBreakLinesToText, addExternalLinksToText }
  },
  computed: {
    proVersionText () {
      const textWithBrs = this.addBreakLinesToText(this.$t('text_671a0300c9fc5600f72f3bd0'))
      return this.addExternalLinksToText(textWithBrs, [this.proVersionUrl])
    }
  }
}
</script>

<style lang="scss" scoped>
  .pro-version-text {
    width: 275px;
    margin: 32px auto;
    position: relative;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.2px;
    color: #848194;
    text-align: center;
  }
</style>
