<template>
  <div v-if="$route.query.continue_url && $route.name === 'Funds'" class="logo">
    <img class="logo__image" src="@/assets/logo/logomark.svg" alt="Temp Number">
    <p class="logo__text">{{ $t('text_6167c4a5321c670152f0e1bc') }}</p>
  </div>
  <div v-else @click="handleLogoClick" class="logo">
    <img class="logo__image" src="@/assets/logo/logomark.svg" alt="Temp Number">
    <p class="logo__text">{{ $t('text_6167c4a5321c670152f0e1bc') }}</p>
  </div>
</template>

<script>
import { useLogo } from '@/use/logo'

export default {
  setup () {
    const { handleLogoClick } = useLogo()

    return { handleLogoClick }
  }
}
</script>

<style scoped lang="scss">
  .logo {
    display: flex;
    padding: 8px 0;
    cursor: pointer;

    &__image {
      padding-right: 8px;
    }

    &__text {
      padding-top: 5px;
      color: #14142B;
      font-weight: 500;
      font-size: 24px;
    }
  }

  .rtl {
    .logo {
      &__image {
        padding-left: 8px;
        padding-right: 0;
      }
    }
  }

  @media (max-width: 590px) {
    .logo {
      padding: 5px 0px;

      &__image {
        width: 32px;
        height: 32px;
        padding-right: 0px;
        margin-right: 8px;
        img {
          height: 100%;
          width: 100%;
        }
      }

      &__text {
        padding-top: 5px;
        font-size: 18px;
        letter-spacing: -0.6px;
      }
    }
  }

  @media (max-width: 320px) {
    .logo {
      &__text {
        padding-top: 0;
        font-size: 14px;
        margin-top: -1px;
        width: 50px;
      }
    }
  }
</style>
