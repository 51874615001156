<template>
  <div :class="{'search--open': isSearchOpen}" v-click-outside="onClickOutside" class="search">
    <div v-if="isSearchOpen" @click="hideList" class="search__close">
      <img src="@/assets/icons/close.svg" alt="close">
    </div>

    <div v-show="!orderLoading" class="search__input">
      <input
        :value="searchString"
        @input="e => searchString = e.target.value"
        @focus="loadAvailableCountries"
        :placeholder="$t('text_6167c4a5321c670152f0e1c4')"
        ref="countrySearchInput"
        type="text"
      />
      <div v-if="searchString || (searchData.length && !isMobile())" @click="clearSearch" class="search__clear" />
    </div>

    <div v-if="isSearchOpen" class="search-items">
      <ul v-if="searchData.length">
        <li
          v-for="country in searchData"
          :key="country.countryId"
          @click="selectCountry(country)"
          :class="{ 'noNumbers': !country.hasNumbers }"
          class="search-item search-item__country"
        >
          <div class="search-item__country-wrap">
            <div class="search-item__images search-item__images-country">
              <img :src="`${iconBaseUrl + country.countryIconPath}`" class="search-item__main-img orderCountryFlag">
            </div>
            <p class="search-item__text">{{ country.countryName }}</p>
          </div>
          <div
            v-if="highDemandMinPrice && country.price >= highDemandMinPrice && country.hasNumbers"
            @click.stop="showHighDemandModal"
            class="search-item__country-high-demand"
          >
            <img src="@/assets/icons/high_demand.svg" alt="high demand">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import Fuse from 'fuse.js'
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useTranslate } from '@/use/translate'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      allCountries: [],
      availableCountries: [],
      searchData: [],
      searchString: '',
      iconBaseUrl: null,
      getAllCountriesInterval: 7200, // in seconds (2 hours)
      cacheMaxAvailableItems: 15,
      cacheAvailableTime: 300 // in seconds
    }
  },
  computed: {
    activeService () {
      return this.$store.state.order.service
    },
    isSearchOpen () {
      return this.$store.state.order.isSearchOpen
    },
    currentTimestamp () {
      return Math.round(Date.now() / 1000)
    },
    availableCountriesCache () {
      return JSON.parse(localStorage.getItem('availableCountries')) || {}
    },
    orderLoading () {
      return this.$store.state.order.orderLoading
    },
    prxPrint () {
      return this.$store.state.common.prxPrint
    },
    highDemandMinPrice () {
      return this.$store.state.common.highDemandMinPrice
    }
  },
  watch: {
    searchString (str) {
      if (str) {
        this.$store.commit('order/setIsSearchOpen', true)
        this.searchCountries(str)
      } else {
        if (!this.isMobile()) {
          this.$store.commit('order/setIsSearchOpen', false)
        } else {
          this.searchData = []
          if (this.$refs.countrySearchInput === document.activeElement) {
            this.loadAvailableCountries()
          }
        }
      }
    }
  },
  setup () {
    const { initApiInst, smsServicesApi, smsUtilsApi } = useSmsApi()
    const { handle503Error, handleNetworkError, showNoNumbersError } = useErrorHandlers()
    const { addBreakLinesToText } = useTranslate()
    return { initApiInst, smsServicesApi, smsUtilsApi, handle503Error, handleNetworkError, showNoNumbersError, addBreakLinesToText }
  },
  mounted () {
    this.setAllCountries()
    if (this.isSearchOpen) {
      this.$refs.countrySearchInput.focus()
    }
  },
  unmounted () {
    this.$store.commit('order/setIsSearchOpen', false)
  },
  methods: {
    onClickOutside () {
      if (!this.isMobile()) {
        this.hideList()
      }
    },
    async setAllCountries () {
      const allCountries = JSON.parse(localStorage.getItem('allCountries'))
      const notNeedUpdateByTime = allCountries && allCountries.fromTimestamp > this.currentTimestamp - this.getAllCountriesInterval
      if (allCountries && allCountries.countries && allCountries.countries.length > 0 && allCountries.lang === this.$i18n.locale && notNeedUpdateByTime) {
        this.allCountries = allCountries.countries
        this.iconBaseUrl = allCountries.iconBaseUrl
      } else {
        const allCountriesResult = await this.getAllCountries(allCountries)
        if (allCountriesResult && allCountriesResult.countries) {
          this.allCountries = allCountriesResult.countries
          this.iconBaseUrl = allCountriesResult.iconBaseUrl
        } else {
          if (allCountries && allCountries.countries) {
            this.allCountries = allCountries.countries
            this.iconBaseUrl = allCountries.iconBaseUrl
          }
        }
      }
    },
    async getAllCountries (allCountries) {
      try {
        const fromTimestamp = allCountries && allCountries.countries && allCountries.countries.length > 0 && allCountries.fromTimestamp && allCountries.lang === this.$i18n.locale
          ? allCountries.fromTimestamp : 0
        await this.initApiInst()
        const allCountriesResp = await this.smsUtilsApi.getAllCountries({
          fromTimestamp,
          prxLang: this.$i18n.locale,
          prxPrint: this.prxPrint
        })
        if (allCountriesResp && allCountriesResp.countries && allCountriesResp.countries.length > 0) {
          allCountriesResp.fromTimestamp = this.currentTimestamp
          allCountriesResp.lang = this.$i18n.locale
          localStorage.setItem('allCountries', JSON.stringify(allCountriesResp))
          return allCountriesResp
        } else {
          allCountries.fromTimestamp = this.currentTimestamp
          localStorage.setItem('allCountries', JSON.stringify(allCountries))
          return null
        }
      } catch (e) {
        return null
      }
    },
    async searchCountries (str) {
      if (this.availableCountries.length === 0) return false
      const fuse = new Fuse(this.availableCountries, {
        useExtendedSearch: true,
        keys: ['altName']
      })
      const searchResults = fuse.search("'" + str)
      this.searchData = []
      searchResults.map(result => {
        this.searchData.push(result.item)
      })
      this.orderCountriesByName()
    },
    orderCountriesByName () {
      this.searchData.sort(function (a, b) {
        if (a.countryName < b.countryName) return -1
        if (a.countryName > b.countryName) return 1
        return 0
      })
    },
    async loadAvailableCountries () {
      if (this.searchString.length) return false
      try {
        let needSaveToCache = false
        let countriesResp = this.getAvailableCountriesFromCache()
        if (!countriesResp) {
          await this.initApiInst()
          countriesResp = await this.smsServicesApi.getAvailableCountriesByService(this.activeService.serviceId, {
            prxLang: this.$i18n.locale,
            prxPrint: this.prxPrint
          })
          needSaveToCache = true
        }
        if (countriesResp) {
          if (needSaveToCache) this.saveAvailableCountriesCache(countriesResp)
          this.searchData = []
          this.availableCountries = []
          countriesResp.countries.map(country => {
            const countryData = this.allCountries.find(item => item.id === country.countryId)
            const countryRes = {
              countryId: country.countryId,
              countryName: countryData.name,
              countryIconPath: countryData.iconPath,
              price: country.price,
              hasNumbers: country.hasNumbers,
              isVirtual: countryData.isVirtual
            }
            this.searchData.push(countryRes)
            countryRes.altName = countryData.altName
            this.availableCountries.push(countryRes)
          })
          this.orderCountriesByName()
          this.$store.commit('order/setIsSearchOpen', true)
        }
      } catch (e) {
        if (e.status === 503) {
          this.handle503Error()
        } else {
          this.handleNetworkError()
        }
      }
    },
    getAvailableCountriesFromCache () {
      if (this.availableCountriesCache.items) {
        const availableCountriesByService = this.availableCountriesCache.items.find(item => item.service === this.activeService.serviceId)
        if (availableCountriesByService) {
          if (availableCountriesByService.time > this.currentTimestamp) {
            return {
              iconBaseUrl: this.availableCountriesCache.iconBaseUrl,
              countries: availableCountriesByService.countries
            }
          } else {
            const index = this.availableCountriesCache.items.findIndex(item => item.service === this.activeService.serviceId)
            if (index !== -1) {
              this.availableCountriesCache.items.splice(index, 1)
              localStorage.setItem('availableCountries', JSON.stringify(this.availableCountriesCache))
            }
          }
        }
      }
      return null
    },
    saveAvailableCountriesCache (data) {
      if (this.availableCountriesCache.items && this.availableCountriesCache.items.length >= this.cacheMaxAvailableItems) {
        this.availableCountriesCache.items.pop()
      }
      const newItem = {
        time: this.currentTimestamp + this.cacheAvailableTime,
        service: this.activeService.serviceId,
        countries: data.countries
      }
      this.availableCountriesCache.iconBaseUrl = data.iconBaseUrl
      if (!this.availableCountriesCache.items) {
        this.availableCountriesCache.items = []
      }
      this.availableCountriesCache.items.unshift(newItem)

      localStorage.setItem('availableCountries', JSON.stringify(this.availableCountriesCache))
    },
    selectCountry (country) {
      if (country.hasNumbers) {
        this.$store.commit('order/setCountry', country)
        this.$store.commit('order/setPrice', country.price)
        this.$store.commit('order/setIconBaseUrl', this.iconBaseUrl)
        this.$store.commit('order/setIsSearchOpen', false)
        this.$amplitude.logEvent('order.country_selected', {
          selection_source: 'search',
          country: country.countryId
        })
        this.$router.push({ name: 'OrderStep3' })
      } else {
        this.showNoNumbersError()
      }
    },
    hideList () {
      this.$store.commit('order/setIsSearchOpen', false)
      this.searchString = ''
      this.searchData = []
    },
    clearSearch () {
      if (this.isMobile()) {
        this.searchString = ''
        this.searchData = []
        this.$refs.countrySearchInput.focus()
      } else {
        this.hideList()
      }
    },
    showHighDemandModal () {
      this.$store.commit('notificationModal/show', {
        title: this.$t('text_64403b51498b0c006499ab70'),
        text: this.addBreakLinesToText(this.$t('text_64403b51498b0c006499ab71')),
        buttonText: this.$t('text_64403b51498b0c006499ab72')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-item__country{
    justify-content: space-between;
    &-wrap{
      display: flex;
    }
    &-high-demand{
      display: flex;
      align-items: center;
      margin: 0 16px;
    }
  }
</style>
