export default {
  namespaced: true,
  state: {
    service: null,
    country: null,
    oneClickSelect: false,
    iconBaseUrl: null,
    price: null,
    forceActivation: false,
    activateAgain: false,
    isSearchOpen: false,
    orderLoading: false
  },
  mutations: {
    setService (state, service) {
      state.service = service
    },
    setCountry (state, country) {
      state.country = country
    },
    setOneClickSelect (state, value) {
      state.oneClickSelect = value
    },
    setIconBaseUrl (state, url) {
      state.iconBaseUrl = url
    },
    setPrice (state, price) {
      state.price = price
    },
    setForceActivation (state, val) {
      state.forceActivation = val
    },
    setIsSearchOpen (state, val) {
      state.isSearchOpen = val
    },
    setActivateAgain (state, val) {
      state.activateAgain = val
    },
    clearOrder (state) {
      state.service = state.country = state.iconBaseUrl = state.price = null
      state.oneClickSelect = state.forceActivation = false
    },
    clearCountry (state) {
      state.country = state.price = null
      state.oneClickSelect = state.forceActivation = false
    },
    setOrderLoading (state, val) {
      state.orderLoading = val
    },
    setOrderData (state, orderData) {
      this.commit('order/setService', orderData.service)
      this.commit('order/setCountry', orderData.country)
      this.commit('order/setOneClickSelect', orderData.oneClickSelect)
      this.commit('order/setIconBaseUrl', orderData.iconBaseUrl)
      this.commit('order/setPrice', orderData.price)
      this.commit('order/setForceActivation', orderData.forceActivation)
      this.commit('order/setActivateAgain', orderData.activateAgain)
      this.commit('order/setOrderLoading', false)
    }
  }
}
