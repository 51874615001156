<template>
  <ul v-if="service" class="radio-buttons radio-buttons__column radio-buttons__order-res">
    <li>
      <AnyOtherWarning v-if="service.serviceId === 'other' && anyOtherWarning === 1" />
      <div @click="backToStep(1)" class="radio-button">
        <div class="radio-button__images">
          <img :src="`${$store.state.order.iconBaseUrl}${service.serviceIconPath}`" class="radio-button__main-image" alt="img">
        </div>
        <div class="radio-button__text">{{ service.serviceName }}</div>
        <div v-if="service.snippet" @click.prevent.stop="showSnippet" class="radio-button__snippet">
          <img src="@/assets/icons/snippet.svg" alt="Service snippet">
        </div>
      </div>
    </li>
    <li>
      <div @click="backToStep(2)" class="radio-button">
        <div class="radio-button__images radio-button__images-country">
          <img :src="`${$store.state.order.iconBaseUrl}${country.countryIconPath}`" class="radio-button__main-image orderCountryFlag" alt="img">
        </div>
        <div class="radio-button__text">{{ country.countryName }}</div>
        <div v-if="country.isVirtual" @click.prevent.stop="showIsVirtual" class="radio-button__snippet">
          <img src="@/assets/icons/snippet.svg" alt="Virtual country snippet">
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import AnyOtherWarning from '@/components/Order/AnyOtherWarning'

export default {
  components: { AnyOtherWarning },
  data () {
    return {
      servicesData: null
    }
  },
  computed: {
    service () {
      return this.$store.state.order.service
    },
    country () {
      return this.$store.state.order.country
    },
    anyOtherWarning () {
      return this.$store.state.common.anyOtherWarning
    }
  },
  methods: {
    showSnippet () {
      this.$store.commit('notificationModal/show', {
        title: this.service.serviceName.charAt(0).toUpperCase() + this.service.serviceName.slice(1),
        text: this.service.snippet,
        buttonText: this.$t('text_616fe99d27618401311d6c44')
      })
    },
    showIsVirtual () {
      this.$store.commit('notificationModal/show', {
        title: this.$t('text_616fe99d27618401311d6c42'),
        text: this.$t('text_616fe99d27618401311d6c45'),
        buttonText: this.$t('text_616fe99d27618401311d6c44')
      })
    },
    backToStep (step) {
      this.$store.commit('order/setOneClickSelect', false)
      this.$store.commit('order/setIsSearchOpen', true)
      if (step === 1) {
        this.$router.push({ name: 'Home' })
      } else if (step === 2) {
        this.$router.push({ name: 'OrderStep2' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .radio-buttons__order-res {
    width: 390px;
    margin-top: 75px;
    li {
      width: 100%;
      margin-bottom: 19px;
      position: relative;
      .radio-button{
        padding: 20px 13px 20px 18px;
      }
    }
  }
  .radio-button__snippet {
    margin-right: 0;
    margin-left: auto;
    width: 32px;
    height: 32px;
  }

  @media (max-width: 590px) {
    .radio-buttons__order-res {
      width: 100%;
      max-width: 448px;
      margin-top: 25px;
      li {
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
        }

        .radio-button{
          padding: 18px 10px 18px 18px;
          &__images-country{
            margin-left: 3px;
          }
        }
      }
    }
    .radio-button__snippet {
      margin-top: -4px;
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .radio-buttons__order-res {
      margin-top: 40px;
      li {
        .radio-button {
          padding: 14px 13px 14px 18px;
        }
      }
    }
  }
</style>
