export function useUserCountry () {
  const getUserCountry = async () => {
    try {
      const countryCheckPromise = fetch(process.env.VUE_APP_GEO_DETECTION_SERVICE_URL)
      const countryRes = await countryCheckPromise
      const countryResText = await countryRes.text()
      const match = countryResText.match(/cf-ipcountry: [A-Za-z]{2}/i)
      if (match && match[0]) {
        const countryStr = match[0]
        const countryArr = countryStr.split(': ')
        if (countryArr && countryArr.length > 1) {
          return countryArr[1]
        }
      }
    } catch (e) {
      return ''
    }
  }

  return { getUserCountry }
}
