import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useLoadScript } from '@/use/loadScript'
import { useUserCountry } from '@/use/userCountry'

export function usePaddle () {
  const store = useStore()
  const router = useRouter()
  const { loadScript } = useLoadScript()
  const { getUserCountry } = useUserCountry()

  const initPaddle = async () => {
    await loadScript(process.env.VUE_APP_PADDLE_CDN_URL)

    if (parseInt(process.env.VUE_APP_PADDLE_PROD_MODE, 10) === 0) {
      window.Paddle.Environment.set('sandbox')
    }
    window.Paddle.Setup({ vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID, 10) })
  }

  const addPaddleFunds = async (amount, uid, email) => {
    const products = JSON.parse(process.env.VUE_APP_PADDLE_PROD_IDS_PRICES)
    const productId = products[amount] ? products[amount] : 'custom'
    const userCountry = await getUserCountry()

    if (productId === 'custom') {
      const overrideUrl = await getOverrideUrl(amount, productId, uid, userCountry, email)
      if (overrideUrl) {
        window.Paddle.Checkout.open({
          override: overrideUrl,
          successCallback: function () {
            store.commit('funds/setAddFundsLoading', false)
            router.push({ name: 'Funds', query: { status: 'success' } })
          },
          closeCallback: function () {
            store.commit('funds/setAddFundsLoading', false)
          }
        })
      } else {
        store.commit('funds/setAddFundsLoading', false)
        await router.push({ name: 'Funds', query: { status: 'error' } })
      }
    } else {
      const passthrough = { uid }

      window.Paddle.Checkout.open({
        product: productId,
        email,
        country: userCountry,
        allowQuantity: false,
        passthrough,
        successCallback: function () {
          store.commit('funds/setAddFundsLoading', false)
          router.push({ name: 'Funds', query: { status: 'success' } })
        },
        closeCallback: function () {
          store.commit('funds/setAddFundsLoading', false)
        }
      })
    }
  }

  async function getOverrideUrl (amount, productId, fbUid, userCountry, email) {
    try {
      const overrideParams = {
        amount,
        product_id: process.env.VUE_APP_PADDLE_CUSTOM_AMOUNT_PROD_ID,
        uid: fbUid,
        userCountry,
        email
      }

      const resp = await axios.get(`${process.env.VUE_APP_PADDLE_OVERRIDE_URL}`, {
        params: overrideParams
      })

      return resp && resp.data && resp.data.url ? resp.data.url : false
    } catch (e) {
      return false
    }
  }

  return { initPaddle, addPaddleFunds }
}
