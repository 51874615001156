<template>
  <div @click="$store.commit('modal/toggleConfirmPasswordModal', false)" class="modal">
    <div @click.stop="" class="modal__container">
      <div class="title">{{ $t('text_6172a25b475cfb00cc3e4696') }}</div>
      <div class="body">{{ $t('text_6172a25b475cfb00cc3e4699') }}</div>

      <PasswordControl
        :errorMessage="this.v$.password.$errors?.[0]?.$validator"
        v-model.trim="password"
        :placeholder="$t('text_6172a25b475cfb00cc3e4698')"
        class="form__control"
      />

      <ButtonElement @click="reauthenticate" :loading="loading" class="modal__button" fill height="58px">{{ $t('text_6172a25b475cfb00cc3e4697') }}</ButtonElement>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useTranslate } from '@/use/translate'
import { required, minLength } from '@vuelidate/validators'

import PasswordControl from '@/components/Controls/PasswordControl.vue'
import ButtonElement from '@/components/ButtonElement'
import { getAuth, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'

export default {
  components: { PasswordControl, ButtonElement },
  emits: ['userReauthenticated'],
  data () {
    return {
      password: '',
      loading: false
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { v$: useVuelidate(), addBreakLinesToText }
  },
  validations () {
    return {
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    async reauthenticate () {
      const isValid = await this.v$.$validate()

      if (isValid) {
        this.loading = true
        try {
          const auth = getAuth()
          const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            this.password
          )
          await reauthenticateWithCredential(auth.currentUser, credential)
          this.$store.commit('modal/toggleConfirmPasswordModal', false)
          this.$emit('userReauthenticated')
        } catch (e) {
          if (e.code === 'auth/invalid-login-credentials') {
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: this.$t('text_6172a00933ba91016926eff2'),
              timeout: 3000,
              name: 'invalid-login-credentials'
            })
          } else if (e.code === 'auth/too-many-requests') {
            this.$store.commit('notificationModal/show', {
              title: this.$t('login_requests_error_title'),
              text: this.addBreakLinesToText(this.$t('login_requests_error_subtitle')),
              buttonText: this.$t('text_616e4d4b66577e00e3d6ba11')
            })
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal')
          }
        } finally {
          this.v$.$reset()
          this.password = ''
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal__container{
    min-width: 496px;
    max-width: 496px;
    padding: 48px;
    @media screen and (max-width: 590px) {
      min-width: 310px !important;
      max-width: 310px !important;
    }

    .title{
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: -0.2px;
      color: #14142B;
      margin-bottom: 16px;
      @media screen and (max-width: 590px) {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 8px;
      }
    }
    .body{
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #14142B;
      opacity: 0.6;
      margin: 16px 0;
      @media screen and (max-width: 590px) {
        font-size: 14px;
        line-height: 20px;
        margin: 8px 0;
      }
    }
    .modal__button{
      margin-top: 0;
    }
    .password-control{
      margin-bottom: 32px;
    }
  }
</style>
