<template>
  <div class="container api">
    <div>
      <div class="api__header">
        <TitleElement class="api__title">
          <h1 class="title__main title__main--pb0">{{ $t('text_625fd073376b5300ff6dfda4') }}</h1>
          <h2 class="title__subtitle title__subtitle-api">
            <component :is="{ template: addBreakLinesToText($t('text_625fd073376b5300ff6dfda6')) }" />
          </h2>
        </TitleElement>

        <TitleElement class="api__title--mobile" tace>
          <h1 class="title__main title__main--pb0">{{ $t('text_625fd073376b5300ff6dfda4') }}</h1>
          <h2>
            <component :is="{ template: addBreakLinesToText($t('text_625fd073376b5300ff6dfda6')) }" />
          </h2>
        </TitleElement>

        <ButtonElement @click="openDocumentation" class="api__docs-btn" height="64px"  width="210px" fill>
          <img src="@/assets/icons/documentation.svg" alt="plus">
          <span>{{ $t('text_625fd073376b5300ff6dfda8') }}</span>
        </ButtonElement>
      </div>

      <transition name="fade" mode="out-in">
        <div v-if="pageLoading">
          <PageLoader />
        </div>
        <div v-else>
          <div class="api-key">
            <div class="api-key__block-title">{{ $t('text_625fd073376b5300ff6dfdaa') }}</div>
            <div class="api-key__bl">
              <ApiKeyView v-if="apiKey" @regenerateApiKey="questionModal = true" @showApiKey="keyHidden = false" :keyHidden="keyHidden" />
              <ApiKeyGeneration v-else @generateApiKey="generateApiKey" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>

  <QuestionModal v-if="questionModal" @decline="questionModal = false">
    <template v-slot:title>{{ $t('text_625fcc8f4da9c70109f990d4') }}</template>
    <template v-slot:text>{{ questionModalText }}</template>
    <template v-slot:acceptBtn>
      <ButtonElement @click="generateApiKey" :loading="btnLoading" class="modal__button modal__button-accept" fill height="58px">
        {{ $t('text_625fcc8f4da9c70109f990d6') }}
      </ButtonElement>
    </template>
    <template v-slot:declineBtn>{{ $t('text_625fcc8f4da9c70109f990d7') }}</template>
  </QuestionModal>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useTranslate } from '@/use/translate'
import TitleElement from '@/components/TitleElement.vue'
import ButtonElement from '@/components/ButtonElement.vue'
import PageLoader from '@/components/Loaders/PageLoader.vue'
import ApiKeyGeneration from '@/components/Api/ApiKeyGeneration.vue'
import ApiKeyView from '@/components/Api/ApiKeyView.vue'
import QuestionModal from '@/components/Modals/QuestionModal'

export default {
  components: {
    TitleElement,
    ButtonElement,
    PageLoader,
    ApiKeyGeneration,
    ApiKeyView,
    QuestionModal
  },
  data () {
    return {
      keyHidden: true,
      questionModal: false
    }
  },
  computed: {
    pageLoading () {
      return this.$store.state.common.pageLoading
    },
    btnLoading () {
      return this.$store.state.common.btnLoading
    },
    apiKey () {
      return this.$store.state.user.apiKey
    },
    questionModalText () {
      return this.addBreakLinesToText(this.$t('text_625fcc8f4da9c70109f990d5'))
    }
  },
  mounted () {
    this.getApiKey()
  },
  setup () {
    const { initApiInst, smsUserApi } = useSmsApi()
    const { handleNotAuthorizedError, showCommonError } = useErrorHandlers()
    const { addBreakLinesToText } = useTranslate()
    return { initApiInst, smsUserApi, handleNotAuthorizedError, showCommonError, addBreakLinesToText }
  },
  methods: {
    openDocumentation () {
      window.open(process.env.VUE_APP_API_DOCS_LINK)
    },
    async getApiKey () {
      this.$store.commit('user/setApiKey', null)
      try {
        await this.initApiInst()
        const resp = await this.smsUserApi.getApiKey({ prxPrint: this.$store.state.common.prxPrint })
        if (resp && resp.apiKey) {
          this.$store.commit('user/setApiKey', resp.apiKey)
        }
      } catch (e) {
        this.$store.commit('user/setApiKey', null)
      } finally {
        this.$store.commit('common/setPageLoading', false)
      }
    },
    async generateApiKey () {
      if (this.btnLoading) return
      try {
        this.$store.commit('common/setBtnLoading', true)
        await this.initApiInst()
        const resp = await this.smsUserApi.generateApiKey({ prxPrint: this.$store.state.common.prxPrint })
        if (resp && resp.apiKey) {
          this.questionModal = false
          this.keyHidden = false
          this.$store.commit('user/setApiKey', resp.apiKey)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('text_625fcccc4da9c70109f990e4'),
            type: 'success',
            timeout: 2500,
            name: 'api_key_generated'
          })
          this.$amplitude.logEvent('api.user_generated_api_key')
          const emailVerifiedIdentity = new this.$amplitude.Identify().set('api_key_generated', 'true')
          this.$amplitude.getInstance().identify(emailVerifiedIdentity)
        }
      } catch (e) {
        if (e.status && e.status === 401) {
          this.handleNotAuthorizedError()
        } else {
          this.showCommonError(e)
        }
      } finally {
        this.$store.commit('common/setBtnLoading', false)
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/animation/fade-history.scss';

  .api {
    padding-top: 60px;
    max-width: 718px;
    margin: 0 auto 60px auto;

    .title__subtitle-api {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
      margin: 2px 0;
    }

    &__title--mobile {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__docs-btn {
      margin-top: 5px;
      img {
        margin-right: 10px;
      }

      span {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: justify;
        top: -1px;
      }
    }

    &-key {
      margin-top: 55px;
      color: #14142b;

      &__block-title {
        height: 42px;
        background: #f8f8f8;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 0 32px;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 32px;
      }

      &__bl {
        margin: 32px 0;
        width: 100%;
        max-width: 536px;
        height: 62px;
        background: #F8F8F8;
        border-radius: 15px;
        padding: 0 18px;

        &>div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: inherit;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: justify;
          color: #191632;

          .generateBtn,
          .regenerateBtn {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px 0;
            .btnImg {
              display: flex;
              align-items: center;
              margin: 0 4px;
            }
          }
          .generateBtn{
            margin-left: 30px;
          }
        }

        .copyApiKeyBtn,
        .showApiKeyBtn {
          display: flex;
          align-items: center;
          cursor: pointer;
          border: none;
          background: transparent;
          img {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .rtl{
    .api{
      &__docs-btn img {
        margin-left: 10px;
        margin-right: 0;
      }
      .api-key__bl .copyApiKeyBtn img,
      .api-key__bl .showApiKeyBtn img {
        margin-left: 4px;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 590px) {
    .api-key {
      margin-top: 28px;

      &__block-title {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        padding: 12px 14px;
        height: 40px;
        margin-bottom: 16px;
      }

      &__bl {
        padding: 0 10px;
      }

      &__value {
        font-size: 13px;
      }
    }

    .api {
      padding-top: 0;

      &__title {
        display: none;

        &--mobile {
          display: block;
          margin: 0 auto;
          h2{
            margin-top: 8px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            font-weight: normal;
            color: #14142B;
            opacity: 0.6;
          }
        }
      }

      &__docs-btn {
        position: fixed;
        bottom: 35px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    .copyApiKeyBtn,
    .showApiKeyBtn,
    .generateBtn,
    .regenerateBtn {
      .btnImg {
        margin: 0 !important;
      }
      span {
        display: none;
      }
      img {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  @media (max-width: 320px) {
    .api-key {
      &__value{
        max-width: 150px;
        word-wrap: break-word;
      }
    }
  }
</style>
