<template>
  <div class="mobile-bottom-menu-placeholder" />
  <div class="mobile-bottom-shadow-bl" />
  <div class="mobile-bottom-menu">
    <div class="mobile-bottom-menu__container">
      <router-link :to="{ name: 'History' }" class="mobile-bottom-menu__link mobile-bottom-menu__link-history">
        <div >
          <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 18.5C16.9704 18.5 20.9999 14.4706 20.9999 9.5C20.9999 4.52944 16.9704 0.5 11.9999 0.5C7.02929 0.5 2.99985 4.52944 2.99985 9.5H0.709852C0.574266 9.49803 0.443928 9.55234 0.349852 9.65L0.149852 9.85C0.055196 9.94388 0.00195312 10.0717 0.00195312 10.205C0.00195312 10.3383 0.055196 10.4661 0.149852 10.56L3.67985 14.1C3.87767 14.2918 4.19204 14.2918 4.38985 14.1L7.91985 10.56C8.01451 10.4661 8.06775 10.3383 8.06775 10.205C8.06775 10.0717 8.01451 9.94388 7.91985 9.85L7.71985 9.65C7.62578 9.55234 7.49544 9.49803 7.35985 9.5H4.99985C4.99985 5.63401 8.13386 2.5 11.9999 2.5C15.8658 2.5 18.9999 5.63401 18.9999 9.5C18.9999 13.366 15.8658 16.5 11.9999 16.5C10.6176 16.5036 9.26605 16.0925 8.11985 15.32C7.9223 15.1876 7.65887 15.2127 7.48985 15.38L6.76985 16.11C6.66898 16.2136 6.61792 16.3559 6.62985 16.5C6.64261 16.648 6.7193 16.7831 6.83985 16.87C8.34868 17.9363 10.1523 18.5061 11.9999 18.5ZM11.1199 4.99999C11.1199 4.72385 11.3437 4.49999 11.6199 4.49999H12.3799C12.5125 4.49999 12.6396 4.55267 12.7334 4.64644C12.8272 4.7402 12.8799 4.86738 12.8799 4.99999V9.13999L15.7999 12.06C15.8945 12.1539 15.9478 12.2817 15.9478 12.415C15.9478 12.5483 15.8945 12.6761 15.7999 12.77L15.2699 13.3C15.176 13.3946 15.0482 13.4479 14.9149 13.4479C14.7815 13.4479 14.6537 13.3946 14.5599 13.3L11.2699 10.01C11.1782 9.91593 11.1248 9.79121 11.1199 9.65999V4.99999Z" fill="#FA8231"/>
          </svg>
          <span class="mobile-bottom-menu__link-text">
            {{ $t('text_6193a2427a5c5501258e0d08') }}
            <span v-if="activeActivationsCount" class="mobile-bottom-menu__link-notification">{{ activeActivationsCount }}</span>
          </span>
        </div>
      </router-link>
      <div @click="handleNumberButton" :class="{'router-link-active': !['History', 'Funds'].includes($route.name)}" class="mobile-bottom-menu__link">
        <div v-if="lastUsedNumber">
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="2.5 2 20 20">
            <path fill="black" d="M12.5 2C6.989 2 2.5 6.489 2.5 12C2.5 17.511 6.989 22 12.5 22C18.011 22 22.5 17.511 22.5 12C22.5 6.489 18.011 2 12.5 2ZM12.5 4C16.9301 4 20.5 7.56988 20.5 12C20.5 16.4301 16.9301 20 12.5 20C8.06988 20 4.5 16.4301 4.5 12C4.5 7.56988 8.06988 4 12.5 4Z"/>
            <path fill="black" d="M10.8571 7.80467L11.5938 8.76743C11.9148 9.18697 11.947 9.76935 11.6727 10.2073L11.0259 11.2404C10.953 11.3569 10.9372 11.5028 10.9926 11.6284C11.2044 12.1085 11.515 12.5557 11.9243 12.9704C12.3328 13.3842 12.7842 13.7096 13.2786 13.9463C13.4096 14.0089 13.5652 13.9938 13.687 13.9149L14.5999 13.3244C15.0202 13.0526 15.5661 13.0998 15.9549 13.4413L16.8607 14.2372C17.3126 14.6343 17.4249 15.3138 17.1234 15.8269L16.8001 16.377C16.4784 16.9243 15.8994 17.2421 15.28 17.211C13.8177 17.1376 12.2504 16.205 10.5777 14.4129C8.90266 12.6184 8.04499 10.9503 8.00459 9.40928C7.98755 8.76076 8.29771 8.16094 8.81994 7.8325L9.34655 7.50123C9.84081 7.19108 10.4868 7.32079 10.8571 7.80467Z"/>
          </svg>
          <span class="mobile-bottom-menu__link-text">{{ $t('text_6193a2427a5c5501258e0d0c') }}</span>
        </div>
        <div v-else>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM11 9H13.5C13.7761 9 14 9.22386 14 9.5V10.5C14 10.7761 13.7761 11 13.5 11H11V13.5C11 13.7761 10.7761 14 10.5 14H9.5C9.22386 14 9 13.7761 9 13.5V11H6.5C6.22386 11 6 10.7761 6 10.5V9.5C6 9.22386 6.22386 9 6.5 9H9V6.5C9 6.22386 9.22386 6 9.5 6H10.5C10.7761 6 11 6.22386 11 6.5V9Z" fill="white"/>
          </svg>
          <span class="mobile-bottom-menu__link-text">{{ $t('text_62697379bd87eb00f53aa898') }}</span>
        </div>
      </div>
      <router-link :to="{ name: 'Funds' }" class="mobile-bottom-menu__link">
        <div >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM11 4.5V5C12.1046 5 13 5.89543 13 7V7.75C13 8.02614 12.7761 8.25 12.5 8.25H11.75C11.4739 8.25 11.25 8.02614 11.25 7.75V6.75H8.75V7.63C8.74584 7.7045 8.77972 7.77603 8.84 7.82L12.27 10.65C12.7308 11.0288 12.9985 11.5935 13 12.19V13C13 14.1046 12.1046 15 11 15V15.5C11 15.7761 10.7761 16 10.5 16H9.5C9.22386 16 9 15.7761 9 15.5V15C7.89543 15 7 14.1046 7 13V12.25C7 11.9739 7.22386 11.75 7.5 11.75H8.25C8.52614 11.75 8.75 11.9739 8.75 12.25V13.25H11.25V12.37C11.2542 12.2955 11.2203 12.224 11.16 12.18L7.73 9.35C7.26919 8.97122 7.0015 8.40651 7 7.81V7C7 5.89543 7.89543 5 9 5V4.5C9 4.22386 9.22386 4 9.5 4H10.5C10.7761 4 11 4.22386 11 4.5Z" fill="black"/>
          </svg>
          <span class="mobile-bottom-menu__link-text">{{ $t('text_6193a2427a5c5501258e0d17') }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { useOrder } from '@/use/order'

export default {
  computed: {
    activeActivationsCount () {
      return this.$store.state.activation.activeActivationsCount
    },
    lastUsedNumber () {
      return localStorage.getItem('lastUsedNumber')
    }
  },
  setup () {
    const { handleNewOrderClick } = useOrder()
    return { handleNewOrderClick }
  },
  methods: {
    handleNumberButton () {
      if (this.lastUsedNumber) {
        this.$router.push({ name: 'Activation', params: { id: this.lastUsedNumber } })
      } else {
        this.handleNewOrderClick()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-bottom-menu-placeholder{
    display: none;
    height: 70px;
  }
  .mobile-bottom-shadow-bl{
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 16px;
    bottom: 28px;
    height: 73px;
    background: #E3E7EA;
    mix-blend-mode: multiply;
    opacity: 0.5;
    filter: blur(20px);
    border-radius: 24px;
  }
  .mobile-bottom-menu {
    display: none;
    position: relative;

    &__container {
      background: #fff;
      height: inherit;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      font-weight: 500;
      font-size: 13px;
      padding: 16px 28px 25px;
      border-radius: 24px 24px 0 0;
      display: flex;
      justify-content: center;
    }

    .router-link-active {
      background: #FA8231;
      color: #fff;
      padding: 13px 17px 12px;
      min-width: 114px;

      .mobile-bottom-menu__link-notification {
        color: #ffff;
      }

      path {
        fill: #fff;
      }
    }

    &__link {
      position: relative;
      padding: 12px;
      margin: 0 4px;
      border-radius: 28px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #14142B;

      &-history{
        padding-right: 20px;
      }

      path {
        fill: #14142B;
      }

      svg {
        margin-right: 10px;
      }
    }

    &__link-text {
      position: relative;
      top: -5px;
    }

    &__link-notification {
      position: absolute;
      top: 0;
      right: -11px;
      font-size: 8px;
      line-height: 8px;
      color: #14142B;
    }
  }

  .rtl{
    .mobile-bottom-menu {
      &__link {
        &-history{
          padding-left: 20px;
          padding-right: 12px;
        }

        svg {
          margin-left: 10px;
          margin-right: 0;
        }
      }

      &__link-notification {
        left: -11px;
        right: auto;
      }
    }
  }

  @media (max-width: 590px) {
    .mobile-bottom-menu-placeholder,
    .mobile-bottom-shadow-bl,
    .mobile-bottom-menu {
      display: block;
    }
  }

  @media (max-width: 410px) {
    .mobile-bottom-menu__container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 345px) {
    .mobile-bottom-menu{
      .router-link-active{
        padding: 13px 5px 12px;
        min-width: 100px;
        text-align: center;
      }
    }
    .mobile-bottom-menu__link{
      font-size: 12px;
      padding: 12px 3px;
      min-width: 85px;
      &-notification{
        right: -7px;
      }
      svg{
        margin-right: 5px;
      }
    }

    .rtl{
      .mobile-bottom-menu__link{
        &-notification{
          left: -7px;
          right: auto;
        }
        svg{
          margin-left: 5px;
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 320px) {
    .mobile-bottom-menu__link {
      min-width: 75px !important;
    }
  }
</style>
