<template>
  <router-view name="header">
    <HeaderElement />
  </router-view>
  <router-view/>
  <NeedHelp />
  <NotificationModal />
  <VerifyEmailModal />
</template>

<script>
import { useCrispChat } from '@/use/crispChat'
import HeaderElement from '@/components/Header/HeaderElement.vue'
import NeedHelp from '@/components/NeedHelp.vue'
import NotificationModal from '@/components/Modals/NotificationModal'
import VerifyEmailModal from '@/components/Modals/VerifyEmailModal'

export default {
  components: {
    HeaderElement,
    NeedHelp,
    NotificationModal,
    VerifyEmailModal
  },
  watch: {
    $route (to) {
      this.openCrispChatFromSupportLink(to)
    }
  },
  setup () {
    const { openCrispChatFromSupportLink } = useCrispChat()
    return { openCrispChatFromSupportLink }
  }
}
</script>
