<template>
  <div class="activating-done">
    <div class="activating-done__status">
      <img :src="require(`@/assets/success.gif`) + `?ts=${Date.now()}`" alt="success">
    </div>
    <TitleElement class="activating-done__title" tace>
      <h1 v-html="addBreakLinesToText($t('text_61939fd80716840139f47674'))" class="title__main title__main-done" />
      <h2 class="title__subtitle title__subtitle-done">
        <template v-if="retryRequested || activation.status === 'retryRequested'">
          <component :is="{ template: addBreakLinesToText($t('text_619ddfad069eca010ef41535')) }" />
        </template>
        <template v-else-if="activation.status === 'smsReceived'">
          <component :is="{ template: addBreakLinesToText($t('text_61939fd80716840139f4767d')) }" />
        </template>
        <template v-else-if="activation.status === 'retryReceived'">
          <component :is="{ template: addBreakLinesToText($t('text_619ddfb7ef7c0d00c4f8b62e')) }" />
        </template>
      </h2>
    </TitleElement>
    <ReceivedBar @updateActivation="$emit('updateActivation')" @retryRequest="retryRequest" :activation="activation" :expired="false" />
    <ActivationInfo @updateActivation="$emit('updateActivation')" :activation="activation" :expired="false" />
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import TitleElement from '@/components/TitleElement.vue'
import ReceivedBar from '@/components/Activation/ReceivedBar.vue'
import ActivationInfo from '@/components/Activation/ActivationInfo.vue'

export default {
  emits: ['updateActivation'],
  components: {
    TitleElement,
    ReceivedBar,
    ActivationInfo
  },
  props: {
    activation: Object
  },
  data () {
    return {
      confettiTimeout: null,
      retryRequested: false
    }
  },
  computed: {
    showConfetti () {
      return this.$store.state.common.confetti
    },
    confettiConfig () {
      return {
        defaultSize: this.isMobile() ? 9 : 10,
        particlesPerFrame: this.isMobile() ? 0.4 : 1,
        particles: [
          { type: 'rect' },
          { type: 'circle' }
        ]
      }
    }
  },
  watch: {
    showConfetti (val) {
      if (val) {
        this.runConfetti()
      } else {
        this.clearConfetti()
      }
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { addBreakLinesToText }
  },
  mounted () {
    if (this.showConfetti) {
      this.runConfetti()
    }
  },
  beforeUnmount () {
    this.clearConfetti()
  },
  methods: {
    retryRequest (value) {
      this.retryRequested = value
    },
    runConfetti () {
      this.$confetti.start(this.confettiConfig)
      this.confettiTimeout = setTimeout(() => {
        this.$confetti.stop()
      }, 3500)
    },
    clearConfetti () {
      clearTimeout(this.confettiTimeout)
      this.confettiTimeout = null
      this.$confetti.removeCanvas()
      this.$store.commit('common/setConfetti', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .activating-done {
    margin: 0 auto;
    max-width: 536px;

    .title__main-done{
      padding-bottom: 8px;
    }
    .title__subtitle-done {
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
    }

    &__status {
      height: 100px;
      margin-top: 60px;
      margin-bottom: 46px;
      text-align: center;

      img {
        height: 100%;
      }
    }

    &__title {
      padding-bottom: 40px;
    }

    &__temp-number {
      padding-top: 30px;
    }
  }

  @media (max-width: 590px) {
    .activating-done {
      &__status {
        margin-top: 30px;
        margin-bottom: 16px;
      }
      &__title {
        padding-bottom: 24px;
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .activating-done {
      &__status {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &__title {
        padding-bottom: 20px;
      }
    }
  }
</style>
