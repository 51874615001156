<template>
  <form @submit.prevent="reset" class="form">
    <EmailControl
      :errorMessage="this.v$.email.$errors?.[0]?.$validator"
      v-model.trim="email"
      :placeholder="$t('text_6167c4a5321c670152f0e21f')"
      class="form__control"
    />

    <ButtonElement :loading="loading" class="form__send" width="100%" height="58px" fill>
      {{ $t('text_6167c4a5321c670152f0e220') }}
    </ButtonElement>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

import ButtonElement from '@/components/ButtonElement'
import EmailControl from '@/components/Controls/EmailControl.vue'

export default {
  components: {
    ButtonElement,
    EmailControl
  },
  data () {
    return {
      email: '',
      loading: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    async reset () {
      const isValid = await this.v$.$validate()

      if (isValid) {
        this.loading = true
        const auth = getAuth()
        try {
          await sendPasswordResetEmail(auth, this.email)
          this.v$.$reset()
          this.email = ''
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('check_link_psw_reset_notification'),
            type: 'success',
            timeout: 3000,
            name: 'check_link_psw_reset_notification'
          })
        } catch (e) {
          if (e.code === 'auth/user-not-found') {
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: this.$t('email_user_not_found_notification'),
              timeout: 3000,
              name: 'email_user_not_found_notification'
            })
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal')
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
