<template>
  <div v-if="showWhatsWrongModal" @click="closeModal" class="modal whats-wrong-modal">
    <div @click.stop="" class="modal__container">
      <div @click.stop="closeModal" class="closeBtn">
        <img src="@/assets/icons/closeModalBtn.svg" alt="close modal">
      </div>
      <AlreadyRefunded
        v-if="activation.status === 'supRefunded'"
        @closeModal="closeModal"
        :price="activation.price"
        :titleMarginClass="'margin-top: 44px'"
      />
      <Reasons
        v-else-if="showReasons"
        @handleReason="handleReason"
      />
      <Reporting1 v-if="reportingId === 1" @closeModal="closeModal" :price="activation.price" />
      <Reporting2 v-if="reportingId === 2" @closeModal="closeModal" />
      <Reporting3 v-if="reportingId === 3" @closeModal="closeModal" />
      <Reporting4 v-if="reportingId === 4" @closeModal="closeModal" />
      <Reporting5 v-if="reportingId === 5" @changeReason="changeReason" />
      <Reporting6 v-if="reportingId === 6" @closeModal="closeModal" />
      <Reporting7 v-if="reportingId === 7" @closeModal="closeModal" />
      <Reporting8 v-if="reportingId === 8" @closeModal="closeModal" @changeReason="changeReason" />
      <Reporting9 v-if="reportingId === 9" @closeModal="closeModal" />
      <Reporting10 v-if="reportingId === 10" @closeModal="closeModal" :activation="activation" />
    </div>
  </div>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import Reasons from '@/components/Modals/Support/Reasons'
import AlreadyRefunded from '@/components/Modals/Support/AlreadyRefunded'
import Reporting1 from '@/components/Modals/Support/Reporting1'
import Reporting2 from '@/components/Modals/Support/Reporting2'
import Reporting3 from '@/components/Modals/Support/Reporting3'
import Reporting4 from '@/components/Modals/Support/Reporting4'
import Reporting5 from '@/components/Modals/Support/Reporting5'
import Reporting6 from '@/components/Modals/Support/Reporting6'
import Reporting7 from '@/components/Modals/Support/Reporting7'
import Reporting8 from '@/components/Modals/Support/Reporting8'
import Reporting9 from '@/components/Modals/Support/Reporting9'
import Reporting10 from '@/components/Modals/Support/Reporting10'

export default {
  components: {
    Reasons,
    AlreadyRefunded,
    Reporting1,
    Reporting2,
    Reporting3,
    Reporting4,
    Reporting5,
    Reporting6,
    Reporting7,
    Reporting8,
    Reporting9,
    Reporting10
  },
  props: {
    activation: {
      type: Object,
      default: () => {}
    },
    expired: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      showReasons: true,
      reportingId: 0
    }
  },
  computed: {
    showWhatsWrongModal () {
      return this.$store.state.modal.whatsWrongModal
    }
  },
  setup () {
    const { initApiInst, smsActivationApi } = useSmsApi()
    return { initApiInst, smsActivationApi }
  },
  methods: {
    closeModal () {
      this.showReasons = true
      this.reportingId = 0
      this.$store.commit('modal/toggleWhatsWrongModal')
    },
    handleReason (reasonId) {
      this.showReasons = false
      switch (this.activation.status) {
        case 'numberRequested':
        case 'numberReceived':
        case 'smsRequested':
          switch (reasonId) {
            case 1:
            case 3:
            case 4:
            case 5:
              this.reportingId = 4
              break
            case 2:
            case 6:
              this.reportingId = 5
              break
          }
          break
        case 'smsReceived':
        case 'retryRequested':
        case 'retryReceived':
          switch (reasonId) {
            case 1:
              this.reportingId = 1
              break
            case 2:
              if (this.activation.retryAvailable && !this.expired) {
                this.reportingId = 10
              } else {
                this.reportingId = 9
              }
              break
            case 3:
              this.reportingId = 8
              break
            case 4:
            case 5:
              this.reportingId = 7
              break
            case 6:
              this.reportingId = 6
              break
          }
          break
        case 'error':
          this.reportingId = 2
          break
        case 'refunded':
          this.reportingId = 3
      }

      this.saveReason(reasonId)
    },
    async saveReason (reasonId) {
      if (!this.activation) return
      try {
        await this.initApiInst()
        this.smsActivationApi.newRating({
          rating: {
            activation_id: this.activation.id,
            reason: reasonId
          },
          prxPrint: this.$store.state.common.prxPrint
        })
      } catch (e) {}
    },
    changeReason () {
      this.reportingId = 0
      this.showReasons = true
    }
  }
}
</script>

<style lang="scss">
  .whats-wrong-modal{
    .modal__container{
      width: 90%;
      max-width: 375px;
      position: relative;
      padding: 100px 38px 46px;
      @media (max-width: 590px) {
        padding: 96px 24px 46px !important;
      }

      .closeBtn {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }

      .title {
        span {
          display: block;
          margin: 16px 0 8px;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          letter-spacing: -0.2px;
          color: #14142B;
        }
      }

      .body {
        .subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #14142B;
          opacity: 0.6;
        }
        .buttons,
        .report-form {
          margin: 32px 0 0;
          padding-bottom: 6px;

          .reasonBtn {
            cursor: pointer;
            height: 50px;
            margin-bottom: 16px;
            background: #F8F8F8;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #14142B;
            transition: background-color 150ms linear;

            &:hover {
              background: #f0f0f0;
            }
          }
        }
      }

      .input-control {
        margin-bottom: 16px;
      }
      .email-control {
        &-icon {
          position: absolute;
          left: 18px;
          top: 24px;
          width: 13px;
          height: 11px;
          z-index: 2;
          background-size: cover !important;
          background: url('../../assets/form/email.svg');
        }
      }
      .message-control {
        &-icon {
          position: absolute;
          left: 15px;
          top: 23px;
          width: 16px;
          height: 16px;
          background-size: cover !important;
          background: url('../../assets/form/message-outline.svg');
        }

        textarea {
          width: 100% !important;
          border: 1px solid #EFEFEF;
          border-radius: 15px;
          padding: 20px 20px 20px 40px;
          font-size: 14px;
          color: #848194;
          background: #FFF;
          outline: none;
          resize: none;
        }
      }

      .control__error{
        text-align: left;
      }
    }
  }

  .rtl{
    .whats-wrong-modal{
      .modal__container{
        .closeBtn {
          left: 24px;
          right: auto;
        }

        .email-control {
          &-icon {
            right: 18px;
            left: auto;
          }
        }
        .message-control {
          &-icon {
            right: 15px;
            left: auto;
          }

          textarea {
            padding: 20px 40px 20px 20px;
          }
        }
      }
    }
  }
</style>
