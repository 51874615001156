<template>
  <form ref="coinpaymentsForm" action="https://www.coinpayments.net/index.php" method="post" class="d-none">
    <template v-if="coinpaymentsFormData">
      <input type="hidden" name="cmd" value="_pay_simple">
      <input type="hidden" name="reset" value="1">
      <input type="hidden" name="merchant" :value="coinpaymentsMerchantId">
      <input type="hidden" name="item_name" :value="`Temp-Number purchase ${amount}$`">
      <input type="hidden" name="currency" value="USD">
      <input type="hidden" name="amountf" :value="amount">
      <input type="hidden" name="success_url" :value="`${baseUrl}?status=success&type=crypto`">
      <input type="hidden" name="cancel_url" :value="`${baseUrl}?status=error`">
      <input type="hidden" name="email" :value="email">
      <input type="hidden" name="custom" :value="coinpaymentsFormData.custom">
    </template>
  </form>
</template>

<script>
import { useCoinpayments } from '@/use/payments/coinpayments'

export default {
  props: {
    coinpaymentsPay: Boolean,
    amount: Number,
    uid: String,
    email: String
  },
  data () {
    return {
      baseUrl: `${process.env.VUE_APP_URL}funds`,
      coinpaymentsMerchantId: process.env.VUE_APP_COINPAYMENTS_MERCHANT_ID,
      coinpaymentsFormData: null
    }
  },
  watch: {
    coinpaymentsPay (val) {
      if (val) {
        this.addCoinpaymentsFunds()
      }
    }
  },
  setup () {
    const { getCoinpaymentsFormData } = useCoinpayments()
    return { getCoinpaymentsFormData }
  },
  methods: {
    async addCoinpaymentsFunds () {
      this.coinpaymentsFormData = await this.getCoinpaymentsFormData(this.amount, this.uid)
      if (this.coinpaymentsFormData && this.coinpaymentsFormData.custom) {
        this.$nextTick(() => {
          this.$refs.coinpaymentsForm.submit()
        })
      }
    }
  }
}
</script>
