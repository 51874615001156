<template>
  <PageLoader v-if="orderLoading" :marginTop="20" />
  <ul v-else-if="!isSearchOpen && servicesData" class="radio-buttons radio-buttons__column">
    <li v-for="(service, index) in servicesData.services" :key="index">
      <div
        @click="selectService(service, index, !!service.countryId)"
        :class="{ 'noNumbers': !service.hasNumbers }"
        class="radio-button"
      >
        <div class="radio-button__images" :class="{ 'radio-button__images--pair': !!service.countryId }">
          <img class="radio-button__pre-image"
             v-if="service.countryIconPath"
             :src="`${servicesData.iconBaseUrl + service.countryIconPath}`"
             alt="img"
          >
          <img :src="`${servicesData.iconBaseUrl + service.serviceIconPath}`" class="radio-button__main-image" alt="img">
        </div>
        <div class="radio-button__text">{{ service.serviceName }}{{ (!!service.countryName ? ' (' + service.countryName + ')' : '') }}</div>
        <div
          :class="{ 'radio-button__toggle--active': index === selectedIndex }"
          class="radio-button__toggle"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import PageLoader from '@/components/Loaders/PageLoader.vue'

export default {
  components: { PageLoader },
  data () {
    return {
      servicesData: null,
      selectedIndex: null,
      cacheTime: 300 // in seconds
    }
  },
  computed: {
    activeService () {
      return this.$store.state.order.service
    },
    activeCountry () {
      return this.$store.state.order.country
    },
    isSearchOpen () {
      return this.$store.state.order.isSearchOpen
    },
    orderLoading () {
      return this.$store.state.order.orderLoading
    },
    orderOneClickSelect () {
      return this.$store.state.order.oneClickSelect
    }
  },
  watch: {
    activeService (val) {
      if (!val) {
        this.selectedIndex = null
      }
    }
  },
  setup () {
    const { initApiInst, smsServicesApi } = useSmsApi()
    const { handle503Error } = useErrorHandlers()
    return { initApiInst, smsServicesApi, handle503Error }
  },
  mounted () {
    this.$store.commit('order/setOrderLoading', true)
    this.loadPopularServices()
  },
  methods: {
    async loadPopularServices () {
      const currentTimestamp = Math.round(Date.now() / 1000)
      const popularServices = JSON.parse(localStorage.getItem('popularServices'))
      if (popularServices && popularServices.time > currentTimestamp) {
        delete popularServices.time
        this.servicesData = popularServices
        this.formPopularServices()
        this.$store.commit('order/setOrderLoading', false)
      } else {
        try {
          await this.initApiInst()
          const popularServicesResp = await this.smsServicesApi.getPopularService({
            prxLang: this.$i18n.locale,
            prxPrint: this.$store.state.common.prxPrint
          })
          if (popularServicesResp) {
            this.servicesData = popularServicesResp
            popularServicesResp.time = currentTimestamp + this.cacheTime
            localStorage.setItem('popularServices', JSON.stringify(popularServicesResp))
            this.formPopularServices()
          }
        } catch (e) {
          if (e.status === 503) {
            this.handle503Error()
          }
        } finally {
          this.$store.commit('order/setOrderLoading', false)
        }
      }
    },
    selectService (service, index, selectCountry = false) {
      if (this.isMobile() && this.$store.state.order.service && this.$store.state.order.service.serviceId === service.serviceId) {
        // Clear selected service on it click (only on Mobile)
        this.$store.commit('order/clearOrder')
        return
      }
      this.selectedIndex = index
      this.$store.commit('order/setIconBaseUrl', this.servicesData.iconBaseUrl)
      const { serviceId, serviceName, serviceIconPath, price, snippet, warning, hasNumbers } = service
      this.$store.commit('order/setService', { serviceId, serviceName, serviceIconPath, snippet, warning, hasNumbers })

      let country = this.activeCountry
      let oneClickSelect = this.orderOneClickSelect
      if (oneClickSelect) country = null
      oneClickSelect = null
      if (selectCountry && service.countryName && service.countryIconPath) {
        const { countryId, countryName, countryIconPath, hasNumbers, price, isVirtual } = service
        country = { countryId, countryName, countryIconPath, hasNumbers, price, isVirtual }
        oneClickSelect = true
      }

      this.$store.commit('order/setPrice', price)
      this.$store.commit('order/setCountry', country)
      this.$store.commit('order/setOneClickSelect', oneClickSelect)
    },
    formPopularServices () {
      if (!this.servicesData.services) return
      this.servicesData.services.map((service, index) => {
        // Delete country info for services index > serviceCountryCount
        if (index + 1 > this.servicesData.serviceCountryCount) {
          service.countryName = service.countryId = service.countryIconPath = ''
        }
        return service
      })

      if (this.activeService) {
        let selectedService = null
        if (this.activeCountry && this.orderOneClickSelect) {
          const existsServiceCountryIndex = this.servicesData.services.findIndex(service => {
            return service.serviceId === this.activeService.serviceId && service.countryId === this.activeCountry.countryId
          })
          if (existsServiceCountryIndex !== -1) {
            this.servicesData.services.splice(existsServiceCountryIndex, 1)
          }
          selectedService = Object.assign({}, this.activeService, this.activeCountry)
        } else {
          const servicesForSearch = this.servicesData.services.slice(2)
          const existsServiceIndex = servicesForSearch.findIndex(service => service.serviceId === this.activeService.serviceId)
          if (existsServiceIndex !== -1) {
            servicesForSearch.splice(existsServiceIndex, 1)
          }
          this.servicesData.services.splice(2, servicesForSearch.length, ...servicesForSearch)
          selectedService = this.activeService
        }

        this.servicesData.services.unshift(selectedService)
        this.servicesData.services = this.servicesData.services.slice(0, 5)
        this.selectedIndex = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
