<template>
  <form @submit.prevent="changePassword" class="form">
    <PasswordControl
      :errorMessage="this.v$.newPassword.$errors?.[0]?.$validator"
      v-model.trim="newPassword"
      :placeholder="$t('text_6167c4a5321c670152f0e226')"
      class="form__control"
    />
    <PasswordControl
      :errorMessage="this.v$.repeatNewPassword.$errors?.[0]?.$validator"
      v-model.trim="repeatNewPassword"
      :placeholder="$t('text_6167c4a5321c670152f0e227')"
      class="form__control"
    />

    <ButtonElement :loading="loading && !$store.state.modal.showConfirmPasswordModal" class="form__send" width="100%" height="58px" fill>
      {{ $t('text_6167c4a5321c670152f0e228') }}
    </ButtonElement>
  </form>

  <ConfirmPasswordModal v-if="$store.state.modal.showConfirmPasswordModal" @userReauthenticated="changePassword" />
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useTranslate } from '@/use/translate'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { getAuth, updatePassword } from 'firebase/auth'

import ButtonElement from '@/components/ButtonElement'
import PasswordControl from '@/components/Controls/PasswordControl.vue'
import ConfirmPasswordModal from '@/components/Modals/ConfirmPasswordModal'

export default {
  components: {
    ButtonElement,
    PasswordControl,
    ConfirmPasswordModal
  },
  data () {
    return {
      newPassword: '',
      repeatNewPassword: '',
      loading: false
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { v$: useVuelidate(), addBreakLinesToText }
  },
  validations () {
    return {
      newPassword: {
        required,
        minLength: minLength(6)
      },
      repeatNewPassword: {
        sameAsPassword: sameAs(this.newPassword)
      }
    }
  },
  methods: {
    async changePassword () {
      const isValid = await this.v$.$validate()

      if (isValid) {
        this.loading = true
        try {
          const auth = getAuth()
          await updatePassword(auth.currentUser, this.newPassword)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('text_61725fc52f256400e1bfe8df'),
            type: 'success',
            timeout: 3000
          })
          await this.$router.push({ name: 'Home' })
        } catch (e) {
          if (e.code === 'auth/requires-recent-login') {
            this.$store.commit('modal/toggleConfirmPasswordModal', true)
          } else if (e.code === 'auth/too-many-requests') {
            this.$store.commit('notificationModal/show', {
              title: this.$t('login_requests_error_title'),
              text: this.addBreakLinesToText(this.$t('login_requests_error_subtitle')),
              buttonText: this.$t('text_616e4d4b66577e00e3d6ba11')
            })
          } else if (e.code === 'auth/weak-password') {
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: this.$t('new_psw_not_strong_validation'),
              timeout: 3000,
              name: 'new_psw_not_strong_validation'
            })
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal')
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
