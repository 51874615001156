<template>
  <Stepper v-if="!loading" />
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useOrder } from '@/use/order'
import Stepper from '@/components/Stepper/Stepper.vue'

export default {
  components: {
    Stepper
  },
  data () {
    return {
      loading: true
    }
  },
  setup () {
    const { forceActivationRedirect } = useOrder()
    const { initApiInst, smsServicesApi } = useSmsApi()
    return { forceActivationRedirect, initApiInst, smsServicesApi }
  },
  async beforeMount () {
    if (this.$store.state.order.forceActivation) {
      this.forceActivationRedirect()
    } else {
      if (this.$route.query.service) {
        const serviceId = this.$route.query.service
        const service = await this.findServiceById(serviceId)
        if (service) {
          const { serviceId, serviceName, serviceIconPath, snippet, warning, hasNumbers } = service
          this.$store.commit('order/setService', { serviceId, serviceName, serviceIconPath, snippet, warning, hasNumbers })
          if (this.$route.query.country) {
            const countryId = this.$route.query.country
            const country = await this.finCountryByServiceAndCountryId(service.serviceId, countryId)
            if (country.hasNumbers) {
              this.$store.commit('order/setCountry', country)
              this.$store.commit('order/setPrice', country.price)
              this.$store.commit('order/setOneClickSelect', true)
              await this.$router.push({ name: 'OrderStep3' })
            }
          }
        }
      }
      if (!this.$route.query.ref) this.$router.replace({ 'query': null })
      this.loading = false
    }
  },
  methods: {
    async findServiceById (serviceId) {
      if (serviceId.length < 2) return false
      const serviceStr = serviceId.split('_')[0]
      try {
        await this.initApiInst()
        const servicesResp = await this.smsServicesApi.getService(serviceStr, {
          prxLang: this.$i18n.locale,
          prxPrint: this.$store.state.common.prxPrint
        })
        if (servicesResp && servicesResp.services && servicesResp.services.length > 0) {
          this.$store.commit('order/setIconBaseUrl', servicesResp.iconBaseUrl)
          return servicesResp.services.find(item => item.serviceId === serviceId)
        }
      } catch (e) {
        return false
      }
    },
    async finCountryByServiceAndCountryId (serviceId, countryId) {
      if (countryId.length < 2) return false
      try {
        await this.initApiInst()
        const countriesResp = await this.smsServicesApi.getServiceCountries(countryId, serviceId, {
          prxLang: this.$i18n.locale,
          prxPrint: this.$store.state.common.prxPrint
        })
        if (countriesResp && countriesResp.countries && countriesResp.countries.length > 0) {
          this.$store.commit('order/setIconBaseUrl', countriesResp.iconBaseUrl)
          return countriesResp.countries.find(item => item.countryId === countryId)
        }
      } catch (e) {
        return false
      }
    }
  }
}
</script>
