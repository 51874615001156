<template>
  <div @click.prevent.stop="copy(item.id)">
    <button><img src="@/assets/icons/copy.svg" alt="copy" /></button>
    <span :ref="'id' + item.id">{{ `${item.id}` }}</span>
  </div>
</template>

<script>
import selectText from '@/helpers/select-text.js'

export default {
  props: {
    item: {}
  },
  methods: {
    copy (id) {
      const _this = this
      this.$copyText(id, undefined, (error) => {
        if (!error) {
          selectText(_this.$refs[`id${id}`])
          _this.$store.commit('alert/add', {
            id: Date.now(),
            text: _this.$t('text_618d5a996329050141a2473d'),
            type: 'success',
            timeout: 1500,
            name: 'copy'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  div {
    display: inline-flex !important;
    cursor: pointer;
    position: relative;
    margin-left: 22px;
    padding-left: 10px;
    padding-right: 10px;
    button {
      height: 20px;
      cursor: pointer;
      border: none;
      background: #fff;
      margin-top: 2px;
      margin-right: 8px;

      > img {
        height: 100%;
      }
    }
  }

  .rtl{
    div {
      margin-right: 22px;
      margin-left: 0;
      button {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 590px) {
    div {
      width: 100%;
      height: 48px;
      border-radius: 12px 0 0 12px;
      border-left: 1px solid rgba(25, 22, 50, 0.12);
      padding-left: 14px !important;

      span {
        opacity: 0;
        position: absolute;
      }

      button {
        width: 16px;
        height: 16px;
        margin-left: 0;
        margin-top: 0;

        > img {
          height: 100%;
        }
      }
    }

    .rtl{
      div {
        border-left: none;
        border-right: 1px solid rgba(25, 22, 50, 0.12);
        border-radius: 0 12px 12px 0;
        padding-right: 14px !important;
        padding-left: 0 !important;

        button {
          margin-right: 0;
        }
      }
    }
  }
</style>
