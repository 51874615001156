import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useTranslate } from '@/use/translate'

export function useErrorHandlers () {
  const router = useRouter()
  const route = useRoute()
  const store = useStore()
  const { t } = useI18n()
  const { addBreakLinesToText, addCrispChatLink } = useTranslate()

  const handleNotAuthorizedError = async () => {
    store.commit('alert/add', {
      id: Date.now(),
      text: t('auth_needed_error_login_signup'),
      timeout: 3000,
      name: 'auth_needed_error_login_signup'
    })
    await router.push({ name: 'CreateAccount' })
  }

  const handle503Error = async () => {
    store.commit('order/clearOrder')
    await router.push({ name: 'Error503' })
  }

  const handleNetworkError = (loop = false) => {
    if (loop) {
      store.commit('alert/add', {
        id: Date.now(),
        text: t('text_620bd0549ba19f00c679aed8'),
        name: 'network_error'
      })
    } else {
      store.commit('notificationModal/show', {
        title: t('network_error_title'),
        text: t('network_error_subtitle'),
        buttonText: t('retry_button'),
        buttonAction: 'reloadPage'
      })
    }
  }

  const showNoNumbersError = () => {
    let routeName = 'Home'
    if (route.name) {
      routeName = route.name
    }
    const textWithBrs = addBreakLinesToText(t('no_numbers_error_subtitle'))
    const text = addCrispChatLink(textWithBrs, routeName)

    store.commit('notificationModal/show', {
      title: t('no_numbers_error_title'),
      text: text,
      buttonText: t('text_616e4d4b66577e00e3d6ba23')
    })
  }

  const showCommonError = (e) => {
    let text = addBreakLinesToText(t('general_error_subtitle'))
    text = addCrispChatLink(text, 'Home')

    store.commit('notificationModal/show', {
      outsideClickHide: false,
      title: t('general_error_title'),
      text: e.status ? text.replace('__0__', e.status) : text.replace(' (__0__)', ''),
      buttonText: t('ok_button'),
      buttonRouteName: 'Home'
    })
  }

  return { handleNotAuthorizedError, handle503Error, handleNetworkError, showNoNumbersError, showCommonError }
}
