<template>
  <form @submit.prevent="createAccount" class="form">
    <EmailControl
      :errorMessage="this.v$.email.$errors?.[0]?.$validator"
      v-model="email"
      :placeholder="$t('text_6167c4a5321c670152f0e216')"
      class="form__control"
    />
    <PasswordControl
      :errorMessage="this.v$.password.$errors?.[0]?.$validator"
      v-model="password"
      :placeholder="$t('text_6167c4a5321c670152f0e217')"
      class="form__control"
    />
    <PasswordControl
      :errorMessage="this.v$.repeatPassword.$errors?.[0]?.$validator"
      v-model="repeatPassword"
      :placeholder="$t('text_6167c4a5321c670152f0e218')"
      class="form__control"
    />

    <ButtonElement :loading="loading" class="form__send" width="100%" height="58px" fill>
      {{ $t('text_6167c4a5321c670152f0e219') }}
    </ButtonElement>

    <div class="form__help-link form__help-link-bold">
      <component :is="{ template: signInText }"></component>
    </div>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useTranslate } from '@/use/translate'
import { useTapfiliate } from '@/use/tapfiliate'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useUser } from '@/use/user'

import ButtonElement from '@/components/ButtonElement'
import EmailControl from '@/components/Controls/EmailControl.vue'
import PasswordControl from '@/components/Controls/PasswordControl.vue'

export default {
  components: {
    ButtonElement,
    EmailControl,
    PasswordControl
  },
  data () {
    return {
      email: '',
      password: '',
      repeatPassword: '',
      loading: false
    }
  },
  setup () {
    const { addBreakLinesToText, addLinksToText } = useTranslate()
    const { updateTapfiliateTrackId } = useTapfiliate()
    const { getUserProject } = useUser()
    return { v$: useVuelidate(), addBreakLinesToText, addLinksToText, updateTapfiliateTrackId, getUserProject }
  },
  computed: {
    signInText () {
      const textWithBrs = this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e21a'))
      return this.addLinksToText(textWithBrs, ['SignIn'])
    }
  },
  validations () {
    return {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        sameAsPassword: sameAs(this.password)
      }
    }
  },
  methods: {
    async createAccount () {
      const isValid = await this.v$.$validate()
      const auth = getAuth()

      if (isValid) {
        this.loading = true
        try {
          const result = await createUserWithEmailAndPassword(auth, this.email, this.password)
          if (result && result.user) {
            const analytics = getAnalytics()
            logEvent(analytics, 'email_signup', {
              uid: auth.currentUser?.uid ?? ''
            })
            this.$amplitude.logEvent('signup.completed_email')
            const emailVerifiedIdentity = new this.$amplitude.Identify().set('email_verified', 'False')
            this.$amplitude.getInstance().identify(emailVerifiedIdentity)
            await this.updateTapfiliateTrackId()
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: this.$t('text_6231df74c9ebfc012db2a5bb'),
              type: 'success',
              timeout: 2000,
              name: 'success_sign_up'
            })
            await sendEmailVerification(auth.currentUser)
            await this.getUserProject(result.user.uid)
            await this.$router.push({ name: 'Home' })
          }
        } catch (e) {
          if (e.code === 'auth/email-already-in-use' || e.code === 'auth/credential-already-in-use') {
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: this.$t('email_already_registered_validation'),
              timeout: 3000,
              name: 'email_already_registered_validation'
            })
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal')
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
