import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export function useNps () {
  const route = useRoute()
  const store = useStore()

  const handleNps = () => {
    const npsActivationsFrequency = store.state.common.npsScoreActivationsFrequency
    let npsActivationsCount = localStorage.getItem('npsActivationsCount') || 0
    const npsTimestamp = localStorage.getItem('npsTimestamp')

    npsActivationsCount = parseInt(npsActivationsCount, 10) + 1
    localStorage.setItem('npsActivationsCount', npsActivationsCount.toString())

    // Show NPS window not frequent than 10 (get param from RK) activations no more than once every 7 (get param from RK) days
    if (npsActivationsCount >= npsActivationsFrequency) {
      if (!npsTimestamp || parseInt(npsTimestamp, 10) < Date.now()) {
        setTimeout(() => {
          if (route.name !== 'Funds') {
            store.commit('common/setShowNps', true)
          }
        }, 60000)
      }
    }
  }

  return { handleNps }
}
