<template>
  <div :style="`z-index: ${zIndex}`" class="alert">
    <ul class="alert__list">
      <transition-group name="list">
      <li
        v-for="alert in alerts"
        :key="alert.name ? alert.name : alert.id"
        class="alert__item"
        :class="{
          'alert__item--success': alert.type === 'success',
          'alert__item--error': alert.type === 'error',
        }"
      >
        <MessageNotificationAlert v-if="alert.name === 'messageNotification'" @closeAlert="closeAlert" :payload="alert" />
        <template v-else>
          <component :is="{ template: alert.text }" />
        </template>
        <div @click="remove(alert.id)" class="closeBtn" />
      </li>
      </transition-group>
    </ul>
  </div>
</template>

<script>
import MessageNotificationAlert from '@/components/MessageNotificationAlert'

export default {
  components: { MessageNotificationAlert },
  data () {
    return {
      zIndex: 22
    }
  },
  computed: {
    alerts () {
      return this.$store.state.alert.alerts
    }
  },
  watch: {
    alerts (items) {
      this.zIndex = Math.max.apply(Math, items.map(function (item) { return item.zIndex }))
    }
  },
  methods: {
    remove (id) {
      this.$store.commit('alert/remove', id)
    },
    closeAlert (id) {
      this.remove(id)
    }
  }
}
</script>

<style lang="scss" scoped>
  /*@import '../assets/animation/fade-items.scss';*/

  .alert {
    position: fixed;
    left: calc(50% + 350px);
    top: 150px;
    width: 284px;

    &__item {
      cursor: pointer;
      margin-bottom: 32px;
      position: relative;
      padding: 15px 32px 15px 20px;
      background: #FA8231;
      border-radius: 8px;
      font-size: 15px;
      color: #fff;
      width: 284px;

      &--success {
        background:#47B01A;
      }

      &--error {
        background:#FF4757;;
      }

      .closeBtn {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 13px;
        right: 10px;
        background: url('../assets/icons/close-white.svg') no-repeat center;
      }
    }
  }

  .rtl{
    .alert {
      right: calc(50% + 350px);
      left: auto;

      &__item {
        padding: 15px 20px 15px 32px;

        .closeBtn {
          left: 10px;
          right: auto;
        }
      }
    }
  }

  @media (max-width: 1320px) {
    .alert {
      left: auto;
      right: 25px;
    }

    .rtl{
      .alert {
        left: 25px;
        right: auto;
      }
    }
  }

  @media (max-width: 590px) {
    .alert {
      top: 70px;
      left: 25px;
      width: calc(100% - 50px);

      &__item {
        margin-bottom: 24px;
        width: 100%;
      }
    }

    .rtl{
      .alert {
        right: 25px;
        left: auto;
      }
    }
  }
</style>
