<template>
  <div>
    <TitleElement :text="$t('text_64119de89cf25d009d31c9d4')" />
    <TextElement :text="$t('text_64119de89cf25d009d31c9d5')" />
    <ButtonElement
      @click="$emit('changeReason')" class="change-reason" fill height="58px" width="100%"
    >
      {{ $t('text_64119de89cf25d009d31c9d7') }}
    </ButtonElement>
  </div>
</template>

<script>
import TitleElement from '@/components/Modals/Support/Elements/TitleElement'
import TextElement from '@/components/Modals/Support/Elements/TextElement'
import ButtonElement from '@/components/ButtonElement'

export default {
  emits: ['changeReason'],
  components: {
    TitleElement,
    TextElement,
    ButtonElement
  }
}
</script>

<style lang="scss" scoped>
  .change-reason{
    background: #F8F8F8;
    border: 1px solid #F8F8F8;
    border-radius: 12px;
    color: #14142B;
    font-weight: 400;
    font-size: 16px;
    padding: 15px 25px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    &:active{
      background: #F8F8F8;
      border: 1px solid #F8F8F8;
      color: #14142B;
    }
    &:hover{
      border: 1px solid #F8F8F8;
    }
  }
</style>
