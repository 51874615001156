<template>
  <div>
    <TitleElement :text="$t('text_64119de89cf25d009d31c9e3')" />
    <TextElement :text="$t('text_64119de89cf25d009d31c9ed')" />
    <OpenLiveChatBtn @closeModal="$emit('closeModal')" />
    <ButtonElement
      @click="retry" class="retry" fill height="58px" width="100%"
    >
      {{ $t('text_64119de89cf25d009d31c9f5') }}
    </ButtonElement>
  </div>
</template>

<script>
import TitleElement from '@/components/Modals/Support/Elements/TitleElement'
import TextElement from '@/components/Modals/Support/Elements/TextElement'
import OpenLiveChatBtn from '@/components/Modals/Support/Elements/OpenLiveChatBtn'
import ButtonElement from '@/components/ButtonElement'

export default {
  emits: ['closeModal'],
  components: {
    TitleElement,
    TextElement,
    OpenLiveChatBtn,
    ButtonElement
  },
  props: {
    activation: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    retry () {
      this.$emit('closeModal')
      if (this.activation && this.activation.status === 'retryRequested') return false
      this.$store.commit('modal/toggleRetryConfirmationModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .retry{
    margin-bottom: 10px;
  }
</style>
