<template>
  <div class="reset-password">
    <TitleElement tace>
      <h1 class="auth-title__main title__main--pb0">{{ $t('text_6167c4a5321c670152f0e2a4') }}</h1>
      <h2 class="auth-title__subtitle">{{ $t('text_6167c4a5321c670152f0e21e') }}</h2>
    </TitleElement>
    <ResetPasswordForm />
  </div>
</template>

<script>
import TitleElement from '@/components/TitleElement.vue'
import ResetPasswordForm from '@/components/Forms/ResetPasswordForm/ResetPasswordForm.vue'

export default {
  components: {
    TitleElement,
    ResetPasswordForm
  }
}
</script>

<style lang="scss" scoped>
  .reset-password {
    width: 400px;
  }
</style>
