<template>
  <div class="received-bar">
    <div
      v-if="code || message"
      @click="copyText($refs.code, code)"
      class="received-bar__top"
      :class="{
        'received-bar__top--no-retry': !retryAvailable,
        'received-bar__top--no-code': !code && message
      }"
    >
      <button v-if="retryAvailable" @click.stop="handleRetry" class="received-bar__btn received-bar__retry services-button">
        <template v-if="retryLoading || activation.status === retryRequestedStatus">
          <v-lottie-player
            :animationData="require('@/assets/loaders/restart.json')"
            loop
            width="18px"
            height="18px"
          />
          <span class="retryingText">{{ $t('text_619ddfad069eca010ef41534') }}</span>
        </template>
        <template v-else>
          <img src="@/assets/icons/retry.svg" alt="retry">
          <span>{{ $t('text_61939fd80716840139f47675') }}</span>
        </template>
      </button>
      <div
        ref="code"
        :class="{
          'received-bar__received-code-with-retry': retryAvailable,
          'received-bar__received-code-long': !code && message && message.length > 12
        }"
        class="received-bar__received-code"
      >
        <span v-if="code" class="codeText">{{ code }}</span>
        <span v-if="!code && message" class="messageText">
          <ActivationMessage :message="message" />
        </span>
      </div>
      <button @click="!code ? copyText($refs.code, message) : copyText($refs.code, '')" class="received-bar__btn received-bar__copy services-button services-button--hide-word">
        <img src="@/assets/icons/copy.svg" alt="copy">
        <span>{{ $t('text_61939fd80716840139f47676') }}</span>
      </button>
    </div>
    <div v-else @click="showNotReceivedModal" class="received-bar__top">
      <div class="received-bar__error">
        <img src="@/assets/icons/attention.svg" alt="attention">
        <span class="notReceived">{{ $t('text_61939fd80716840139f4768f') }}</span>
      </div>
    </div>

    <div v-if="code && message" class="received-bar__full-message">
      <div @click="fullMessageOpen = !fullMessageOpen" class="toggleFullMessageBtn">{{ fullMessageOpen ? $t('text_61c5b795f968a2015b404e19') : $t('text_61939fd80716840139f4766f') }}</div>
      <transition name="fade">
        <div v-if="message && fullMessageOpen" class="received-bar__full-message-text-bl">
          <div ref="message" class="full-message-text">
            <ActivationMessage :message="message" />
          </div>
          <button @click="copyText($refs.message, message)" class="full-message-copy-btn">
            <img src="@/assets/icons/copy.svg" alt="copy">
            <span>{{ $t('text_61939fd80716840139f47676') }}</span>
          </button>
        </div>
      </transition>
    </div>
    <div v-else-if="!code && !message" class="received-bar__full-message">
      <div @click="toOrderWithPreset(activation)" class="tryAnotherNumber">{{ $t('text_619b652713b24500c4303b2c') }}</div>
    </div>
  </div>

  <RetryConfirmationModal v-if="$store.state.modal.retryConfirmationModal" @retry="retry" />
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useOrder } from '@/use/order'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useLoadScript } from '@/use/loadScript'
import selectText from '@/helpers/select-text.js'
import ActivationMessage from '@/components/Activation/ActivationMessage'
import RetryConfirmationModal from '@/components/Modals/RetryConfirmationModal'

export default {
  emits: ['updateActivation', 'retryRequest'],
  components: { ActivationMessage, RetryConfirmationModal },
  props: {
    activation: {
      type: Object || null,
      default: () => {}
    },
    expired: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      code: '',
      message: '',
      retryRequestedStatus: 'retryRequested',
      retryLoading: false,
      fullMessageOpen: false
    }
  },
  computed: {
    retryAvailable () {
      return this.activation.retryAvailable && !this.expired
    }
  },
  watch: {
    activation (activation) {
      this.sanitizeSms(activation)
      if (activation && activation.status === 'retryReceived') {
        this.retryLoading = false
        this.$emit('retryRequest', false)
      }
    }
  },
  setup () {
    const { initApiInst, smsActivationApi } = useSmsApi()
    const { toOrderWithPreset } = useOrder()
    const { handleNotAuthorizedError, handleNetworkError } = useErrorHandlers()
    const { loadScript } = useLoadScript()

    return { initApiInst, smsActivationApi, toOrderWithPreset, handleNotAuthorizedError, handleNetworkError, loadScript }
  },
  created () {
    this.initXssSanitizer()
  },
  methods: {
    handleRetry () {
      if (this.activation && this.activation.status === this.retryRequestedStatus) return false
      this.$store.commit('modal/toggleRetryConfirmationModal')
    },
    async retry () {
      try {
        await this.initApiInst()
        const resp = await this.smsActivationApi.updateActivation(this.activation.id, {
          activationUpdate: {
            status: 'retry'
          },
          prxPrint: this.$store.state.common.prxPrint
        })
        if (resp && resp.result === 'success') {
          this.$amplitude.getInstance().logEvent('ui.activated_retry', {
            service: this.activation.serviceId,
            country: this.activation.countryId
          })
          // Set delay for check new SMS after successful retrying
          this.$emit('retryRequest', true)
          this.retryLoading = true
          this.$store.commit('common/setConfetti', false)
          setTimeout(() => {
            this.$emit('updateActivation')
          }, 5000)
        }
      } catch (e) {
        if (e.status) {
          switch (e.status) {
            case 401:
              await this.handleNotAuthorizedError()
              break
            case 400:
            case 404:
            case 409:
              this.$store.commit('notificationModal/show', {
                title: this.$t('text_619ddd4d3aa69d00f206cbab'),
                text: this.$t('text_619ddd4d3aa69d00f206cbaa'),
                buttonText: this.$t('text_619ddd4d3aa69d00f206cba8')
              })
              break
            case 502:
              this.handleNetworkError()
              break
            default:
              this.$store.commit('notificationModal/showSomethingWrongModal', e.status ? e.status : '')
          }
        } else {
          if (e.error && e.error.message && e.error.message.includes('Request has been terminated')) {
            this.handleNetworkError()
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal', e.status ? e.status : '')
          }
        }
      } finally {
        this.$store.commit('modal/toggleRetryConfirmationModal')
      }
    },
    copyText (ref, text) {
      if (!text) return
      const _this = this
      this.$copyText(text, undefined, (error) => {
        if (!error) {
          selectText(ref)
          _this.$store.commit('alert/add', {
            id: Date.now(),
            text: _this.$t('text_618d5a996329050141a2473d'),
            type: 'success',
            timeout: 1500,
            name: 'copy'
          })
        }
      })
    },
    showNotReceivedModal () {
      this.$store.commit('notificationModal/show', {
        title: this.$t('text_6193db2a6253c201008eb3d6'),
        text: this.$t('text_6193db2a6253c201008eb3d4'),
        buttonText: this.$t('text_6193db2a6253c201008eb3d8')
      })
    },
    sanitizeSms (activation = null) {
      const code = activation && activation.code ? activation.code : this.activation.code
      const message = activation && activation.message ? activation.message : this.activation.message
      this.code = this.sanitizeData(code)
      this.message = this.sanitizeData(message)
    },
    async initXssSanitizer () {
      try {
        await this.loadScript(`${process.env.VUE_APP_URL}js/html-sanitizer.compiled.min.js`)
        this.sanitizeSms()
      } catch (e) {}
    },
    sanitizeData (data) {
      let sanitizedData = ''
      try {
        // eslint-disable-next-line no-undef
        const builder = new goog.html.sanitizer.HtmlSanitizer.Builder()
        // eslint-disable-next-line no-undef
        builder.withCustomNetworkRequestUrlPolicy(goog.html.SafeUrl.sanitize)
        const sanitizer = builder.build()
        sanitizedData = (sanitizer.sanitize(data)).getTypedStringValue()
        return sanitizedData
      } catch (e) {
        return data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .received-bar {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(25, 22, 50, 0.12);

    &__top {
      cursor: pointer;
      position: relative;
      color: #191632;
      font-weight: 500;
      font-size: 20px;
      background-color: #f8f8f8;
      display: flex;
      border-radius: 15px;
      width: 100%;
      height: auto;
      min-height: 60px;
      align-items: center;
      padding: 0 20px;
      justify-content: space-between;

      &--no-retry {
        justify-content: center;

        .received-bar__copy{
          position: absolute;
          right: 18px;
          top: calc(50% - 10px);
        }
      }
      &--no-code {
        cursor: auto;
      }
    }

    &__btn {
      margin-top: 1px;
      display: flex;
      align-items: center;
      span{
        top: 0;
      }
    }

    &__retry{
      position: absolute;
      left: 20px;
      z-index: 2;
    }
    &__received-code{
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 60%;
      text-align: center;
      z-index: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__received-code-long {
      position: relative;
      overflow: visible;
      white-space: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      padding: 21px 0;
    }
    &__copy{
      position: absolute;
      right: 20px;
      z-index: 2;
    }

    &__error {
      margin: 0 auto;
      font-size: 17px;
      font-weight: normal;

      img {
        position: relative;
        top: 2px;
        right: 2px;
        cursor: pointer;
      }
    }

    &__full-message {
      text-align: center;
      margin-top: 16px;

      .toggleFullMessageBtn,
      .tryAnotherNumber {
        display: inline-block;
        cursor:pointer;
        font-size: 14px;
        color: #FA8231;
        border-bottom: 1px solid #FA8231;
        &:hover{
          border-bottom: 1px solid #fff;
        }
      }

      &-text-bl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 21px 18px;
        background: #F8F8F8;
        border-radius: 15px;
        margin-top: 16px;
        .full-message-text {
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          text-align: justify;
          color: #191632;
          margin-right: 50px;
          word-break: break-all;
        }
        .full-message-copy-btn {
          display: flex;
          border: none;
          align-items: center;
          background: none;
          cursor: pointer;
          font-size: 14px;
          img {
            margin-right: 4px;
          }
        }
      }
    }

    .notReceived{
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      margin: 0 4px;
      color: #191632;
    }
    .retryingText {
      margin-left: 4px;
    }
  }

  .rtl{
    .received-bar {
      &__top {
        &--no-retry {
          .received-bar__copy{
            left: 18px;
            right: auto;
          }
        }
      }

      &__retry{
        right: 20px;
        left: auto;
      }
      &__copy{
        left: 20px;
        right: auto;
      }

      &__error {
        img {
          left: 2px;
          right: auto;
        }
      }

      &__full-message {
        &-text-bl {
          .full-message-text {
            margin-left: 50px;
            margin-right: 0;
          }
          .full-message-copy-btn {
            img {
              margin-left: 4px;
              margin-right: 0;
            }
          }
        }
      }

      .retryingText {
        margin-right: 4px;
        margin-left: 0;
      }
    }
  }

  @media (max-width: 590px) {
    .activating-expired {
      .received-bar {
        &__top {
          justify-content: center;
        }
      }
    }

    .received-bar {
      padding-bottom: 0;
      border-bottom: none;

      &__top {
        &--no-retry {
          justify-content: center;

          .received-bar__received-code{
            position: relative;
            width: auto;
            text-align: center;
            right: auto;
            margin: 0;
            max-width: 80%;
            &-long{
              margin-right: 20px;
            }
          }
          .received-bar__copy{
            position: relative;
            top: 0;
            right: 0;
          }
        }
        &--no-code {
          justify-content: left;
          .received-bar__received-code-long{
            text-align: left;
          }
          .retryingText{
            display: none;
          }
          .received-bar__retry{
            span{
              display: none;
            }
          }
        }
      }

      &__retry {
        margin-top: 0;

        img {
          position: relative;
        }
      }

      &__btn {
        min-width: 0;
      }

      &__received-code{
        text-align: right;
        right: 47px;
        left: auto;
        width: 50%;
      }
      &__received-code-long{
        right: 0;
        left: 0;
        word-break: break-all;
        width: 76%;
        text-align: center;
      }
      &__copy {
        margin-left: 4px;
        img{
          margin-right: 0;
        }
      }

      &__error {
        font-size: 14px;
        img {
          top: 4px;
        }
      }

      &__full-message {
        line-height: 18px;
        &-text-bl {
          .full-message-text{
            text-align: left;
            margin-right: 10px;
          }
          .full-message-copy-btn {
            span {
              display: none;
            }
          }
        }
      }

      .notReceived{
        font-size: 14px;
        line-height: 18px;
      }
    }

    .rtl{
      .received-bar {
        &__top {
          &--no-retry {
            .received-bar__received-code{
              left: auto;
              &-long{
                margin-left: 20px;
                margin-right: 0;
              }
            }
            .received-bar__copy{
              left: 0;
              right: auto;
            }
          }
        }

        &__received-code{
          text-align: left;
          right: auto;
          left: 47px;
        }
        &__copy {
          margin-right: 4px;
          margin-left: 0;
          img{
            margin-right: 0;
            margin-left: 0;
          }
        }

        &__full-message {
          &-text-bl {
            .full-message-text{
              text-align: right;
              margin-left: 10px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .fade-enter-active {
    transition: opacity 0.3s ease;
  }
  .fade-leave-active {
    transition: opacity 0.15s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
