<template>
  <div class="wrap">
    <div class="alertBl">
      <Loader />
      <div class="text">
        <p>{{ $t('text_62b2bd1d33de2d008bdd97b9') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, applyActionCode } from 'firebase/auth'
import { useAuth } from '@/use/auth'
import { useOrder } from '@/use/order'
import Loader from '@/components/Loader.vue'

export default {
  components: { Loader },
  setup () {
    const { logout } = useAuth()
    const { forceActivationRedirect } = useOrder()
    return { logout, forceActivationRedirect }
  },
  beforeMount () {
    if (this.$route.query.mode && this.$route.query.oobCode) {
      if (this.$route.query.mode === 'verifyEmail' || this.$route.query.mode === 'verifyAndChangeEmail') {
        this.confirmEmail()
      } else if (this.$route.query.mode === 'recoverEmail') {
        this.recoverEmail()
      } else if (this.$route.query.mode === 'resetPassword') {
        if (this.$route.query?.continueUrl?.includes('/auth/new-password')) {
          // Redirect to TN Pro NewPassword page with query params
          location.href = this.$route.query.continueUrl + `?mode=${this.$route.query.mode}&oobCode=${this.$route.query.oobCode}`
        } else {
          this.$router.push({
            name: 'NewPassword',
            query: {
              mode: this.$route.query.mode,
              oobCode: this.$route.query.oobCode,
              continueUrl: this.$route.query.continueUrl
            }
          })
        }
      } else {
        this.$router.push({ name: 'Home' })
      }
    } else {
      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    async confirmEmail () {
      const lastVisitedPath = localStorage.getItem('lastVisitedPath')
      localStorage.removeItem('lastVisitedPath')
      try {
        const auth = getAuth()
        await applyActionCode(auth, this.$route.query.oobCode)
        const continueUrl = this.$route.query.continueUrl
        if (continueUrl) {
          location.href = continueUrl
        } else {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('email_verified_notification'),
            type: 'success',
            timeout: 3000,
            name: 'email_verified_notification'
          })
          if (!auth.currentUser) {
            await this.$router.push({ name: 'Home' })
            return
          }

          this.$amplitude.logEvent('signup.email_verified')
          const emailVerifiedIdentity = new this.$amplitude.Identify().set('email_verified', 'True')
          this.$amplitude.getInstance().identify(emailVerifiedIdentity)
          this.$store.commit('alert/removeByName', 'confirm_email')
          this.$store.commit('modal/toggleForceEmailConfirmationModal', false)

          if (this.$route.query.mode === 'verifyAndChangeEmail') {
            await this.logout('SignIn', false)
            return
          }
          await auth.currentUser.reload()

          // Force activation after email confirmation
          const forceActivationData = JSON.parse(localStorage.getItem('order'))
          if (forceActivationData) {
            if (forceActivationData.timestamp && (forceActivationData.timestamp + 15 * 60 * 1000) > Date.now()) {
              this.$store.commit('order/setOrderData', forceActivationData)
              await this.forceActivationRedirect()
            } else {
              this.redirectToLastVisitedPath(lastVisitedPath)
            }
          } else {
            this.redirectToLastVisitedPath(lastVisitedPath)
          }
        }
      } catch (e) {
        let alert = null
        switch (e.code) {
          case 'auth/expired-action-code':
            alert = this.$t('auth_error_email_code_expired')
            break
          case 'auth/invalid-action-code':
          case 'auth/user-not-found':
            alert = this.$t('auth_error_email_code_invalid')
            break
        }

        if (alert) {
          this.showErrorAlert(alert)
        } else {
          this.$store.commit('notificationModal/showSomethingWrongModal')
        }

        this.redirectToHomePage()
      }
    },
    async recoverEmail () {
      try {
        const auth = getAuth()
        await applyActionCode(auth, this.$route.query.oobCode)
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: this.$t('text_617fb02f0b1df101328d34ad'),
          type: 'success',
          timeout: 5000,
          name: 'email_successfully_recovered'
        })
        await this.logout('SignIn')
      } catch (e) {
        let alert = null
        switch (e.code) {
          case 'auth/expired-action-code':
          case 'auth/invalid-action-code':
          case 'auth/user-not-found':
            alert = this.$t('text_617fafbee6e3dd01273be2d6')
            break
        }

        if (alert) {
          this.showErrorAlert(alert)
        } else {
          this.$store.commit('notificationModal/showSomethingWrongModal')
        }

        await this.$router.push({ name: 'Home' })
      }
    },
    showErrorAlert (alert) {
      this.$store.commit('alert/add', {
        id: Date.now(),
        text: alert,
        timeout: 4000,
        name: 'recover_email_error'
      })
    },
    async redirectToLastVisitedPath (lastVisitedPath) {
      if (lastVisitedPath) {
        await this.$router.push(lastVisitedPath)
      } else {
        this.redirectToHomePage()
      }
    },
    async redirectToHomePage () {
      await this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrap{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alertBl{
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text{
    margin: 10px 0 30px;
    text-align: center;
    p {
      text-align: center;
      margin: 0 0 10px;
      font-size: 16px;
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
    }
  }
</style>
