export function useLocale () {
  const setAdditionalLocaleParams = async (locale = null) => {
    if (!locale) {
      locale = localStorage.getItem('locale') || 'en'
    }
    const curLocale = locale
    let direction = 'ltr'
    if (curLocale === 'ar' || curLocale === 'he') {
      direction = 'rtl'
    }

    // @ts-ignore
    document.querySelector('body').className = direction
    // @ts-ignore
    document.querySelector('html').setAttribute('lang', curLocale)
    // @ts-ignore
    document.querySelector('html').setAttribute('dir', direction)
  }

  return { setAdditionalLocaleParams }
}
