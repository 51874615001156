export default {
  namespaced: true,
  state: {
    activeActivationsTimeoutId: null,
    activeActivationsCount: 0,
    reportProblemActivationData: null
  },
  mutations: {
    setActiveActivationsCount: (state, val = 0) => {
      state.activeActivationsCount = val
    },
    setActiveActivationsTimeoutId: (state, val = null) => {
      state.activeActivationsTimeoutId = val
    },
    setReportProblemActivationData: (state, val) => {
      state.reportProblemActivationData = val
    }
  }
}
