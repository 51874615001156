<template>
  <div class="text">
    <component :is="{ template: addBreakLinesToText(text) }" />
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'

export default {
  props: {
    text: String
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { addBreakLinesToText }
  }
}
</script>

<style lang="scss" scoped>
  .text{
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #14142B;
    opacity: 0.6;
    margin: 8px 0 30px;
  }
</style>
