<template>
  <div class="activating-expired-error">
    <div class="activating-expired-error__status">
      <img :src="require(`@/assets/alert.gif`) + `?ts=${Date.now()}`" alt="error">
    </div>
    <TitleElement class="activating-expired-error__title" tace>
      <h1 v-html="addBreakLinesToText($t('text_61939fd80716840139f47694'))" class="title__main title__main-expired-error" />
      <h2 v-html="addBreakLinesToText($t('text_61939fd80716840139f4769b'))" class="title__subtitle title__subtitle-expired-error" />
    </TitleElement>
    <ReceivedBar :activation="activation" />
    <ActivationInfo :activation="activation" />
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import TitleElement from '@/components/TitleElement.vue'
import ReceivedBar from '@/components/Activation/ReceivedBar.vue'
import ActivationInfo from '@/components/Activation/ActivationInfo.vue'

export default {
  components: {
    TitleElement,
    ReceivedBar,
    ActivationInfo
  },
  props: {
    activation: Object
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { addBreakLinesToText }
  }
}
</script>

<style lang="scss" scoped>
  .activating-expired-error {
    margin: 0 auto;
    max-width: 536px;

    .title__main-expired-error {
      padding-bottom: 8px;
    }
    .title__subtitle-expired-error {
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
    }

    &__status {
      height: 100px;
      margin-top: 60px;
      margin-bottom: 46px;
      text-align: center;

      img {
        height: 100%;
      }
    }

    &__title {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 590px) {
    .activating-expired-error {
      .title__subtitle-expired-error {
        line-height: 24px;
      }
      &__title {
        padding-bottom: 24px;
      }
      &__status {
        margin-top: 30px;
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .activating-expired-error {
      &__status {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &__title {
        padding-bottom: 20px;
      }
    }
  }
</style>
