<template>
  <div>
    <TitleElement :text="$t('text_64119de89cf25d009d31c9e2')" />
    <TextElement :text="$t('text_64119de89cf25d009d31c9ec')" />
    <OpenLiveChatBtn @closeModal="$emit('closeModal')" />
    <ButtonElement
      @click="$emit('changeReason')" class="change-reason" fill height="58px" width="100%"
    >
      {{ $t('text_64119de89cf25d009d31c9f4') }}
    </ButtonElement>
  </div>
</template>

<script>
import TitleElement from '@/components/Modals/Support/Elements/TitleElement'
import TextElement from '@/components/Modals/Support/Elements/TextElement'
import OpenLiveChatBtn from '@/components/Modals/Support/Elements/OpenLiveChatBtn'
import ButtonElement from '@/components/ButtonElement'

export default {
  emits: ['closeModal', 'changeReason'],
  components: {
    TitleElement,
    TextElement,
    OpenLiveChatBtn,
    ButtonElement
  }
}
</script>

<style lang="scss" scoped>
  .change-reason{
    margin-bottom: 10px;
  }
</style>
