<template>
  <div class="temp-number">
    <p v-html="addBreakLinesToText($t('text_61939fd80716840139f4765b'))" class="temp-number__title" />
    <div @click="copy('number')" class="temp-number__num-bar">
      <Tooltip v-if="!noButtons" :text="!expired ? $t('timer_active_message') : $t('timer_expired_message')">
        <div @click.stop class="temp-number__time-bar">
          <vue-countdown
            v-if="!expired"
            :transform="addLeadingZeroToCountdown"
            @end="updateActivation()"
            :time="(activation.numberExpireAt * 1000 + (Date.now() - activation.utc * 1000)) - Date.now()"
            v-slot="{ minutes, seconds }"
          >
            {{ minutes }}:{{ seconds }}
          </vue-countdown>
          <span v-else>
            {{ $t('text_6193c22cf00dec01166051de') }}
          </span>
        </div>
      </Tooltip>
      <div class="temp-number__num temp-number__num--big">
        <span v-if="showCountryCode" ref="number">{{ activation.formatNumber }}</span>
        <span v-else><span class="hidden-country-code">{{ countryCode }}</span> <span ref="number">{{ clippedNumber }}</span></span>
      </div>
      <button v-if="!noButtons" @click.stop="copy('number')" class="temp-number__btn services-button services-button--hide-word">
        <img src="@/assets/icons/copy.svg" alt="">
        <span>{{ $t('text_61939fd80716840139f47655') }}</span>
      </button>
    </div>
  </div>
  <div v-if="activation.status === 'supRefunded'" class="sup-refunded-block">
    <component :is="{ template: addBreakLinesToText($t('text_64147a1ad1b68f00bc456be2').replace('__0__', activation.price)) }" />
  </div>
  <div class="activation-info">
    <ul class="activation-info__bottom-block">
      <li class="activation-info__bar-item activation-info__code-bar">
        <p>{{ $t('text_61939fd80716840139f47658') }}</p>
        <div
          @click="handleClickSwitcher"
          class="activation-info__code-switcher"
          :class="{'activation-info__code-switcher--active': showCountryCode}"
        >
          <div class="activation-info__code-switcher-inner"></div>
        </div>
      </li>
      <li v-if="showPrice" class="activation-info__bar-item activation-info__price">
        <p>{{ $t('text_6193c22cf00dec01166051d4') }}</p>
        {{ expired && !activation.code ? $t('text_6193c22cf00dec01166051db') : '$' + activation.price }}
      </li>
      <li @click="toOrderWithPreset(activation)" class="activation-info__bar-item activation-info__social">
        <p>{{ activation.serviceName }} ({{ activation.countryName }})</p>
        <div class="activation-info__social-icon">
          <img :src="`${activation.iconBaseUrl}${activation.serviceIconPath}`" class="activation-info__social-img" alt="Service">
          <img :src="`${activation.iconBaseUrl}${activation.countryIconPath}`" class="activation-info__country-img" alt="Country">
        </div>
      </li>
      <li class="activation-info__bar-item">
        <p>{{ $t('text_625807084b5f1301245fcdd2') }}</p>
        <StarRating @update:rating="setRating" v-model="rating" :rating="rating" />
      </li>
    </ul>
  </div>

  <WhatsWrongModal :activation="activation" :expired="expired" />
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useTranslate } from '@/use/translate'
import StarRating from '@/components/StarRating/StarRating'
import { useOrder } from '@/use/order'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useCountdown } from '@/use/countdown'
import { useActiveActivationsCount } from '@/use/activeActivationsCount'
import selectText from '@/helpers/select-text.js'
import Tooltip from '@/components/Tooltip'
import WhatsWrongModal from '@/components/Modals/WhatsWrongModal'

export default {
  components: { StarRating, Tooltip, WhatsWrongModal },
  emits: ['updateActivation'],
  props: {
    activation: Object,
    expired: {
      type: Boolean,
      default: () => true
    },
    showPrice: {
      type: Boolean,
      default: () => true
    },
    noButtons: Boolean
  },
  data () {
    return {
      showCountryCode: false,
      maxShowWithCountryCodeIdsCount: 50,
      rating: 0,
      showWithCountryCodeIds: [],
      showWithoutCountryCodeIdsForce: []
    }
  },
  computed: {
    countryCode () {
      return `+${this.activation.countryCode}`
    },
    clippedNumber () {
      const countryCodeLength = this.activation.countryCode.toString().length
      return this.activation.formatNumber.substring(countryCodeLength + 2)
    },
    servicesIdsWithCountryCode () {
      return this.$store.state.common.servicesIdsWithCountryCode
    }
  },
  mounted () {
    this.rating = this.activation.rating
    this.initShowCodesData()
    this.initShowCountryCode()
  },
  setup () {
    const { initApiInst, smsActivationApi } = useSmsApi()
    const { toOrderWithPreset } = useOrder()
    const { addLeadingZeroToCountdown } = useCountdown()
    const { handleNotAuthorizedError } = useErrorHandlers()
    const { getActiveActivationsCount } = useActiveActivationsCount()
    const { addBreakLinesToText } = useTranslate()
    return {
      initApiInst,
      smsActivationApi,
      toOrderWithPreset,
      addLeadingZeroToCountdown,
      handleNotAuthorizedError,
      getActiveActivationsCount,
      addBreakLinesToText
    }
  },
  methods: {
    initShowCodesData () {
      let showWithCountryCodeIds = JSON.parse(localStorage.getItem('showWithCountryCodeIds'))
      this.showWithCountryCodeIds = !showWithCountryCodeIds ? [] : showWithCountryCodeIds
      let showWithoutCountryCodeIdsForce = JSON.parse(localStorage.getItem('showWithoutCountryCodeIdsForce'))
      this.showWithoutCountryCodeIdsForce = !showWithoutCountryCodeIdsForce ? [] : showWithoutCountryCodeIdsForce
    },
    copy (id) {
      const _this = this
      const text = this.showCountryCode ? this.activation.number : this.activation.nationalNumber
      this.$copyText(text, undefined, (error) => {
        if (!error) {
          selectText(_this.$refs[id])
          _this.$store.commit('alert/add', {
            id: Date.now(),
            text: _this.$t('text_618d5a996329050141a2473d'),
            type: 'success',
            timeout: 1500,
            name: 'copy'
          })
        }
      })
    },
    handleClickSwitcher () {
      this.$amplitude.getInstance().logEvent('ui.clicked_on_show_country_code')
      this.showCountryCode = !this.showCountryCode
      const index = this.showWithCountryCodeIds.indexOf(this.activation.id)
      if (!this.showCountryCode && index > -1) {
        this.showWithCountryCodeIds.splice(index, 1)
      } else if (this.showCountryCode) {
        this.showWithCountryCodeIds = this.formIdsArray(this.showWithCountryCodeIds, index)
      }
      localStorage.setItem('showWithCountryCodeIds', JSON.stringify(this.showWithCountryCodeIds))
      // Handle force click to show without country code for service in FB RK
      this.handleForceShowWithoutCountryCode()
    },
    updateActivation () {
      this.$emit('updateActivation')
      const _this = this
      setTimeout(() => {
        _this.getActiveActivationsCount()
      }, 2000)
    },
    async setRating (rating) {
      const reason = 0
      try {
        await this.initApiInst()
        this.smsActivationApi.newRating({
          rating: {
            activation_id: this.activation.id,
            rating,
            reason
          },
          prxPrint: this.$store.state.common.prxPrint
        })
      } catch (e) {
      } finally {
        if (rating === 4 || rating === 5) {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('text_625807084b5f1301245fcdd8'),
            type: 'success',
            timeout: 2000,
            name: 'rating_saved'
          })
        }
      }
      if (rating === 1 || rating === 2 || rating === 3) {
        this.$store.commit('modal/toggleWhatsWrongModal')
      }
    },
    initShowCountryCode () {
      if (
        (this.showWithCountryCodeIds.indexOf(this.activation.id) > -1 ||
        this.servicesIdsWithCountryCode.indexOf(this.activation.serviceId) > -1) &&
        this.showWithoutCountryCodeIdsForce.indexOf(this.activation.id) === -1
      ) {
        this.showCountryCode = true
      }
    },
    handleForceShowWithoutCountryCode () {
      if (this.servicesIdsWithCountryCode.includes(this.activation.serviceId)) {
        const index = this.showWithoutCountryCodeIdsForce.indexOf(this.activation.id)
        if (this.showCountryCode && index > -1) {
          this.showWithoutCountryCodeIdsForce.splice(index, 1)
        } else if (!this.showCountryCode) {
          this.showWithoutCountryCodeIdsForce = this.formIdsArray(this.showWithoutCountryCodeIdsForce, index)
        }
        localStorage.setItem('showWithoutCountryCodeIdsForce', JSON.stringify(this.showWithoutCountryCodeIdsForce))
      }
    },
    formIdsArray (idsArr, index) {
      if (index > -1) {
        idsArr.splice(index, 1)
      }
      idsArr.unshift(this.activation.id)
      if (idsArr.length > this.maxShowWithCountryCodeIdsCount) {
        idsArr.splice(this.maxShowWithCountryCodeIdsCount)
      }
      return idsArr
    }
  }
}
</script>

<style lang="scss" scoped>
  .temp-number {
    color: #191632;
    &__title {
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
      color: #14142B;
    }
    &__num-bar {
      cursor: pointer;
      height: 60px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba(25, 22, 50, 0.12);
      border-radius: 15px;
    }
    &__num {
      margin: 0 auto;
    }
    &__time-bar {
      min-width: 46px;
      height: 26px;
      background: #ffc100;
      border-radius: 4px;
      margin-top: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      padding: 0 7px;
      span {
        text-align: center;
        line-height: 10px;
      }
    }
    &__num {
      font-size: 14px;
      & {
        .hidden-country-code {
          color: #E3E3E6;
        }
      }
      &--big {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #191632;
        position: absolute;
        left: 0;
        right: 0;
         margin: 0 auto;
        text-align: center;
        max-width: 260px;
      }
    }
    &__btn {
      margin-top: 5px;
    }
  }

  .rtl{
    .temp-number{
      &__num{
        direction: ltr;
      }
    }
  }

  @media (max-width: 590px) {
    .activating-done__temp-number .temp-number__time-bar {
      display: none;
    }
    .activating-expired {
      padding-top: 0;
      .temp-number__num {
        margin: 0 auto;
      }
      .activation-info {
        &__code-bar {
          display: none;
        }
      }
      .hidden-country-code{
        color: #14142B;
      }
    }
    .activating-expired-error {
      .temp-number__num {
        margin: 0 auto;
      }
      .activation-info {
        &__code-bar {
          display: none;
        }
      }
      .hidden-country-code{
        color: #14142B;
      }
    }
    .activating-done {
      .activation-info__price {
        display: none;
      }
    }
    .activating-ready {
      .temp-number{
        order: 3;
      }
      .activation-info{
        order: 4;
      }
    }

    .temp-number {
      padding-top: 0;
      .tooltip{
        order: 2;
      }
      &__time-bar {
        order: 2;
        line-height: 13px;
        margin-top: 0;
        max-width: 70px;
      }
      &__btn {
        position: absolute;
        right: 20px;
        img {
          margin-right: 0;
        }
      }
      &__num-bar {
        position: relative;
        justify-content: left;
      }
      &__num{
        display: inline-block;
        margin-left: 0;
        order: 1;
        padding-right: 20px;
        &--big {
          color: #14142B;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          margin: 0 !important;
          position: relative;
        }
      }
      &__title{
        display: none;
        font-size: 14px;
      }
    }

    .rtl{
      .temp-number {
        .tooltip{
          order: 2;
        }
        &__time-bar {
          order: 2;
        }
        &__num-bar {
          justify-content: right;
        }
        &__btn {
          left: 20px;
          right: auto;
          img {
            margin-left: 0;
          }
        }
        &__num{
          margin-right: 0;
          order: 1;
          padding-left: 20px;
          padding-right: 0;
        }
      }
    }
  }

  .activation-info {
    color: #191632;
    &__bottom-block {
      border: 1px solid rgba(25, 22, 50, 0.12);
      border-radius: 15px;
      margin-top: 24px;
      padding: 8px 16px;
    }
    &__bar-item {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      line-height: 19px;
      border-bottom: 1px solid rgba(25, 22, 50, 0.12);
      &:last-child {
        border-bottom: none;
      }
    }
    &__code-switcher {
      width: 42px;
      height: 24px;
      background: gray;
      border-radius: 18px;
      display: flex;
      align-items: center;
      padding: 0 3px;
      cursor: pointer;
      &--active {
        justify-content: flex-end;
        background: #26d583;
      }
    }
    &__code-switcher-inner {
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
    }
    &__social {
      cursor: pointer;
    }
    &__social-icon {
      position: relative;
    }
    &__social-img {
      height: 24px;
      width: 24px;
    }
    &__country-img {
      position: absolute;
      top: -1px;
      left: -8px;
      width: 16px;
      height: 12px;
      border: 1px solid #FFFFFF;
      border-radius: 2px;
    }
  }

  .rtl{
    .activation-info {
      &__country-img {
        right: -8px;
        left: auto;
      }
    }
  }

  @media (max-width: 320px) {
    .activation-info__bottom-block{
      padding: 8px 10px;
    }
    .temp-number {
      &__num{
        padding-right: 10px;
      }
      &__num-bar {
        padding: 0 10px;
      }
      &__num--big {
        font-size: 13px;
      }
      &__btn {
        right: 5px;
      }
    }

    .rtl{
      .temp-number {
        &__num{
          padding-left: 10px;
          padding-right: 0;
        }
        &__btn {
          left: 5px;
          right: auto;
        }
      }
    }
  }

  .sup-refunded-block{
    margin-top: 24px;
    padding: 6px;
    background: #26D483;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
  }
</style>
