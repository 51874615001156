<template>
  <ButtonElement @click="openChat" class="open-chat-btn" fill>
    <img src="@/assets/icons/headset_black.svg" class="headsetIcon" alt="headset">
    {{ $t('text_xbivcgf3q6nvkix6im8') }}
    <img src="@/assets/icons/open_chat_arrow.svg" class="arrowIcon" alt="arrow">
  </ButtonElement>
</template>

<script>
import { useCrispChat } from '@/use/crispChat'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  components: { ButtonElement },
  emits: ['closeModal'],
  setup () {
    const { openChatWindow } = useCrispChat()
    return { openChatWindow }
  },
  methods: {
    openChat () {
      this.$emit('closeModal')
      this.openChatWindow()
    }
  }
}
</script>

<style lang="scss" scoped>
  .open-chat-btn{
    height: 58px;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #F8F8F8;
    border-radius: 12px;
    color: #14142B;
    font-weight: 400;
    font-size: 16px;
    padding: 15px 25px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    &:active{
      background: #F8F8F8;
      border: 1px solid #F8F8F8;
      color: #14142B;
    }
    &:hover{
      border: 1px solid #F8F8F8;
    }
  }

  .rtl{
    .open-chat-btn{
      .arrowIcon{
        transform: rotate(180deg);
      }
    }
  }
</style>
