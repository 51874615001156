export default {
  namespaced: true,
  state: {
    balance: null,
    balanceTimeoutId: null,
    fcmEnabled: false, // Firebase Cloud Messages,
    accountOnHold: false,
    apiKey: null
  },
  mutations: {
    setBalance (state, balance = 0) {
      state.balance = balance
    },
    setBalanceTimeoutId (state, val = null) {
      state.balanceTimeoutId = val
    },
    setFcmEnabled (state, val) {
      state.fcmEnabled = val
    },
    setAccountOnHold (state, val) {
      state.accountOnHold = val
    },
    setApiKey (state, val) {
      state.apiKey = val
    }
  }
}
