import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useStore } from 'vuex'

export function useUser () {
  const { initApiInst, smsUserApi } = useSmsApi()
  const { handleNotAuthorizedError, showCommonError } = useErrorHandlers()
  const store = useStore()

  const isUserOnHold = async () => {
    try {
      await initApiInst()
      const resp = await smsUserApi.value.getUser({ prxPrint: store.state.common.prxPrint })
      if (resp && resp.accountOnHold) {
        store.commit('user/setAccountOnHold', true)
        return true
      }
      return false
    } catch (e) {
      if (e.status === 401) {
        handleNotAuthorizedError()
      } else {
        showCommonError(e)
      }
      return true
    }
  }

  const isUserEmailVerified = () => {
    const auth = getAuth()
    if (store.state.auth.authPasswordProvider) {
      return !!auth.currentUser.emailVerified
    }
    return true
  }

  const getUserProject = async (fbUid) => {
    try {
      const params = { fb_uid: fbUid }
      const resp = await axios.get(`${process.env.VUE_APP_PRO_VERSION_API_URL}/user/project`, { params })
      if (resp.data && resp.data.project && resp.data.project === 'act') {
        location.href = process.env.VUE_APP_PRO_VERSION_URL + '?project=act'
      }
    } catch (e) {}
  }

  const getOS = () => {
    let os = 'Unknown OS'
    if (navigator.userAgent.indexOf('Win') !== -1) {
      os = 'Windows'
    }
    if (navigator.userAgent.indexOf('Mac') !== -1) {
      os = 'Mac OS'
    }
    if (navigator.userAgent.indexOf('Linux') !== -1) {
      os = 'Linux'
    }
    if (navigator.userAgent.indexOf('Android') !== -1) {
      os = 'Android'
    }
    if (navigator.userAgent.indexOf('like Mac') !== -1) {
      os = 'iOS'
    }

    return os
  }

  const getBrowserData = () => {
    const ua = navigator.userAgent
    let tem
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera')
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1])
    }
    return M.join(' ')
  }

  const getGaClientId = () => {
    let clientId = ''
    const cookie = {}
    document.cookie.split(';').forEach((el) => {
      const splitCookie = el.split('=')
      const key = splitCookie[0].trim()
      cookie[key] = splitCookie[1]
    })
    if (cookie._ga) {
      clientId = cookie._ga.substring(6)
    }

    return clientId
  }

  return { isUserOnHold, isUserEmailVerified, getUserProject, getOS, getBrowserData, getGaClientId }
}
