<template>
  <form @submit.prevent="changeEmail" class="form">
    <EmailControl
      :errorMessage="this.v$.email.$errors?.[0]?.$validator"
      v-model.trim="email"
      :placeholder="$t('text_6167c4a5321c670152f0e22d')"
      class="form__control"
    />
    <EmailControl
      :errorMessage="this.v$.newEmail.$errors?.[0]?.$validator"
      v-model.trim="newEmail"
      :placeholder="$t('text_6167c4a5321c670152f0e22e')"
      class="form__control"
    />
    <ButtonElement :loading="loading && !$store.state.modal.showConfirmPasswordModal" class="form__send" width="100%" height="58px" fill>
      {{ $t('text_6167c4a5321c670152f0e22f') }}
    </ButtonElement>
  </form>

  <ConfirmPasswordModal v-if="$store.state.modal.showConfirmPasswordModal" @userReauthenticated="changeEmail" />
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useTranslate } from '@/use/translate'
import { required, email, sameAs } from '@vuelidate/validators'
import { getAuth, verifyBeforeUpdateEmail } from 'firebase/auth'

import ButtonElement from '@/components/ButtonElement'
import EmailControl from '@/components/Controls/EmailControl'
import ConfirmPasswordModal from '@/components/Modals/ConfirmPasswordModal'

export default {
  components: {
    ButtonElement,
    EmailControl,
    ConfirmPasswordModal
  },
  data () {
    return {
      email: '',
      newEmail: '',
      password: '',
      loading: false
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { v$: useVuelidate(), addBreakLinesToText }
  },
  validations () {
    return {
      email: {
        email,
        required
      },
      newEmail: {
        email,
        sameAsEmail: sameAs(this.email)
      }
    }
  },
  methods: {
    async changeEmail () {
      const isValid = await this.v$.$validate()

      if (isValid) {
        this.loading = true
        try {
          const auth = getAuth()
          await verifyBeforeUpdateEmail(auth.currentUser, this.email)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: `${this.$t('text_6172600eb04bdf00d337f650')}! ${this.$t('verify_email_popup_subtitle').replace('__0__', this.email)}`,
            type: 'success',
            timeout: 6000
          })
          const forceEmailConfirmation = this.$store.state.common.forceEmailConfirmation
          const alertExists = this.$store.state.alert.alerts.find(alert => alert.name === 'confirm_email')
          if (!alertExists && !auth.currentUser.emailVerified) {
            if (forceEmailConfirmation) {
              await this.$store.commit('modal/toggleForceEmailConfirmationModal', true)
            } else {
              this.$store.commit('alert/add', {
                id: Date.now(),
                name: 'confirm_email',
                text: this.$t('confirm_email_notification')
              })
            }
          }
          await this.$router.push({ name: 'Home' })
        } catch (e) {
          if (e.code === 'auth/requires-recent-login') {
            this.$store.commit('modal/toggleConfirmPasswordModal', true)
          } else if (e.code === 'auth/too-many-requests') {
            this.$store.commit('notificationModal/show', {
              title: this.$t('login_requests_error_title'),
              text: this.addBreakLinesToText(this.$t('login_requests_error_subtitle')),
              buttonText: this.$t('text_616e4d4b66577e00e3d6ba11')
            })
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal')
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
