<template><div/></template>

<script>
import { useEnot } from '@/use/payments/enot'

export default {
  props: {
    mivionPay: Boolean,
    qiwiPay: Boolean,
    perfectMoneyPay: Boolean,
    amount: Number,
    uid: String,
    os: String,
    afId: String
  },
  data () {
    return {
      mivionFormData: null,
      appUrl: process.env.VUE_APP_URL
    }
  },
  watch: {
    mivionPay (val) {
      if (val) {
        this.addMivionFunds()
      }
    }
  },
  setup () {
    const { getMivionFormData } = useEnot()
    return { getMivionFormData }
  },
  methods: {
    async addMivionFunds () {
      let includeServices = []
      let excludeServices = []
      if (this.qiwiPay) {
        includeServices.push('qiwi')
      } else {
        excludeServices.push('qiwi')
      }
      if (this.perfectMoneyPay) {
        includeServices.push('perfect_money')
      } else {
        excludeServices.push('perfect_money')
      }

      this.mivionFormData = await this.getMivionFormData(this.amount, this.uid, includeServices, excludeServices, this.os, this.afId)
      if (this.mivionFormData && this.mivionFormData.redirectUrl) {
        this.$nextTick(() => {
          window.location.href = this.mivionFormData.redirectUrl
        })
      }
    }
  }
}
</script>
