<template>
  <div @click="hideMenu" class="side-nav">
    <div @click.stop="" class="side-nav__side">
      <template v-if="accountNavEnabled">
        <AccountNav @hideAccountNav="hideAccountNav" @handleClickLink="handleClickLink" />
      </template>
      <template v-else>
        <div @click="hideMenu" class="side-nav__close">
          <img src="@/assets/icons/close.svg" alt="close">
        </div>

        <LocaleSwitcher @hideMenu="hideMenu" />

        <nav class="menu">
          <div class="menu__header">{{ $t('text_6178e7f8bc5bec0144b85df1') }}</div>
          <ul @click="handleClickLink">
            <li>
              <div @click="handleNewOrderClick">
                <div class="menu__link">
                  <img src="@/assets/icons/order-new.svg" alt="order new">
                  <p>{{ $t('text_6178e7f8bc5bec0144b85de4') }}</p>
                </div>
              </div>
            </li>
            <li>
              <router-link :to="{ name: 'History' }">
                <div class="menu__link">
                  <img src="@/assets/icons/history.svg" alt="history">
                  <p>{{ $t('text_6178e7f8bc5bec0144b85deb') }}</p>
                  <div v-if="activeActivationsCount > 0" class="menu__link-notification">{{ activeActivationsCount }}</div>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Funds' }">
                <div class="menu__link">
                  <img src="@/assets/icons/funds.svg" alt="funds">
                  <p>{{ $t('text_6178e7f8bc5bec0144b85de7') }}</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Transactions' }">
                <div class="menu__link">
                  <img src="@/assets/icons/transactions.svg" alt="transactions">
                  <p>{{ $t('text_6178e7f8bc5bec0144b85de6') }}</p>
                </div>
              </router-link>
            </li>
            <li>
              <a href="https://help.temp-number.org/" target="_blank">
                <div class="menu__link">
                  <img src="@/assets/icons/help_circle.svg" alt="help">
                  <p>{{ $t('text_6178e7f8bc5bec0144b85dea') }}</p>
                </div>
              </a>
            </li>
            <li v-if="isAuthenticated && fcmSupported" @click.stop="handleNotificationsSwitcher">
              <div class="menu__link menu__notifications-link">
                <div>
                  <img src="@/assets/icons/notifications.svg" alt="notifications">
                  <p>{{ $t('text_61c31682d5425f00f8233826') }}</p>
                </div>
                <div>
                  <v-lottie-player
                    v-if="notificationsLoading"
                    :animationData="require('@/assets/loaders/iphone-orange-spinner.json')"
                    loop
                    width="25px"
                    height="25px"
                  />
                  <div
                    v-else
                    class="notifications-switcher"
                    :class="{'notifications-switcher--active': notificationsEnabled}"
                  >
                    <div class="notifications-switcher-inner"></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>

        <ButtonElement v-if="isAuthenticated" @click="openChat" class="openChatBtn" fill>
          <img src="@/assets/icons/headset_black.svg" class="headsetIcon" alt="Open chat">
          {{ $t('text_xbivcgf3q6nvkix6im8') }}
          <img src="@/assets/icons/open_chat_arrow.svg" class="arrowIcon" alt="Open chat">
        </ButtonElement>

        <div class="side-nav__info-links">
          <ul @click="handleClickLink">
            <li v-if="showProVersion">
              <a :href="proVersionUrl">Temp Number <span class="tnPro">PRO</span></a>
            </li>
            <li>
              <router-link :to="{ name: 'Terms' }">{{ $t('text_6178e7f8bc5bec0144b85de3') }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Privacy' }">{{ $t('text_6178e7f8bc5bec0144b85df9') }}</router-link>
            </li>
            <li>
              <a href="https://help.temp-number.org/en/article/refund-policy-1ma49c2/" target="_blank">{{ $t('text_60tpjd1sj7mzut2fdmd') }}</a>
            </li>
            <li>
              <a href="https://tempnumber.tapfiliate.com/" target="_blank">
                {{ $t('text_64b8f9fcf017080061541921') }}
              </a>
            </li>
            <li>
              <a :href="proVersionApiUrl">{{ $t('text_625fc8bd481518015daf97b1') }}</a>
            </li>
          </ul>
        </div>

        <div class="side-nav__in-out">
          <router-link v-if="this.$store.state.auth.isAuthenticated" to="" @click.prevent="accountNavEnabled = true">
            <img src="@/assets/icons/account.svg" alt="account">
            <span>{{ $t('text_6220f0c84a419000bcd72e89') }}</span>
          </router-link>
          <router-link v-else  @click="handleClickLink" :to="{ name: 'CreateAccount' }">
            <img src="@/assets/icons/sign_in.svg" alt="login" class="loginBtn">
            <span>{{ $t('text_6178ed29bb3c82015abbc1df') }}</span>
          </router-link>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import { useCrispChat } from '@/use/crispChat'
import { isSupported } from 'firebase/messaging'
import { useCloudMessaging } from '@/use/cloudMessaging'
import { useOrder } from '@/use/order'
import AccountNav from '@/components/SideNav/AccountNav'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  emits: ['hideMenu'],
  components: { AccountNav, LocaleSwitcher, ButtonElement },
  data () {
    return {
      fcmSupported: true,
      notificationsLoading: false,
      accountNavEnabled: false,
      showProVersion: process.env.VUE_APP_SHOW_PRO_VERSION ? Number(process.env.VUE_APP_SHOW_PRO_VERSION) : 0,
      proVersionUrl: process.env.VUE_APP_PRO_VERSION_URL + '?project=act',
      proVersionApiUrl: process.env.VUE_APP_PRO_VERSION_URL + '/api'
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.isAuthenticated
    },
    activeActivationsCount () {
      return this.$store.state.activation.activeActivationsCount
    },
    notificationsEnabled () {
      return this.$store.state.user.fcmEnabled
    }
  },
  watch: {
    isAuthenticated () {
      this.initNotifications()
    }
  },
  setup () {
    const { updateFbNotificationToken, enableNotifications } = useCloudMessaging()
    const { handleNewOrderClick } = useOrder()
    const { addBreakLinesToText } = useTranslate()
    const { openChatWindow } = useCrispChat()
    return { updateFbNotificationToken, enableNotifications, handleNewOrderClick, addBreakLinesToText, openChatWindow }
  },
  mounted () {
    this.initNotifications()
    this.checkFcmSupport()
  },
  methods: {
    hideMenu () {
      this.$emit('hideMenu')
      this.accountNavEnabled = false
      document.querySelector('body').style.removeProperty('overflow')
    },
    hideAccountNav () {
      this.accountNavEnabled = false
    },
    handleClickLink () {
      this.hideMenu()
    },
    async handleNotificationsSwitcher () {
      if (this.notificationsLoading) return
      if (this.notificationsEnabled) {
        this.notificationsLoading = true
        await this.updateFbNotificationToken()
        this.notificationsLoading = false
      } else {
        if (Notification.permission === 'granted') {
          this.notificationsLoading = true
          await this.enableNotifications()
          this.notificationsLoading = false
        } else {
          this.hideMenu()
          this.$store.commit('notificationModal/show', {
            title: this.$t('text_61d5bb9a8bf09f00d48aab2d'),
            text: this.addBreakLinesToText(this.$t('text_61d5bb9a8bf09f00d48aab33')),
            buttonText: this.$t('text_61d5bb9a8bf09f00d48aab31'),
            buttonAction: 'enableNotifications'
          })
        }
      }
    },
    async initNotifications () {
      if (this.isAuthenticated) {
        const fcmEnabled = localStorage.getItem('fcmEnabled')
        if (Notification.permission === 'granted' && fcmEnabled === 'true') {
          await this.enableNotifications()
        } else {
          const changeLocalStorageFcmEnabled = !!fcmEnabled
          await this.updateFbNotificationToken('', changeLocalStorageFcmEnabled)
        }
      } else {
        this.$store.commit('user/setFcmEnabled', false)
      }
    },
    async checkFcmSupport () {
      this.fcmSupported = await isSupported()
    },
    help () {
      this.$store.commit('modal/toggleHelpModal', true)
    },
    openChat () {
      this.hideMenu()
      this.openChatWindow()
    }
  }
}
</script>

<style lang="scss" scoped>
  .openChatBtn{
    height: 54px;
    background: rgba(20, 20, 43, 0.12);
    border: 1px solid rgba(20, 20, 43, 0.02);
    border-radius: 12px;
    color: #14142B;
    font-weight: 400;
    font-size: 14px;
    padding: 15px;
    margin-top: 32px;
    .headsetIcon{
      margin-right: 15px;
    }
    .arrowIcon{
      margin-left: 20px;
    }
    &:active{
      background: rgba(20, 20, 43, 0.12);
      border: 1px solid rgba(20, 20, 43, 0.02);
      color: #14142B;
    }
    &:hover{
      border: 1px solid rgba(20, 20, 43, 0.1);
    }
  }

  .rtl {
    .openChatBtn{
      .headsetIcon{
        margin-left: 15px;
        margin-right: 0;
      }
      .arrowIcon{
        margin-right: 20px;
        margin-left: 0;
        transform: rotate(180deg);
      }
    }
  }

  .tnPro {
    background: #FA8231;
    color: #fff;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }
</style>
