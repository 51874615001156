<template>
  <form @submit.prevent="setNewPassword" class="form">
    <PasswordControl
      :errorMessage="this.v$.password.$errors?.[0]?.$validator"
      v-model.trim="password"
      :placeholder="$t('text_61725b080fcb340162b9e41a')"
      class="form__control"
    />
    <PasswordControl
      :errorMessage="this.v$.repeatPassword.$errors?.[0]?.$validator"
      v-model.trim="repeatPassword"
      :placeholder="$t('text_61725b080fcb340162b9e41b')"
      class="form__control"
    />

    <ButtonElement :loading="loading" class="form__send" width="100%" height="58px" fill>{{ $t('text_61725a931769100136b9ca0a') }}</ButtonElement>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useTranslate } from '@/use/translate'
import { required, minLength, sameAs } from '@vuelidate/validators'
import { getAuth, confirmPasswordReset } from 'firebase/auth'

import ButtonElement from '@/components/ButtonElement'
import PasswordControl from '@/components/Controls/PasswordControl.vue'

export default {
  components: {
    ButtonElement,
    PasswordControl
  },
  data () {
    return {
      password: '',
      repeatPassword: '',
      code: '',
      continueUrl: '',
      loading: false
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { v$: useVuelidate(), addBreakLinesToText }
  },
  mounted () {
    if (this.$route.query.mode && this.$route.query.oobCode && this.$route.query.mode === 'resetPassword') {
      this.code = this.$route.query.oobCode
      this.continueUrl = this.$route.query.continueUrl
    }
  },
  validations () {
    return {
      password: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        sameAsPassword: sameAs(this.password)
      }
    }
  },
  methods: {
    async setNewPassword () {
      const isValid = await this.v$.$validate()

      if (isValid) {
        this.loading = true
        try {
          const auth = getAuth()
          await confirmPasswordReset(auth, this.code, this.password)
          if (this.continueUrl) {
            location.href = this.continueUrl
          } else {
            this.$store.commit('notificationModal/show', {
              title: this.$t('psw_changed_title'),
              text: this.addBreakLinesToText(this.$t('psw_changed_subtitle')),
              buttonText: this.$t('text_616e4d4b66577e00e3d6ba38'),
              buttonRouteName: 'SignIn'
            })
          }
        } catch (e) {
          let alert = null
          switch (e.code) {
            case 'auth/expired-action-code':
              alert = this.$t('auth_error_psw_reset_code_expired')
              break
            case 'auth/invalid-action-code':
            case 'auth/user-not-found':
              alert = this.$t('auth_error_psw_reset_code_invalid')
              break
            case 'auth/weak-password':
              alert = this.$t('new_psw_not_strong_validation')
              break
          }

          if (alert) {
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: alert,
              timeout: 4000,
              name: 'setNewPassword'
            })
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal')
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
