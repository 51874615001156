export function useCountdown () {
  const addLeadingZeroToCountdown = (props) => {
    Object.entries(props).forEach(([key, value]) => {
      const digits = value < 10 ? `0${value}` : value
      props[key] = `${digits}`
    })

    return props
  }

  return { addLeadingZeroToCountdown }
}
