import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export function useEnot () {
  const store = useStore()
  const router = useRouter()

  const getEnotFormData = async (amount, os = '', prxAfId = '') => {
    try {
      const products = JSON.parse(process.env.VUE_APP_ENOT_PACKAGES)
      const productId = products[`${amount}_dollars_purchase`] ? `${amount}_dollars_purchase` : 'custom'
      const params = {
        product_id: productId,
        amount
      }
      if (os) params.os = os

      const headers = { 'prx-af-id': prxAfId }
      const formDataResp = await axios.get(process.env.VUE_APP_ENOT_FORM_DATA_URL + '/enot-form', {
        params, headers
      })
      if (formDataResp.data && formDataResp.data.paymentId) {
        return formDataResp.data
      }
    } catch (e) {
      await router.push({ name: 'Funds', query: { status: 'error' } })
    } finally {
      store.commit('funds/setAddFundsLoading', false)
    }
  }

  const getMivionFormData = async (amount, uid, includeServices = [], excludeServices = [], os = '', prxAfId = '') => {
    try {
      const products = JSON.parse(process.env.VUE_APP_ENOT_PACKAGES)
      const productId = products[`${amount}_dollars_purchase`] ? `${amount}_dollars_purchase` : 'custom'
      const params = {
        product_id: productId,
        amount,
        uid,
        include_services: includeServices,
        exclude_services: excludeServices
      }
      if (os) params.os = os

      const headers = { 'prx-af-id': prxAfId }
      const formDataResp = await axios.get(process.env.VUE_APP_ENOT_FORM_DATA_URL + '/mivion-form', {
        params, headers
      })
      if (formDataResp.data && formDataResp.data.redirectUrl) {
        return formDataResp.data
      }
    } catch (e) {
      await router.push({ name: 'Funds', query: { status: 'error' } })
    } finally {
      store.commit('funds/setAddFundsLoading', false)
    }
  }

  return { getEnotFormData, getMivionFormData }
}
