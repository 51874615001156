<template>
  <div class="stepper">
    <div class="container">
      <transition name="fade" appear>
        <TitleElement :tace="true">
          <h1 class="title__main">{{ $t('text_6167c4a5321c670152f0e1d3') }}</h1>
          <h2 v-html="chooseCountryH2" class="title__subtitle" />
        </TitleElement>
      </transition>

      <Steps :currentStep="2" />

      <CountriesSearch />

      <div class="stepper__buttons">
        <transition name="fade">
          <CountriesButtons />
        </transition>
      </div>
    </div>

    <StepperControl :currentStep="2" />
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import Steps from '@/components/Stepper/Steps.vue'
import CountriesSearch from '@/components/Search/CountriesSearch.vue'
import CountriesButtons from '@/components/Order/CountriesButtons.vue'
import StepperControl from '@/components/Stepper/StepperControl.vue'
import TitleElement from '@/components/TitleElement.vue'

export default {
  components: {
    Steps,
    CountriesSearch,
    CountriesButtons,
    StepperControl,
    TitleElement
  },
  computed: {
    chooseCountryH2 () {
      return this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e1d4'))
    },
    activeService () {
      return this.$store.state.order.service
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { addBreakLinesToText }
  },
  mounted () {
    if (!this.activeService) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/animation/fade-solo.scss';
</style>
