import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useStore } from 'vuex'

export function useBinance () {
  const { initApiInst, smsPaymentsApi } = useSmsApi()
  const { handleNotAuthorizedError, showCommonError } = useErrorHandlers()
  const store = useStore()

  const getBinancePayment = async (amount, fbUid, os = '', prxAfId = '') => {
    const binanceData = {
      amount,
      user_id: fbUid,
      currency: 'USDT'
    }
    if (os) binanceData.os = os
    try {
      await initApiInst()
      return await smsPaymentsApi.value.newBinancePayment({
        binance: binanceData,
        prxAfId
      })
    } catch (e) {
      store.commit('funds/setAddFundsLoading', false)
      if (e.status === 401) {
        handleNotAuthorizedError()
      } else {
        showCommonError(e)
      }
    }
  }

  return { getBinancePayment }
}
