export default {
  namespaced: true,
  state: {
    addFundsLoading: false,
    promoCode: '',
    promoCodeAmount: null
  },
  mutations: {
    setAddFundsLoading (state, loading) {
      state.addFundsLoading = loading
    },
    setPromoCodeData (state, payload) {
      state.promoCode = payload.promoCode
      state.promoCodeAmount = payload.promoCodeAmount
    },
    clearPromoCodeData (state) {
      state.promoCode = ''
      state.promoCodeAmount = null
    }
  }
}
