<template>
  <div class="create-account">
    <transition name="fade">
      <div>
        <TitleElement tace>
          <h1 class="auth-title__main title__main--sm title__main--pb0 mb-2">{{ $t('text_6167c4a5321c670152f0e2a1') }}</h1>
          <h2 v-html="allowToSaveHistoryText" class="auth-title__subtitle title__subtitle--sm" />
        </TitleElement>
        <CreateAccountBySocials />
        <div class="form__help-link">
          <component :is="{ template: termsOfServiceText }"></component>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import TitleElement from '@/components/TitleElement.vue'
import CreateAccountBySocials from '@/components/CreateAccount/CreateAccountBySocials/CreateAccountBySocials.vue'
import CreateAccountByEmail from '@/components/CreateAccount/CreateAccountByEmail/CreateAccountByEmail.vue'

export default {
  components: {
    TitleElement,
    CreateAccountBySocials,
    CreateAccountByEmail
  },
  computed: {
    allowToSaveHistoryText () {
      return this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e1f5'))
    },
    termsOfServiceText () {
      const textWithBrs = this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e1fb'))
      return this.addLinksToText(textWithBrs, ['Terms'])
    }
  },
  setup () {
    const { addBreakLinesToText, addLinksToText } = useTranslate()
    return { addBreakLinesToText, addLinksToText }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/animation/fade-pair.scss';

  .create-account {
    position: relative;
    width: 400px;

    .auth-title__main{
      line-height: 30px;
      letter-spacing: -0.2px;
    }
    .auth-title__subtitle{
      font-size: 14px;
      line-height: 24px;
      color: #14142B;
      opacity: 0.6;
    }
  }
</style>
