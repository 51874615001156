<template>
  <ul>
    <li v-for="item in items" :key="item.id">
      <CreateAccountItem
        @click="$emit('socialLogin', item.provider)"
        :id="item.id"
        :text="item.text"
        :imgName="item.imgName"
        :imgAlt="item.provider"
      />
    </li>
  </ul>
</template>

<script>
import CreateAccountItem from '@/components/CreateAccount/CreateAccountBySocials/CreateAccountItem.vue'

export default {
  components: {
    CreateAccountItem
  },
  props: {
    items: Array
  },
  emits: ['socialLogin']
}
</script>

<style lang="scss" scoped>

</style>
