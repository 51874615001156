import * as SmsActivationsApi from '@privatix/sms-sdk'
import { getAuth } from 'firebase/auth'

let activationApi = null
let userApi = null
let servicesApi = null
let utilsApi = null
let paymentsApi = null

class SmsApi {
  constructor () {
    this.defaultClient = SmsActivationsApi.ApiClient.instance
    this.defaultClient.basePath = process.env.VUE_APP_API_BASE_PATH
  }

  async updateFbToken () {
    const auth = getAuth()
    if (auth.currentUser) {
      this.defaultClient.authentications.jwt_auth.accessToken = await auth.currentUser.getIdToken()
    }
  }

  async activationApi () {
    if (!activationApi) {
      activationApi = new SmsActivationsApi.ActivationApi()
      activationApi.apiClient.defaultHeaders = {}
    }

    return activationApi
  }

  async userApi () {
    if (!userApi) {
      userApi = new SmsActivationsApi.UserApi()
      userApi.apiClient.defaultHeaders = {}
    }

    return userApi
  }

  async servicesApi () {
    if (!servicesApi) {
      servicesApi = new SmsActivationsApi.ServicesApi()
      servicesApi.apiClient.defaultHeaders = {}
    }

    return servicesApi
  }

  async utilsApi () {
    if (!utilsApi) {
      utilsApi = new SmsActivationsApi.UtilsApi()
      utilsApi.apiClient.defaultHeaders = {}
    }

    return utilsApi
  }

  async paymentsApi () {
    if (!paymentsApi) {
      paymentsApi = new SmsActivationsApi.PaymentsApi()
      paymentsApi.apiClient.defaultHeaders = {}
    }

    return paymentsApi
  }
}

export default SmsApi
