<template>
  <div class="funds container">
    <div class="funds__header">
      <TitleElement class="transaction__title title--mobile">
        <h1 class="title__main title__main--pb0">{{ $t('text_61a8d7461cd34e01670c871c') }}</h1>
        <h1 class="title__main title__main--pb0 title__main--mobile">{{ $t('text_61a8d71ebd4b2f01602c4031') }}</h1>
        <h2 class="title__subtitle">{{ $t('text_61a8d7461cd34e01670c8719') }}</h2>
        <h2 class="title__subtitle title__subtitle--mobile">{{ $t('text_61a8d71ebd4b2f01602c403b') }}</h2>
      </TitleElement>

      <div class="funds__balance">
        <div v-if="isAuthenticated && !$route.query.continue_url" class="big-card-balance">
          <div class="big-card-balance__info">
            <img src="@/assets/icons/money-bag.svg" alt="money-bag">
            <div class="big-card-balance__balance">${{ balance ? balance : '0.00' }}</div>
          </div>
          <p class="big-card-balance__text">{{ $t('text_61a8d7461cd34e01670c8718') }}</p>
        </div>
      </div>

      <div class="funds__payment-methods">
        <div
          v-for="(method, index) in userPaymentMethods"
          :key="index"
        >
          <div
            class="funds__payment-method"
            :class="{'funds__payment-method--active': method.id === currentPaymentMethod}"
            @click="selectPaymentMethod(method.id)"
          >
            <img :src="require(`@/assets/payment-methods/${method.name}.svg`)" :class="`img-${method.name}Img`" :alt="method.name">
          </div>
        </div>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="pageLoading">
        <PageLoader />
      </div>
      <div v-else class="funds__add-balance-wrap">
        <div v-if="currentPaymentMethod !== null" class="fund-amount-add">
          <div v-if="currentPaymentMethod === 0" class="fund-amount-add__title">{{ $t('text_61ade364a526e900e81f86ec') }}</div>
          <div v-if="currentPaymentMethod === 1" class="fund-amount-add__title">{{ $t('text_61ade3a93110030166f9f376') }}</div>
          <div v-if="currentPaymentMethod === 2" class="fund-amount-add__title">{{ $t('text_61ade364a526e900e81f86ee') }}</div>
          <div v-if="currentPaymentMethod === 3" class="fund-amount-add__title">{{ $t('text_61ade364a526e900e81f86f0') }}</div>
          <div v-if="currentPaymentMethod === 4" class="fund-amount-add__title">{{ $t('text_62459c30f6f1df00cfbcbe22') }}</div>
          <div v-if="currentPaymentMethod === 5" class="fund-amount-add__title">{{ $t('text_627be1630155d000e27cb4cc') }}</div>
          <div v-if="currentPaymentMethod === 6" class="fund-amount-add__title">{{ $t('text_627be1630155d000e27cb4d0') }}</div>
          <div v-if="currentPaymentMethod === 7" class="fund-amount-add__title">{{ $t('text_62a1ca9a32cde41aad6f8b07') }}</div>
          <div v-if="currentPaymentMethod === 8" class="fund-amount-add__title">{{ $t('text_629db93b4527a500b0e4205e') }}</div>
          <div v-if="currentPaymentMethod === 9" class="fund-amount-add__title">{{ $t('text_629db8484e8fa000ed6f8cb1') }}</div>
          <div v-if="currentPaymentMethod === 10" class="fund-amount-add__title">{{ $t('text_61ade364a526e900e81f86ee') }}</div>
          <div v-if="currentPaymentMethod === 11" class="fund-amount-add__title">{{ $t('text_61ade364a526e900e81f86ec') }}</div>
          <div v-if="currentPaymentMethod === 12" class="fund-amount-add__title">{{ $t('text_64cb804464921f005827b58c') }}</div>
        </div>

        <div v-if="currentPaymentMethod !== null" class="fund-amount">
          <ul class="fund-amount__items">
            <template v-if="currentPaymentMethod === 4">
              <li>
                <PromoCodeControl v-model="promoCode" class="fund-amount__input" />
              </li>
            </template>
            <template v-else>
              <li
                v-for="(amount, key) in prices"
                :key="key"
                class="fund-amount__item"
                :class="{'fund-amount__item--active': amount === currentAmount}"
                @click="selectAmount(amount)"
              >
                <p class="fund-amount__mobile-text">{{ $t('text_61a8d71ebd4b2f01602c4033').replace('__0__', amount).replace('__1__', `$${amount}`) }}</p>
                <p class="fund-amount__text">${{ amount }}</p>
                <div v-if="amount === recommendedPrice" class="fund-amount__recommended">{{ $t('text_61a8d71ebd4b2f01602c402f') }}</div>
              </li>

              <li v-if="currentPaymentMethod !== 1" class="fund-amount__separator">
                <div class="fund-amount__line">
                  <svg width="348" height="1" viewBox="0 0 348 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="0.5" x2="347.5" y2="0.5" stroke="#14142B" stroke-opacity="0.2" stroke-dasharray="5 5"/>
                  </svg>
                  <span>{{ $t('text_61a8d71ebd4b2f01602c403c') }}</span>
                </div>
              </li>

              <li v-if="currentPaymentMethod !== 1">
                <AmountControl
                  @click="selectAmount('custom')"
                  v-model="customAmountValue"
                  :active="currentAmount === 'custom'"
                  class="fund-amount__input"
                />
                <div v-if="recommendedPrice === 'custom'" class="fund-amount__recommended">{{ $t('text_61a8d71ebd4b2f01602c402f') }}</div>
              </li>
            </template>
          </ul>
        </div>

        <div v-show="currentPaymentMethod === 11" class="recaptcha-wrap">
          <div id="recaptcha" class="g-recaptcha"></div>
        </div>

        <div v-if="currentPaymentMethod !== null" class="funds__buttons container">
          <ButtonElement @click="addFunds" :loading="addFundsLoading" class="funds__add-button" fill >
            <img src="@/assets/icons/dollar_outlined.svg" alt="dollar">
            {{ $t('text_61a8d7461cd34e01670c871a') }}
          </ButtonElement>
          <ButtonElement v-if="!addFundsLoading && (currentPaymentMethod !== null || currentAmount !== null)" @click="clearData" class="funds__cancel-button">
            {{ $t('text_61a8d7461cd34e01670c8714') }}
          </ButtonElement>
        </div>

        <PayeerForm :payeerPay="payeerPay" :amount="amountValue" :uid="uid" :os="os" :afId="afId" />
        <CoinpaymentsForm :coinpaymentsPay="coinpaymentsPay" :amount="amountValue" :uid="uid" :email="email" />
        <EnotForm
          :enotPay="enotPay"
          :qiwiPay="qiwiPay"
          :perfectMoneyPay="perfectMoneyPay"
          :amount="amountValue"
          :uid="uid"
          :os="os"
          :afId="afId"
        />
        <MivionForm
          :mivionPay="mivionPay"
          :qiwiPay="qiwiPay"
          :perfectMoneyPay="perfectMoneyPay"
          :amount="amountValue"
          :uid="uid"
          :os="os"
          :afId="afId"
        />
        <Teleport to="#app">
          <div v-show="showBrickForm" @click="hideBrickForm" class="brickFormWrap">
            <div @click.stop id="brickForm" />
          </div>
        </Teleport>
        <OxprocessingForm
          :oxprocessingPay="oxprocessingPay"
          :currency="cryptoCurrency"
          :amount="amountValue"
          :uid="uid"
          :email="email"
          :os="os"
          :afId="afId"
        />
      </div>
    </transition>
  </div>

  <div v-if="mobileAppPaymentImitation" class="mobilePaymentImitation">
    <button @click="imitateMobPayment('success')">Success</button>
    <button @click="imitateMobPayment('successCrypto')">Success by crypto</button>
    <button @click="imitateMobPayment('error')">Payment error</button>
  </div>

  <QuestionModal v-if="redeemPromoCodeModal" @decline="redeemPromoCodeModal = false">
    <template v-slot:title>{{ $t('text_62459c30f6f1df00cfbcbe25') }}</template>
    <template v-slot:text>{{ promoCodeModalText }}</template>
    <template v-slot:acceptBtn>
      <ButtonElement @click="redeemPromoCode" :loading="addFundsLoading" class="modal__button modal__button-accept" fill height="58px">
        {{ $t('text_62459c30f6f1df00cfbcbe2a') }}
      </ButtonElement>
    </template>
    <template v-slot:declineBtn>{{ $t('text_62459c30f6f1df00cfbcbe2c') }}</template>
  </QuestionModal>
  <QuestionModal v-if="selectCryptoCurrencyModal" @decline="hideCryptoCurrencyModal">
    <template v-slot:title>{{ $t('text_62aae8f9b01ff38a84096687') }}</template>
    <template v-slot:text>
      <CryptoCurrencySelect @selectCryptoCurrency="selectCryptoCurrency"/>
      <div v-if="showCryptoCurrencyError" class="control__error ">{{ $t('text_62b2bbd98e496800629a91de') }}</div>
    </template>
    <template v-slot:acceptBtn>
      <ButtonElement @click="payBy0xprocessing" :loading="addFundsLoading" class="modal__button modal__button-accept" fill height="58px">
        {{ $t('text_62aae8f9b01ff38a8409668b') }}
      </ButtonElement>
    </template>
    <template v-slot:declineBtn>{{ $t('text_62aae8f9b01ff38a8409668d') }}</template>
  </QuestionModal>
  <StripeModal v-if="stripeModal" @decline="cancelStripePayment" :paymentIntent="stripePaymentIntent" :email="email">
    <template v-slot:declineBtn>{{ $t('text_62aae8f9b01ff38a8409668d') }}</template>
  </StripeModal>
</template>

<script>
import { getAuth } from 'firebase/auth'
import { useSmsApi } from '@/use/smsApi'
import { useBalance } from '@/use/balance'
import { useUserCountry } from '@/use/userCountry'
import { usePaddle } from '@/use/payments/paddle'
import { useStripe } from '@/use/payments/stripe'
import { usePaymentwall } from '@/use/payments/paymentwall'
import { useBrick } from '@/use/payments/brick'
import { useBinance } from '@/use/payments/binance'
import { useUser } from '@/use/user'
import { useTranslate } from '@/use/translate'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useLoadScript } from '@/use/loadScript'
import AmountControl from '@/components/Controls/AmountControl.vue'
import CoinpaymentsForm from '@/components/Payments/Forms/Coinpayments.vue'
import PayeerForm from '@/components/Payments/Forms/Payeer.vue'
import EnotForm from '@/components/Payments/Forms/Enot.vue'
import MivionForm from '@/components/Payments/Forms/Mivion.vue'
import OxprocessingForm from '@/components/Payments/Forms/Oxprocessing.vue'
import ButtonElement from '@/components/ButtonElement.vue'
import TitleElement from '@/components/TitleElement.vue'
import PromoCodeControl from '@/components/Controls/PromoCodeControl.vue'
import QuestionModal from '@/components/Modals/QuestionModal'
import PageLoader from '@/components/Loaders/PageLoader.vue'
import CryptoCurrencySelect from '@/components/Payments/CryptoCurrencySelect.vue'
import StripeModal from '@/components/Modals/StripeModal.vue'

export default {
  components: {
    AmountControl,
    CoinpaymentsForm,
    PayeerForm,
    EnotForm,
    MivionForm,
    OxprocessingForm,
    ButtonElement,
    TitleElement,
    PromoCodeControl,
    QuestionModal,
    PageLoader,
    CryptoCurrencySelect,
    StripeModal
  },
  data () {
    return {
      mobileAppActivationPrice: null,
      mobileAppUserId: '',
      mobileAppUserEmail: '',
      uid: '',
      email: '',
      os: '',
      afId: '',
      prices: JSON.parse(process.env.VUE_APP_PRICES),
      currentPaymentMethod: null,
      recommendedPrice: null,
      amountValue: 0,
      currentAmount: null,
      customAmountValue: null,
      promoCode: '',
      minPayAmount: parseInt(process.env.VUE_APP_MIN_PAY_AMOUNT, 10),
      maxPayAmount: parseInt(process.env.VUE_APP_MAX_PAY_AMOUNT, 10),
      cryptoMinPaymentAmount: 25,
      paymentMethods: [
        { id: 0, name: 'paddle' },
        { id: 1, name: 'paypal' },
        { id: 2, name: 'crypto' },
        { id: 3, name: 'payeer' },
        { id: 4, name: 'promoCode' },
        { id: 5, name: 'enot' },
        { id: 6, name: 'qiwi' },
        { id: 7, name: 'perfectMoney' },
        { id: 8, name: 'paymentwall' },
        { id: 9, name: 'brick' },
        { id: 10, name: '0xprocessing' },
        { id: 11, name: 'stripe' },
        { id: 12, name: 'binancePay' }
      ],
      userPaymentMethods: [],
      activateAfterBalanceUpdated: false,
      coinpaymentsPay: false,
      oxprocessingPay: false,
      payeerPay: false,
      enotPay: false,
      mivionPay: false,
      qiwiPay: false,
      perfectMoneyPay: false,
      mobileAppPaymentImitation: parseInt(process.env.VUE_APP_SHOW_MOB_PAYMENT_IMITATION_BUTTONS, 10),
      redeemPromoCodeModal: false,
      promoCodeModalText: '',
      pageLoading: true,
      selectCryptoCurrencyModal: false,
      cryptoCurrency: null,
      showCryptoCurrencyError: false,
      stripeModal: false,
      stripePaymentIntent: null,
      recaptchaToken: '',
      recaptchaWidgetId: null
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.auth.isAuthenticated
    },
    balance () {
      return this.$store.state.user.balance
    },
    orderPrice () {
      return this.$store.state.order.price
    },
    addFundsLoading () {
      return this.$store.state.funds.addFundsLoading
    },
    showBrickForm () {
      return this.$store.state.common.showBrickForm
    },
    paymentErrorText () {
      const textWithBrs = this.addBreakLinesToText(this.$t('text_61b30e273fead60105540986'))
      return this.addCrispChatLink(textWithBrs, 'Funds')
    },
    forceEmailConfirmation () {
      return this.$store.state.common.forceEmailConfirmation
    }
  },
  watch: {
    $route (to, from) {
      if (to.name !== 'Funds') {
        localStorage.removeItem('mobileAppPaymentContinueUrl')
      }
      this.handlePaymentStatus()
    },
    currentPaymentMethod (val) {
      if (val === 1) {
        this.currentAmount = this.customAmountValue = null
      }
    },
    balance (val) {
      if (val >= this.orderPrice && this.activateAfterBalanceUpdated) {
        this.$router.push({ name: 'Activating' })
      }
    },
    selectCryptoCurrencyModal (val) {
      if (!val) {
        this.showCryptoCurrencyError = false
        this.cryptoCurrency = null
      }
    },
    cryptoCurrency (val) {
      if (val) this.showCryptoCurrencyError = false
    },
    pageLoading (val) {
      if (!val) {
        this.initReCaptchaV2()
      }
    }
  },
  setup () {
    const { restartCheckBalance } = useBalance()
    const { getUserCountry } = useUserCountry()
    const { initPaddle, addPaddleFunds } = usePaddle()
    const { getPaymentIntent } = useStripe()
    const { getPaymentwallWidgetUrl } = usePaymentwall()
    const { initBrick, addBrickFunds, hideBrickForm } = useBrick()
    const { getBinancePayment } = useBinance()
    const { isUserOnHold, isUserEmailVerified, getGaClientId } = useUser()
    const { initApiInst, smsUserApi, smsPaymentsApi } = useSmsApi()
    const { addBreakLinesToText, addCrispChatLink } = useTranslate()
    const { handleNotAuthorizedError, showCommonError } = useErrorHandlers()
    const { loadScript } = useLoadScript()
    return {
      restartCheckBalance,
      getUserCountry,
      initPaddle,
      addPaddleFunds,
      getPaymentwallWidgetUrl,
      initBrick,
      addBrickFunds,
      hideBrickForm,
      getBinancePayment,
      isUserOnHold,
      isUserEmailVerified,
      getGaClientId,
      initApiInst,
      smsUserApi,
      smsPaymentsApi,
      addBreakLinesToText,
      addCrispChatLink,
      getPaymentIntent,
      handleNotAuthorizedError,
      showCommonError,
      loadScript
    }
  },
  async mounted () {
    this.getUserPaymentMethods()
    this.restartCheckBalance()
    this.handlePromoCodeLink()
    this.handlePaymentStatus()
    this.initMobileAppPayment()
    this.initPaymentState()
  },
  methods: {
    async getUserPaymentMethods () {
      let userPaymentMethodsArr = []
      const userCountry = await this.getUserCountry()
      const paymentsGroups = JSON.parse(process.env.VUE_APP_PAYMENTS_SEGMENTATION)
      if (userCountry) {
        Object.keys(paymentsGroups).map((key) => {
          if (key !== 'default') {
            const group = paymentsGroups[key]
            if (group.countries.includes(userCountry)) {
              userPaymentMethodsArr = group.paysystems
            }
          }
        })
      }
      if (userPaymentMethodsArr.length === 0) {
        userPaymentMethodsArr = paymentsGroups.default.paysystems
      }

      // Do not show Stripe on mobile payments
      if (this.$route.query.user_id && this.$route.query.continue_url) {
        userPaymentMethodsArr = userPaymentMethodsArr.filter(item => item !== 'stripe')
      }

      userPaymentMethodsArr.map(userPaymentMethod => {
        const paymentMethod = this.paymentMethods.find(item => item.name === userPaymentMethod)
        this.userPaymentMethods.push(paymentMethod)
      })
      this.pageLoading = false
    },
    async handlePaymentStatus () {
      if (this.$route.query.status) {
        const mobileAppPaymentContinueUrl = localStorage.getItem('mobileAppPaymentContinueUrl')
        localStorage.removeItem('mobileAppPaymentContinueUrl')
        if (this.$route.query.status === 'success') {
          const routeParams = this.$route.query
          if (routeParams.payment_intent && routeParams.payment_gateway && routeParams.payment_gateway === 'stripe') {
            await this.handleStripeSuccessPayment(routeParams.payment_intent)
            if (this.balance >= this.orderPrice && this.activateAfterBalanceUpdated) {
              this.$router.push({ name: 'Activating' })
              return
            }
          } else {
            this.handleSuccessPaymentStatus()
          }
        } else if (this.$route.query.status === 'error') {
          if (mobileAppPaymentContinueUrl) {
            location.href = mobileAppPaymentContinueUrl + '?status=error'
          } else {
            this.coinpaymentsPay = false
            this.payeerPay = false
            this.enotPay = false
            this.mivionPay = false
            this.qiwiPay = false
            this.perfectMoneyPay = false
            if (this.$route.query.type === 'promoCode') {
              this.$store.commit('alert/add', {
                id: Date.now(),
                text: this.$t('general_payment_error'),
                timeout: 3000,
                name: 'redeem_promo_code_error'
              })
            } else {
              this.showPaymentErrorStatus()
            }
          }
        }

        await this.$router.push({ name: 'Funds' })
      } else {
        localStorage.removeItem('mobileAppPaymentContinueUrl')
      }
    },
    handleSuccessPaymentStatus () {
      const mobileAppPaymentContinueUrl = localStorage.getItem('mobileAppPaymentContinueUrl')
      this.moveOrderDataToStoreFromLocalStorage()

      this.clearData()
      this.restartCheckBalance()
      if (this.$store.state.order.forceActivation) {
        if (this.$route.query.type && this.$route.query.type === 'crypto') {
          this.showCryptoSuccessModal()
        } else {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('text_61b30ba551843101376b7b8d'),
            type: 'success',
            timeout: 5000,
            name: 'payment_successful'
          })
        }
        this.activateAfterBalanceUpdated = true
      } else {
        if (this.$route.query.type && this.$route.query.type === 'crypto') {
          if (mobileAppPaymentContinueUrl) {
            location.href = mobileAppPaymentContinueUrl + '?status=success&type=crypto'
          } else {
            this.showCryptoSuccessModal()
          }
        } else {
          if (mobileAppPaymentContinueUrl) {
            location.href = mobileAppPaymentContinueUrl + '?status=success'
          } else {
            this.$store.commit('notificationModal/show', {
              title: this.$t('text_61b3138c5ce0b801458e85c6'),
              text: this.$t('text_61b3138c5ce0b801458e85ce'),
              buttonText: this.$t('text_61b3138c5ce0b801458e85ca'),
              buttonRouteName: 'Home',
              button2Text: this.$t('text_61b3138c5ce0b801458e85cc')
            })
          }
        }
      }
      localStorage.removeItem('order')
    },
    async handleStripeSuccessPayment (paymentIntent) {
      try {
        await this.initApiInst()
        const resp = await this.smsPaymentsApi.getPaymentIntentStatus({
          id: paymentIntent
        })
        if (resp?.status === 'review') {
          this.$store.commit('order/clearOrder')
          this.$store.commit('payment/setPaymentOnReview', true)
        } else if (resp?.status === 'paid') {
          this.handleSuccessPaymentStatus()
        } else {
          this.showPaymentErrorStatus()
        }
      } catch (e) {
        this.$store.commit('order/clearOrder')
        if (e.status === 401) {
          this.handleNotAuthorizedError()
        } else if (e.status === 404) {
          this.$store.commit('payment/setPaymentOnReview', true)
        } else {
          this.showCommonError(e)
        }
      }
    },
    moveOrderDataToStoreFromLocalStorage () {
      const localStorageOrder = localStorage.getItem('order')
      if (localStorageOrder) {
        const orderData = JSON.parse(localStorageOrder)
        this.$store.commit('order/setOrderData', orderData)
      }
    },
    initPaymentState () {
      if (this.$store.state.order.forceActivation || this.mobileAppActivationPrice) {
        this.currentPaymentMethod = 0
        const recommendedPrice = this.prices.find(price => price > (this.mobileAppActivationPrice ? this.mobileAppActivationPrice : this.orderPrice))
        if (!recommendedPrice) {
          this.recommendedPrice = this.currentAmount = 'custom'
        } else {
          this.recommendedPrice = this.currentAmount = recommendedPrice
        }
      } else {
        this.recommendedPrice = this.prices[1]
      }
    },
    selectAmount (amount) {
      this.currentAmount = amount
    },
    selectPaymentMethod (method) {
      this.prices = JSON.parse(process.env.VUE_APP_PRICES)
      this.minPayAmount = parseInt(process.env.VUE_APP_MIN_PAY_AMOUNT, 10)
      if (method === 2 || method === 10) {
        // set amount params for crypto
        this.prices.shift()
        this.minPayAmount = this.cryptoMinPaymentAmount
      }
      this.currentPaymentMethod = method
    },
    clearData () {
      this.currentPaymentMethod = this.currentAmount = this.customAmountValue = null
    },
    async showForceEmailConfirmModal () {
      if (this.forceEmailConfirmation && !this.isUserEmailVerified()) {
        await this.$store.commit('modal/toggleForceEmailConfirmationModal', false)
        await this.$store.commit('modal/toggleForceEmailConfirmationModal', true)
        return true
      }
      return false
    },
    async addFunds () {
      if (await this.showForceEmailConfirmModal()) return

      if (this.currentPaymentMethod === 4) {
        this.handlePromoCode()
        return
      }

      if (this.currentAmount === null) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: this.$t('text_61af15790d5c8800cd44123c'),
          timeout: 3000,
          name: 'please_select_plan'
        })
        return
      }

      if (this.currentPaymentMethod === 11 && !this.recaptchaToken) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Please complete the reCAPTCHA',
          timeout: 3000,
          name: 'recaptcha_required'
        })
        return
      }

      this.$store.commit('funds/setAddFundsLoading', true)
      if (!localStorage.getItem('mobileAppPaymentContinueUrl')) {
        const isUserOnHold = await this.isUserOnHold()
        if (isUserOnHold) {
          this.$store.commit('funds/setAddFundsLoading', false)
          return
        }
      }

      this.getAmount()
      if (this.amountValue) {
        this.uid = this.getUserId()
        if (!this.uid) {
          this.redirectForLogin()
          return
        }
        this.email = this.getUserEmail()
        this.os = this.getOs()
        this.afId = this.getAfId()
        if (this.currentPaymentMethod === 0 || this.currentPaymentMethod === 1) {
          // Temporary disable Paddle init
          this.showCommonError(new Error(''))
          this.$store.commit('funds/setAddFundsLoading', false)
        } else if (this.currentPaymentMethod === 2) {
          this.coinpaymentsPay = true
        } else if (this.currentPaymentMethod === 3) {
          this.payeerPay = true
        } else if (this.currentPaymentMethod === 5) {
          this.mivionPay = true
        } else if (this.currentPaymentMethod === 6) {
          this.mivionPay = true
          this.qiwiPay = true
        } else if (this.currentPaymentMethod === 7) {
          this.mivionPay = true
          this.perfectMoneyPay = true
        } else if (this.currentPaymentMethod === 8) {
          const widgetUrl = await this.getPaymentwallWidgetUrl(this.amountValue, this.uid, this.email)
          location.href = widgetUrl
        } else if (this.currentPaymentMethod === 9) {
          await this.initBrick()
          this.addBrickFunds(this.amountValue, this.uid, this.email)
        } else if (this.currentPaymentMethod === 10) {
          this.selectCryptoCurrencyModal = true
          this.$store.commit('funds/setAddFundsLoading', false)
        } else if (this.currentPaymentMethod === 11) {
          this.stripePaymentIntent = await this.getPaymentIntent(this.amountValue, this.os, this.afId, this.recaptchaToken)
          this.resetRecaptcha()
          if (this.stripePaymentIntent && this.stripePaymentIntent.clientSecret) {
            this.stripeModal = true
          }
        } else if (this.currentPaymentMethod === 12) {
          const binancePayment = await this.getBinancePayment(this.amountValue, this.uid, this.os, this.afId)
          if (binancePayment && binancePayment.checkoutUrl) {
            location.href = binancePayment.checkoutUrl
          }
        }
      } else {
        this.$store.commit('funds/setAddFundsLoading', false)
      }
    },
    getAmount () {
      const amount = this.currentAmount === 'custom' ? parseInt(this.customAmountValue, 10) : parseInt(this.currentAmount, 10)
      if (amount >= this.minPayAmount && amount <= this.maxPayAmount) {
        this.amountValue = amount
      } else {
        const warnText = amount < this.minPayAmount || amount > this.maxPayAmount || isNaN(amount)
          ? this.$t('text_61baddd222c04d01709d06cc').replace('__0__', `$${this.minPayAmount}`).replace('__1__', `$${this.maxPayAmount}`)
          : this.$t('general_error_title')
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: warnText,
          timeout: 2500,
          name: 'get_amount_warning'
        })
        this.amountValue = 0
        this.$store.commit('funds/setAddFundsLoading', false)
      }
    },
    showCryptoSuccessModal () {
      this.$store.commit('notificationModal/show', {
        title: this.$t('text_6200f409bd91d200c5076c05'),
        text: this.$t('text_6200f409bd91d200c5076c11'),
        buttonText: this.$t('text_6200f409bd91d200c5076c0d')
      })
    },
    initMobileAppPayment () {
      if (!this.isAuthenticated && (!this.$route.query.user_id || !this.$route.query.continue_url)) {
        this.redirectForLogin()
      }
      // Init mobile apps payment if exists route params: continue_url, user_id
      if (this.$route.query.continue_url && this.$route.query.user_id) {
        localStorage.setItem('mobileAppPaymentContinueUrl', this.$route.query.continue_url)
        this.mobileAppUserId = this.$route.query.user_id
        if (this.$route.query.activation_price) {
          this.mobileAppActivationPrice = parseFloat(this.$route.query.activation_price)
        }
        if (this.$route.query.email) {
          this.mobileAppUserEmail = this.$route.query.email
        }
      }
    },
    redirectForLogin () {
      this.$store.commit('alert/add', {
        id: Date.now(),
        text: this.$t('auth_needed_error_login_signup'),
        timeout: 3000,
        name: 'auth_needed_error_login_signup'
      })
      this.$router.push({ name: 'CreateAccount' })
    },
    getUserId () {
      const auth = getAuth()
      return this.mobileAppUserId ? this.mobileAppUserId : (auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : '')
    },
    getUserEmail () {
      const auth = getAuth()
      return this.mobileAppUserEmail ? this.mobileAppUserEmail : (auth.currentUser && auth.currentUser.email ? auth.currentUser.email : '')
    },
    getOs () {
      return this.$route.query.continue_url && this.mobileAppUserId ? (this.$route.query.os || '') : 'web'
    },
    getAfId () {
      return this.$route.query.continue_url && this.mobileAppUserId ? (this.$route.query.af_id || '') : this.getGaClientId()
    },
    handlePromoCodeLink () {
      if (this.$route.query.promo_code || this.$store.state.funds.promoCode) {
        if (this.$route.query.promo_code) {
          this.promoCode = this.$route.query.promo_code
        } else if (this.$store.state.funds.promoCode) {
          this.promoCode = this.$store.state.funds.promoCode
        }
        if (!this.isAuthenticated) {
          if (this.promoCode) {
            const promoCodeAmount = this.$route.query.promo_code_amount ? this.$route.query.promo_code_amount : null
            this.$store.commit('funds/setPromoCodeData', {
              promoCode: this.promoCode,
              promoCodeAmount
            })
          }
          this.redirectForLogin()
          return
        }
        this.handlePromoCode()
      }
    },
    handlePromoCode () {
      this.$store.commit('funds/clearPromoCodeData')
      if (this.promoCode === '') {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: this.$t('text_624ec9fff64853017660e695'),
          timeout: 3000,
          name: 'please_enter_promo_code'
        })
        return
      }
      if (this.$route.query.promo_code_amount || this.$store.state.funds.promoCodeAmount) {
        const promoCodeAmount = this.$route.query.promo_code_amount ? this.$route.query.promo_code_amount
          : (this.$store.state.funds.promoCodeAmount ? this.$store.state.funds.promoCodeAmount : null)
        this.promoCodeModalText = this.$t('text_62459c30f6f1df00cfbcbe2e').replace('__0__', promoCodeAmount)
      } else {
        this.promoCodeModalText = this.$t('text_62459c30f6f1df00cfbcbe2e').replace('$__0__ ', '')
      }
      this.redeemPromoCodeModal = true
    },
    async redeemPromoCode () {
      this.uid = this.getUserId()
      if (!this.uid) {
        this.redirectForLogin()
        return
      }
      try {
        this.$store.commit('funds/setAddFundsLoading', true)
        await this.initApiInst()
        const resp = await this.smsUserApi.redeemPromoCode(this.uid, this.promoCode, { prxPrint: this.$store.state.common.prxPrint })
        if (resp && resp.result === 'success') {
          this.clearPromoCodeState()
          await this.$router.push({ name: 'Funds', query: { status: 'success' } })
        }
      } catch (e) {
        this.clearPromoCodeState()
        await this.$router.push({ name: 'Funds', query: { status: 'error', type: 'promoCode' } })
      }
    },
    clearPromoCodeState () {
      this.promoCode = ''
      this.redeemPromoCodeModal = false
      this.$store.commit('funds/setAddFundsLoading', false)
    },
    selectCryptoCurrency (currency) {
      this.cryptoCurrency = currency
    },
    payBy0xprocessing () {
      if (this.cryptoCurrency) {
        this.$store.commit('funds/setAddFundsLoading', true)
        this.oxprocessingPay = true
      } else {
        this.showCryptoCurrencyError = true
      }
    },
    cancelStripePayment () {
      this.stripeModal = false
      this.stripePaymentIntent = null
      this.$store.commit('funds/setAddFundsLoading', false)
    },
    hideCryptoCurrencyModal () {
      this.selectCryptoCurrencyModal = false
      this.$store.commit('funds/setAddFundsLoading', false)
    },
    imitateMobPayment (type) {
      const mobileAppPaymentContinueUrl = localStorage.getItem('mobileAppPaymentContinueUrl')
      if (!mobileAppPaymentContinueUrl) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Please provide ContinueUrl',
          timeout: 5000
        })
      } else {
        switch (type) {
          case 'successCrypto':
            location.href = mobileAppPaymentContinueUrl + '?status=success&type=crypto'
            break
          case 'success':
            location.href = mobileAppPaymentContinueUrl + '?status=success'
            break
          case 'error':
            location.href = mobileAppPaymentContinueUrl + '?status=error'
            break
        }
      }
    },
    showPaymentErrorStatus () {
      this.$store.commit('notificationModal/show', {
        title: this.$t('text_61b30e273fead60105540980'),
        text: this.paymentErrorText,
        buttonText: this.$t('text_61b30e273fead60105540984')
      })
    },
    async initReCaptchaV2 () {
      if (!document.getElementById('recaptcha')) {
        setTimeout(() => {
          this.initReCaptchaV2()
        }, 500)
        return
      }
      try {
        if (window.grecaptcha && window.grecaptcha.render) {
          this.renderReCaptcha()
        } else {
          await this.loadScript('https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit')
          window.onRecaptchaLoad = () => this.renderReCaptcha()
        }
      } catch (e) {
        console.log('Error loading reCaptcha', e)
      }
    },
    renderReCaptcha () {
      this.recaptchaWidgetId = window.grecaptcha.render('recaptcha', {
        'sitekey': process.env.VUE_APP_RECAPTCHA_V2_SITE_KEY ?? '',
        'callback': (response) => {
          this.recaptchaToken = response
        },
        'expired-callback': () => {
          this.recaptchaToken = ''
        },
        'error-callback': () => {
          this.recaptchaToken = ''
        }
      })
    },
    resetRecaptcha () {
      if (this.recaptchaWidgetId !== null) {
        window.grecaptcha.reset(this.recaptchaWidgetId)
        this.recaptchaToken = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/animation/fade-history.scss';

  .message-modal {
    img {
      width: 64px;
      height: 64px;
      margin-bottom: 32px;
    }
  }
  .funds {
    margin-top: 65px;

    .title__main {
      margin-bottom: 8px;
    }
    .title__subtitle {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__balance {
      min-height: 190px;
    }

    &__payment-methods {
      position: relative;
      top: -90px;
      max-width: 800px;
      display: flex;
      flex-wrap:wrap
    }

    &__payment-method {
      cursor: pointer;
      margin-top: 20px;
      margin-right: 26px;
      width: 166px;
      height: 94px;
      display: flex;
      justify-content: center;
      align-content: center;
      border: 2px solid rgba(25, 22, 50, 0.12);
      border-radius: 13.8373px;

      &--active {
        border: 2px solid #FA8231;;
      }

      .img-paddleImg,
      .img-stripeImg {
        width: 78%;
      }
      .img-paypalImg {
        width: 40%;
      }
      .img-cryptoImg,
      .img-0xprocessingImg {
        width: 65%;
      }
      .img-payeerImg {
        width: 70%;
      }
      .img-promoCodeImg {
        width: 40%;
      }
      .img-enotImg {
        width: 64%;
      }
      .img-qiwiImg {
        width: 67%;
      }
      .img-perfectMoneyImg {
        width: 80%;
      }
      .img-paymentwallImg {
        width: 76%;
      }
      .img-binancePayImg {
        width: 81%;
      }
      .img-brickImg {
        width: 78%;
      }
    }

    &__buttons {
      margin-top: 88px;
      margin-bottom: 60px;
      display: flex;
      position: relative;
      left: -24px;
    }

    &__add-button{
      width: 220px;
      height: 64px;
      margin-right: 16px;

      img {
        margin-right: 10px;
      }
    }

    &__cancel-button {
      width: 151px;
      height: 64px;
    }
  }

  .rtl{
    .funds{
      &__payment-method {
        margin-left: 26px;
        margin-right: 0;
      }

      &__buttons {
        right: -24px;
        left: auto;
      }

      &__add-button{
        margin-left: 16px;
        margin-right: 0;

        img {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }

  .big-card-balance {
    max-height: 194px;
    padding: 60px 110px;
    padding-top: 50px;
    background: #F8F8F8;
    border-radius: 20px;
    color: #14142B;

    &__info {
      display: flex;
      margin-bottom: 8px;
    }

    &__text {
      font-size: 22px;
      text-align: center;
      letter-spacing: -0.2px;
    }

    &__balance {
      margin-left: 15px;
      font-weight: 500;
      font-size: 35px;
      letter-spacing: -0.2px;
    }
  }

  .rtl{
    .big-card-balance {
      &__balance {
        margin-right: 15px;
        margin-left: 0;
      }
    }
  }

  .fund-amount-add {
    display: flex;
    position: relative;
    top: -50px;
    &__title {
      margin-right: 16px;
      font-size: 18px;
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
    }
  }

  .rtl{
    .fund-amount-add {
      &__title {
        margin-left: 16px;
        margin-right: 0;
      }
    }
  }

  .selector {
    position: relative;
    &__title {
      cursor: pointer;

      &--open {
        transform: rotateX(180deg);
      }

      span {
        margin-right: 10px;
        font-size: 18px;
        color: #FA8231;
        border-bottom: 1px solid #FA8231;
      }
    }

    &__items {
      z-index: 2;
      top: calc(100% - 5px);
      position: absolute;
      background: #fff;
      padding: 3px;
    }

    &__item {
      cursor: pointer;

      &:hover {
        color: #FA8231;
      }
    }
  }

  .rtl{
    .selector {
      &__title {
        span {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }

  .fund-amount {
    position: relative;
    top: -26px;

    &__separator {
      display: none;
    }

    &__line {
      position: relative;
      max-width: 100%;
      margin: 25px auto 0px auto;
      height: 1px;

      svg {
        width: 100%;
        position: absolute;
        top: 0;
      }

      span {
        top: -8px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 500;
        font-size: 12px;
        color: rgba(20, 20, 43, 0.6);
        padding: 0 7px;
        background: #FFF;
      }
    }

    &__items {
      display: flex;
      li {
        width: 100%;
        position: relative;
      }
    }

    &__mobile-text {
      display: none;
    }

    &__item {
      cursor: pointer;
      max-width: 112px;
      font-size: 18px;
      margin-right: 18px;
      padding: 24px 10px;
      border: 1px solid rgba(25, 22, 50, 0.12);
      border-radius: 15px;

      &--active {
        border: 1px solid #FA8231;;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__text {
      text-align: center;
    }

    &__recommended {
      display: block;
      position: absolute;
      left: 6px;
      bottom: -46px;
      font-size: 10px;
      line-height: 13px;
      font-weight: 300;
      background: #FFC100;
      border-radius: 4px;
      padding: 7px 13px 8px;
      margin-left: 0;
    }

    &__input {
      max-width: 238px;
    }
  }

  .rtl{
    .fund-amount {
      &__item {
        margin-left: 18px;
        margin-right: 0;

        &:last-child {
          margin-left: 0;
        }
      }

      &__recommended {
        right: 6px;
        left: auto;
        margin-right: 0;
      }
    }
  }

  .brickFormWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;

    #brickForm {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #FFFFFF;
      text-align: center;
    }
  }

  .mobilePaymentImitation{
    margin: 40px 30px 150px;
    button {
      display: block;
      margin: 10px;
      padding: 10px;
    }
  }

  .recaptcha-wrap{
    margin-top: 50px;
  }

  @media (max-width: 1200px) {
    .funds {
      &__payment-methods {
        top: 0;
      }
      &__balance {
        min-height: 0;
      }
    }

    .fund-amount-add {
      margin-top: 40px;
      top: 0;
    }

    .fund-amount {
      top: 0;

      &__item {
        margin-top: 22px;
      }

      &__input {
        margin-top: 22px;
      }
    }

    .big-card-balance {
      width: 150px;
      max-height: 60px;
      padding: 17px 20px;
      border-radius: 15px;

      &__info {
        margin-bottom: 0;
        justify-content: center;

        img {
          display: none;
        }
      }

      &__text {
        display: none;
      }

      &__balance {
        margin-left: 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  @media (max-width: 810px) {
    .funds__payment-methods {
      min-width: 100%;
    }

    .fund-amount {
      &__items {
        flex-wrap: wrap;
      }
      &__item {
        display: flex;
        margin-right: 0;
        max-width: 100%;
        height: 60px;
        margin-top: 16px;
        padding: 15px 14px;
        font-size: 14px;
        position: relative;

        &:first-child {
          margin-top: 12px;
        }

        &:before {
          position: absolute;
          content: '';
          width: 18px;
          height: 18px;
          border: 1px solid #FA8231;
          border-radius: 50%;
          top: 19px;
          right: 20px;
        }

        &--active {
          position: relative;

          &:before {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            border: none;
            background-image: url('../assets/icons/active.svg');
            background-size: cover;
            border-radius: 50%;
            top: 20px;
            right: 20px;
          }
        }
      }

      &__recommended {
        margin-left: 8px;
        display: inline-block;
        position: relative;
        bottom: auto;
        left: auto;
      }

      &__input {
        max-width: 100%;
      }

      &__mobile-text {
        align-self: center;
        display: block;
      }

      &__text {
        display: none;
      }

      &__separator {
        display: block;
      }
    }

    .rtl{
      .fund-amount {
        &__item {
          margin-left: 0;

          &:before {
            left: 20px;
            right: auto;
          }

          &--active {
            &:before {
              left: 20px;
              right: auto;
            }
          }
        }

        &__recommended {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 590px) {
    .fund-amount {
      &__items {
        flex-direction: column;
      }
    }

    .funds {
      margin-top: 0;
      .title__main--mobile {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.2px;
        padding-bottom: 0;
      }
      .title__subtitle--mobile {
        font-size: 14px;
        line-height: 24px;
      }

      &__add-balance-wrap {
        margin-bottom: 160px;
      }

      &__payment-methods {
        margin-top: 22px;
        padding-top: 29px;
        border-top: 1px solid rgba(25, 22, 50, 0.12);
      }

      &__buttons {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 40px;
        background: #fff;
        position: fixed;
        justify-content: center;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &__cancel-button {
        display: none;
      }

      &__add-button {
        margin-right: 0;
        height: 58px;
        width: 100%;
      }

      &__payment-method {
        margin-top: 10px;
        margin-right: 10px;
        width: 100px;
        height: 56px;
      }

      .fund-amount-add {
        margin-top: 33px;
        &__title {
          font-weight: normal;
          font-size: 14px;
          line-height: 28px;
          margin-right: 0;
        }
      }
    }

    .rtl{
      .funds {
        &__buttons {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 10px;
          padding-bottom: 40px;
          background: #fff;
          position: fixed;
          justify-content: center;
          bottom: 0;
          left: 0;
          right: 0;
        }

        &__add-button {
          margin-left: 0;
        }

        &__payment-method {
          margin-left: 10px;
          margin-right: 0;
        }

        .fund-amount-add {
          &__title {
            margin-left: 0;
          }
        }
      }
    }
  }
  .modal {
    &__button-accept {
      background: #FA8231;
      color: #fff;
    }
  }

  @media (max-width: 360px) {
    .funds__payment-methods {
      justify-content: center;
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .funds {
      &.container {
        margin-top: 40px;
      }
      &__buttons {
        margin-top: 60px;
      }
    }
  }

  @media (min-width: 1200px) {
    .funds__header{
      .title{
        min-width: 768px;
      }
    }
  }
</style>
