<template>
  <div class="success-bl">
    <img :src="require(`@/assets/success.gif`) + `?ts=${Date.now()}`" alt="success">
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false
    }
  },
  mounted () {
    this.show = true
  }
}
</script>

<style lang="scss" scoped>
  .success-bl {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 0 40px;
    img{
      width: 42px;
      height: 42px;
    }
  }
</style>
