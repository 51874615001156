import { reactive, toRefs } from 'vue'
import SmsApi from '@/helpers/SmsApi'

export function useSmsApi () {
  const smsApiInst = new SmsApi()
  const smsApiInstances = reactive({
    smsActivationApi: null,
    smsUserApi: null,
    smsServicesApi: null,
    smsUtilsApi: null,
    smsPaymentsApi: null
  })

  const initApiInst = async () => {
    await getActivationApi()
    await getUserApi()
    await getServicesApi()
    await getUtilsApi()
    await getPaymentsApi()
    await smsApiInst.updateFbToken()
  }

  const getActivationApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsActivationApi = await smsApiInst.activationApi()
    }
  }

  const getUserApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsUserApi = await smsApiInst.userApi()
    }
  }

  const getServicesApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsServicesApi = await smsApiInst.servicesApi()
    }
  }

  const getUtilsApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsUtilsApi = await smsApiInst.utilsApi()
    }
  }

  const getPaymentsApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsPaymentsApi = await smsApiInst.paymentsApi()
    }
  }

  return {
    initApiInst,
    ...toRefs(smsApiInstances)
  }
}
