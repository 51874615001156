import en from '@/locales/en.json'
import ru from '@/locales/ru.json'
import ar from '@/locales/ar.json'
import es from '@/locales/es.json'
import pt from '@/locales/pt.json'
import tr from '@/locales/tr.json'
import zh from '@/locales/zh.json'

export const locales = {
  en: en,
  ru: ru,
  ar: ar,
  es: es,
  pt: pt,
  tr: tr,
  zh: zh
}
