<template>
  <form :action="qiwiPay? 'https://oplata.to/pay' : 'https://enot.io/pay'" ref="enotForm" method="get">
    <template v-if="enotFormData">
      <input type='hidden' name='m' :value='enotFormData.merchantId'>
      <input type='hidden' name='oa' :value='enotFormData.paySum'>
      <input type='hidden' name='o' :value='enotFormData.paymentId'>
      <input type='hidden' name='s' :value='enotFormData.sign'>
      <input type='hidden' name='cr' value='USD'>
      <input type='hidden' name='c' :value='`Enot ${enotFormData.paySum}$ payment`'>
      <input v-if="perfectMoneyPay" type='hidden' name='p' value='pm'>
      <input type='hidden' name='success_url' :value='`${appUrl}funds?status=success`'>
      <input type='hidden' name='fail_url' :value='`${appUrl}funds?status=error`'>
       <input type='hidden' name='cf[fb_uid]' :value='uid'>
       <input type='hidden' name='cf[product_id]' :value='enotFormData.productId'>
      <input type="submit" value="send" class="d-none" />
    </template>
  </form>
</template>

<script>
import { useEnot } from '@/use/payments/enot'

export default {
  props: {
    enotPay: Boolean,
    qiwiPay: Boolean,
    perfectMoneyPay: Boolean,
    amount: Number,
    uid: String,
    os: String,
    afId: String
  },
  data () {
    return {
      enotFormData: null,
      appUrl: process.env.VUE_APP_URL
    }
  },
  watch: {
    enotPay (val) {
      if (val) {
        this.addEnotFunds()
      }
    }
  },
  setup () {
    const { getEnotFormData } = useEnot()
    return { getEnotFormData }
  },
  methods: {
    async addEnotFunds () {
      this.enotFormData = await this.getEnotFormData(this.amount, this.os, this.afId)
      if (this.enotFormData && this.enotFormData.paymentId) {
        this.$nextTick(() => {
          this.$refs.enotForm.submit()
        })
      }
    }
  }
}
</script>
