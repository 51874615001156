<template>
  <div class="title">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
  .title{
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #14142B;
  }
</style>
