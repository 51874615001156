<template>
  <div class="activating container">
    <transition name="fade" mode="out-in">
      <PageLoader v-if="loading" />
      <div v-else>
        <ActivationReady v-if="state === 1" @updateActivation="getActivation" :activation="activation" />
        <ActivationDone v-if="state === 2" @updateActivation="getActivation" :activation="activation" />
        <ActivationExpired v-if="state === 3" :activation="activation" />
        <ActivationExpiredError v-if="state === 4" :activation="activation" />

        <p class="help-link">
          <a @click.prevent="needHelp" href="#">{{ $t('text_61939fd80716840139f47657') }}</a>
        </p>
      </div>
    </transition>

    <MobileBottomMenu />
  </div>
</template>

<script>
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useBalance } from '@/use/balance'
import { useAuth } from '@/use/auth'
import { useTranslate } from '@/use/translate'
import PageLoader from '@/components/Loaders/PageLoader.vue'
import ActivationReady from '@/components/Activation/ActivationReady.vue'
import ActivationDone from '@/components/Activation/ActivationDone.vue'
import ActivationExpired from '@/components/Activation/ActivationExpired.vue'
import ActivationExpiredError from '@/components/Activation/ActivationExpiredError.vue'
import MobileBottomMenu from '@/components/MobileBottomMenu/MobileBottomMenu.vue'

export default {
  components: {
    PageLoader,
    ActivationReady,
    ActivationDone,
    ActivationExpired,
    ActivationExpiredError,
    MobileBottomMenu
  },
  data () {
    return {
      loading: true,
      state: 0,
      updateActivationInterval: process.env.VUE_APP_UPDATE_ACTIVATION_INTERVAL || 5000,
      timeoutId: null,
      activation: null,
      doneStatuses: ['smsReceived', 'retryReceived', 'retryRequested'],
      requestedStatuses: ['numberRequested', 'smsRequested', 'retryRequested'],
      receivedStatuses: ['smsReceived', 'retryReceived']
    }
  },
  watch: {
    activation (activation, oldActivation) {
      if (activation) {
        const timeSkew = Date.now() - activation.utc * 1000
        const isActive = (activation.numberExpireAt * 1000 + timeSkew) > Date.now()
        if (activation.status === 'smsRequested' && isActive) {
          this.state = 1
        } else if (this.doneStatuses.includes(activation.status) && isActive) {
          if (this.receivedStatuses.includes(activation.status) && oldActivation && this.requestedStatuses.includes(oldActivation.status)) {
            this.$store.commit('common/setConfetti', true)
            if (activation.status === 'smsReceived') {
              this.restartCheckBalance()
            }
          }
          this.state = 2
        } else if (activation.code || activation.message) {
          this.state = 3
        } else {
          this.state = 4
        }
      }
    }
  },
  mounted () {
    this.getActivation()
  },
  beforeUnmount () {
    this.resetTimeout()
  },
  setup () {
    const { initApiInst, smsActivationApi } = useSmsApi()
    const { handleNotAuthorizedError, handleNetworkError } = useErrorHandlers()
    const { restartCheckBalance } = useBalance()
    const { logout } = useAuth()
    const { addBreakLinesToText, addCrispChatLink } = useTranslate()
    return {
      initApiInst,
      smsActivationApi,
      handleNotAuthorizedError,
      handleNetworkError,
      restartCheckBalance,
      logout,
      addBreakLinesToText,
      addCrispChatLink
    }
  },
  methods: {
    async needHelp () {
      if (this.activation) {
        this.$amplitude.getInstance().logEvent('ui.clicked_on_need_help', {
          service: this.activation.serviceId,
          country: this.activation.countryId
        })
      }
      this.$store.commit('modal/toggleWhatsWrongModal')
    },
    refreshActivation () {
      if (!this.activation || !this.activation.status || this.requestedStatuses.includes(this.activation.status)) {
        this.timeoutId = setTimeout(async () => {
          await this.getActivation()
        }, this.updateActivationInterval)
      }
    },
    async getActivation () {
      this.resetTimeout()
      const id = this.$route.params.id
      if (id) {
        try {
          await this.initApiInst()
          const resp = await this.smsActivationApi.getActivation(id, { prxPrint: this.$store.state.common.prxPrint })
          if (resp && resp.id) {
            this.activation = resp
            this.$store.commit('alert/removeByName', 'network_error')
            if (!this.activation || !this.receivedStatuses.includes(this.activation.status)) {
              this.refreshActivation()
            }
            localStorage.setItem('lastUsedNumber', resp.id)
          }
        } catch (e) {
          if (e.status) {
            switch (e.status) {
              case 401:
                await this.logout()
                await this.handleNotAuthorizedError()
                break
              case 400:
                this.refreshActivation()
                break
              case 404: {
                const textWithBrs = this.addBreakLinesToText(this.$t('no_numbers_error_subtitle'))
                const text = this.addCrispChatLink(textWithBrs, 'History')
                this.$store.commit('notificationModal/show', {
                  outsideClickHide: false,
                  title: this.$t('text_61a0ade46fa8f3013e5be662'),
                  text,
                  buttonText: this.$t('text_61a0ade46fa8f3013e5be667'),
                  buttonRouteName: 'History'
                })
                break
              }
              case 410:
                this.$store.commit('notificationModal/show', {
                  outsideClickHide: false,
                  title: this.$t('text_61a49ca394844a00cd0a02a6'),
                  text: this.$t('text_61a49ca394844a00cd0a02a7'),
                  buttonText: this.$t('text_61a49ca394844a00cd0a02a5'),
                  buttonRouteName: 'Home'
                })
                break
              case 502:
                this.handleNetworkError(true)
                break
              default:
                this.refreshActivation()
            }
          } else {
            if (e.error && e.error.message && e.error.message.includes('Request has been terminated')) {
              this.handleNetworkError(true)
            }
            this.refreshActivation()
          }
        } finally {
          this.loading = false
        }
      } else {
        await this.$router.push({ name: 'Home' })
      }
    },
    resetTimeout () {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/animation/fade-pair.scss';

  .activating {
    height: 100%;
    position: relative;
  }

  @media (max-width: 590px) {
    .help-link{
      margin-top: 32px;
    }
  }
  @media (min-width: 900px) and (max-height: 768px) {
    .help-link {
      margin-top: 50px;
      margin-bottom: 60px;
    }
  }
</style>
