import Tap from '@tapfiliate/tapfiliate-js'
import { getAuth } from 'firebase/auth'
import { useCookies } from 'vue3-cookies'
import { useStore } from 'vuex'
import { useSmsApi } from '@/use/smsApi'

export function useTapfiliate () {
  const auth = getAuth()
  const { cookies } = useCookies()
  const store = useStore()
  const { initApiInst, smsUserApi } = useSmsApi()

  const initTapfiliate = () => {
    Tap.init(process.env.VUE_APP_TAPFILIATE_ACCOUNT_ID)
  }

  const updateTapfiliateTrackId = async () => {
    const tapTrackingId = cookies.get('tap_vid')
    if (tapTrackingId && auth.currentUser && auth.currentUser.uid) {
      try {
        await initApiInst()
        await smsUserApi.value.updateAffiliateId({
          affiliate: {
            id: tapTrackingId
          },
          prxPrint: store.state.common.prxPrint
        })
      } catch (e) {}
    }
  }

  return { initTapfiliate, updateTapfiliateTrackId }
}
