<template>
  <div class="transaction container">
    <div class="transaction__header">
      <TitleElement class="transaction__title">
        <h1 class="title__main title__main--pb0">{{ $t('text_61a8d73647a3700141aa7a0f') }}</h1>
        <h2 class="title__subtitle">
          <template v-if="itemsCount !== null">
            <component v-if="itemsCount === 0" :is="{ template: addBreakLinesToText($t('text_61b1ec70bf4ac4012bdc4b0c')) }" />
            <component v-if="itemsCount > 0" :is="{ template: addBreakLinesToText($t('text_61a8d73647a3700141aa7a3f')) }" />
          </template>
        </h2>
      </TitleElement>

      <TitleElement class="transaction__title--mobile" tace>
        <h1 class="title__main title__main--pb0">{{ $t('text_61a8d7139b65fc015c8afef5') }}</h1>
        <h2 v-if="itemsCount === 0">
          <component :is="{ template: addBreakLinesToText($t('text_61b1ec70bf4ac4012bdc4b0c')) }" />
        </h2>
      </TitleElement>

      <ButtonElement @click="downloadCsv" :loading="btnLoading" class="transaction__download-csv" fill>
        <img src="@/assets/icons/download.svg" alt="download">
        <span>{{ $t('text_61a8d73647a3700141aa7a42') }}</span>
      </ButtonElement>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="loading">
        <PageLoader />
      </div>
      <div v-else>
        <table v-if="itemsCount > 0" class="transaction__table table">
          <thead class="table__thead">
            <th><div>{{ $t('text_61a8d73647a3700141aa7a39') }}</div></th>
            <th><div>{{ $t('text_61a8d73647a3700141aa7a3a') }}</div></th>
            <th><div>{{ $t('text_61a8d73647a3700141aa7a3b') }}</div></th>
            <th><div>{{ $t('text_61a8d73647a3700141aa7a40') }}</div></th>
          </thead>

          <tbody class="table__tbody">
            <tr
              v-for="(item, idx) in transactions"
              :key="idx"
              :class="{'cursorPointer': item.details.type === 'out'}"
              class="table__tr"
            >
              <td class="table__id">
                <router-link
                  v-if="['out', 'refund'].includes(item.details.type) && item.details.activationId"
                  :to="{ name: 'Activation', params: { id: item.details.activationId } }"
                >
                  <TransactionId :item="item" />
                </router-link>
                <TransactionId v-else :item="item" />
              </td>
              <td class="table__date">
                <router-link
                  v-if="['out', 'refund'].includes(item.details.type) && item.details.activationId"
                  :to="{ name: 'Activation', params: { id: item.details.activationId } }"
                >
                  <TransactionDate :item="item" />
                </router-link>
                <TransactionDate v-else :item="item" />
              </td>
              <td class="table__details">
                <router-link
                  v-if="['out', 'refund'].includes(item.details.type) && item.details.activationId"
                  :to="{ name: 'Activation', params: { id: item.details.activationId } }"
                >
                  <TransactionDetails :item="item" />
                </router-link>
                <TransactionDetails v-else :item="item" />
              </td>
              <td
                class="table__amount"
              >
                <router-link
                  v-if="['out', 'refund'].includes(item.details.type) && item.details.activationId"
                  :to="{ name: 'Activation', params: { id: item.details.activationId } }"
                >
                  <TransactionAmount :item="item" />
                </router-link>
                <TransactionAmount v-else :item="item" />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <EmptyListState>
            <component :is="{ template: addBreakLinesToText($t('text_61b1ec70bf4ac4012bdc4b0e')) }" />
          </EmptyListState>
        </div>

        <Paginator
          @next="showNextPage"
          @prev="showPrevPage"
          @selectPage="selectPage"
          :pagesCount="pagesCount"
          :currentPage="currentPage"
          :limit="limit"
          :itemsCount="itemsCount"
          class="pagination"
        />
      </div>
    </transition>

    <MobileBottomMenu />
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useTranslate } from '@/use/translate'
import TitleElement from '@/components/TitleElement'
import PageLoader from '@/components/Loaders/PageLoader.vue'
import ButtonElement from '@/components/ButtonElement'
import Paginator from '@/components/Paginator.vue'
import MobileBottomMenu from '@/components/MobileBottomMenu/MobileBottomMenu.vue'
import EmptyListState from '@/components/EmptyListState.vue'
import TransactionId from '@/components/Transactions/Id.vue'
import TransactionDate from '@/components/Transactions/Date.vue'
import TransactionDetails from '@/components/Transactions/Details.vue'
import TransactionAmount from '@/components/Transactions/Amount.vue'

export default {
  components: {
    TitleElement,
    PageLoader,
    ButtonElement,
    Paginator,
    MobileBottomMenu,
    EmptyListState,
    TransactionId,
    TransactionDate,
    TransactionDetails,
    TransactionAmount
  },
  data () {
    return {
      currentPage: 1,
      transactions: [],
      limit: parseInt(process.env.VUE_APP_TRANSACTIONS_PAGINATION_LIMIT, 10) || 10,
      pagesCount: 1,
      itemsCount: null,
      loading: true,
      btnLoading: false
    }
  },
  watch: {
    $route (to) {
      if (to.name === 'Transactions') {
        this.currentPage = to.query.page && parseInt(to.query.page, 10) > 1 ? parseInt(to.query.page, 10) : 1
        this.getTransactions()
      }
    }
  },
  setup () {
    const { initApiInst, smsUserApi } = useSmsApi()
    const { handleNotAuthorizedError, handleNetworkError } = useErrorHandlers()
    const { addBreakLinesToText } = useTranslate()
    return { initApiInst, smsUserApi, handleNotAuthorizedError, handleNetworkError, addBreakLinesToText }
  },
  mounted () {
    this.currentPage = this.$route.query.page && parseInt(this.$route.query.page, 10) > 1
      ? parseInt(this.$route.query.page, 10) : 1
    this.getTransactions()
  },
  methods: {
    async getTransactions () {
      this.loading = true
      try {
        await this.initApiInst()
        const requestData = {
          page: this.currentPage,
          limit: this.limit,
          prxPrint: this.$store.state.common.prxPrint
        }
        const resp = await this.smsUserApi.getTransactions(requestData)
        if (resp) {
          this.pagesCount = resp.pages
          this.itemsCount = resp.total
          this.transactions = resp.transactions
        }
      } catch (e) {
        if (e.status === 401) {
          await this.handleNotAuthorizedError()
        } else if (e.status === 502) {
          await this.handleNetworkError()
        } else {
          if (e.error && e.error.message && e.error.message.includes('Request has been terminated')) {
            this.handleNetworkError()
          } else {
            this.$store.commit('notificationModal/showSomethingWrongModal', e.status ? e.status : '')
          }
        }
      } finally {
        this.loading = false
      }
    },
    showNextPage () {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage () {
      this.openPage(this.currentPage - 1)
    },
    selectPage (id) {
      this.openPage(id)
    },
    async openPage (page) {
      if (page > 1) {
        await this.$router.push({ name: 'Transactions', query: { page } })
      } else {
        await this.$router.push({ name: 'Transactions' })
      }
    },
    async downloadCsv () {
      this.btnLoading = true
      try {
        await this.initApiInst()
        const respData = await this.smsUserApi.getTransactionsCsv({ prxPrint: this.$store.state.common.prxPrint })
        if (respData) {
          const blob = new Blob([respData], { type: 'text/csv' })
          saveAs(blob, `temp-number-transactions-${Date.now()}.csv`)
        }
      } catch (e) {
        if (e.status === 401) {
          await this.handleNotAuthorizedError()
        } else {
          this.$store.commit('notificationModal/showSomethingWrongModal', e.status ? e.status : '')
        }
      } finally {
        this.btnLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/animation/fade-history.scss';

  .transaction {
    max-width: 718px;
    margin-bottom: 60px;
    .title__subtitle {
      font-weight: normal;
      line-height: 28px;
    }

    &__header {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
    }

    &__download-csv {
      margin-top: 6px;
      margin-left: 40px;
      width: 210px;
      height: 64px;
      img {
        margin-right: 12px;
      }

      span {
        position: relative;
        top: -1px;
      }
    }

    &__title {
      &--mobile {
        width: 100%;
        display: none;
      }
    }

    &__table {
      margin: 0 auto 90px auto;
      max-width: 718px;
      width: 100%;
    }
  }

  .rtl{
    .transaction {
      &__download-csv {
        margin-right: 40px;
        margin-left: 0;
        img {
          margin-left: 12px;
          margin-right: 0;
        }
      }
    }
  }

  .table {
    margin-top: 55px;
    color: #14142B;
    border-spacing: 0;
    border-collapse: collapse;

    &__thead {
      th {
        div {
          background: #F8F8F8;
          padding: 10px 10px 13px 10px;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          text-align: left;
        }

        &:first-child {
          width: 200px;

          div {
            padding-left: 32px;
            border-radius: 6px 0 0 6px;
          }
        }

        &:nth-child(2) {
          width: 120px;
        }

        &:last-child {
          div {
            padding-right: 32px;
            text-align: right;
            border-radius: 0 6px 6px 0;
          }
        }

      }
    }

    &__tbody {
      &:before {
        content: "-";
        display: block;
        line-height: 22px;
        color: transparent;
      }

      .table__tr {
        td {
          > a > div,
          > div {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            padding-top: 8px;
            padding-bottom: 10px;
            font-size: 16px;
            line-height: 20px;
          }

          > a {
            display: flex;
            color: #14142B;
          }

          &:last-child {
            > a {
              display: block;
            }
          }
        }
      }
    }
  }

  .rtl{
    .table {
      &__thead {
        th {
          div {
            text-align: right;
          }
          &:first-child {
            div {
              padding-right: 32px;
              padding-left: 0;
              border-radius: 0 6px 6px 0;
            }
          }

          &:last-child {
            div {
              padding-left: 32px;
              padding-right: 0;
              text-align: left;
              border-radius: 6px 0 0 6px;
            }
          }

        }
      }
    }
  }

  @media (max-width: 590px) {
    .transaction {
      &__header {
        margin-top: 0;
      }

      &__table {
        margin: 0 auto 38px auto;
      }

      &__title {
        display: none;

        &--mobile {
          display: block;
          .title__main{
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            letter-spacing: -0.2px;
          }
          h2{
            margin-top: 8px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            font-weight: normal;
            color: #14142B;
            opacity: 0.6;
          }
        }
      }

      &__download-csv {
        display: none;
      }
    }

    .table {
      margin-top: 30px;

      &__thead {
        display: flex;
        th {
          div {
            padding: 10px 5px 10px 10px;
            font-size: 13px;
            line-height: 16px;
          }

          &:first-child {
            width: 12%;
            div {
              width: 100%;
              text-align: left;
              padding-left: 16px;
            }
          }

          &:nth-child(2) {
            width: 17%;
            div {
              padding-left: 7px;
            }
          }
          &:nth-child(3) {
            width: 51%;
          }

          &:last-child {
            width: 20%;
            div {
              padding-left: 0;
              padding-right: 16px;
            }
          }

        }
      }

      &__tbody {
        &:before {
          line-height: 16px;
        }

        .table__tr {
          display: flex;
          td {
            > a > div,
            > div {
              font-size: 13px;
              line-height: 16px;
              margin-left: 0;
              border-bottom: 1px solid rgba(25, 22, 50, 0.12);
              border-top: 1px solid rgba(25, 22, 50, 0.12);
              margin-bottom: 12px;
              padding: 15px 3px 15px 10px;
            }
          }
        }
      }

      &__id {
        width: 12%;
      }

      &__date {
        width: 17%;
      }

      &__details {
        width: 51%;
      }

      &__amount {
        width: 20%;
      }
    }

    .rtl{
      .table {
        &__thead {
          th {
            div {
              padding: 10px 10px 10px 5px;
            }

            &:first-child {
              div {
                text-align: right;
                padding-right: 16px;
                padding-left: 0;
              }
            }

            &:nth-child(2) {
              div {
                padding-right: 7px;
                padding-left: 0;
              }
            }

            &:last-child {
              div {
                padding-left: 16px;
                padding-right: 0;
              }
            }
          }
        }

        &__tbody {
          .table__tr {
            td {
              > a > div,
              > div {
                margin-right: 0;
                padding: 15px 10px 15px 3px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .transaction {
      padding-top: 20px;
      &__header {
        margin-top: 10px;
      }
      .pagination {
        margin-top: 14px;
      }
    }
  }

  @media (max-width: 320px) {
    .table__thead:first-child,
    .table__id {
      div {
        padding-left: 6px !important;
      }
    }
    .table__thead th:nth-child(2),
    .table__date {
      width: 24%;
      div{
        padding-left: 0 !important;
      }
    }
    .table__thead th:nth-child(3),
    .table__details {
      width: 40%;
      div {
        padding-left: 6px !important;
      }
    }
    .table__thead th:last-child,
    .table__amount {
      width: 24%;
      div {
        padding-left: 2px !important;
        padding-right: 6px !important;
      }
    }

    .rtl{
      .table__thead:first-child,
      .table__id {
        div {
          padding-right: 6px !important;
        }
      }
      .table__thead th:nth-child(2),
      .table__date {
        div{
          padding-right: 0 !important;
        }
      }
      .table__thead th:nth-child(3),
      .table__details {
        div {
          padding-right: 6px !important;
        }
      }
      .table__thead th:last-child,
      .table__amount {
        div {
          padding-right: 2px !important;
          padding-left: 6px !important;
        }
      }
    }
  }
</style>
