import { useSmsApi } from '@/use/smsApi'
import { useStore } from 'vuex'

export function useActiveActivationsCount () {
  const { initApiInst, smsActivationApi } = useSmsApi()
  const store = useStore()
  const timeoutInterval = process.env.VUE_APP_UPDATE_ACTIVE_ACTIVATIONS_COUNT_INTERVAL || 30000

  const refreshActiveActivationsCount = () => {
    const timeoutId = setTimeout(async () => {
      await getActiveActivationsCount()
    }, timeoutInterval)
    store.commit('activation/setActiveActivationsTimeoutId', timeoutId)
  }

  const getActiveActivationsCount = async () => {
    const timeoutId = store.state.activation.activeActivationsTimeoutId
    clearTimeout(timeoutId)
    store.commit('activation/setActiveActivationsTimeoutId')

    if (!store.state.auth.isAuthenticated) return

    try {
      await initApiInst()
      const resp = await smsActivationApi.value.getActiveActivations({ prxPrint: store.state.common.prxPrint })
      const activeCount = resp && resp.count ? resp.count : 0
      store.commit('activation/setActiveActivationsCount', activeCount)
      refreshActiveActivationsCount()
    } catch (e) {
      store.commit('activation/setActiveActivationsCount')
    }
  }

  return { getActiveActivationsCount }
}
