<template>
  <div>
    {{ $t('text_625fd073376b5300ff6dfdae') }}
    <div @click="$emit('generateApiKey')" class="generateBtn">
      <div class="btnImg">
        <v-lottie-player
          v-if="btnLoading"
          :animationData="require('@/assets/loaders/iphone-orange-spinner.json')"
          loop
          width="20px"
          height="20px"
        />
        <img v-else src="@/assets/icons/generateApiKey.svg" alt="plus">
      </div>
      <span>{{ $t('text_625fd073376b5300ff6dfdac') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['generateApiKey'],
  computed: {
    btnLoading () {
      return this.$store.state.common.btnLoading
    }
  }
}
</script>

<style lang="scss" scoped></style>
