-<template>
  <div class="activating-ready">
    <TitleElement tace>
      <h1 v-html="addBreakLinesToText($t('text_61939fd80716840139f476ba'))" class="title__main" />
      <h2 v-html="addBreakLinesToText($t('text_61939fd80716840139f4765e'))" class="title__subtitle title__subtitle-activation" />
    </TitleElement>

    <div class="activating-ready__loader">
      <Loader />
    </div>

    <ActivationInfo :activation="activation" :expired="false" :showPrice="false" />
    <EnableNotificationsModal v-if="$store.state.modal.enableNotificationsModal" />
  </div>
</template>

<script>
import { isSupported } from 'firebase/messaging'
import { useTranslate } from '@/use/translate'
import TitleElement from '@/components/TitleElement.vue'
import Loader from '@/components/Loader.vue'
import ActivationInfo from '@/components/Activation/ActivationInfo.vue'
import EnableNotificationsModal from '@/components/Modals/EnableNotificationsModal'

export default {
  components: {
    TitleElement,
    Loader,
    ActivationInfo,
    EnableNotificationsModal
  },
  props: {
    activation: Object
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    return { addBreakLinesToText }
  },
  mounted () {
    this.showNotificationsModal()
  },
  methods: {
    async showNotificationsModal () {
      if (Notification.permission === 'default' && localStorage.getItem('fcmEnabled') === null) {
        const fcmSupported = await isSupported()
        if (fcmSupported) {
          setTimeout(() => {
            this.$store.commit('modal/toggleEnableNotificationsModal')
          }, 1000)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .activating-ready {
    padding-top: 58px;
    max-width: 536px;
    margin: 0 auto;

    .title__subtitle-activation{
      line-height: 28px;
      color: rgba(20, 20, 43, 0.6);
    }

    &__loader {
      margin-top: 24px;
      margin-bottom: 45px;
    }
  }

  @media (max-width: 590px) {
    .activating-ready {
      padding-top: 30px;
      display: flex;
      flex-direction: column;

      &__loader {
        order: 1;
        margin-top: 0;
        margin-bottom: 16px;
      }
      .title {
        order: 2;
        margin-bottom: 24px;

        &__main {
          padding-bottom: 8px;
        }
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .activating-ready{
      &__loader {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 320px) {
    .activating-ready{
      padding-top: 0;
      &__loader {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
</style>
