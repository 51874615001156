<template>
  <div class="content-page container">
    <h1 style="text-align: center;">Privacy policy</h1>
    <p style="text-align: right;"><strong>Last update: June 1, 2023</strong><strong><br /><br /></strong></p>
    <p>Following Privacy policy refers to Temp-Number website (temp-number.org) and mobile applications.</p>
    <p>We are committed to protecting your privacy.&nbsp; All personal information collected by us is kept in the strictest confidence. We will not share, or sell any personal information to any third party unless required to do so by law.</p>
    <p>The Temp-Number service is provided by Privatix LTD, a company registered in the State of Israel and having its registered address at 150 Menachem Begin Road, WE Tower - Level 9, Tel Aviv 6492105.&nbsp;</p>
    <p>&nbsp;</p>
    <h2>What Temp-Number apps and website do</h2>
    <p>Temp-Number service provides temporary disposable phone numbers that may be used on different websites and services, avoiding exposing the real phone number. The numbers are good for short usage (up to 20 minutes) and may receive only SMS messages. The user should choose the exact pair of the service name and country and will be charged if the number received the message.&nbsp;</p>
    <p>&nbsp;</p>
    <h2>Information We Collect and Why</h2>
    <p>Temp-Number must collect some information to operate, understand, support, and market our service. &nbsp; The information we collect will depend on how you use our service, and will be collected when you install, login, or use our services.</p>
    <p>&nbsp;</p>
    <h2>Information You Provide</h2>
    <p>Your Account &ndash; To create a Temp-Number account you provide your email which will be used as the main account username.</p>
    <p>Payments &ndash; If you make payment to access paid services, we will ask for and retain certain information.&nbsp; This is so we can process your payment, process refunds, and meet our obligations to provide itemised billing and invoices:</p>
    <p>Credit/Debit card: &nbsp; Your card details will be collected by our third party payment provider.&nbsp; All card information is transmitted directly between your device and our payment provider using SSL secure encryption.&nbsp; In addition, we will collect the billing name and address of your card, and email address, to help decide if you are required to pay EU VAT, and to provide you with an invoice.</p>
    <p>PayPal:&nbsp; Your payment will be processed directly by PayPal.&nbsp; Your PayPal login and payment acceptance is transmitted directly between your device and PayPal using SSL secure encryption.&nbsp; We will receive from PayPal confirmation of your payment, your PayPal registered name, address and email address from PayPal, which we use to help decide if you are required to pay EU VAT, and to provide you with an invoice.&nbsp; We may also require you to verify your PayPal email address before we accept your payment.</p>
    <p>Customer Support &ndash; If you contact our customer support you will provide us with information related to your use of our Services, including your account email address to help assist and communicate with you.</p>
    <p>&nbsp;</p>
    <h2>Information We Automatically Collect</h2>
    <p>We store your SMS messages to phone numbers on our servers, so that if you reinstall or change web browser, the Temp-Number service will pick up your messages again. We will retain the time, originating phone number, recipient phone number, and number of SMS parts to provide itemised billing and comply with regulations.</p>
    <p>SMS records (time, originating number, recipient number, and SMS parts) of SMS received to Temp-Number virtual numbers are retained to comply with regulations.</p>
    <p>Device Information &ndash; When you use our service, or when you contact customer support, we collect information on your operating system, browser information, and your internet connection IP address.</p>
    <p>Service Usage Data &ndash; We collect service-related diagnostics and logs about your activity on our service, that includes log files, crash reports, and website logs.&nbsp; This information is only held for a short time period for diagnosing issues.</p>
    <p>We also retain time and IP connection information on when you first registered with our service, and when you last connected.</p>
    <p>Cookies &ndash; We use cookies to improve your experience with us when visiting our website, by remembering options such as a login session token and your preferred language.&nbsp; These cookies contain no personally identifiable information.</p>
    <p>Like most websites, we also use Google Analytics to track user interaction.&nbsp; We use this data to determine the number of people using our website, to better understand how they find and use our web pages, and to see their journey through our website.&nbsp; Google Analytics records data such as your geographical location, device, internet browser and operating system, and your internet connection IP address. This information is only processed in a way which does not identify anyone. We do not make, and do not allow Google to make, any attempt to find out the identities of those visiting our website.</p>
    <p>Your web browser allows you to delete cookies and disable them in the future.&nbsp; Should you delete or disable cookies, you may find that some of our services are unavailable to you.</p>
    <p>&nbsp;</p>
    <h2>Third-Party Information</h2>
    <p>Third-Party Service Providers &ndash; We partner with European and US third-party service providers to help us deliver, understand, support, and market our service.&nbsp; For example, we work with companies to terminate and receive calls and SMS, provide virtual phone numbers, process payments, distribute our apps, provide our infrastructure, help us understand how our service is used, and communicate with you.&nbsp; We provide these third-party services only with the information absolutely necessary to deliver the part of the service they provide for us.</p>
    <p>Third-Parties &ndash; All personal information collected by us is kept in the strictest confidence. We will not share, or sell any personal information to any third party unless required to do so by law.</p>
    <p>External Links &ndash; Some of our website pages may contain external links to other websites. We cannot be held responsible for content or privacy policy of those sites.</p>
    <p>We use Stripe for payment, analytics, and other business services. Stripe collects and processes personal data, including identifying information about the devices that connect to its services. Stripe uses this information to operate and improve the services it provides to us, including for fraud detection and prevention. You can learn more about Stripe and its processing activities via privacy policy at https://stripe.com/privacy.</p>
    <p>&nbsp;</p>
    <h2>How We Use Information</h2>
    <p>Temp-Number uses the information we collect to operate, understand, support, and market our service.</p>
    <p>Our Service &ndash; We use the information we collect to operate our service, provide reporting for financial accounting and taxation liabilities, analyse how our service is used, fix and improve our service, and provide customer support.</p>
    <p>Communications from Temp-Number &ndash; We may use either in-browser messaging or email (if provided) to advise you of our services, and inform you of updates to our policies and terms.</p>
    <p>Third-Party Service Providers &ndash; We partner with European GDPR and EU-US Privacy Shield compliant third-party service providers to help us deliver, understand, support, and market our service.</p>
    <p>Security &ndash;&nbsp; We may monitor accounts and usage for suspicious activity to provide security and safety on our service, and to prevent abuse, fraud, illegal usage, and breaches of our terms and conditions.</p>
    <p>&nbsp;</p>
    <h2>How Long We Keep Information</h2>
    <p>We store your information until it is not needed to provide you a service, or until your account is deleted.&nbsp; When information is deleted will depend on the type of information, why it has been collected and used, and any legal or operational retention requirements.</p>
    <p>&nbsp;</p>
    <h2>Data Security</h2>
    <p>We take all aspects of information security seriously.&nbsp; We store the minimum of data necessary to provide the service to you, while complying with our legal obligations.&nbsp; We apply access controls throughout all our systems, and use only trusted third party providers.&nbsp; To protect customer privacy, access to information by employees is restricted.</p>
    <p>Should we have a data breach or be made aware of such at one of our third party providers, we will notify this to the ICO within the prescribed timescales of the GDPR.</p>
    <p>&nbsp;</p>
    <h2>GDPR for European Region Users</h2>
    <p>From May 2018 the General Data Protection Regulation (GDPR) will apply to all data we hold about our customers</p>
    <p>&nbsp;</p>
    <h2>Your Rights</h2>
    <p>Customers and subscribers have clear rights under GDPR:</p>
    <ul>
      <li>The right to be informed about the personal data that is being held and processed</li>
      <li>The right of access to personal data via a Subject Access Request</li>
      <li>The right to rectification of personal data if inaccurate, incomplete, or out of date</li>
      <li>The right to erasure of data where there is no lawful reason for its continued</li>
      <li>processing</li>
      <li>The right to restrict processing of data where information is inaccurate or there is an objection to the lawfulness of the processing</li>
      <li>The right to portability of personal data to reuse elsewhere</li>
      <li>The right to object</li>
      <li>if no legitimate reason exists for the processing of data</li>
      <li>The right to check or challenge automated decision making and profiling</li>
    </ul>
    <p>Please refer to the GDPR regulation or ico.org.uk for full details of these items and the conditions under which it may be appropriate to contact us or make an access request.</p>
    <p>&nbsp;</p>
    <h2>Our Basis for Processing Information</h2>
    <p>We collect, use, and share the information detailed above to:</p>
    <ul>
      <li>To provide our service under our Terms and Conditions.</li>
      <li>With your consent, which you can withdraw at any time.</li>
      <li>For our own legitimate interests, which is to provide a reliable, safe and secure, legal,&nbsp; telecoms service to our users and partners, unless those interests are overruled by your interests or fundamental rights and freedoms for data protection.</li>
      <li>To comply with our legal obligations;</li>
      <li>To protect yours or others vital interests</li>
    </ul>
    <p>&nbsp;</p>
    <h2>Updating and Deleting Your Information</h2>
    <p>We store your information until it is not needed to provide you a service, or until your account is deleted.&nbsp; When the information is deleted will depend on the type of information, why it has been collected and used, and any legal or operational retention requirements.</p>
    <p>If you have any questions regarding your personal information, or would like to exercise any of your rights under GDPR, such as accessing, updating or deleting your data, then please</p>
    <p>Contact us at support@temp-number.org.</p>
  </div>
</template>

<script>
export default {

}
</script>
