import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './utils/i18n'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { getMessaging, onMessage } from 'firebase/messaging'
import initFbRk from './utils/initFbRk'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueLottiePlayer from 'vue-lottie-player'
import deviceMixin from './mixins/device'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueClipboard from 'vue3-clipboard'
import VueConfetti from '@/plugins/confetti'
import Amplitude from './plugins/amplitude'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  storageBucket: '',
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  databaseURL: '',
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID ?? ''
}

const fbApp = initializeApp(firebaseConfig)
getAnalytics(fbApp)
getPerformance(fbApp)
const auth = getAuth()

// Firebase Cloud Messages
navigator.serviceWorker.register(`/firebase-messaging-sw.js?fbProjectId=${new URLSearchParams(firebaseConfig)}`)
const messaging = getMessaging()
onMessage(messaging, (payload) => {
  const currentRoute = router.currentRoute._value
  // Show foreground notification only if not on current activation page
  if (currentRoute.name === 'Activation' && currentRoute.params.id && currentRoute.params.id === payload.data.activationId) {
    return false
  } else {
    if (payload.data) {
      if (payload.data.code || payload.data.fullMessage) {
        store.commit('alert/add', {
          id: Date.now(),
          text: '',
          type: 'success',
          name: 'messageNotification',
          data: payload
        })
      }
      if (payload.data.type) {
        let notificationText = ''
        let colorType = ''
        let alertTitle = ''
        switch (payload.data.type) {
          case 'payment_review_closed':
            alertTitle = `<strong style="font-size: 16px">${i18n.global.t('text_64301428cf337400abd7d054')}</strong><br /><br />`
            notificationText = `${alertTitle}${i18n.global.t('text_64301428cf337400abd7d055')}`
            colorType = 'success'
            break
          case 'payment_refunded':
            alertTitle = `<strong style="font-size: 16px">${i18n.global.t('text_64301428cf337400abd7d057')}</strong><br /><br />`
            notificationText = `${alertTitle}${i18n.global.t('text_64301428cf337400abd7d058')}`
            break
        }

        if (notificationText) {
          store.commit('alert/add', {
            id: Date.now(),
            text: notificationText,
            type: colorType
          })
        }
      }
    }
  }
})

let app
onAuthStateChanged(auth, async () => {
  if (!app) {
    await initFbRk(fbApp)
  }
  await store.dispatch('auth/setUserData')

  if (!app) {
    app = createApp(App)
      .use(store)
      .use(router)
      .use(i18n)
      .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true
      })
      .use(VueLottiePlayer)
      .use(VueConfetti)
      .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
      .use(Amplitude, { apiKey: process.env.VUE_APP_AMPLITUDE_API_KEY })
      .mixin(deviceMixin)
      .component(VueCountdown.name, VueCountdown)
      .mount('#app')
  }
})
