<template>
  <div class="activation-price">
    <div class="activation-price__price">${{ $store.state.order.price }}</div>
    <p class="activation-price__text">{{ $t('text_6167c4a5321c670152f0e1ef') }}</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
  .activation-price {
    width: 327px;
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
    padding: 28px 10px;
    background: #F8F8F8;
    border-radius: 15px;
    text-align: center;

    &__price {
      font-size: 32px;
      line-height: 30px;
      letter-spacing: -0.2px;
      color: #FA8231;
      font-weight: 600;
      margin: 4px 0;
    }

    &__text {
      color: #14142B;
      font-size: 14px;
      line-height: 24px;
      margin: 4px 0;
    }
  }

  @media (max-width: 590px) {
    .activation-price {
      width: 100%;
      max-width: 327px;
      padding: 20px 23px;
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    .activation-price {
      padding: 15px 10px;
    }
  }
</style>
