import { createI18n } from 'vue-i18n'
import { locales } from './locales'

const currentLocale = localStorage.getItem('locale') || 'en'

const i18n = createI18n({
  legacy: false,
  locale: currentLocale,
  fallbackLocale: 'en',
  messages: Object.assign(locales)
})

export default i18n
