<template>
  <div class="authWrapper">
    <transition name="fade">
      <div>
        <TitleElement tace>
          <h1 class="auth-title__main title__main--pb0">{{ $t('text_6167c4a5321c670152f0e2a5') }}</h1>
          <h2 class="auth-title__subtitle">
            <component :is="{ template: termsOfServiceText  }"></component>
          </h2>
        </TitleElement>
        <CreateAccountByEmail />
      </div>
    </transition>
  </div>
</template>

<script>
import { useTranslate } from '@/use/translate'
import TitleElement from '@/components/TitleElement.vue'
import CreateAccountByEmail from '@/components/CreateAccount/CreateAccountByEmail/CreateAccountByEmail.vue'

export default {
  components: {
    TitleElement,
    CreateAccountByEmail
  },
  data () {
    return {
      isEmailSignIn: false
    }
  },
  computed: {
    termsOfServiceText () {
      const textWithBrs = this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e215'))
      return this.addLinksToText(textWithBrs, ['Terms'])
    }
  },
  setup () {
    const { addBreakLinesToText, addLinksToText } = useTranslate()
    return { addBreakLinesToText, addLinksToText }
  },
  methods: {
    changeToEmailForm () {
      this.isEmailSignIn = true
    }
  }
}
</script>
