<template>
  <div @click="$emit('decline')" class="modal">
    <div @click.stop="" class="modal__container">
      <div class="body">
        <form id="payment-form">
          <div id="link-authentication-element">
            <!-- Elements will create authentication element here -->
          </div>
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>

          <div class="sr-input sr-element sr-card-element" id="card-element">
            <!-- A Stripe card Element will be inserted here. -->
          </div>
          <ButtonElement :loading="addFundsLoading" id="submit" class="add-funds-button" fill>
            {{ $t('text_61a8d7461cd34e01670c871a') }}
          </ButtonElement>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  emits: ['decline'],
  props: {
    email: String,
    paymentIntent: {
      id: String,
      clientSecret: String,
      publicKey: String
    }
  },
  data () {
    return {
      addFundsLoading: false,
      errorMessage: ''
    }
  },
  components: {
    ButtonElement
  },
  mounted () {
    this.loadStripeForm()
  },
  methods: {
    loadStripeForm () {
      if (this.paymentIntent.clientSecret && this.paymentIntent.publicKey) {
        let email = this.email
        // eslint-disable-next-line
        const stripe = Stripe(this.paymentIntent.publicKey)
        const options = {
          appearance: {
            theme: 'stripe'
          },
          clientSecret: this.paymentIntent.clientSecret,
          locale: this.$i18n.locale
        }
        const elements = stripe.elements(options)

        const paymentElement = elements.create('payment', {
          defaultValues: {
            billingDetails: {
              email
            }
          }
        })
        paymentElement.mount('#payment-element')

        const form = document.getElementById('payment-form')
        form.addEventListener('submit', async (event) => {
          event.preventDefault()
          this.addFundsLoading = true

          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: `${process.env.VUE_APP_URL}funds?status=success&payment_gateway=stripe`
            }
          })
          this.addFundsLoading = false
          if (error && error.message) {
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: error.message,
              type: 'error',
              timeout: 4000,
              name: 'stripe_error'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal{
    &__container{
      width: 90%;
      max-width: 400px;
      padding: 32px 24px 24px;
      @media screen and (max-height: 700px) {
        max-height: 90%;
        overflow-y: scroll;
      }

      .add-funds-button{
        width: 220px;
        height: 50px;
        margin: 24px auto 15px;
      }
    }
  }
  .hidden{
    display: none;
  }
  #payment-message{
    color: red;
  }
</style>
