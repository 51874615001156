import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import { useStore } from 'vuex'
import { getAuth } from 'firebase/auth'

export function useCloudMessaging () {
  const { initApiInst, smsUserApi } = useSmsApi()
  const { handleNotAuthorizedError } = useErrorHandlers()
  const store = useStore()

  const getFcmToken = async () => {
    const isCmSupported = await isSupported()
    if (isCmSupported) {
      const messaging = getMessaging()
      try {
        return await getToken(messaging, { vapidKey: process.env.VUE_APP_MESSAGING_PUBLIC_KEY })
      } catch (e) {
        return ''
      }
    }
  }

  const updateFbNotificationToken = async (fcmToken = '', changeLocalStorageFcmEnabled = true) => {
    const auth = getAuth()
    if (!auth.currentUser) return
    try {
      await initApiInst()
      const resp = await smsUserApi.value.updateUserFirebaseToken({
        firebaseToken: {
          token: fcmToken
        },
        prxPrint: store.state.common.prxPrint
      })
      if (resp && resp.result && resp.result === 'success' && fcmToken !== '') {
        localStorage.setItem('fcmEnabled', 'true')
        store.commit('user/setFcmEnabled', true)
      } else {
        if (changeLocalStorageFcmEnabled) {
          localStorage.setItem('fcmEnabled', 'false')
        }
        store.commit('user/setFcmEnabled', false)
      }
    } catch (e) {
      if (e.status === 401) {
        await handleNotAuthorizedError()
      }
    }
  }

  const enableNotifications = async () => {
    const fcmToken = await getFcmToken()
    if (fcmToken) {
      await updateFbNotificationToken(fcmToken)
    } else {
      localStorage.setItem('fcmEnabled', 'false')
      store.commit('user/setFcmEnabled', false)
    }
  }

  return { getFcmToken, updateFbNotificationToken, enableNotifications }
}
