<template>
  <div class="create-account-item">
    <img class="create-account-item__img" :src="require(`@/assets/socials/${imgName}`)" :alt="imgAlt">
    <p class="create-account-item__text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    imgName: String,
    imgAlt: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
  .create-account-item {
    height: 56px;
    cursor: pointer;
    padding: 15px 0px 15px 0px;
    margin-bottom: 12px;
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    display: flex;
    justify-content: center;

    &__img {
      margin-right: 8px;
    }

    &__text {
      padding-top: 2px;
    }
  }

  .rtl{
    .create-account-item {
      &__img {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 590px) {
    .create-account-item {
      &__text {
        padding-top: 4px;
        font-size: 14px;
      }
    }
  }
</style>
