<template>
  <div @click="copy()">
    <div @click.stop="$emit('regenerateApiKey')" class="regenerateBtn">
      <div class="btnImg">
        <v-lottie-player
          v-if="btnLoading"
          :animationData="require('@/assets/loaders/restart.json')"
          loop
          width="18px"
          height="18px"
        />
        <img v-else src="@/assets/icons/retry.svg" alt="retry">
      </div>
      <span>{{ $t('text_625fd073376b5300ff6dfdb0') }}</span>
    </div>

    <div ref="apiKey" class="api-key__value">
      {{ keyHidden ? (isMobile() ? '*************************' : '********************************') : apiKey }}
    </div>

    <button v-if="keyHidden" @click.stop="$emit('showApiKey')" class="showApiKeyBtn">
      <img src="@/assets/icons/showApiKey.svg" alt="">
      <span>{{ $t('text_625fd073376b5300ff6dfdb8') }}</span>
    </button>
    <button v-else @click.stop="copy" class="copyApiKeyBtn">
      <img src="@/assets/icons/copy.svg" alt="">
      <span>{{ $t('text_61939fd80716840139f47655') }}</span>
    </button>
  </div>
</template>

<script>
import selectText from '@/helpers/select-text.js'

export default {
  emits: ['regenerateApiKey', 'showApiKey'],
  props: {
    keyHidden: Boolean
  },
  computed: {
    btnLoading () {
      return this.$store.state.common.btnLoading
    },
    apiKey () {
      return this.$store.state.user.apiKey
    }
  },
  methods: {
    copy () {
      if (this.keyHidden) return
      const _this = this
      this.$copyText(this.apiKey, undefined, (error) => {
        if (!error) {
          selectText(_this.$refs.apiKey)
          _this.$store.commit('alert/add', {
            id: Date.now(),
            text: _this.$t('text_618d5a996329050141a2473d'),
            type: 'success',
            timeout: 1500,
            name: 'copy'
          })
        }
      })
    }
  }
}
</script>
