<template>
  <div v-if="forceEmailConfirmationModal">
    <div class="modal">
      <div class="modal__container">
        <div class="title">{{ $t('verify_email_popup_title') }}</div>
        <div class="body">
          <component :is="{ template: modalText }" />
        </div>
        <div>
          <ButtonElement @click="sendAgain" :loading="loading" class="modal__button" height="58px">
            {{ $t('send_again_cta_button') }}
          </ButtonElement>
          <ButtonElement @click="changeEmail" :loading="loading" class="modal__button mt-16" height="58px">
            {{ $t('burger_item_change_email') }}
          </ButtonElement>
          <ButtonElement @click="signOut" :loading="loading" class="modal__button mt-16" fill height="58px">
            {{ $t('burger_item_logout') }}
          </ButtonElement>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth'
import { useTranslate } from '@/use/translate'
import { useAuth } from '@/use/auth'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  components: {
    ButtonElement
  },
  computed: {
    loading () {
      return this.$store.state.common.btnLoading
    },
    forceEmailConfirmationModal () {
      return this.$store.state.modal.forceEmailConfirmationModal
    },
    modalText () {
      const auth = getAuth()
      const email = auth.currentUser && auth.currentUser.email ? auth.currentUser.email : ''
      const textWithBrs = this.addBreakLinesToText(this.$t('verify_email_popup_subtitle'))
      return textWithBrs.replace('__0__', `<strong>${email}</strong>`)
    }
  },
  setup () {
    const { addBreakLinesToText } = useTranslate()
    const { logout, resendEmailConfirmation } = useAuth()
    return { addBreakLinesToText, logout, resendEmailConfirmation }
  },
  methods: {
    sendAgain () {
      this.resendEmailConfirmation()
    },
    async changeEmail () {
      await this.$router.push({ name: 'ChangeEmail' })
    },
    signOut () {
      this.logout()
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal{
    &__container{
      width: 311px;

      .mt-16{
        margin-top: 16px !important;
      }
    }
    @media (max-width: 320px) {
      &__container {
        width: 270px;
      }
    }
  }
</style>
