<template>
  <div @click="hideAccountNav" class="side-nav__close">
    <img src="@/assets/icons/go-back.svg" alt="close">
  </div>

  <nav class="menu account-menu">
    <div class="menu__header">{{ $t('text_6220f0c84a419000bcd72e7b') }}</div>
    <ul v-if="user" class="user-info">
      <li @click="copy(user.uid, 'uid')" :title="user.uid" class="user-info__li">
        <span class="user-info__bold">{{ $t('text_6220f0c84a419000bcd72e7f').replace('__0__', '') }}</span>
        <span ref="uid">{{ user.uid }}</span>
      </li>
      <li v-if="user.email" @click="copy(user.email, 'email')" :title="user.email" class="user-info__li">
        <span class="user-info__bold">{{ $t('text_6220f0c84a419000bcd72eb7').replace('__0__', '') }}</span>
        <span ref="email">{{ user.email }}</span>
      </li>
    </ul>
    <ul>
      <li v-if="this.$store.state.auth.authPasswordProvider && user && !user.emailVerified" @click="resend">
        <span class="menu__link">
          <v-lottie-player
            v-if="loading"
            :animationData="require('@/assets/loaders/iphone-orange-spinner.json')"
            loop
            width="24px"
            height="25px"
          />
          <img v-else src="@/assets/icons/resend-conf-email.svg" alt="resend confirmation">
          <p>{{ $t('text_62320149a2b12000ff2f4d85') }}</p>
        </span>
      </li>
      <li v-if="this.$store.state.auth.authPasswordProvider" @click="$emit('handleClickLink')">
        <router-link :to="{ name: 'ChangeEmail' }" class="menu__link">
          <img src="@/assets/icons/email.svg" alt="email">
          <p>{{ $t('text_6220f0c84a419000bcd72e83') }}</p>
        </router-link>
      </li>
      <li v-if="this.$store.state.auth.authPasswordProvider" @click="$emit('handleClickLink')">
        <router-link :to="{ name: 'ChangePassword' }" class="menu__link">
          <img src="@/assets/icons/password.svg" alt="password">
          <p>{{ $t('text_6220f0c84a419000bcd72e87') }}</p>
        </router-link>
      </li>
      <li @click="showQuestionModal = true">
        <div class="menu__link delete-account">
          <img src="@/assets/icons/delete-account.svg" alt="delete account">
          <p>{{ $t('text_6220f0c84a419000bcd72e8b') }}</p>
        </div>
      </li>
    </ul>
  </nav>

  <div class="side-nav__in-out">
    <router-link v-if="this.$store.state.auth.isAuthenticated" to="" @click.prevent="signOut">
      <img src="@/assets/icons/sign_out.svg" alt="logout" class="logoutBtn">
      <span>{{ $t('text_6220f0c84a419000bcd72e93') }}</span>
    </router-link>
  </div>

  <QuestionModal v-if="showQuestionModal" @decline="showQuestionModal = false">
    <template v-slot:title>{{ $t('text_6220e5f0015e37011ef89687') }}</template>
    <template v-slot:text>{{ $t('text_6220e5f0015e37011ef8968f') }}</template>
    <template v-slot:acceptBtn>
      <ButtonElement @click="deleteUser" class="modal__button modal__button-accept" fill height="58px">
        {{ $t('text_6220e5f0015e37011ef8968b') }}
      </ButtonElement>
    </template>
    <template v-slot:declineBtn>{{ $t('text_6220e5f0015e37011ef8968d') }}</template>
  </QuestionModal>
</template>

<script>
import { getAuth } from 'firebase/auth'
import { useAuth } from '@/use/auth'
import { useSmsApi } from '@/use/smsApi'
import { useErrorHandlers } from '@/use/errorHandlers'
import selectText from '@/helpers/select-text.js'
import QuestionModal from '@/components/Modals/QuestionModal'
import ButtonElement from '@/components/ButtonElement.vue'

export default {
  emits: ['hideAccountNav', 'handleClickLink'],
  components: { QuestionModal, ButtonElement },
  data () {
    return {
      user: null,
      showQuestionModal: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.common.btnLoading
    }
  },
  setup () {
    const { initApiInst, smsUserApi } = useSmsApi()
    const { logout, resendEmailConfirmation } = useAuth()
    const { handleNotAuthorizedError, handleNetworkError, showCommonError } = useErrorHandlers()
    return { initApiInst, smsUserApi, logout, resendEmailConfirmation, handleNotAuthorizedError, handleNetworkError, showCommonError }
  },
  mounted () {
    const auth = getAuth()
    this.user = auth.currentUser
  },
  methods: {
    hideAccountNav () {
      this.$emit('hideAccountNav')
    },
    signOut () {
      this.$emit('handleClickLink')
      this.logout()
    },
    async resend () {
      if (this.loading) return
      await this.resendEmailConfirmation()
      this.$emit('handleClickLink')
    },
    async deleteUser () {
      this.showQuestionModal = false
      try {
        await this.initApiInst()
        const resp = await this.smsUserApi.deleteAccount({ prxPrint: this.$store.state.common.prxPrint })
        if (resp && resp.result === 'success') {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: this.$t('text_6220f3b06553c900d75b4cef'),
            type: 'success',
            timeout: 3500,
            zIndex: 24
          })
          this.logout()
        }
      } catch (e) {
        if (e.status) {
          switch (e.status) {
            case 401:
              await this.handleNotAuthorizedError()
              break
            case 502:
              this.handleNetworkError()
              break
            default:
              this.showCommonError(e)
          }
        } else {
          if (e.error && e.error.message && e.error.message.includes('Request has been terminated')) {
            this.handleNetworkError()
          } else {
            this.showCommonError(e)
          }
        }
      } finally {
        this.$emit('handleClickLink')
      }
    },
    copy (data, ref) {
      const _this = this
      this.$copyText(data, undefined, (error) => {
        if (!error) {
          selectText(_this.$refs[ref])
          _this.$store.commit('alert/add', {
            id: Date.now(),
            text: _this.$t('text_618d5a996329050141a2473d'),
            type: 'success',
            timeout: 1500,
            name: 'copy',
            zIndex: 24
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .side-nav__close{
    padding-top: 15px !important;
  }
  .account-menu{
    flex: 1;
  }
  .user-info{
    margin-bottom: 30px;
    &__li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 19px;
      color: #14142B;
      margin-bottom: 16px;
      cursor: pointer;
    }
    &__bold {
      display: inline-block;
      font-weight: bold;
      margin-right: 5px;
    }
  }
  .delete-account{
    p {
      color: #FF3D00 !important;
    }
  }
  .modal {
    &__button-accept {
      background: #FF3D00;
      color: #fff;
    }
  }

  .rtl {
    .logoutBtn {
      transform: rotate(180deg);
    }

    .user-info__bold {
      margin-right: 0;
      margin-left: 5px;
    }
  }
</style>
