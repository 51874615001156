<template>
  <form @submit.prevent="login" class="form">
    <EmailControl
      :errorMessage="this.v$.email.$errors?.[0]?.$validator"
      v-model.trim="email"
      :placeholder="$t('text_6167c4a5321c670152f0e20c')"
      class="form__control"
    />
    <PasswordControl
      :errorMessage="this.v$.password.$errors?.[0]?.$validator"
      v-model.trim="password"
      :placeholder="$t('text_6167c4a5321c670152f0e20e')"
      class="form__control"
    />

    <div class="forgotPassword">
      <router-link :to="{ name: 'ResetPassword' }">{{ $t('text_6167c4a5321c670152f0e20f') }}</router-link>
    </div>

    <ButtonElement :loading="loading" class="form__send" width="100%" height="58px" fill>{{ $t('text_6167c4a5321c670152f0e210') }}</ButtonElement>

    <div class="form__help-link form__help-link-bold">
      <component :is="{ template: signUpText }"></component>
    </div>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useTranslate } from '@/use/translate'
import { useTapfiliate } from '@/use/tapfiliate'
import { useUser } from '@/use/user'
import { required, email, minLength } from '@vuelidate/validators'

import ButtonElement from '@/components/ButtonElement'
import EmailControl from '@/components/Controls/EmailControl.vue'
import PasswordControl from '@/components/Controls/PasswordControl.vue'
import NotificationModal from '@/components/Modals/NotificationModal.vue'
import {
  getAuth,
  signInWithEmailAndPassword
} from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
  components: {
    ButtonElement,
    EmailControl,
    PasswordControl,
    NotificationModal
  },
  data () {
    return {
      email: '',
      password: '',
      loading: false
    }
  },
  setup () {
    const { addBreakLinesToText, addLinksToText } = useTranslate()
    const { updateTapfiliateTrackId } = useTapfiliate()
    const { getUserProject } = useUser()
    return { v$: useVuelidate(), addBreakLinesToText, addLinksToText, updateTapfiliateTrackId, getUserProject }
  },
  computed: {
    signUpText () {
      const textWithBrs = this.addBreakLinesToText(this.$t('text_6167c4a5321c670152f0e211'))
      return this.addLinksToText(textWithBrs, ['SignUp'])
    },
    authCredentials () {
      return this.$store.state.auth.authCredentials
    }
  },
  mounted () {
    if (this.authCredentials && this.authCredentials.email) this.email = this.authCredentials.email
  },
  validations () {
    return {
      email: {
        email,
        required
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    async login () {
      const isValid = await this.v$.$validate()
      const auth = getAuth()

      if (isValid) {
        this.loading = true
        try {
          const result = await signInWithEmailAndPassword(auth, this.email, this.password)
          if (result && result.user) {
            await this.updateTapfiliateTrackId()
            const analytics = getAnalytics()
            logEvent(analytics, 'login', {
              uid: auth.currentUser?.uid ?? ''
            })
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: this.$t('text_6231a5d0ff911700d6c42070'),
              type: 'success',
              timeout: 2000,
              name: 'success_login'
            })
            await this.getUserProject(result.user.uid)
            await this.$router.push({ name: 'Home' })
          }
        } catch (e) {
          if (e.code === 'auth/user-not-found' || e.code === 'auth/invalid-login-credentials') {
            this.$store.commit('alert/add', {
              id: Date.now(),
              text: this.$t('no_match_email_psw_validation'),
              timeout: 3000,
              name: 'no_match_email_psw_validation'
            })
          } else {
            if (e.code === 'auth/too-many-requests') {
              this.$store.commit('notificationModal/show', {
                title: this.$t('login_requests_error_title'),
                text: this.addBreakLinesToText(this.$t('login_requests_error_subtitle')),
                buttonText: this.$t('text_616e4d4b66577e00e3d6ba11')
              })
            } else {
              this.$store.commit('notificationModal/showSomethingWrongModal')
            }
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .forgotPassword{
    text-align: right;
    margin: 18px 0 16px;

    a{
      display: inline-block;
      font-size: 13px;
      line-height: 18px;
      text-align: right;
      letter-spacing: -0.2px;
      color: #848194;
      opacity: 0.8;
    }
  }
</style>
