<template>
  <div>
    <div class="nps-comment">
      <div class="icon" />
      <textarea v-model="message" :placeholder="$t('text_640ebc83d294120056cf31e6')" rows="4" />
    </div>
    <ButtonElement @click.prevent="$emit('saveComment', message)" class="form__send" width="100%" height="58px" fill>
      {{ $t('text_640ebc83d294120056cf31e8') }}
    </ButtonElement>
  </div>
</template>

<script>
import ButtonElement from '@/components/ButtonElement'

export default {
  components: { ButtonElement },
  emits: ['saveComment'],
  data () {
    return {
      message: ''
    }
  }
}
</script>

<style lang="scss">
  .nps-comment {
    position: relative;

    textarea {
      width: 100% !important;
      border: 1px solid #EFEFEF;
      border-radius: 15px;
      padding: 20px 20px 20px 40px;
      font-size: 14px;
      color: #848194;
      background: #FFF;
      outline: none;
      resize: none;
      margin: 24px 0 16px;
    }

    .icon {
      position: absolute;
      left: 16px;
      top: 45px;
      width: 16px;
      height: 16px;
      background-size: cover !important;
      background: url('../../assets/form/comment_outlined.svg');
    }
  }

  .rtl{
    .nps-comment {
      textarea{
        padding: 20px 40px 20px 20px;
      }
      .icon {
        right: 16px;
        left: auto;
      }
    }
  }
</style>
