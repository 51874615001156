<template>
  <form ref="payeerForm" method="post" action="https://payeer.com/merchant/">
    <template v-if="payeerFormData">
      <input type="hidden" name="m_shop" :value="payeerFormData.m_shop">
      <input type="hidden" name="m_orderid" :value="payeerFormData.m_orderid">
      <input type="hidden" name="m_amount" :value="payeerFormData.m_amount">
      <input type="hidden" name="m_curr" :value="payeerFormData.m_curr">
      <input type="hidden" name="m_desc" :value="payeerFormData.m_desc">
      <input type="hidden" name="m_sign" :value="payeerFormData.m_sign">
      <input type="hidden" name="m_params" :value="payeerFormData.m_params">
      <input type="hidden" name="m_cipher_method" :value="payeerFormData.m_cipher_method">
      <input type="submit" name="m_process" value="send" class="d-none" />
    </template>
  </form>
</template>

<script>
import { usePayeer } from '@/use/payments/payeer'

export default {
  props: {
    payeerPay: Boolean,
    amount: Number,
    uid: String,
    os: String,
    afId: String
  },
  data () {
    return {
      payeerFormData: null
    }
  },
  watch: {
    payeerPay (val) {
      if (val) {
        this.addPayeerFunds()
      }
    }
  },
  setup () {
    const { getPayeerFormData } = usePayeer()
    return { getPayeerFormData }
  },
  methods: {
    async addPayeerFunds () {
      this.payeerFormData = await this.getPayeerFormData(this.amount, this.uid, this.os, this.afId)
      if (this.payeerFormData && this.payeerFormData.m_orderid) {
        this.$nextTick(() => {
          this.$refs.payeerForm.submit()
        })
      }
    }
  }
}
</script>
